import Notify from 'quasar/src/plugins/notify/Notify.js';;
import Loading from 'quasar/src/plugins/loading/Loading.js';;
import ptBR from "quasar/lang/pt-BR";

import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";

// To be used on app.use(Quasar, { ... })
export default {
  config  : {},
  plugins : { Notify, Loading },
  lang    : ptBR
};
