import { financialInstitutionInterface } from '@/types/types.d';

export class FinancialInstitutionModel implements financialInstitutionInterface {
	codif							: string | undefined;
	nome							: string | undefined;
	codagrpacionista	: number | undefined | null;
	nomagrpacionista	: string | undefined | null;
	slot1              : boolean | undefined | null;
	slot2              : boolean | undefined | null;
	slot3              : boolean | undefined | null;

	constructor(
		newPC: {
			codif?				: string,
			nome?				: string,
			codagrpacionista?	: number | null,
			nomagrpacionista?	: string | null,
			slot1?              : boolean | null,
			slot2?              : boolean | null,
			slot3?              : boolean | null
		} ) {
		this.codif						= newPC.codif;
		this.nome							= newPC.nome;
		this.codagrpacionista	= newPC.codagrpacionista;
		this.nomagrpacionista	= newPC.nomagrpacionista;
		this.slot1 = newPC.slot1;
		this.slot2 = newPC.slot2;
		this.slot3 = newPC.slot3;
	}
}
