<template>
  <q-btn
    id="btn-close-modal"
    icon="close"
    size="12px"
    flat
    @click="onClearFilterBtnClick"
    round
    dense
    v-close-popup
  />
</template>

<script setup lang="ts">
import { useCampaignStore } from "@/stores/campaignStore";
import { usePCStore } from "@/stores/pcStore";
import { useFinancialInstitutionStore } from "@/stores/financialInstitutionStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";

const campaignStore = useCampaignStore();
const pcStore = usePCStore();
const financialInstitutionStore = useFinancialInstitutionStore();
const pagesUtilStore = usePagesUtilStore();

const onClearFilterBtnClick = () => {
  financialInstitutionStore.resetFilterState();
  financialInstitutionStore.resetState();
  pcStore.resetFilterState();
  campaignStore.resetCampaignFilter();

  pagesUtilStore.showModalConfirmationDialogIfs = false;
  pagesUtilStore.showModalConfirmationDialogPcs = false;
  pagesUtilStore.showModalConfirmationEditCampaign = false;
  pagesUtilStore.showModalConfirmationCancelCampaign = false;
  pagesUtilStore.showModalConfirmationDialogSlots = false;
};
</script>

<style scoped></style>
