import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { EnumButtonSizes }	from '@/enums/enums';
import { useRouter }        from 'vue-router';
import { useCampaignStore }		from '@/stores/campaignStore';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';



export default /*@__PURE__*/_defineComponent({
  __name: 'BtnCancelCreateNewCampaign',
  setup(__props) {

const router = useRouter();
const campaignStore		= useCampaignStore();

const backToPreviousPage = () => {
	router.push({ name: "campaign"});
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_unref(campaignStore).focusedCampaign.status != _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED.id 
		|| _unref(campaignStore).focusedCampaign.status != _unref(CONST_CAMPAIGN_STATUS).APPROVED.id)
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-cancel-create-new-campaign",
        propBtnLabel: "Cancelar",
        propBtnFunction: backToPreviousPage,
        propBtnTransparent: true,
        propBtnSize: _unref(EnumButtonSizes).LARGE_1
      }, null, 8, ["propBtnSize"]))
    : _createCommentVNode("", true)
}
}

})