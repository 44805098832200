import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { CONST_CAMPAIGN_STATUS } from "@/static/constants";
import { EnumRouteNames } from "@/enums/enums";

import { EnumButtonSizes } from "@/enums/enums";
import { useCampaignStore } from "@/stores/campaignStore";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";

import moment from "moment";


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnViewCampaignDistribution',
  setup(__props) {

const campaignStore = useCampaignStore();
const authStore = useAuthStore();
const router = useRouter();

const diferancaEntreDatas = (data: string | undefined) => {
  const hoje = moment();

  data = campaignStore.focusedCampaign.startDate;

  const data1 = moment(data);

  const diferenca = data1.diff(hoje, "days");

  return diferenca >= 1;
};

const onViewCampaignDistributionBtnClick = async () => {
  router.push({ name: EnumRouteNames.CAMPAIGN_DISTRIBUTION });
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (
      _unref(authStore).currentUserCanViewCampaign &&
      _unref(campaignStore).focusedCampaign.status ==
        _unref(CONST_CAMPAIGN_STATUS).PUBLISHED.id 
      || _unref(campaignStore).focusedCampaign.status ==
        _unref(CONST_CAMPAIGN_STATUS).ACTIVE_CAMPAIGN.id
      || _unref(campaignStore).focusedCampaign.status ==
      _unref(CONST_CAMPAIGN_STATUS).PENDING_PUBLICATION.id
    )
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-view-focused-campaign-distribution",
        propBtnLabel: "Distribuição da Campanha",
        propBtnFunction: onViewCampaignDistributionBtnClick,
        propBtnTransparent: false,
        propBtnSize: _unref(EnumButtonSizes).LARGE_1
      }, null, 8, ["propBtnSize"]))
    : _createCommentVNode("", true)
}
}

})