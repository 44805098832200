<template>
	<c-q-btn
		v-if="authStore.currentUserCanCancelCampaign 
		&& (campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id 
        || campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_REISSUE.id 
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.REAPPROVED.id)"
		id="btn-discard-changes"
		propBtnLabel="Descartar Alterações"
		:propBtnFunction="onDiscardChangesBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore } 	from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages								from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';
import moment from 'moment';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const onDiscardChangesBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.discardChanges();

		messages.showSuccessMsg("Alterações descartadas com sucesso!");

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar descartar alterações...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
