import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineDocumentDownload.svg'


const _hoisted_1 = {
  class: "row fit no-wrap items-center q-pb-xs",
  style: {"margin-left":"-4px"}
}
const _hoisted_2 = { class: "midia-app-label-lg" }
const _hoisted_3 = { class: "row q-py-xs" }

import { EnumButtonSizes } from "@/enums/enums";
import { useCampaignStore } from "@/stores/campaignStore";
import { exportAsCsvFile } from "@/utils/commonUtils";

import messages from "@/utils/messages";

import { DEFAULT_DISTRIBUTION_EXPORT_FILENAME } from "@/static/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignDistributionSection',
  setup(__props) {

const campaignStore = useCampaignStore();

const onDownloadDevicesBtnClick = () => {
  const now = new Date();
  const filename = `${DEFAULT_DISTRIBUTION_EXPORT_FILENAME}_${now.getFullYear()}-${now.getMonth()}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;

  if (!campaignStore?.campaignDistribtion?.pcsCampaign?.length) {
    messages.showErrorMsg("Não há PCs na lista de distribuição");
    return;
  }

  const pcsToExport = campaignStore.campaignDistribtion.pcsCampaign.map(
    (pc) => ({
      CODIGO_PC: pc.pc,
      NOME_PC: pc.nome,
      STATUS: pc.status,
      MOTIVO: pc.motivo,
    })
  );

  exportAsCsvFile(pcsToExport, filename);
};

const onDownloadDevicesGreenBtnClick = () => {
  onDownloadDevicesBtnClick();
};

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row q-pb-xs" }, [
      _createElementVNode("span", { class: "midia-app-label-sm" }, "Lista de Distribuição")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_c_q_btn, {
        id: "btn-icon-download-devices",
        padding: "none",
        propBtnLabel: "",
        propBtnFunction: onDownloadDevicesGreenBtnClick,
        propBtnTransparent: true,
        propBtnSize: _unref(EnumButtonSizes).SMALL,
        propBorderless: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_icon, { size: "32px" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "green_arrow_down_icon"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["propBtnSize"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(DEFAULT_DISTRIBUTION_EXPORT_FILENAME)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_c_q_btn, {
        id: "btn-download-devices",
        propBtnLabel: "Download",
        propBtnFunction: onDownloadDevicesBtnClick,
        propBtnTransparent: true,
        propBtnSize: _unref(EnumButtonSizes).SMALL
      }, null, 8, ["propBtnSize"])
    ])
  ]))
}
}

})