import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { EnumButtonSizes }	from '@/enums/enums';
  import { useRouter }        from 'vue-router';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'BtnBackEdit',
  setup(__props) {

  const router = useRouter();
  
  const backToPreviousPage = () => {
              router.back();
  };
  
return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createBlock(_component_c_q_btn, {
    id: "back-button",
    propBtnLabel: "Voltar",
    propBtnFunction: backToPreviousPage,
    propBtnTransparent: true,
    propBtnSize: _unref(EnumButtonSizes).LARGE_1
  }, null, 8, ["propBtnSize"]))
}
}

})