import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { EnumMultipleSingleMode, EnumButtonSizes }	from '@/enums/enums';
import { usePagesUtilStore }												from '@/stores/pagesUtilStore';
import { useCampaignStore }													from '@/stores/campaignStore';
import { computed }																	from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnDeleteFocusedCampaign',
  props: {
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: true,
		default		: EnumButtonSizes.LARGE_1
	}
},
  setup(__props) {

const pagesUtilStore	= usePagesUtilStore();
const campaignStore		= useCampaignStore();

const props = __props;

const onDeleteFocusedCampaignBtnClick = () => {
  pagesUtilStore.modalDeleteCampaignConfirmationMode = EnumMultipleSingleMode.SINGLE;
  pagesUtilStore.showModalDeleteCampaignConfirmation = true;
};

const disableDeleteFocusedCampaignButton = computed(() => {
	return !campaignStore.campaignCanBeDeleted(campaignStore.focusedCampaign);
});

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createBlock(_component_c_q_btn, {
    id: "btn-delete-focused-campaign",
    propBtnLabel: "Excluir",
    propBtnFunction: onDeleteFocusedCampaignBtnClick,
    propBtnTransparent: true,
    propBtnSize: props.propBtnSize,
    disable: disableDeleteFocusedCampaignButton.value
  }, null, 8, ["propBtnSize", "disable"]))
}
}

})