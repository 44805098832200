import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logos/logo_TecBan_MidiaFlow.svg'


const _hoisted_1 = {
  class: "flex items-center justify-center outline-red",
  style: {"min-height":"inherit","background-color":"var(--globalAppDefaultBackgroundColor)"}
}
const _hoisted_2 = { class: "row no-wrap items-center" }
const _hoisted_3 = {
  class: "col-6 col-md-6 justify-start q-pl-xl q-py-sm",
  style: {"border-left":"1px solid var(--globalAppSecondaryColor2)"}
}
const _hoisted_4 = {
  class: "column no-wrap items-start q-pl-sm",
  style: {"width":"400px"}
}
const _hoisted_5 = { class: "col-grow full-width q-pb-xs" }

import { EnumButtonSizes }  from '@/enums/enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageLogin',
  setup(__props) {

const simularLogin = () => {
  var url = `${process.env.VUE_APP_COGNITO_URL_TOKEN}/oauth2/authorize?client_id=${process.env.VUE_APP_APP_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${process.env.VUE_APP_AUTH_CALLBACK_URI}`
  // Use the Vue Router to navigate to the SAML login page
  window.location.href= url;
  // authStore.currentLoggedInUserEmail = userEmail.value;
}

const onLoginBtnClick = () => {
  simularLogin();
}

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-6 col-md-6 flex flex-grow items-center justify-end q-pr-xl" }, [
        _createElementVNode("img", {
          src: _imports_0,
          width: "410"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex full-width q-pb-lg" }, [
            _createElementVNode("span", { class: "midia-app-title-login-xl q-pb-xs" }, "Faça o login em sua conta")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_c_q_btn, {
              id: "btn-login",
              style: {"width":"100%"},
              propBtnLabel: "Entrar",
              propBtnFunction: onLoginBtnClick,
              propBtnTransparent: false,
              propBtnSize: _unref(EnumButtonSizes).LARGE_2
            }, null, 8, ["propBtnSize"])
          ])
        ])
      ])
    ])
  ]))
}
}

})