import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "div-page-caption-section",
  class: "q-pb-md"
}
const _hoisted_2 = { class: "page-caption-title" }
const _hoisted_3 = { class: "page-caption-description" }


export default /*@__PURE__*/_defineComponent({
  __name: 'PageCaption',
  props: {
  pageCaptionTitle: {
    type    : String,
    default : ''
  },

  pageCaptionDescription: {
    type    : String,
    default : ''
  }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(props.pageCaptionTitle), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(props.pageCaptionDescription), 1)
  ]))
}
}

})