<template>
	<div>
		<span class="midia-app-caption-sm">Agência</span>

		<q-select
			v-model="campaignStore.focusedCampaign.agency"
			maxlength="500"
			:options="options"
			@filter="filterFN"
			use-input
			outlined
			use-chips
			@new-value="createValue"
			dense
			color="globalAppPrimaryColor"
			bg-color="globalAppDefaultBackgroundColor"
			:label-slot="!campaignStore.focusedCampaign.agency"
			:readonly="campaignStore.focusedCampaign.iscampaignpublished"
		>
			<template v-slot:label>
				<span class="midia-app-placeholder-md">Selecione uma Agência ou digite e tecle Enter</span>
			</template>

			<template v-slot:selected>
				<span class="midia-app-content-lg">{{ campaignStore.focusedCampaign.agency }}</span>
			</template>
		</q-select>
	</div>
</template>

<script setup lang="ts">
import { useCampaignStore } from '@/stores/campaignStore';
import { Ref, ref }					from 'vue';

const campaignStore	= useCampaignStore();

const agencyOptions	= [
    'Nacional',
	'Propeg',
	'Ogilvy',
	'Calia',
	'De Brito',
	'Opus Multipla',
	'Mene e Portella',
	'Media Brands',
	'OCTOPUS',
	'Acessooh',
	'ID Publicidade',
	'Nova S/B',
	'Mestiça Propaganda',
	'Babel',
	'WMCCANN',
	'MEDIABRANDS PUBLICIDADE (BE180)',
	'David',
	'Dual Mídia',
	'MOMENTUM',
	'Casa Blanca'
];

const options = ref(agencyOptions);

const filterFN = (val: string, update: (callback: () => void) => void, abort: () => void) => {
	update(() => {
		const needle = val.toLocaleLowerCase();
		options.value = agencyOptions.filter((v: string) => v.toLocaleLowerCase().indexOf(needle) > -1);
	});
};

const createValue = ((
		val: string,
		done: ref<(
			inputValue: string,
			doneFn: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				item?: any,
				mode?: "add" | "add-unique" | "toggle" | undefined
			) => void
		) => void>
	) => {

	if (val.length > 0) {
		if (!agencyOptions.includes(val)) {
			agencyOptions.push(val)
		}
		done(val, 'toggle')
	}
});
</script>

<style scoped>

</style>