import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { EnumButtonSizes }		from '@/enums/enums';
import ModalConfirmationDialog		from '@/views/modals/ModalConfirmationDialog.vue';
import { useCampaignStore } 	from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages								from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnCancelFocusedCampaign',
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const diferancaEntreDatas  = ( data : string | undefined ) => {

const hoje = moment();

data = campaignStore.focusedCampaign.startDate;

const data1 = moment(data);

const diferenca = data1.diff(hoje, 'days');

return diferenca >= 1;

};

//NOVO
const abreDialogo = () => {
  pagesUtilStore.showModalConfirmationCancelCampaign = true;
}

const onCancelFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.cancelFocusedCampaign();

		messages.showSuccessMsg("Campanha cancelada com sucesso!");

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar cancelar a campanha...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(authStore).currentUserCanCancelCampaign 
		&& (_unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED.id 
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_EDITING.id 
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).APPROVED.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).PENDING_PUBLICATION.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ACTIVE_CAMPAIGN.id
		||
		(_unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).PUBLISHED.id
		)))
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 0,
          id: "btn-cancel-focused-campaign",
          propBtnLabel: "cancelar",
          propBtnFunction: abreDialogo,
          propBtnTransparent: true,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true),
    _createVNode(ModalConfirmationDialog, {
      propDialogTitle: "Atenção!",
      propConfirmationMsg: "Tem certeza de que deseja cancelar a campanha?",
      propDialogType: "cancelar",
      onConfirm: onCancelFocusedCampaignBtnClick
    })
  ], 64))
}
}

})