import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PageCaption	                      from '@/components/PageCaption.vue';
import CampaignIdentificationSectionView  from '@/components/campaign/CampaignIdentificationSectionView.vue';
import CampaignIdentificationSectionEdit  from '@/components/campaign/CampaignIdentificationSectionEdit.vue';
import CampaignFormatSectionEdit          from '@/components/campaign/CampaignFormatSectionEdit.vue';
import CampaignActionButtonsSection       from '@/components/campaign/CampaignActionButtonsSection.vue';
import { useCampaignStore }               from '@/stores/campaignStore';
import {onMounted}                           from 'vue'
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageCampaignEdit',
  setup(__props) {

const campaignStore = useCampaignStore();
const router = useRouter();

onMounted(() => {
      const from = sessionStorage.getItem('from');
      // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
      if (!from) {
        router.push({ name: 'home' });
      } else {
        sessionStorage.removeItem('from');
      }
    });

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('from', 'true'); // Mantém o histórico

  next(); // Continua a navegação normalmente
});



return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_q_separator, {
      class: "q-pb-lg",
      withBackButton: true
    }),
    _createVNode(PageCaption, {
      pageCaptionTitle: "Editar Campanha",
      pageCaptionDescription: ""
    }),
    _createVNode(CampaignIdentificationSectionView, {
      propCampaignToView: _unref(campaignStore).focusedCampaignSnapshot
    }, null, 8, ["propCampaignToView"]),
    _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-lg" }),
    _createVNode(CampaignIdentificationSectionEdit),
    _createVNode(CampaignFormatSectionEdit),
    _createVNode(_component_c_q_separator, { class: "q-my-md" }),
    _createVNode(CampaignActionButtonsSection, { propShowBtnReviewFocusedCampaign: true })
  ]))
}
}

})