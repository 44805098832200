import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';

import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';



export default /*@__PURE__*/_defineComponent({
  __name: 'BtnResendFocusedCampaignToApprove',
  setup(__props) {

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onResendFocusedCampaignToApproveBtnClick = async () => {
  const comentAux = campaignStore.focusedCampaign.commentadjustment;
  try {
    pagesUtilStore.showLoading = true;   
  
    campaignStore.focusedCampaign.commentadjustment = campaignStore.refRequestedAdjustments;
    
    try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }

   	await campaignStore.resendToApproveFocusedCampaign();

    campaignStore.refRequestedAdjustments = "";
		
		messages.showSuccessMsg("Campanha reenviada para aprovação com sucesso!");

  } catch (errorResendingCampaignToApprove) {
    messages.showErrorMsg(errorResendingCampaignToApprove.message);
    campaignStore.focusedCampaign.commentadjustment = comentAux;
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

const onSendFocusedCampaignToReapproveBtnClick = async () => {
  const comentAux = campaignStore.focusedCampaign.commentadjustment;
  try {
    pagesUtilStore.showLoading = true;   
  
    campaignStore.focusedCampaign.commentadjustment = campaignStore.refRequestedAdjustments;
    
    try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }

   	await campaignStore.sendToReapproveCampaign();

    campaignStore.refRequestedAdjustments = "";
		
		messages.showSuccessMsg("Campanha enviada para reaprovação com sucesso!");

  } catch (errorResendingCampaignToApprove) {
    messages.showErrorMsg(errorResendingCampaignToApprove.message);
    campaignStore.focusedCampaign.commentadjustment = comentAux;
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(authStore).currentUserCanSendCampaignToApprove
    && _unref(campaignStore).focusedCampaign.iscampanhaedit
    && (
        _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED.id
        || _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).DRAFT.id
        || _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_EDITING.id
    ))
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 0,
          id: "btn-send-campaign-to-approve",
          propBtnLabel: "Reenviar para Aprovação",
          propBtnFunction: onResendFocusedCampaignToApproveBtnClick,
          propBtnTransparent: false,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true),
    (_unref(authStore).currentUserCanSendCampaignToApprove
    && _unref(campaignStore).focusedCampaign.iscampanhaedit
        && _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_REISSUE.id)
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 1,
          id: "btn-send-campaign-to-reapprove",
          propBtnLabel: "Enviar para Reaprovação",
          propBtnFunction: onSendFocusedCampaignToReapproveBtnClick,
          propBtnTransparent: false,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})