import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/icon_filter.svg'
import _imports_1 from '@/assets/icons/icon_perfil_red.svg'
import _imports_2 from '@/assets/icons/icon_advertiser_HiOutlineSpeakerphone.svg'
import _imports_3 from '@/assets/icons/status/icon_status_approved_Vector.svg'
import _imports_4 from '@/assets/icons/status/icon_status_approving_HiClock.svg'
import _imports_5 from '@/assets/icons/status/icon_status_canceled_HiXCircle.svg'
import _imports_6 from '@/assets/icons/status/icon_status_requested_adjustments_HiExclamation.svg'
import _imports_7 from '@/assets/icons/status/icon_status_edit.svg'
import _imports_8 from '@/assets/icons/status/icon_status_rascunho.svg'
import _imports_9 from '@/assets/icons/status/icon_status_published.svg'
import _imports_10 from '@/assets/icons/status/icon_status_completed.svg'
import _imports_11 from '@/assets/icons/status/icon_status_active.svg'
import _imports_12 from '@/assets/icons/status/icon_status_pending_publication.svg'
import _imports_13 from '@/assets/icons/icon_IoEllipsisVerticalCircleOutline.svg'


const _hoisted_1 = {
  id: "div-pesquisa-campanha",
  class: "row q-pb-lg"
}
const _hoisted_2 = { class: "row fit no-wrap justify-between items-end content-start" }
const _hoisted_3 = {
  class: "col-grow",
  style: {"overflow":"auto"}
}
const _hoisted_4 = { style: {"position":"relative"} }
const _hoisted_5 = {
  class: "q-pl-md",
  style: {"overflow":"auto"}
}
const _hoisted_6 = {
  id: "div-table-campanha",
  class: "row justify-center q-gutter-sm"
}
const _hoisted_7 = { style: {"width":"100%"} }
const _hoisted_8 = { class: "midia-app-title-header-table-lg" }
const _hoisted_9 = { class: "row items-center" }
const _hoisted_10 = { class: "midia-app-label-lg" }
const _hoisted_11 = { class: "row items-center no-wrap" }
const _hoisted_12 = { class: "midia-app-label-lg q-pl-md" }
const _hoisted_13 = { class: "row items-center" }
const _hoisted_14 = { class: "midia-app-label-lg" }
const _hoisted_15 = { class: "row items-center" }
const _hoisted_16 = { class: "midia-app-label-lg" }
const _hoisted_17 = { class: "row items-center" }
const _hoisted_18 = { class: "midia-app-label-lg" }
const _hoisted_19 = { class: "row items-center" }
const _hoisted_20 = { class: "midia-app-label-lg" }
const _hoisted_21 = { class: "row items-center" }
const _hoisted_22 = { class: "row items-center justify-center" }
const _hoisted_23 = {
  key: 0,
  class: "svg-icon",
  src: _imports_3
}
const _hoisted_24 = {
  key: 1,
  class: "svg-icon",
  src: _imports_4
}
const _hoisted_25 = {
  key: 2,
  class: "svg-icon",
  src: _imports_5
}
const _hoisted_26 = {
  key: 3,
  class: "svg-icon",
  src: _imports_6
}
const _hoisted_27 = {
  key: 4,
  class: "svg-icon",
  src: _imports_7
}
const _hoisted_28 = {
  key: 5,
  class: "svg-icon",
  src: _imports_8
}
const _hoisted_29 = {
  key: 6,
  class: "svg-icon",
  src: _imports_9
}
const _hoisted_30 = {
  key: 7,
  class: "svg-icon",
  src: _imports_7
}
const _hoisted_31 = {
  key: 8,
  class: "svg-icon",
  src: _imports_4
}
const _hoisted_32 = {
  key: 9,
  class: "svg-icon",
  src: _imports_6
}
const _hoisted_33 = {
  key: 10,
  class: "svg-icon",
  src: _imports_3
}
const _hoisted_34 = {
  key: 11,
  class: "svg-icon",
  src: _imports_10
}
const _hoisted_35 = {
  key: 12,
  class: "svg-icon",
  src: _imports_11
}
const _hoisted_36 = {
  key: 13,
  class: "svg-icon",
  src: _imports_12
}
const _hoisted_37 = { class: "div-container-action-icons" }
const _hoisted_38 = { style: {"display":"flex","flex-direction":"column","gap":"10px"} }

import CampaignActionButtonsSection           from '@/components/campaign/CampaignActionButtonsSection.vue';
import ModalPageCampaignsTableActions         from '@/views/modals/ModalPageCampaignsTableActions.vue';
import ModalDeleteCampaignConfirmation        from '@/views/modals/ModalDeleteCampaignConfirmation.vue';
import ModalFilterCampaign                    from '@/views/modals/ModalFilterCampaign.vue';
import { ref, computed, onMounted }           from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { campaignInterface }                  from '@/types/types.d';
import { CampaignModel }                      from '@/models/CampaignModel';
import { quasarCustomQTableColumns }          from '@/types/quasarCustomQTableColumn';
import { useCampaignStore }                   from '@/stores/campaignStore';
import { usePagesUtilStore }                  from '@/stores/pagesUtilStore';
import { useRouter }                          from 'vue-router';
import { EnumRouteNames, EnumButtonSizes }    from '@/enums/enums';
import { CONST_CAMPAIGN_STATUS }              from '@/static/constants';
import { useAuthStore }					from '@/stores/authStore'
import messages                               from '@/utils/messages';
import BtnViewFocusedCampaign		from '@/components/campaign/actionButtons/BtnViewFocusedCampaign.vue';
//import BtnEditFocusedCampaign		from '@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue';
import BtnDeleteFocusedCampaign	from '@/components/campaign/actionButtons/BtnDeleteFocusedCampaign.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageCampaignSearch',
  props: {
  propShowBtnViewFocusedCampaign: {
    type      : Boolean,
    required  : true
  },

 /* propShowBtnEditFocusedCampaign: {
    type      : Boolean,
    required  : true
  },*/

  propShowBtnDeleteFocusedCampaign: {
    type      : Boolean,
    required  : true
  }
},
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const $q              = useQuasar();
const router          = useRouter();

const filterTerms     = ref('');

const props = __props;

const qTableCampanhasColumns:quasarCustomQTableColumns[] = [
  {
    name        : 'id',
    label       : 'ID',
    field       : 'id',
    align       : 'center'
  },

  {
    name        : 'advertiser',
    label       : 'Anunciante',
    field       : 'advertiser',
    align       : 'left'
  },

  {
    name        : 'name',
    label       : 'Campanha',
    field       : 'name',
    align       : 'left',
    style       : 'width: auto;',
    headerStyle : 'width: auto;'
  },

  {
    name        : 'format',
    label       : 'Formato',
    field       : 'format',
    align       : 'left'
  },

  {
    name        : 'startDate',
    label       : 'Início',
    field       : 'startDate',
    align       : 'left',
    sortOrder   : 'da'
  },

  {
    name        : 'endDate',
    label       : 'Fim',
    field       : 'endDate',
    align       : 'left'
  },
  {
    name        : 'status',
    label       : 'Status',
    field       : 'status',
    align       : 'center',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format      : (val, row) => {
      switch (val) {
        case CONST_CAMPAIGN_STATUS.APPROVED.id:
          return "status-approved";
          
        case CONST_CAMPAIGN_STATUS.ON_APPROVAL.id:
          return "status-approving";
          
        case CONST_CAMPAIGN_STATUS.CANCELED.id:
          return "status-canceled";
          
        case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id:
          return "status-adjustments-requested";

        case CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.id:
          return "status-adjustmentsues-requested";

        case CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.id:
          return "status-approved";

          //FALTA
        case CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.id:
          return "";

        case CONST_CAMPAIGN_STATUS.ON_REISSUE.id:
          return "status-on-editing";

        case CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id:
          return "status-approving";

        case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id:
          return "status-adjustments-requested";

        case CONST_CAMPAIGN_STATUS.REAPPROVED.id:
          return "status-approved";
          
        default:
          return "";
      }
    },
  },

  {
    name        : 'statusIcon',
    label       : '',
    field       : 'statusIcon',
    align       : 'center'
  },

  {
    name        : 'acoes',
    label       : 'Ações',
    field       : 'acoes',
    align       : 'center'
  }
];

const qTableCampanhasVisibleColumns = [
  'advertiser',
  'name',
  'format',
  'startDate',
  'endDate',
  
  'status',
  'statusIcon',
  'acoes'
];

const onFilterBtnClick = () => {
  pagesUtilStore.showModalFilterCampaign = true;
};

const isFilterActive = computed(() => {
  return Boolean(
    campaignStore.campaignFilter.filterCampaign.advertiser       
    || campaignStore.campaignFilter.filterCampaign.agency           
    || campaignStore.campaignFilter.filterCampaign.name             
    || campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy
    || campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy  
    || campaignStore.campaignFilter.filterCampaign.format           
    || campaignStore.campaignFilter.filterCampaign.status
    || campaignStore.campaignFilter.filterCampaignFormatATMScreen.screen
    || (campaignStore.campaignFilter.filterCampaignFormatATMScreen.pcs?.length > 0)
    || (campaignStore.campaignFilter.filterCampaignFormatATMScreen.institutions?.length > 0)
  ).valueOf();
});

const qTableCampanhasPagination = ref({
  sortBy      : null,
  descending  : true,
  page        : 1,
  rowsPerPage : 5,
  /* modelValue  : 1, */
  max         : 100
});

const onRequest = async () => {
  console.log('onRequest');
};

const onActionsBtnClick = (row: campaignInterface) => {
  campaignStore.focusedCampaign          = new CampaignModel(row);
  campaignStore.focusedCampaignSnapshot  = Object.freeze(new CampaignModel(row));

  if (typeof campaignStore.focusedCampaign.id !== "undefined") {
    sessionStorage.setItem('sessionPersistentFocusedCampaignId', campaignStore.focusedCampaign.id.toString());
  }

  // pagesUtilStore.showModalPageCampaignsTableActions = true;
};

const onNewCampaignBtnClick = () => {
  router.push({ name: EnumRouteNames.CAMPAIGN_NEW });
  campaignStore.resetState();
};

const statusTextCssClass = ((statusId: number) => {
  switch (statusId) {
    case CONST_CAMPAIGN_STATUS.APPROVED.id:
      return "status-approved";
      
    case CONST_CAMPAIGN_STATUS.ON_APPROVAL.id:
      return "status-approving";
      
    case CONST_CAMPAIGN_STATUS.CANCELED.id:
      return "status-canceled";
      
    case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id:
      return "status-adjustments-requested";
    
    case CONST_CAMPAIGN_STATUS.ON_EDITING.id:
      return "status-on-editing";
    
    case CONST_CAMPAIGN_STATUS.DRAFT.id:
      return "status-draft";
    
    case CONST_CAMPAIGN_STATUS.PUBLISHED.id:
      return "status-published";

    case CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.id:
      return "status-adjustments-requested";

    case CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.id:
        return "status-approved";

    //FALTA
    case CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.id:
        return "";

    case CONST_CAMPAIGN_STATUS.ON_REISSUE.id:
        return "status-on-editing";

    case CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id:
        return "status-approving";

    case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id:
        return "status-adjustments-requested";

    case CONST_CAMPAIGN_STATUS.REAPPROVED.id:
        return "status-approved";
          
      
    default:
      return "";
  }
});

const fetchedCampaignsFiltered = computed(() => {
  if (filterTerms.value) {
    return campaignStore.campaigns.filter(c => (
      c.name?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || c.advertiser?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || c.format?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || c.startDateDdMmYyyy?.includes(filterTerms.value.toLowerCase())
      || c.endDateDdMmYyyy?.includes(filterTerms.value.toLowerCase())
      || c.statusDescription?.toLowerCase().includes(filterTerms.value.toLowerCase())
    ));

  } else {
    return campaignStore.campaigns;
  }
});

const showBtnViewFocusedCampaign = computed(() => {
  // TODO: montar logica...
  return true;
});

const showBtnEditFocusedCampaign = computed(() => {
  // TODO: montar logica...
  return true;
});

const showBtnDeleteFocusedCampaign = computed(() => {
  // TODO: montar logica...
  return true;
});

onMounted(async () => {
  campaignStore.resetState();

  try {
    pagesUtilStore.showLoading = true;
  
    await campaignStore.fetchCampaigns();

  } catch (errorFetchingCampaigns) {
    console.error(errorFetchingCampaigns);
    pagesUtilStore.showLoading = false;
    // if(errorFetchingCampaigns.response.status == 401){
    //   console.error("Sessão Expirada");
    //   pagesUtilStore.showLoading = false;
    // }else{
    //   messages.showErrorMsg("Erro ao tentar obter a lista de campanhas...");
    //   pagesUtilStore.showLoading = false;
    // }
  } finally {
    pagesUtilStore.showLoading = false;
  }

  onRequest();
});

return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_q_separator, { class: "q-pb-lg" }),
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "q-pb-xs" }, [
        _createElementVNode("span", { class: "midia-app-label-lg" }, "Pesquise uma Campanha")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_input, {
            id: "searchbar",
            style: {"position":"relative"},
            modelValue: filterTerms.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterTerms).value = $event)),
            color: "globalAppPrimaryColor",
            "bg-color": "globalAppDefaultBackgroundColor",
            outlined: "",
            dense: "",
            placeholder: "Digite ou selecione uma Campanha",
            "input-class": "midia-app-content-lg"
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "search",
                class: "q-pa-sm",
                style: {"background":"var(--globalAppSecondaryColor4)","padding":"6px","position":"relative","left":"-11px","border-right":"1px solid var(--globalAppSecondaryColor2)"}
              })
            ]),
            append: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_btn, {
                    id: "btn-show-filterbox",
                    unelevated: "",
                    color: "globalAppDefaultBackgroundColor",
                    "text-color": "globalAppDefaultTextColor",
                    outlined: "",
                    "no-caps": "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (onFilterBtnClick()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, { size: "16px" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createElementVNode("img", {
                            class: "svg-icon",
                            src: _imports_0
                          }, null, -1)
                        ])),
                        _: 1
                      }),
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "midia-app-label-lg q-pl-sm" }, "Filtrar", -1))
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_unref(authStore).currentUserCanReviewCampaign)
            ? (_openBlock(), _createBlock(_component_c_q_btn, {
                key: 0,
                id: "btn-new-campaign",
                propBtnLabel: "Nova Campanha",
                propBtnFunction: onNewCampaignBtnClick,
                propBtnTransparent: true,
                propBtnSize: _unref(EnumButtonSizes).LARGE_1
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    size: "16px",
                    class: "q-pr-sm"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("img", {
                        class: "svg-icon",
                        src: _imports_1
                      }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["propBtnSize"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "midia-app-label-sm" }, "Campanhas encontradas", -1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_q_table, {
          ref: "qTableCampanhas",
          rows: fetchedCampaignsFiltered.value,
          "row-key": "id",
          columns: qTableCampanhasColumns,
          "visible-columns": qTableCampanhasVisibleColumns,
          pagination: { rowsPerPage: 10 },
          "hide-pagination": (fetchedCampaignsFiltered.value.length <= 10),
          "virtual-scroll": "",
          "no-data-label": "Nenhum registro encontrado!",
          "rows-per-page-label": "Linhas por página:",
          selection: "multiple",
          selected: _unref(campaignStore).selectedCampaigns,
          "onUpdate:selected": _cache[2] || (_cache[2] = ($event: any) => ((_unref(campaignStore).selectedCampaigns) = $event)),
          dense: _unref($q).screen.lt.md,
          flat: "",
          separator: "horizontal",
          class: "midia-app-table",
          onRequest: onRequest
        }, {
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, {
                  style: {"background":"var(--globalAppSecondaryColor4)","text-align":"center","border":"0"},
                  "auto-width": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      modelValue: props.selected,
                      "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                      color: (props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2'),
                      "keep-color": (!props.selected)
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                  ]),
                  _: 2
                }, 1024),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.field,
                    props: props,
                    style: {"background":"var(--globalAppSecondaryColor4)","border":"0"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(col.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, {
              class: "table-body-rows",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "selected",
                  style: {"text-align":"center"},
                  "auto-width": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      modelValue: props.selected,
                      "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                      color: (props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2'),
                      "keep-color": (!props.selected)
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_q_td, {
                  key: "id",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(props.row.id), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "advertiser",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_q_icon, { size: "24px" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("img", {
                            class: "svg-icon",
                            src: _imports_2
                          }, null, -1)
                        ])),
                        _: 1
                      }),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(props.row.advertiser), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "name",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(props.row.name), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "format",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(props.row.format), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "startDate",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("span", _hoisted_18, _toDisplayString(props.row.startDateDdMmYyyy), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "endDate",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(props.row.endDateDdMmYyyy), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "status",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("span", {
                        class: _normalizeClass('midia-app-label-lg ' + statusTextCssClass(props.row.status))
                      }, _toDisplayString(props.row.statusDescription), 3)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "statusIcon",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_q_icon, { size: "24px" }, {
                        default: _withCtx(() => [
                          ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).APPROVED.id))
                            ? (_openBlock(), _createElementBlock("img", _hoisted_23))
                            : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ON_APPROVAL.id))
                              ? (_openBlock(), _createElementBlock("img", _hoisted_24))
                              : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).CANCELED.id))
                                ? (_openBlock(), _createElementBlock("img", _hoisted_25))
                                : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED.id))
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                                  : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ON_EDITING.id))
                                    ? (_openBlock(), _createElementBlock("img", _hoisted_27))
                                    : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).DRAFT.id))
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_28))
                                      : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).PUBLISHED.id))
                                        ? (_openBlock(), _createElementBlock("img", _hoisted_29))
                                        : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ON_REISSUE.id))
                                          ? (_openBlock(), _createElementBlock("img", _hoisted_30))
                                          : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ON_RE_APPROVAL.id))
                                            ? (_openBlock(), _createElementBlock("img", _hoisted_31))
                                            : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED_AGAIN.id))
                                              ? (_openBlock(), _createElementBlock("img", _hoisted_32))
                                              : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).REAPPROVED.id))
                                                ? (_openBlock(), _createElementBlock("img", _hoisted_33))
                                                : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).CAMPAIGN_COMPLETED.id))
                                                  ? (_openBlock(), _createElementBlock("img", _hoisted_34))
                                                  : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).ACTIVE_CAMPAIGN.id))
                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_35))
                                                    : ((props.row.status == _unref(CONST_CAMPAIGN_STATUS).PENDING_PUBLICATION.id))
                                                      ? (_openBlock(), _createElementBlock("img", _hoisted_36))
                                                      : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_btn, {
                          unelevated: "",
                          color: "white",
                          outlined: "",
                          "text-color": "black",
                          "no-caps": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              size: "24px",
                              onClick: ($event: any) => (onActionsBtnClick(props.row))
                            }, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createElementVNode("img", {
                                  class: "svg-icon",
                                  src: _imports_13
                                }, null, -1)
                              ])),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_q_popup_proxy, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_banner, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_card, { style: {"width":"127px","padding":"8px"} }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_38, [
                                          _createVNode(BtnViewFocusedCampaign, {
                                            style: {"width":"100%"},
                                            propBtnSize: _unref(EnumButtonSizes).SMALL
                                          }, null, 8, ["propBtnSize"]),
                                          _createVNode(BtnDeleteFocusedCampaign, {
                                            style: {"width":"100%"},
                                            propBtnSize: _unref(EnumButtonSizes).SMALL
                                          }, null, 8, ["propBtnSize"])
                                        ])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "hide-pagination", "selected", "dense"])
      ])
    ]),
    _createVNode(_component_c_q_separator, { class: "q-my-md" }),
    _createVNode(CampaignActionButtonsSection, { propShowBtnDeleteSelectedCampaigns: true }),
    _createVNode(ModalPageCampaignsTableActions, {
      propShowBtnViewFocusedCampaign: showBtnViewFocusedCampaign.value,
      propShowBtnDeleteFocusedCampaign: showBtnDeleteFocusedCampaign.value
    }, null, 8, ["propShowBtnViewFocusedCampaign", "propShowBtnDeleteFocusedCampaign"]),
    _createVNode(ModalDeleteCampaignConfirmation),
    _createVNode(ModalFilterCampaign)
  ]))
}
}

})