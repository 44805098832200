import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { useCampaignStore }         from '@/stores/campaignStore';
import CampaignFormatATMScreenView  from '@/components/campaign/campaignFormats/CampaignFormatATMScreenView.vue';
import { computed }                 from 'vue';
import { EnumCampaignFormats }      from '@/enums/enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignFormatSectionView',
  setup(__props) {

const campaignStore = useCampaignStore();

const showFormatATMScreen = computed(() => {

  return (campaignStore.focusedCampaign.format == EnumCampaignFormats.ATM_SCREEN);
});

return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!

  return (showFormatATMScreen.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-md" }),
        _createVNode(CampaignFormatATMScreenView)
      ]))
    : _createCommentVNode("", true)
}
}

})