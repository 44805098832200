import { CampaignDistributionPc, CampaignDistribution  } from '@/types/types.d';

export class CampaignDistributionModel implements CampaignDistribution {

	campaignId: number | undefined | null;
	pcsCampaign: CampaignDistributionPc[] | undefined | null;
	totalPcs: number | undefined | null;
	totalSucesso: number | undefined | null;
	totalPendentes: number | undefined | null;
	ultimaAtualizacao: string | undefined | null;
	totalPcPages: number | undefined | null;
	currentPcPage : number | undefined | null;

	constructor(
		newCampaignDistribution: {
            campaignId?: number | undefined | null,
            pcsCampaign?: CampaignDistributionPc[] | undefined | null,
            totalPcs?: number | undefined | null,
            totalSucesso?: number | undefined | null,
            totalPendentes?: number | undefined | null,
            ultimaAtualizacao?: string | undefined | null,
            totalPcPages?: number | undefined | null,
            currentPcPage?: number | undefined | null
		}) {
            this.campaignId = newCampaignDistribution.campaignId;
            this.pcsCampaign = newCampaignDistribution.pcsCampaign;
            this.totalPcs = newCampaignDistribution.totalPcs;
            this.totalSucesso = newCampaignDistribution.totalSucesso;
            this.totalPendentes = newCampaignDistribution.totalPendentes;
            this.ultimaAtualizacao = newCampaignDistribution.ultimaAtualizacao;
            this.totalPcPages = newCampaignDistribution.totalPcPages;
            this.currentPcPage = newCampaignDistribution.currentPcPage;
	}
}
