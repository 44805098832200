<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalExcludeIfs"
      :no-backdrop-dismiss="true"
    >
      <q-card class="midia-app-padding-modal" style=" width: 900px; max-width: 80vw; ">
        <div>
					<c-modal-title
						propModalTitle="Instituições"
						propModalDescription="Pesquise uma Instituição"
					/>

          <!-- <div class="row fit no-wrap justify-center items-center content-start q-pb-lg">
            <div class="col-grow" style=" overflow: auto; ">
              <q-input
                id="searchbar"
                style=" position: relative; "
                v-model="filterTerms"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
                placeholder="Pesquisar"
                input-class="midia-app-content-lg"
                @keyup.enter="onFetchBtnClick"
                autofocus
              >
                <template v-slot:prepend>
                  <q-icon
                    name="search"
                    class="q-pa-sm"
                    style=" background: var(--globalAppSecondaryColor4); padding: 6px; position: relative; left: -11px; border-right: 1px solid var(--globalAppSecondaryColor2); "
                  />
                </template>
              </q-input>
            </div>

            <div class="q-pl-md" style=" overflow: auto; ">
              <c-q-btn
                id="btn-fetch"
                propBtnLabel="Buscar"
                :propBtnFunction="onFetchBtnClick"
                :propBtnTransparent="false"
                :propBtnSize="EnumButtonSizes.LARGE_1"
              />
            </div>
          </div> -->

          <div id="div-table-financial-institutions" class="row justify-center q-gutter-sm">
            <div style=" width: 100%; ">
              <q-table
                ref="qTableFinancialInstitutions"
                :rows="filterSelectedIfs"
                row-key="codif"
                :columns="qTableFinancialInstitutionsColumns"
                :visible-columns="qTableFinancialInstitutionsVisibleColumns"
                virtual-scroll
                no-data-label="Nenhum registro encontrado!"
                rows-per-page-label="Linhas por página:"
                selection="multiple"
                v-model:selected="financialInstitutionStore.selectedFinancialInstitutions"
                dense
                flat
                separator="horizontal"
                class="midia-app-table"
              >
                <template v-slot:header="props">
                  <q-tr :props="props">
                    <q-th
                      style="
                        text-align: center;
                        border: 0;
                      "
                      :auto-width="true"
                    >
                      <q-checkbox
                        v-model="props.selected"
                        :color="(props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2')"
                        :keep-color="(!props.selected)"
                      />
                    </q-th>
                    
                    <q-th
                      v-for="col in props.cols"
                      :key="col.field"
                      :props="props"
                      style="
                        border: 0;
                      "
                    >
                    <span class="midia-app-title-header-table-lg">{{ col.label }}</span>
                    </q-th>
                  </q-tr>
                </template>

                <template v-slot:body="props">
                  <q-tr class="table-body-rows" :props="props">
                    <q-td key="selected" style=" text-align: center; " :auto-width="true">
                      <q-checkbox
                        v-model="props.selected"
                        :color="(props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2')"
                        :keep-color="(!props.selected)"
                      />
                    </q-td>

                    <q-td key="codif" :props="props">                      
                      <div class="row items-center no-wrap">
                        <q-icon size="24px">
                          <img class="svg-icon" src="@/assets/icons/icon_HiOutlineOfficeBuilding.svg" />
                        </q-icon>

                        <span class="midia-app-label-lg q-pl-md">{{ props.row.codif }}</span>
                      </div>
                    </q-td>

                    <q-td key="nome" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nome }}</span>
                    </q-td>

                    <q-td key="nomagrpacionista" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nomagrpacionista }}</span>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </div>
          

          <c-q-separator class="q-pt-lg q-pb-md" />

          <div class="row fit no-wrap justify-end" style=" overflow: auto; ">
            <c-q-btn
              id="btn-continue"
              propBtnLabel="Continuar"
              :propBtnFunction="onContinueBtnClick"
              :propBtnTransparent="false"
              :propBtnSize="EnumButtonSizes.LARGE_1"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>
  
<script setup lang="ts">
import { EnumButtonSizes }	              from '@/enums/enums';
import { useFinancialInstitutionStore }   from '@/stores/financialInstitutionStore';
import { useCampaignStore }               from '@/stores/campaignStore';
import { usePagesUtilStore }              from '@/stores/pagesUtilStore';
import { computed, ref }                  from 'vue';
import { quasarCustomQTableColumns }      from '@/types/quasarCustomQTableColumn';
import { FinancialInstitutionModel }      from '@/models/FinancialInstitutionModel';
  
const financialInstitutionStore   = useFinancialInstitutionStore();
const campaignStore               = useCampaignStore();
const pagesUtilStore              = usePagesUtilStore();
  
const filterTerms = ref('');
  
const qTableFinancialInstitutionsColumns:quasarCustomQTableColumns[] = [
  {
    name  : 'codif',
    label : 'Código',
    field : 'codif',
    align : 'left'
  },
  
  {
    name  : 'nome',
    label : 'Instituição',
    field : 'nome',
    align : 'left'
  },

  {
    name  : 'nomagrpacionista',
    label : 'Categoria',
    field : 'nomagrpacionista',
    align : 'left'
  }
];
  
const qTableFinancialInstitutionsVisibleColumns = [
  'codif',
  'nome',
  'nomagrpacionista'
];

const onContinueBtnClick = () => {
  try {
    // Remove selected PCs from the campaign
    campaignStore.focusedCampaign.ifs = campaignStore.focusedCampaign.ifs.filter(fi => !financialInstitutionStore.selectedFinancialInstitutions.includes(fi));

    // Update ifsIds
    campaignStore.focusedCampaign.ifsIds.length = 0;
    campaignStore.focusedCampaign.ifsIds.push( ...campaignStore.focusedCampaign.pcs.map(data => data.codif) );

    // Clear the ifs store
    financialInstitutionStore.financialInstitutions.length = 0;
    financialInstitutionStore.selectedFinancialInstitutions.length = 0;

  } finally {
    pagesUtilStore.showModalExcludeIfs = false;
  }      
};


const filterSelectedIfs = computed(()=>{
  return campaignStore.focusedCampaign.ifs.filter(fi => (
    (!financialInstitutionStore.ifFilter.filterIF.codif || fi.codif == financialInstitutionStore.ifFilter.filterIF.codif) &&
      (!financialInstitutionStore.ifFilter.filterIF.nome || fi.nome.toUpperCase() == financialInstitutionStore.ifFilter.filterIF.nome.toUpperCase()) &&
      // (!financialInstitutionStore.ifFilter.filterIF.codagrpacionista || fi.codagrpacionista.toUpperCase() === financialInstitutionStore.ifFilter.filterIF.codagrpacionista.toUpperCase()) &&
      (!financialInstitutionStore.ifFilter.filterIF.nomagrpacionista || fi.nomagrpacionista.toUpperCase() == financialInstitutionStore.ifFilter.filterIF.nomagrpacionista.toUpperCase())
    ));
})


const onRequest = async () => {
  console.log('onRequest');
};
</script>

<style scoped>

</style>
