import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { useCampaignStore }         from '@/stores/campaignStore';
import CampaignFormatATMScreenEdit  from '@/components/campaign/campaignFormats/CampaignFormatATMScreenEdit.vue';
import { computed }                 from 'vue';
import { EnumCampaignFormats }      from '@/enums/enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignFormatSectionEdit',
  setup(__props) {

const campaignStore = useCampaignStore();

const showFormatATMScreen = computed(() => {
  return (campaignStore.focusedCampaign.format == EnumCampaignFormats.ATM_SCREEN);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (showFormatATMScreen.value)
      ? (_openBlock(), _createBlock(CampaignFormatATMScreenEdit, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

})