import { defineStore }                                                            from 'pinia';
import { Ref, ref }                                                               from 'vue';
import { listCampaignInterface, campaignInterface, campaignFilterInterface, campaignJsonSlotInterface, CampaignDistribution, pcInterface, pcsPorGrupoInterface }  from '@/types/types.d';
import { CampaignModel }                                                          from '@/models/CampaignModel';
import { ListCampaignModel }                                                      from '@/models/ListCampaignModel';
import { CampaignFilterModel }                                                    from '@/models/CampaignFilterModel';
import { CampaignDistributionModel }                                              from '@/models/CampaignDistributionModel';
import { CampaignDistributionPcModel }                                            from '@/models/CampaignDistributionPcModel';
import ApiService                                                                 from '@/utils/ApiService';
import { useFinancialInstitutionStore }                                           from '@/stores/financialInstitutionStore';
import { usePCStore }                                                             from '@/stores/pcStore';
import { useAuthStore }															                              from '@/stores/authStore';
import { formatDateStringDdMmYyyyAsYyyyMmDd, convertDateToStringYyyyMmDd }        from '@/utils/commonUtils';
import { CONST_CAMPAIGN_STATUS }                                                  from '@/static/constants';
import { EnumCampaignFormats }                                                    from '@/enums/enums';
import messages                                                                   from '@/utils/messages';
import axios from 'axios';
export const useCampaignStore = defineStore('campaign', () => {
  // state
  const campaigns                     : Ref<listCampaignInterface[]>      = ref([] as listCampaignInterface[]);
  const campaignDistribtion           : Ref<CampaignDistribution>     = ref(new CampaignDistributionModel({}));
  const selectedCampaigns             : Ref<campaignInterface[]>      = ref([] as campaignInterface[]);
  const focusedCampaign               : Ref<campaignInterface>        = ref(new CampaignModel({}));
  const focusedCampaignSnapshot       : Ref<campaignInterface>        = ref(new CampaignModel({}));
  const focusedCampaignImageObjectUrl : Ref<string>                   = ref("");
  const campaignFilter                : Ref<campaignFilterInterface>  = ref(new CampaignFilterModel());
  const selectedOption                : Ref<string>                   = ref("");
  const refRequestedAdjustments       : Ref<string>                   = ref("");
  const pcsGroup                      : Ref<any>                      = ref(null);

  const formatAtmScreenImageFileSlot  : Ref<File | undefined>         = ref(undefined);
  const campaignJsonSlot              : Ref<campaignJsonSlotInterface | undefined> = ref(undefined);
  const parameterSlot                 : Ref<string> = ref("");
  // const editIsComingFromDraft         : Ref<boolean>                   = ref(false);
  
  const updateCampaignSnapshot = () => {
    focusedCampaignSnapshot.value = Object.freeze(new CampaignModel(focusedCampaign.value));
  };

  const setSelectedOption = (option?:any) =>{
    selectedOption.value = option;
  }

  const setFocusedCampaign = (newCampaignToSet: campaignInterface) => {
    focusedCampaign.value = new CampaignModel(newCampaignToSet);

    updateCampaignSnapshot();
  };

  const setFocusedCampaignPcs = (pcs: pcInterface[]) => {
    focusedCampaign.value.pcs = pcs
  }

  const onFetchCampaignById = async () => {
    const id = focusedCampaign.value.id;
    try{

      const responseData = await ApiService.get<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_CAMPAIGN + `/${id}`
      );

      // Atribuindo os dados recebidos à propriedade value de focusedCampaign
      focusedCampaign.value = new CampaignModel(responseData);

    }catch (error) {
      console.error(error);
      messages.showErrorMsg("Erro ao tentar obter informações da campanha");
    }

  }

  const fetchCampaigns = async () => {
    try {
      const params = {
        advertiser  : (campaignFilter.value.filterCampaign.advertiser         ? campaignFilter.value.filterCampaign.advertiser                                            : undefined),
        agency      : (campaignFilter.value.filterCampaign.agency             ? campaignFilter.value.filterCampaign.agency                                                : undefined),
        name        : (campaignFilter.value.filterCampaign.name               ? campaignFilter.value.filterCampaign.name                                                  : undefined),
        start_date  : (campaignFilter.value.filterCampaign.startDateDdMmYyyy  ? formatDateStringDdMmYyyyAsYyyyMmDd(campaignFilter.value.filterCampaign.startDateDdMmYyyy) : undefined),
        end_date    : (campaignFilter.value.filterCampaign.endDateDdMmYyyy    ? formatDateStringDdMmYyyyAsYyyyMmDd(campaignFilter.value.filterCampaign.endDateDdMmYyyy)   : undefined),
        format      : (campaignFilter.value.filterCampaign.format             ? campaignFilter.value.filterCampaign.format                                                : undefined),
        status      : (campaignFilter.value.filterCampaign.status             ? campaignFilter.value.filterCampaign.status                                                : undefined),
        pcsIds      : (campaignFilter.value.filterCampaignFormatATMScreen.pcs ? campaignFilter.value.filterCampaignFormatATMScreen.pcs                                    : undefined),
        ifsIds      : (campaignFilter.value.filterCampaignFormatATMScreen.institutions             ? campaignFilter.value.filterCampaignFormatATMScreen.institutions      : undefined),
        slot        : (campaignFilter.value.filterCampaignFormatATMScreen.screen ? campaignFilter.value.filterCampaignFormatATMScreen.screen : undefined) // TODO: verificar!
      };

      const responseData = await ApiService.get<[ListCampaignModel]>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_CAMPAIGN,
        params
      );
      
      campaigns.value.length = 0;
      campaigns.value.push( ...responseData.map(data => new ListCampaignModel(data)) );
      
    } catch (error) {
      console.error(error);
      messages.showErrorMsg("Erro ao tentar obter a lista de campanhas...");
    }
  };

  const deleteCampaigns = async (params: object) => {
    try {
      await ApiService.put<undefined>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_DELETE_CAMPAIGN,
        "",
        params
      );
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const deleteSelectedCampaigns = async () => {
    try {
      const params = {
        ids: selectedCampaigns.value.map(c => c.id).toString()
      };

      await deleteCampaigns(params);
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const deleteFocusedCampaign = async () => {
    try {
      const params = {
        ids: focusedCampaign.value.id
      };

      await deleteCampaigns(params);
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const saveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.post<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_SAVE,
        focusedCampaign.value
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar salvar a campanha...");
    }
  };
  
  const sendToApproveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.post<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_SEND_TO_APPROVE,
        focusedCampaign.value
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar enviar a campanha para aprovação...");
      // throw error;
    }
  };
  

  const resendToApproveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_RESEND_TO_APPROVE,
        focusedCampaign.value,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar reenviar a campanha para aprovação...");
    }
  };

  const  sendToReapproveCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_SUBMIT_FOR_REAPPROVAL,
        focusedCampaign.value,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar reenviar a campanha para aprovação...");
    }
  };
  
  const approveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_APPROVE,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Erro ao aprovar a campanha. Só é possível aprovar campanhas que estejam com o status EM APROVAÇÃO.");
      }
      console.error(error);
      throw new Error("Erro ao tentar aprovar a campanha...");
      // throw error;
    }
  };

  const reapproveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_REAPPROVE,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Erro ao reaprovar a campanha. Só é possível aprovar campanhas que estejam com o status EM REAPROVAÇÃO.");
      }
      console.error(error);
      throw new Error("Erro ao tentar reaprovar a campanha...");
      // throw error;
    }
  };

  
  const cancelFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_CANCEL,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };


  const discardChanges = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_DISCARD_CHANGES,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };



  
  const editFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_EDIT,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };
  
  const sendRequestedAdjustmentsFocusedCampaign = async (requestedAdjustments: string) => {
    try {
      const focusedCampaignWithRequestAdjusmentsText = new CampaignModel(focusedCampaign.value);
      focusedCampaignWithRequestAdjusmentsText.commentadjustment = requestedAdjustments;

      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_REQUEST_ADJUSTMENTS,
        focusedCampaignWithRequestAdjusmentsText,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Erro ao solicitar ajuste para a campanha. Só é possível solicitar ajustes para campanhas que estejam com o status EM APROVAÇÃO.");
      }
      console.error(error);
      throw new Error("Erro ao tentar solicitar ajustes...");
    }
  };



  const sendRequestedAdjustmentsAgainFocusedCampaign = async (requestedAdjustments: string) => {
    try {
      const focusedCampaignWithRequestAdjusmentsText = new CampaignModel(focusedCampaign.value);
      focusedCampaignWithRequestAdjusmentsText.commentadjustment = requestedAdjustments;

      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_REQUEST_ADJUSTMENTS_AGAIN,
        focusedCampaignWithRequestAdjusmentsText,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Erro ao solicitar reajuste para a campanha. Só é possível solicitar ajustes para campanhas que estejam com o status EM REAPROVAÇÃO.");
      }
      console.error(error);
      throw new Error("Erro ao tentar solicitar reajustes...");
    }
  };

  const sendImageToS3 = async (imageFile: File | null) => {
    try {
      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);

        const additionalHeaders = {
          'Content-Type': 'multipart/form-data'
        }
  
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await ApiService.post<any>(
          process.env.VUE_APP_API_GATEWAY_HOST,
          process.env.VUE_APP_FILE_UPLOAD_S3_URL,
          formData,
          additionalHeaders
        );

        return response;
      }
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const saveFormatAtmImageSlotOnS3 = async () => {
    if (formatAtmScreenImageFileSlot.value) {
      try {
        const urlImageS3 = await sendImageToS3(formatAtmScreenImageFileSlot.value);

        if(focusedCampaign.value.slot1 != null){
          const slot1Json = convertCampaignStringSlotToCampaignJsonSlotInterface(focusedCampaign.value.slot1)
          slot1Json.imageObjectUrl = urlImageS3.uploadLocation;
          focusedCampaign.value.slot1 = convertCampaignJsonSlotInterfaceToCampaignStringSlot(slot1Json);
        
        }else if(focusedCampaign.value.slot2 != null){
          const slot2Json = convertCampaignStringSlotToCampaignJsonSlotInterface(focusedCampaign.value.slot2)
          slot2Json.imageObjectUrl = urlImageS3.uploadLocation;
          focusedCampaign.value.slot2 = convertCampaignJsonSlotInterfaceToCampaignStringSlot(slot2Json);
        
        }else if(focusedCampaign.value.slot3 != null){
          const slot3Json = convertCampaignStringSlotToCampaignJsonSlotInterface(focusedCampaign.value.slot3)
          slot3Json.imageObjectUrl = urlImageS3.uploadLocation;
          focusedCampaign.value.slot3 = convertCampaignJsonSlotInterfaceToCampaignStringSlot(slot3Json);
        }

        formatAtmScreenImageFileSlot.value = undefined;
        
      } catch (error) {
        console.error(error);
        // throw new Error("Erro ao salvar a imagem.");
        // messages.showErrorMsg("Erro ao tentar salvar a imagem...");
        throw error;
      }
    }
  };

  const campaignIsInEffect = (c: campaignInterface) => {
    try {      
      if (c.startDate && c.endDate) {
        const nowAsStringYyyyMmDd = convertDateToStringYyyyMmDd(new Date());

        return (
          (nowAsStringYyyyMmDd >= c.startDate)
          && (nowAsStringYyyyMmDd <= c.endDate)
        );

      } else {
        return false;
      }

    } catch (error) {
      return false;
    }
  };

  const campaignCanBeViewed = (c: campaignInterface) => {
    const authStore = useAuthStore();
    return (
      authStore.currentUserCanViewCampaign
      && (
        (c?.status == CONST_CAMPAIGN_STATUS.CANCELED.id || c?.status == CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.id)
      )
    );
  };

  const vigenciaJaTerminou = (c: campaignInterface) => {
    try {      
      if (c.startDate && c.endDate) {
        const nowAsStringYyyyMmDd = convertDateToStringYyyyMmDd(new Date());
 
        return (
          (nowAsStringYyyyMmDd > c.endDate)
        );
 
      } else {
        return false;
      }
 
    } catch (error) {
      return false;
    }
  };
 
  const campaignCanBeDeleted = (c: campaignInterface) => {
    const authStore = useAuthStore();
 
    return (
      authStore.currentUserCanDeleteCampaign
      && (
        (
          (c?.status === CONST_CAMPAIGN_STATUS.CANCELED.id)
          || (c?.status === CONST_CAMPAIGN_STATUS.DRAFT.id)
          || (c?.status === CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.id)
        )
        || (
          (c?.status === CONST_CAMPAIGN_STATUS.PUBLISHED.id)
          && vigenciaJaTerminou(c)
        )
      )
    );
  };

  const campaignCanBeEdited = (c: campaignInterface) => {
    const authStore = useAuthStore();

    return (
      !authStore.currentUserCanEditCampaign
      && (
        !(c?.status == CONST_CAMPAIGN_STATUS.APPROVED.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.PUBLISHED.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.DRAFT.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.ON_REISSUE.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id)
      )
    );
  }

  const allSelectedCampaignsCanBeDeleted = () => {
    try {
      let result = true;

      if (selectedCampaigns.value.length > 0) {
        selectedCampaigns.value.forEach(c => {
          if (!campaignCanBeDeleted(c)) {
            messages.showWarningMsg(`Campanha ${c.name} não pode ser excluída...`);

            result = false;
          }
        });
      }
  
      return result;
        
    } catch (error) {
      return false;
    }
  };

  const publishFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_PUBLISH,
        null,
        { id: focusedCampaign.value.id }
      );
      
     /* try{
        await generateAndSendEmailPackageCampaign();
      }catch(err){
        console.error(err);
      } */
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);
      messages.showErrorMsg(`Não foi possivel publicar a Campanha...`);

      throw error;
    }
  }


  const republishFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_REPUBLISH,
        null,
        { id: focusedCampaign.value.id }
      );
      
     /* try{
        await generateAndSendEmailPackageCampaign();
      }catch(err){
        console.error(err);
      } */
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);
      messages.showErrorMsg(`Não foi possivel republicar a Campanha...`);

      throw error;
    }
  }

  const generateAndSendEmailPackageCampaign = async () =>{
    const s3ImageUrlInfo = focusedCampaign.value.formatAtmScreenImageSlotS3Url();
    
    if (s3ImageUrlInfo) {
    const { imageUrl, fileName } = s3ImageUrlInfo;

    const response = await axios.get(imageUrl,{
      responseType: 'arraybuffer'
    });
    
    const fileBlob = new Blob([response.data]);

    const slotNames = ["slot3-1.0.png", "slot1-1.0.png", "slot2-1.0.png"];
    const slotIndex = focusedCampaign.value.slot1 ? 1 : (focusedCampaign.value.slot2 ? 2 : 0);
    const slotName = slotNames[slotIndex];
    
    const formData = new FormData();
      formData.append('nomeArquivo', process.env.VUE_APP_NAME_FILE);
      formData.append('ID', String(focusedCampaign.value.id));
      formData.append('NOME_CAMPANHA', String(focusedCampaign.value.name));
      formData.append('COD_PCS', String(focusedCampaign.value.pcsIds));
      formData.append('COD_INTERBANK', String(focusedCampaign.value.ifsIds));
      formData.append('SLOT_IMAGE_NAME', String(slotName));
      formData.append('DATA_INI', String(formatDate(focusedCampaign.value.startDateDdMmYyyy)));
      formData.append('DATA_FIM', String(formatDate(focusedCampaign.value.endDateDdMmYyyy)));
      formData.append('IMG1', fileBlob, fileName);
    
      const responseData = await ApiService.post<CampaignModel>(
        process.env.VUE_APP_API_PACKAGE_ENDPOINT,
        process.env.VUE_APP_API_PACKAGE_GENERATE,
        formData
      );
  
      return responseData;
    }
    
  }

  const resetCampaignDistribution = () =>{
    campaignDistribtion.value = new CampaignDistributionModel({});
  }

  const resetState = () => {
    campaigns.value.length         = 0;
    selectedCampaigns.value.length = 0;
    focusedCampaign.value          = new CampaignModel({});
    focusedCampaignSnapshot.value  = new CampaignModel({});
  }

  const resetCampaignFilter = () => {
    campaignFilter.value = new CampaignFilterModel();
  };

  const  validateFocusedCampaignFormatAtmScreens = () => {
    try {
      const messagesArray = [];

      if (focusedCampaign.value.ifs.length == 0) {
        messagesArray.push("Favor informar pelo menos uma instituição.");
      }
  
      if (focusedCampaign.value.pcs.length == 0) {
        messagesArray.push("Favor informar pelo menos um PC.");
      }

      if (
        !focusedCampaign.value.slot1
        && !focusedCampaign.value.slot2
        && !focusedCampaign.value.slot3
      ) {
        messagesArray.push("Favor escolher e importar pelo menos uma imagem.");
      }
      

      const validSlot = isValidAssociationSlotxIF();
      if(!validSlot){
        messagesArray.push("Favor verificar as IFs selecionadas");
      }

      messagesArray.reverse().forEach(m => {
        messages.showWarningMsg(m);
      });

      return (messagesArray.length == 0);
        
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const isValidAssociationSlotxIF = () => {
    try {
      const messagesArray: string[] = [];
  
      // Determina qual slot foi selecionado
      let selectedSlot: 'slot1' | 'slot2' | 'slot3' | null = null;
      if (focusedCampaign.value.slot1) {
        selectedSlot = 'slot1';
      } else if (focusedCampaign.value.slot2) {
        selectedSlot = 'slot2';
      } else if (focusedCampaign.value.slot3) {
        selectedSlot = 'slot3';
      }

      // Mapeamento dos nomes dos slots
      const slotNames = {
        slot1: 'Abertura',
        slot2: 'propagIF',
        slot3: 'Retire Seu Dinheiro'
    };
  
      // Verifica se algum slot foi selecionado
        // Itera sobre as IFs selecionadas
        focusedCampaign.value.ifs.forEach((ifItem) => {
          // Verifica se o slot selecionado está vinculado à IF
          const slotValue = ifItem[selectedSlot!]; // Aqui o selectedSlot é garantido não nulo
          if (!slotValue) {
            messagesArray.push(`O slot ${slotNames[selectedSlot!]} não está vinculado à IF ${ifItem.codif} (${ifItem.nome}).`);
          }
        });
      
      // Exibe as mensagens de erro, se houver
      messagesArray.forEach(m => {
        messages.showWarningMsg(m);
      });
  
      // Retorna true se não houver erros, false caso contrário
      return (messagesArray.length === 0);
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  

  const validateFocusedCampaignFields = () => {
    try {
      let validateResult = true;

      const messagesArray = [];

      if (!focusedCampaign.value.name) {
        messagesArray.push("Favor preencher o Nome da Campanha");
      }
  
      if (!focusedCampaign.value.startDate) {
        messagesArray.push("Favor informar a Data de Início da Vigência desta campanha");
      
      } else if (focusedCampaign.value.startDate <= convertDateToStringYyyyMmDd(new Date()) && focusedCampaign.value.status != CONST_CAMPAIGN_STATUS.ON_REISSUE.id ) {
        messagesArray.push("Data de início de vigência deve ser maior do que a data atual (hoje)");
      }

      if (!focusedCampaign.value.endDate) {
        messagesArray.push("Favor informar a Data de Fim da Vigência desta campanha");
      }

      if (focusedCampaign.value.startDate && focusedCampaign.value.endDate) {
        if (focusedCampaign.value.endDate <= focusedCampaign.value.startDate) {
          messagesArray.push("Data de fim da vigência deve ser maior que a data de Início da vigência");
        }
  
        // Nova validação para verificar o período de vigência
          const startDate = new Date(focusedCampaign.value.startDate);
          const endDate = new Date(focusedCampaign.value.endDate);
          const timeDiff = endDate.getTime() - startDate.getTime();
          const dayDiff = timeDiff / (1000 * 3600 * 24) + 1; // Adiciona 1 para incluir o dia final
          console.log(dayDiff);
  
        if (focusedCampaign.value.pcs.length > 100 && dayDiff < 4) {
          messagesArray.push("Para campanhas com mais de 100 dispositivos, o período de vigência deve ser de pelo menos 4 dias");
        }
      }

      
  
      if (!focusedCampaign.value.advertiser) {
        messagesArray.push("Favor informar o Anunciante da campanha");
      }

      if (!focusedCampaign.value.format) {
        messagesArray.push("Favor selecionar um formato");
      
      } else if (focusedCampaign.value.format == EnumCampaignFormats.ATM_SCREEN) {
        console.log(!validateFocusedCampaignFormatAtmScreens())
        if (!validateFocusedCampaignFormatAtmScreens()) {
          validateResult = false;
        }
      }

      if (messagesArray.length > 0) {
        validateResult = false;

        messagesArray.reverse().forEach(m => {
          messages.showWarningMsg(m);
        });
      }

      return validateResult;
        
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const convertCampaignStringSlotToCampaignJsonSlotInterface = (stringSlot: string | undefined) => {
    try {
      if (stringSlot) {
        const parsedStringSlot: campaignJsonSlotInterface = JSON.parse(stringSlot);
        return parsedStringSlot;
      
      } else {
        return {
          imageObjectUrl  : "",
          imageS3Url      : undefined,
          imageFileName   : "",
          imageWidth		  : undefined,
          imageHeight		  : undefined,
          imageSize			  : undefined
        };
      }
      
    } catch (error) {
      return {
        imageObjectUrl  : "",
        imageS3Url      : undefined,
        imageFileName   : "",
        imageWidth		  : undefined,
        imageHeight		  : undefined,
        imageSize			  : undefined
      };
    }
  };

  const convertCampaignJsonSlotInterfaceToCampaignStringSlot = (jsonSlot: campaignJsonSlotInterface | undefined) => {
    try {
      if (jsonSlot) {
        const stringifiedJsonSlot: string = JSON.stringify(jsonSlot);
  
        return stringifiedJsonSlot;

      } else {
        return undefined;  
      }
      
    } catch (error) {
      return undefined;
    }
  };

  const exportFocusedCampaignIfsAsCsv = (paramFilename?: string) => {
    const financialInstitutionStore = useFinancialInstitutionStore();
    
    financialInstitutionStore.exportIfsAsCsv(focusedCampaign.value.ifs, paramFilename);
  };

  const exportFocusedCampaignPcsAsCsv = (paramFilename?: string) => {
    const pcStore = usePCStore();
    const pcsPorGroup = focusedCampaign.value.pcsPorGrupo;
    
    if(pcsPorGroup && Object.keys(pcsPorGroup).length > 0){
      let allPcs:pcInterface[] = [];

    Object.values(pcsPorGroup).forEach(pcs =>{
      allPcs = allPcs.concat(pcs);
    })

    pcStore.exportPcsAsCsv(allPcs, paramFilename);
    }else{
      pcStore.exportPcsAsCsv(focusedCampaign.value.pcs, paramFilename);
    }
    
  };

  function formatDate(inputDate?: string) {

    if (!inputDate) {
      return ''; // Or handle the case in your application logic
    }
    // Split the input date string into year, month, and day
    const [day, month, year ] = inputDate.split('/');
    
    // Format the date as MMDDYYYY
    const formattedDate = `${day}${month}${year}`;
    
    return formattedDate;
  }

  const fetchCampaignDistribution = async (campaignId: number | undefined | null, pageNumber?: number | undefined | null) => {
    try {
      
      const responseData = await ApiService.get<CampaignDistributionModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_DISTRIBUTION,
        {campanhaId: campaignId, page: pageNumber ? pageNumber : 0}
      );
      
      if(!pageNumber)
        pageNumber = 0;

      if(!responseData) {
        campaignDistribtion.value = new CampaignDistributionModel({});
        return;
      }

      const _lastPage = campaignDistribtion?.value?.pcsCampaign && focusedCampaign?.value?.id && focusedCampaign.value.id == campaignDistribtion.value.campaignId && campaignDistribtion?.value?.pcsCampaign?.length > 0 ? [...campaignDistribtion.value.pcsCampaign] : [];

      if(!responseData?.pcsCampaign || responseData?.pcsCampaign?.length === 0) {
        campaignDistribtion.value =  new CampaignDistributionModel(responseData);
        campaignDistribtion.value.pcsCampaign = [..._lastPage];
        return;
      }

      campaignDistribtion.value =  new CampaignDistributionModel(responseData);
      campaignDistribtion.value.pcsCampaign = [..._lastPage, ...responseData.pcsCampaign];
    
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMockPcGroup = async () => {
    try {
      const responseData = await ApiService.get("http://localhost:8442", "campanhas/grupos-pc") as Record<string, any>;
      pcsGroup.value = [];

      if(!("pcs" in responseData))
        return;

      const dict = responseData.pcs as Record<string, any>;
      
      let i = 0;
      for(const key in dict) {
        pcsGroup.value.push( { name: `D${i}`, pcs: dict[key]});
        i++;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // getters

  return {
    // state
    campaigns,
    campaignDistribtion,
    pcsGroup,
    selectedCampaigns,
    focusedCampaign,
    focusedCampaignSnapshot,
    focusedCampaignImageObjectUrl,
    campaignFilter,
    formatAtmScreenImageFileSlot,
    selectedOption,
    refRequestedAdjustments,
    campaignJsonSlot,
    parameterSlot,
    // editIsComingFromDraft,
    /* advertiserOptions, */

    // actions
    updateCampaignSnapshot,
    setFocusedCampaign,
    fetchCampaigns,
    fetchCampaignDistribution,
    fetchMockPcGroup,
    onFetchCampaignById,
    deleteSelectedCampaigns,
    deleteFocusedCampaign,
    saveFocusedCampaign,
    sendToApproveFocusedCampaign,
    resendToApproveFocusedCampaign,
    sendToReapproveCampaign,
    approveFocusedCampaign,
    reapproveFocusedCampaign,
    cancelFocusedCampaign,
    discardChanges,
    editFocusedCampaign,
    sendRequestedAdjustmentsFocusedCampaign,
    sendRequestedAdjustmentsAgainFocusedCampaign,
    saveFormatAtmImageSlotOnS3,
    setSelectedOption,
    setFocusedCampaignPcs,

    campaignCanBeDeleted,
    allSelectedCampaignsCanBeDeleted,
    campaignCanBeEdited,
    campaignCanBeViewed,
    exportFocusedCampaignIfsAsCsv,
    exportFocusedCampaignPcsAsCsv,
    publishFocusedCampaign,
    republishFocusedCampaign,
    generateAndSendEmailPackageCampaign,
    
    resetState,
    resetCampaignFilter,
    resetCampaignDistribution,

    validateFocusedCampaignFields,

    convertCampaignStringSlotToCampaignJsonSlotInterface,
    convertCampaignJsonSlotInterfaceToCampaignStringSlot,

    // getters
  }
})
