import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pb-md" }
const _hoisted_2 = { class: "row col" }
const _hoisted_3 = { class: "q-pb-md" }
const _hoisted_4 = { class: "row col" }
const _hoisted_5 = { class: "q-pb-md" }
const _hoisted_6 = { class: "row col" }
const _hoisted_7 = { class: "row fit no-wrap justify-center items-center content-start q-pb-lg" }
const _hoisted_8 = {
  class: "row fit no-wrap justify-end",
  style: {"overflow":"auto"}
}
const _hoisted_9 = {
  class: "q-pl-md",
  style: {"overflow":"auto"}
}
const _hoisted_10 = {
  class: "q-pl-md",
  style: {"overflow":"auto"}
}

import { EnumButtonSizes } from "@/enums/enums";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import {useCampaignStore}         from "@/stores/campaignStore"
import {useFinancialInstitutionStore} from "@/stores/financialInstitutionStore"
import messages from "@/utils/messages";
import ModalIfsExclude from "./ModalIFsExclude"
import { computed, onBeforeMount } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFilterExcludeIfs',
  setup(__props) {

const pagesUtilStore = usePagesUtilStore();
const financialInstitutionStore = useFinancialInstitutionStore();
const campaignStore = useCampaignStore();

const filterOptions = () => {
      // Isso é necessário para garantir que as opções sejam mostradas corretamente
      financialInstitutionStore.selectedFinancialInstitutions = [...financialInstitutionStore.selectedFinancialInstitutions]
}

const onFetchBtnClick = () => {
  financialInstitutionStore.resetState();
  try {
    pagesUtilStore.showLoading = true;
    if (
      !financialInstitutionStore.ifFilter.filterIF.codif &&
      !financialInstitutionStore.ifFilter.filterIF.nome &&
      !financialInstitutionStore.ifFilter.filterIF.nomagrpacionista
    ) {
      messages.showWarningMsg("Pelo menos um filtro deve ser preenchido.");
    } else {
      pagesUtilStore.showModalExcludeIfs = true;
    }
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

const onCancelBtnClick = () => {
  financialInstitutionStore.resetFilterState();
  financialInstitutionStore.resetState();
  pagesUtilStore.showModalFilterExcludeIfs = false;
};

const fetchedIfs = computed(()=>{
  return campaignStore.focusedCampaign.ifs;
})


// onBeforeMount(async() =>{
//   console.log('teste')
//   await financialInstitutionStore.fetchFinancialInstitutions();
//   console.log(financialInstitutionStore.financialInstitutions);
// })


return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalFilterExcludeIfs,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(pagesUtilStore).showModalFilterExcludeIfs) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"500px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: "Filtro de IFs",
                propModalDescription: "Filtre as IFs que você deseja Excluir!"
              }),
              _createElementVNode("div", _hoisted_1, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Código")
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    color: "globalAppPrimaryColor",
                    modelValue: _unref(financialInstitutionStore).ifFilter.filterIF.codif,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(financialInstitutionStore).ifFilter.filterIF.codif) = $event)),
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Código do PC",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Instituição")
                ], -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    color: "globalAppPrimaryColor",
                    modelValue: _unref(financialInstitutionStore).ifFilter.filterIF.nome,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(financialInstitutionStore).ifFilter.filterIF.nome) = $event)),
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Nome do PC",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Categoria")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_select, {
                    color: "globalAppPrimaryColor",
                    modelValue: _unref(financialInstitutionStore).ifFilter.filterIF.nomagrpacionista,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(financialInstitutionStore).ifFilter.filterIF.nomagrpacionista) = $event)),
                    options: fetchedIfs.value,
                    outlined: "",
                    dense: "",
                    "use-input": "",
                    "input-class": "midia-app-content-lg",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    "option-label": "nomagrpacionista",
                    "option-value": "nomagrpacionista",
                    label: "Selecione uma opção",
                    style: {"width":"100%"},
                    "emit-value": "",
                    "map-options": ""
                  }, null, 8, ["modelValue", "options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_c_q_btn, {
                      id: "btn-fetch",
                      propBtnLabel: "Buscar",
                      propBtnFunction: onFetchBtnClick,
                      propBtnTransparent: false,
                      propBtnSize: _unref(EnumButtonSizes).LARGE_1
                    }, null, 8, ["propBtnSize"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_c_q_btn, {
                      id: "btn-continue",
                      propBtnLabel: "Cancelar",
                      propBtnFunction: onCancelBtnClick,
                      propBtnTransparent: false,
                      propBtnSize: _unref(EnumButtonSizes).LARGE_1
                    }, null, 8, ["propBtnSize"])
                  ])
                ])
              ]),
              (_unref(pagesUtilStore).showModalExcludeIfs)
                ? (_openBlock(), _createBlock(_unref(ModalIfsExclude), { key: 0 }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})