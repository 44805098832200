import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore } 	from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages								from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnDiscardChanges',
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const onDiscardChangesBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.discardChanges();

		messages.showSuccessMsg("Alterações descartadas com sucesso!");

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar descartar alterações...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_unref(authStore).currentUserCanCancelCampaign 
		&& (_unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED_AGAIN.id 
        || _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_REISSUE.id 
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).REAPPROVED.id))
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-discard-changes",
        propBtnLabel: "Descartar Alterações",
        propBtnFunction: onDiscardChangesBtnClick,
        propBtnTransparent: true,
        propBtnSize: _unref(EnumButtonSizes).LARGE_1
      }, null, 8, ["propBtnSize"]))
    : _createCommentVNode("", true)
}
}

})