import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_CkAdd.svg'
import _imports_1 from '@/assets/icons/icon_CkArrowDown.svg'
import _imports_2 from '@/assets/icons/icon_HiOutlinePhotograph.svg'


const _hoisted_1 = { class: "row fit no-wrap justify-center items-center content-start" }
const _hoisted_2 = {
  class: "col-grow",
  style: {"overflow":"auto"}
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "midia-app-label-format-atm-slot-lg" }
const _hoisted_5 = { class: "row items-center no-wrap" }
const _hoisted_6 = { class: "col-grow q-py-sm" }
const _hoisted_7 = {
  class: "q-px-md q-py-sm",
  style: {"overflow":"auto"}
}
const _hoisted_8 = {
  class: "q-py-sm",
  style: {"overflow":"auto"}
}
const _hoisted_9 = { class: "row fit no-wrap justify-start items-center content-start" }

import CampaignFormatSlotIcon                             from '@/components/campaign/CampaignFormatSlotIcon.vue';
import { EnumATMScreenFormatSlotTypes, EnumButtonSizes }  from '@/enums/enums';
import { useCampaignStore }                               from '@/stores/campaignStore';
import { ref, Ref, computed }                             from 'vue';
import QFile from 'quasar/src/components/file/QFile.js';;
import ModalImagePreview			                            from '@/views/modals/ModalImagePreview.vue';
import { usePagesUtilStore }	                            from '@/stores/pagesUtilStore';
import { ATM_ALLOWED_IMAGE_DIMENSIONS }                   from '@/static/constants';
import messages                                           from '@/utils/messages';
// import { campaignJsonSlotInterface }                      from '@/types/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignFormatSlotsSection',
  props: {
  propSlotType  : {
    type      : String,
    required  : true
  },

  propSlotLabel : String
},
  setup(__props) {

const props = __props;

const campaignStore         = useCampaignStore();
const pagesUtilStore        = usePagesUtilStore();

const qFileUploader         = ref() as Ref<QFile>;
const fileModel             = ref<File | undefined>(undefined);
const selectedImage         = ref<HTMLImageElement | null>(null);

const qInputSlot01Model     = ref<string | null>(null);
const qInputSlot02Model     = ref<string | null>(null);
const qInputSlot03Model     = ref<string | null>(null);

// const campaignJsonSlot      = ref<campaignJsonSlotInterface | undefined>(undefined);

const showImagePreview = (imageObjectUrl: string | undefined) => {
  if (imageObjectUrl) {
    campaignStore.focusedCampaignImageObjectUrl = imageObjectUrl;
  
    pagesUtilStore.showModalImagePreview = true;
  }
};

const onDownloadImageGreenBtnClick = () => {
  console.log("onDownloadImageGreenBtnClick!");
};

const disableProcurar = computed(() => {
  return (
    campaignStore.focusedCampaign.iscampaignpublished &&
  (
    (props.propSlotType != EnumATMScreenFormatSlotTypes.A1) && (campaignStore.focusedCampaign.slot1 != undefined)
    || (props.propSlotType != EnumATMScreenFormatSlotTypes.A2) && (campaignStore.focusedCampaign.slot2 != undefined)
    || (props.propSlotType != EnumATMScreenFormatSlotTypes.A3) && (campaignStore.focusedCampaign.slot3 != undefined)
)
  );
});

const onFileSelected = (selectedFile: File) => {
  try {
    if (selectedFile) {

      // Verifica se o nome do arquivo contém espaços
      if (/\s/.test(selectedFile.name)) {
        messages.showWarningMsg('O nome do arquivo não deve conter espaços.');
        return;
      }

      if(selectedFile.name.length > 50) {
        messages.showWarningMsg(`O nome do arquivo deve ter no máximo 50 caracteres.`);
        return;
      }
  
      if (selectedFile.type != 'image/png') {
        messages.showWarningMsg('A imagem importada deve ser do tipo PNG.');
        return;
      }

      if (selectedFile.size > 600 * 1024) {
        messages.showWarningMsg('A imagem importada deve ter no máximo 600KB.');
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        // Create a new Image to get the dimensions
        selectedImage.value = new Image();

        selectedImage.value.onload = function() {
          if (
            selectedImage.value
            && (
              (selectedImage.value.width != ATM_ALLOWED_IMAGE_DIMENSIONS.width)
              || (selectedImage.value.height != ATM_ALLOWED_IMAGE_DIMENSIONS.height)
            )
          ) {
            messages.showWarningMsg(`A imagem precisa ter 640x480 pixels, mas possui ${selectedImage.value.width}x${selectedImage.value.height}.`);
          
          } else {
            campaignStore.campaignJsonSlot = {
              imageObjectUrl  : URL.createObjectURL(selectedFile),
              imageS3Url      : undefined,
              imageFileName   : selectedFile.name,
              imageWidth		  : selectedImage.value?.width,
              imageHeight		  : selectedImage.value?.height,
              imageSize			  : selectedFile.size
            }

            switch (props.propSlotType) {
              case EnumATMScreenFormatSlotTypes.A1:
                qInputSlot01Model.value = selectedFile.name;
                
                break;
            
              case EnumATMScreenFormatSlotTypes.A2:
                qInputSlot02Model.value = selectedFile.name;
                
                break;
            
              case EnumATMScreenFormatSlotTypes.A3:
                qInputSlot03Model.value = selectedFile.name;
                
                break;
            
              default:
                break;
            }
            showImagePreview(campaignStore.campaignJsonSlot.imageObjectUrl);
          };
        }

        if (event.target) {
          selectedImage.value.src = event.target.result as string;
        }
      }

      reader.readAsDataURL(selectedFile);
    }
    
  } catch (error) {
    console.error(error);
  }
};

const onSearchMoreBtnClick = () => {
  if (qFileUploader.value) {
    qFileUploader.value.pickFiles();
  }
};

const onImportBtnClick = () => {
  if (campaignStore.campaignJsonSlot) {
    if (selectedImage.value) {
      campaignStore.formatAtmScreenImageFileSlot = fileModel.value;
    }

    switch (props.propSlotType) {
      case EnumATMScreenFormatSlotTypes.A1:
        campaignStore.focusedCampaign.slot1 = campaignStore.convertCampaignJsonSlotInterfaceToCampaignStringSlot(campaignStore.campaignJsonSlot);
        campaignStore.focusedCampaign.slot2 = undefined;
        campaignStore.focusedCampaign.slot3 = undefined;
        break;
    
      case EnumATMScreenFormatSlotTypes.A2:
        campaignStore.focusedCampaign.slot1 = undefined;
        campaignStore.focusedCampaign.slot2 = campaignStore.convertCampaignJsonSlotInterfaceToCampaignStringSlot(campaignStore.campaignJsonSlot);
        campaignStore.focusedCampaign.slot3 = undefined;
        
        break;
    
      case EnumATMScreenFormatSlotTypes.A3:
        campaignStore.focusedCampaign.slot1 = undefined;
        campaignStore.focusedCampaign.slot2 = undefined;
        campaignStore.focusedCampaign.slot3 = campaignStore.convertCampaignJsonSlotInterfaceToCampaignStringSlot(campaignStore.campaignJsonSlot);
        
        break;
    
      default:
        break;
    }

    qInputSlot01Model.value = null;
    qInputSlot02Model.value = null;
    qInputSlot03Model.value = null;

    campaignStore.campaignJsonSlot  = undefined;
  }
};

const computedFocusedCampaignSlot = computed(() => {
  try {
    switch (props.propSlotType) {
      case EnumATMScreenFormatSlotTypes.A1:
        return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot1)?.imageFileName;
    
      case EnumATMScreenFormatSlotTypes.A2:
        return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot2)?.imageFileName;
        
      case EnumATMScreenFormatSlotTypes.A3:
        return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot3)?.imageFileName;
        
      default:
        return "";
    }
    
  } catch (error) {
    return "";
  }
});

/* const testaUploadImagem = async () => {
  await campaignStore.sendImageToS3(fileModel.value);
} */

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(CampaignFormatSlotIcon, {
        propSlotType: props.propSlotType,
        propSlotLabel: props.propSlotLabel
      }, null, 8, ["propSlotType", "propSlotLabel"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(props.propSlotLabel), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            ((props.propSlotType == _unref(EnumATMScreenFormatSlotTypes).A1))
              ? (_openBlock(), _createBlock(_component_q_input, {
                  key: 0,
                  id: "q-input-slot-a1",
                  modelValue: qInputSlot01Model.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((qInputSlot01Model).value = $event)),
                  color: "globalAppPrimaryColor",
                  "bg-color": "globalAppDefaultBackgroundColor",
                  outlined: "",
                  dense: "",
                  placeholder: `Selecione uma imagem .png com resolução de ${_unref(ATM_ALLOWED_IMAGE_DIMENSIONS).width}x${_unref(ATM_ALLOWED_IMAGE_DIMENSIONS).height} pixels.`,
                  "input-class": "midia-app-content-sm",
                  readonly: ""
                }, null, 8, ["modelValue", "placeholder"]))
              : ((props.propSlotType == _unref(EnumATMScreenFormatSlotTypes).A2))
                ? (_openBlock(), _createBlock(_component_q_input, {
                    key: 1,
                    id: "q-input-slot-a2",
                    modelValue: qInputSlot02Model.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((qInputSlot02Model).value = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: `Selecione uma imagem .png com resolução de ${_unref(ATM_ALLOWED_IMAGE_DIMENSIONS).width}x${_unref(ATM_ALLOWED_IMAGE_DIMENSIONS).height} pixels.`,
                    "input-class": "midia-app-content-sm",
                    readonly: ""
                  }, null, 8, ["modelValue", "placeholder"]))
                : ((props.propSlotType == _unref(EnumATMScreenFormatSlotTypes).A3))
                  ? (_openBlock(), _createBlock(_component_q_input, {
                      key: 2,
                      id: "q-input-slot-a3",
                      modelValue: qInputSlot03Model.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((qInputSlot03Model).value = $event)),
                      color: "globalAppPrimaryColor",
                      "bg-color": "globalAppDefaultBackgroundColor",
                      outlined: "",
                      dense: "",
                      placeholder: `Selecione uma imagem .png com resolução de ${_unref(ATM_ALLOWED_IMAGE_DIMENSIONS).width}x${_unref(ATM_ALLOWED_IMAGE_DIMENSIONS).height} pixels.`,
                      "input-class": "midia-app-content-sm",
                      readonly: ""
                    }, null, 8, ["modelValue", "placeholder"]))
                  : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(QFile), {
              modelValue: fileModel.value,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((fileModel).value = $event)),
                onFileSelected
              ],
              ref_key: "qFileUploader",
              ref: qFileUploader,
              style: {"display":"none"}
            }, null, 8, ["modelValue"]),
            _createVNode(_component_c_q_btn, {
              id: "btn-search-more",
              propBtnLabel: "Procurar",
              propBtnFunction: onSearchMoreBtnClick,
              propBtnTransparent: true,
              propBtnSize: _unref(EnumButtonSizes).MEDIUM,
              disable: disableProcurar.value
            }, {
              afterLabel: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  class: "q-pl-sm",
                  size: "16px"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("img", { src: _imports_0 }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["propBtnSize", "disable"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_c_q_btn, {
              id: "btn-import",
              propBtnLabel: "Importar",
              propBtnFunction: onImportBtnClick,
              propBtnTransparent: false,
              propBtnSize: _unref(EnumButtonSizes).MEDIUM,
              disable: !_unref(campaignStore).campaignJsonSlot
            }, {
              afterLabel: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  class: "q-pl-sm",
                  size: "12px"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("img", { src: _imports_1 }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["propBtnSize", "disable"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_c_q_btn, {
            id: "btn-icon-download-image",
            padding: "none",
            propBtnLabel: "",
            propBtnFunction: onDownloadImageGreenBtnClick,
            propBtnTransparent: true,
            propBtnSize: _unref(EnumButtonSizes).SMALL,
            propBorderless: true,
            disable: !computedFocusedCampaignSlot.value
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { size: "32px" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: "green_img_icon"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["propBtnSize", "disable"]),
          _createElementVNode("span", null, _toDisplayString(computedFocusedCampaignSlot.value), 1)
        ])
      ])
    ]),
    _createVNode(ModalImagePreview, {
      propImgObjectUrl: _unref(campaignStore).focusedCampaignImageObjectUrl,
      propShowDownloadButton: false
    }, null, 8, ["propImgObjectUrl"])
  ]))
}
}

})