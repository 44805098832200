import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter }												from 'vue-router';
import { useCampaignStore }													from '@/stores/campaignStore';
import { useAuthStore }															from '@/stores/authStore';
import { computed }													from 'vue';
import { usePagesUtilStore }                  from '@/stores/pagesUtilStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnViewFocusedCampaign',
  props: {
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: false,
		default		: EnumButtonSizes.LARGE_1
	}
},
  setup(__props) {

const router = useRouter();
const authStore		= useAuthStore();
const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const props = __props;

const onRequestCampaignBtnClick = async () => {

	try {
    pagesUtilStore.showLoading = true;
  
    await campaignStore.onFetchCampaignById();
		router.push({ name: EnumRouteNames.CAMPAIGN_VIEW });

  } catch (errorFetchingCampaigns) {
    console.error(errorFetchingCampaigns);
    pagesUtilStore.showLoading = false;

  } finally {
    pagesUtilStore.showLoading = false;
  }

};

const disableViewSelectedCampaignsBtn = computed(() => {
	try {
		return campaignStore.campaignCanBeViewed(campaignStore.focusedCampaign);
		
	} catch (error) {
		return true;
	}
})

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_unref(authStore).currentUserCanViewCampaign)
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-view-campaign",
        propBtnLabel: "Visualizar",
        propBtnFunction: onRequestCampaignBtnClick,
        propBtnTransparent: true,
        propBtnSize: props.propBtnSize,
        disable: disableViewSelectedCampaignsBtn.value
      }, null, 8, ["propBtnSize", "disable"]))
    : _createCommentVNode("", true)
}
}

})