import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PageCaption	                      from '@/components/PageCaption.vue';
import CampaignIdentificationSectionEdit  from '@/components/campaign/CampaignIdentificationSectionEdit.vue';
import CampaignFormatSectionEdit          from '@/components/campaign/CampaignFormatSectionEdit.vue';
import CampaignActionButtonsSection       from '@/components/campaign/CampaignActionButtonsSection.vue';
import { onBeforeMount }                  from 'vue';
import { useCampaignStore }               from '@/stores/campaignStore';
import { CampaignModel }                  from '@/models/CampaignModel';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageCampaignNew',
  setup(__props) {

const campaignStore = useCampaignStore();

/* onBeforeMount(() => {
  campaignStore.focusedCampaign          = new CampaignModel({});
  campaignStore.focusedCampaignSnapshot  = new CampaignModel({});
}); */

return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_q_separator, {
      class: "q-pb-lg",
      withBackButton: true
    }),
    _createVNode(PageCaption, {
      pageCaptionTitle: "Nova Campanha",
      pageCaptionDescription: "Envie uma nova Campanha para Aprovação"
    }),
    _createVNode(CampaignIdentificationSectionEdit),
    _createVNode(CampaignFormatSectionEdit),
    _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-lg" }),
    _createVNode(CampaignActionButtonsSection, {
      propShowBtnCancelCreateNewCampaign: true,
      propShowBtnReviewFocusedCampaign: true
    })
  ]))
}
}

})