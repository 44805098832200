import { defineStore }                    from 'pinia';
import { Ref, ref, computed}                       from 'vue';
import { ifFilterInterface, financialInstitutionInterface, financialInstitutionSlotInterface }  from '@/types/types.d';
import { FinancialInstitutionModel }      from '@/models/FinancialInstitutionModel';
import ApiService                         from '@/utils/ApiService';
import { exportAsCsvFile }                from '@/utils/commonUtils';
import { DEFAULT_IFS_EXPORT_FILENAME }    from '@/static/constants';
import { FinancialInstitutionFilterModel }                  from '@/models/FinancialInstitutionFilterModel';

export const useFinancialInstitutionStore = defineStore('financialInstitution', () => {
  // state
  const ifFilter    : Ref<ifFilterInterface>  = ref(new FinancialInstitutionFilterModel());
  const financialInstitutions         : Ref<financialInstitutionInterface[]> = ref([] as financialInstitutionInterface[]);
  const selectedFinancialInstitutions : Ref<financialInstitutionInterface[]> = ref([] as financialInstitutionInterface[]);
  const focusedFinancialInstitution   : Ref<financialInstitutionInterface>   = ref(new FinancialInstitutionModel({}));

  const pagination = ref({
    sortBy: 'codif',  
    descending: false,
    page: 2,   
    rowsPerPage: 5
  })

  // actions
  const fetchFinancialInstitutions = async (filterTerms?: string, selectedSlot?: 'slot1' | 'slot2' | 'slot3') => {
    try {
      const params = {
        filtroIfs: (filterTerms ? filterTerms : "")
      };

      const responseData = await ApiService.get<[FinancialInstitutionModel]>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_IFS,
        params
      );
        
      financialInstitutions.value.length = 0;

      // Mapeia os dados recebidos para o modelo e aplica o filtro de slot, se fornecido
      const filteredData = responseData
      .map(data => new FinancialInstitutionModel(data))
      .filter(f => !selectedSlot || f[selectedSlot] === true);

      // Adiciona as instituições financeiras filtradas à lista
      financialInstitutions.value.push(...filteredData);

      // financialInstitutions.value.push( ...responseData.map(data => new FinancialInstitutionModel(data)) );
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const updateFinancialInstitutions = async (operation: string, slot: string, ifList: financialInstitutionInterface[]) => {
    try {
      const payload: financialInstitutionSlotInterface = {
        codIfList: ifList.map(f => {
          return f.codif
        }),
        slot1: undefined,
        slot2: undefined,
        slot3: undefined
      };

      switch(slot) {
        case 'A1' : {
           payload["slot1"] = operation === "L" ? true : false;
          break;
        } 
        case 'A2' : {
           payload["slot2"] = operation === "L" ? true : false;
          break;
        } 
        case 'A3' : {
           payload["slot3"] = operation === "L" ? true : false;
          break;
        } 

        default: return;
      }

      console.log(payload)

      const response = await ApiService.put(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_UPDATE_IFS_SLOTS,
        payload
      );

      console.log(response);
        
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const resetState = () => {
    financialInstitutions.value.length          = 0;
    selectedFinancialInstitutions.value.length  = 0;
    focusedFinancialInstitution.value           = new FinancialInstitutionModel({});
  }

  const resetFilterState =() => {
    ifFilter.value.filterIF.codif = undefined
    ifFilter.value.filterIF.nome = ""
    ifFilter.value.filterIF.nomagrpacionista = ""
    ifFilter.value.filterIF.codagrpacionista = undefined
    }

  const exportIfsAsCsv = (paramIfsToExport: Array<financialInstitutionInterface>, paramFilename: string = DEFAULT_IFS_EXPORT_FILENAME) => {
    if (paramIfsToExport) {
      const ifsToExport = paramIfsToExport.map(i => ({
        //"Código"      : i.codif,
        //"Código"      : `'${i.codif}`,
        "Código"      : `="${i.codif}"`,
        "Instituição" : i.nome,
        "Categoria"   : i.nomagrpacionista
      }));
  
      exportAsCsvFile(ifsToExport, paramFilename);
    }
  }

  // getters

  return {
    // state
    ifFilter,
    financialInstitutions,
    selectedFinancialInstitutions,
    focusedFinancialInstitution,
    pagination,
    pagesNumber: computed(() => Math.ceil(financialInstitutions.value.length / pagination.value.rowsPerPage)),

    // actions
    fetchFinancialInstitutions,
    updateFinancialInstitutions,
    resetState,
    exportIfsAsCsv,
    resetFilterState

    // getters
  }
})
