import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row fit no-wrap items-top" }
const _hoisted_2 = { class: "q-pr-xl q-mr-xl" }
const _hoisted_3 = { class: "q-pl-xl q-ml-md" }
const _hoisted_4 = { class: "row fit no-wrap items-top" }
const _hoisted_5 = { class: "q-pr-xl q-mr-xl" }

import PageCaption	                              from '@/components/PageCaption.vue';
import CampaignIdentificationSectionView          from '@/components/campaign/CampaignIdentificationSectionView.vue';
import CampaignFormatSectionView                  from '@/components/campaign/CampaignFormatSectionView.vue';
import CampaignDevicesSection                     from '@/components/campaign/CampaignDevicesSection.vue';
import CampaignDevicesDistributionSection         from "@/components/campaign/CampaignDevicesDistributionSection.vue";
import CampaignRequestedAdjustmentsSection        from '@/components/campaign/CampaignRequestedAdjustmentsSection.vue';
import CampaignActionButtonsSection               from '@/components/campaign/CampaignActionButtonsSection.vue';
import ModalRequestAdjustments                    from '@/views/modals/ModalRequestAdjustments.vue';
import { useCampaignStore }               from '@/stores/campaignStore';
import {onMounted}                           from 'vue'
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageCampaignReview',
  setup(__props) {

const campaignStore = useCampaignStore();
const router = useRouter();

onMounted(() => {
      const from = sessionStorage.getItem('from');

      // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
      if (!from) {
        router.push({ name: 'home' });
      } else {
        sessionStorage.removeItem('from');
      }
    });

  router.beforeEach((to, from, next) => {
    sessionStorage.setItem('from', 'true'); // Mantém o histórico

    next(); // Continua a navegação normalmente
  });

return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_q_separator, {
      class: "q-pb-lg",
      withBackButton: true
    }),
    _createVNode(PageCaption, {
      pageCaptionTitle: "Revisão",
      pageCaptionDescription: "Revise a Campanha e Envie para Aprovação"
    }),
    _createVNode(CampaignIdentificationSectionView, {
      propCampaignToView: _unref(campaignStore).focusedCampaign
    }, null, 8, ["propCampaignToView"]),
    _createVNode(CampaignFormatSectionView),
    _createVNode(_component_c_q_separator, { class: "q-pb-md" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(CampaignDevicesSection)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(CampaignRequestedAdjustmentsSection)
      ])
    ]),
    _createVNode(_component_c_q_separator, { class: "q-pt-xl q-pb-md" }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(CampaignDevicesDistributionSection)
      ])
    ]),
    _createVNode(_component_c_q_separator, { class: "q-pt-xl q-pb-md" }),
    _createVNode(CampaignActionButtonsSection, {
      propShowBtnBackEdit: true,
      propShowBtnCancelCreateNewCampaign: true,
      propShowBtnSaveFocusedCampaign: true,
      propShowBtnSendFocusedCampaignToApprove: true,
      propShowBtnApproveFocusedCampaign: true,
      propShowBtnResendFocusedCampaignToApprove: true
    }),
    _createVNode(ModalRequestAdjustments)
  ]))
}
}

})