import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-center" }
const _hoisted_2 = { class: "col-auto" }

import PageCaption from "@/components/PageCaption.vue";
  import { usePagesUtilStore }	from '@/stores/pagesUtilStore';

  import { useContainmentStore } from '@/stores/containmentStore'
  import { computed, onBeforeMount } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ContainmentButton',
  setup(__props) {

  const containmentStore = useContainmentStore();
  const pagesUtilStore = usePagesUtilStore();

  const containmentData = computed(() => {
    return containmentStore.containmentData;
  })

  onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});
  

  const onActivateContainment = async () =>{
    pagesUtilStore.showLoading = true;
    try{  
      await containmentStore.onFetchContainmentActivate();
      await containmentStore.onFetchContainmentData();
    }catch(error){
      console.error(error);
    }finally{
      pagesUtilStore.showLoading = false;
    }
  }

  const onDesactivateContainment = async () =>{
    pagesUtilStore.showLoading = true;
    try{
      await containmentStore.onFetchContainmentDeactivate();
      await containmentStore.onFetchContainmentData();
    }catch(error){
      console.error(error);
    }finally{
      pagesUtilStore.showLoading = false;
    }
  }





return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_q_separator, {
      class: "q-pb-lg q-pt-md q-pl-lg",
      withBackButton: true
    }),
    _withDirectives(_createVNode(PageCaption, {
      class: "q-ma-lg",
      pageCaptionTitle: "Ativar Botão de Contenção",
      pageCaptionDescription: "Contenção desativada no momento. Ative para interromper a distribuição das campanhas às ATMs"
    }, null, 512), [
      [_vShow, !containmentData.value.is_active]
    ]),
    _withDirectives(_createVNode(PageCaption, {
      class: "q-ma-lg",
      pageCaptionTitle: "Desativar Botão de Contenção",
      pageCaptionDescription: "Contenção ativa no momento. Desative para retomar a distribuição das campanhas às ATMs"
    }, null, 512), [
      [_vShow, containmentData.value.is_active]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createVNode(_component_c_q_btn, {
          class: "text-bold",
          color: "red",
          padding: "lg",
          size: "10px",
          propBtnLabel: "Ativar Contenção",
          propBtnFunction: onActivateContainment
        }, null, 512), [
          [_vShow, !containmentData.value.is_active]
        ]),
        _withDirectives(_createVNode(_component_c_q_btn, {
          class: "text-bold",
          color: "red",
          padding: "lg",
          size: "10px",
          propBtnLabel: "Desativar Contenção",
          propBtnFunction: onDesactivateContainment
        }, null, 512), [
          [_vShow, containmentData.value.is_active]
        ])
      ])
    ])
  ]))
}
}

})