import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row items-center q-pb-xs" }
const _hoisted_2 = { class: "q-pr-sm" }
const _hoisted_3 = { class: "q-pr-sm" }
const _hoisted_4 = {
  id: "div-01",
  class: "row no-wrap items-center"
}
const _hoisted_5 = {
  id: "div-02",
  style: {"max-height":"100px","overflow-y":"scroll","width":"100%"}
}
const _hoisted_6 = {
  id: "div-03",
  class: "col-auto"
}

import ModalFinancialInstitutions	from '@/views/modals/ModalFinancialInstitutions.vue';
import ModalConfirmationDialog		from '@/views/modals/ModalConfirmationDialog.vue';
import ModalFilterExcludeIfs			from '@/views/modals/ModalFilterExcludeIfs.vue';
import { EnumButtonSizes }				from '@/enums/enums';
import {useFinancialInstitutionStore} 												from '@/stores/financialInstitutionStore'
import { useCampaignStore }				from '@/stores/campaignStore';
import { usePagesUtilStore }      from '@/stores/pagesUtilStore';
import { computed }								from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignInstitutions',
  setup(__props) {

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const financialInstitutionStore = useFinancialInstitutionStore();

const removeIfIdFromIfsIds = (ifIdToRemove: string) => {
	const index = campaignStore.focusedCampaign.ifsIds.indexOf(ifIdToRemove, 0);

	if (index > -1) {
		campaignStore.focusedCampaign.ifsIds.splice(index, 1);
	}
};

const onRemove = (
	scope: {
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opt: any;
    selected: boolean;
    removeAtIndex: (index: number) => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleOption: (opt: any) => void;
    tabindex: number;
	}
) => {
	scope.removeAtIndex(scope.index);

	removeIfIdFromIfsIds(scope.opt.codif);
};

const disableClearBtn = computed(() => {
	try {
		return (campaignStore.focusedCampaign.ifs.length == 0);
		
	} catch (error) {
		return false;
	}
});

/*const onClearBtnClick = () => {
  pagesUtilStore.showModalConfirmationDialog = true;
};*/

//NOVO
const onClearIfsBtnClick = () => {
  pagesUtilStore.showModalConfirmationDialogIfs = true;
};

const onClearSelectedInstitutionsConfirmation = () => {
	campaignStore.focusedCampaign.ifs.length = 0;
};

const onFilterBtnClick = () => {
	const { slot1, slot2, slot3 } = campaignStore.focusedCampaign;

	// Determina qual slot foi selecionado
  const selectedSlot = slot1 ? 'slot1' : slot2 ? 'slot2' : slot3 ? 'slot3' : null;

	if(!financialInstitutionStore.financialInstitutions.length != 0){
		financialInstitutionStore.fetchFinancialInstitutions("", selectedSlot);
	}
  pagesUtilStore.showModalFinancialInstitutions = true;
};

const onFilterExcludeBtnClick = () => {
  pagesUtilStore.showModalFilterExcludeIfs = true;

};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("span", {
        id: "span-01",
        class: "midia-app-label-sm q-pr-sm"
      }, "Instituições *", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_c_q_btn, {
          id: "btn-clear",
          propBtnLabel: "Limpar",
          propBtnFunction: onClearIfsBtnClick,
          propBtnTransparent: true,
          propBtnSize: _unref(EnumButtonSizes).SMALL,
          disable: disableClearBtn.value
        }, null, 8, ["propBtnSize", "disable"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_c_q_btn, {
          id: "btn-clear",
          propBtnLabel: "Excluir",
          propBtnFunction: onFilterExcludeBtnClick,
          propBtnTransparent: true,
          propBtnSize: _unref(EnumButtonSizes).SMALL,
          disable: disableClearBtn.value
        }, null, 8, ["propBtnSize", "disable"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_q_select, {
          modelValue: _unref(campaignStore).focusedCampaign.ifs,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).focusedCampaign.ifs) = $event)),
          color: "globalAppPrimaryColor",
          "bg-color": "globalAppDefaultBackgroundColor",
          outlined: "",
          dense: "",
          "use-chips": "",
          multiple: "",
          "hide-dropdown-icon": "",
          "input-debounce": "250",
          "new-value-mode": "add-unique",
          "input-style": " white-space: nowrap; overflow: hidden; text-overflow: ellipsis; "
        }, {
          "selected-item": _withCtx((scope) => [
            _createVNode(_component_q_chip, {
              removable: "",
              onRemove: ($event: any) => (onRemove(scope)),
              tabindex: scope.tabindex,
              color: "globalAppPrimaryColor",
              "text-color": "globalAppDefaultBackgroundColor",
              class: "q-ma-xs",
              size: "sm"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.opt.codif) + " - " + _toDisplayString(scope.opt.nome), 1)
              ]),
              _: 2
            }, 1032, ["onRemove", "tabindex"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_c_q_btn, {
          id: "btn-filtrar",
          propBtnLabel: "Filtrar",
          propBtnFunction: onFilterBtnClick,
          propBtnTransparent: true,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"])
      ])
    ]),
    _createVNode(ModalFinancialInstitutions),
    _createVNode(ModalFilterExcludeIfs),
    _createVNode(ModalConfirmationDialog, {
      propDialogTitle: "Atenção!",
      propConfirmationMsg: "Tem certeza de que deseja excluir todas as instituições selecionadas?",
      propDialogType: "institutions",
      onConfirm: onClearSelectedInstitutionsConfirmation
    })
  ]))
}
}

})