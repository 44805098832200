<template>
	<div>
    <div class="row q-pb-xs">
      <span class="midia-app-label-sm">Dispositivos da Campanha</span>
    </div>

		<div class="row fit no-wrap items-center q-pb-xs" style=" margin-left: -4px; ">
			<c-q-btn
				id="btn-icon-download-devices"
				padding="none"
				propBtnLabel=""
				:propBtnFunction="onDownloadDevicesGreenBtnClick"
				:propBtnTransparent="true"
				:propBtnSize="EnumButtonSizes.SMALL"
				:propBorderless="true"
			>
				<template v-slot:default>
					<q-icon size="32px">
						<img src="@/assets/icons/icon_HiOutlineDocumentDownload.svg" alt="green_arrow_down_icon">
					</q-icon>
				</template>
			</c-q-btn>

			<span class="midia-app-label-lg">{{ DEFAULT_PCS_EXPORT_FILENAME }}</span>
    </div>

		<div class="row q-py-xs">
			<c-q-btn
				id="btn-download-devices"
				propBtnLabel="Download"
				:propBtnFunction="onDownloadDevicesBtnClick"
				:propBtnTransparent="true"
				:propBtnSize="EnumButtonSizes.SMALL"
			/>
    </div>
	</div>
</template>

<script setup lang="ts">
import { EnumButtonSizes }							from '@/enums/enums';
import { useCampaignStore }							from '@/stores/campaignStore';
import { DEFAULT_PCS_EXPORT_FILENAME }	from '@/static/constants';

const campaignStore = useCampaignStore();

const onDownloadDevicesBtnClick = () => {
		campaignStore.exportFocusedCampaignPcsAsCsv(DEFAULT_PCS_EXPORT_FILENAME);
}

const onDownloadDevicesGreenBtnClick = () => {
	onDownloadDevicesBtnClick();
}
</script>

<style scoped>

</style>