<template>
	<c-q-btn
		v-if="authStore.currentUserCanPublishCampaign 
		&& campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.APPROVED.id"
		id="btn-publish-campaign"
		propBtnLabel="Publicar Campanha"
		:propBtnFunction="onPublishFocusedCampaignBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>

	<c-q-btn
		v-if="authStore.currentUserCanPublishCampaign 
		&& campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.REAPPROVED.id"
		id="btn-publish-campaign"
		propBtnLabel="Republicar Campanha"
		:propBtnFunction="onRepublishFocusedCampaignBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }				from '@/stores/authStore'
import messages               from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onPublishFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
   	await campaignStore.publishFocusedCampaign();
		
		messages.showSuccessMsg("Campanha publicada com sucesso!");

  } catch (errorApprovingCampaign) {
    messages.showErrorMsg("Erro ao tentar publicar a campanha...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};


const onRepublishFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
   	await campaignStore.republishFocusedCampaign();
		
		messages.showSuccessMsg("Campanha republicada com sucesso!");

  } catch (errorApprovingCampaign) {
    messages.showErrorMsg("Erro ao tentar republicar a campanha...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
