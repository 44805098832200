import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pb-md" }
const _hoisted_2 = { class: "row col" }
const _hoisted_3 = { class: "q-pb-md" }
const _hoisted_4 = { class: "row col" }
const _hoisted_5 = { class: "q-pb-md" }
const _hoisted_6 = { class: "row col" }
const _hoisted_7 = { class: "q-pb-md" }
const _hoisted_8 = { class: "row col" }
const _hoisted_9 = { class: "q-pb-md" }
const _hoisted_10 = { class: "row col" }
const _hoisted_11 = { class: "q-pb-md" }
const _hoisted_12 = { class: "row col" }
const _hoisted_13 = { class: "q-pb-md" }
const _hoisted_14 = { class: "row col" }
const _hoisted_15 = { class: "row fit no-wrap justify-center items-center content-start q-pb-lg" }
const _hoisted_16 = {
  class: "row fit no-wrap justify-end",
  style: {"overflow":"auto"}
}
const _hoisted_17 = {
  class: "q-pl-md",
  style: {"overflow":"auto"}
}
const _hoisted_18 = {
  class: "q-pl-md",
  style: {"overflow":"auto"}
}

import { EnumButtonSizes } from "@/enums/enums";
import { usePCStore } from "@/stores/pcStore";
import { useCampaignStore } from "@/stores/campaignStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { computed } from "vue";
import { PcModel } from "@/models/PcModel";
import messages from "@/utils/messages";
import ModalPCs from "./ModalPCs.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFilterPcs',
  setup(__props) {

const pcStore = usePCStore();
const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();

const onFetchBtnClick = () => {
  pcStore.resetState();
  try {
    pagesUtilStore.showLoading = true;

    if (
      !pcStore.pcFilter.filterPc.codpontocaptura &&
      !pcStore.pcFilter.filterPc.nompontocaptura &&
      !pcStore.pcFilter.filterPc.nomcidredeautoatndto &&
      !pcStore.pcFilter.filterPc.ufredeautoatndto &&
      !pcStore.pcFilter.filterPc.nomsubredeautoatndto &&
      !pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto &&
      !pcStore.pcFilter.filterPc.nomtpmaqautoatndto
    ) {
      messages.showWarningMsg("Pelo menos um filtro deve ser preenchido.");
    } else {
      pagesUtilStore.showModalPCs = true;
    }
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

const onContinueBtnClick = () => {
  pcStore.resetFilterState();
  pagesUtilStore.showModalFilterPCs = false;
};

const selectedPcsNotYetOnTheCampaign = computed(() => {
  return pcStore.selectedPCs.filter(
    (pc) =>
      !campaignStore.focusedCampaign.pcs.some(
        (campaignPc) => campaignPc.codpontocaptura == pc.codpontocaptura
      )
  );
});

return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalFilterPCs,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(pagesUtilStore).showModalFilterPCs) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"500px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: "Filtro de PCs",
                propModalDescription: "Pesquise um PC"
              }),
              _createElementVNode("div", _hoisted_1, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Código do PC")
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.codpontocaptura,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.codpontocaptura) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Código do PC",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Nome do PC")
                ], -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.nompontocaptura,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.nompontocaptura) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Nome do PC",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Cidade")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.nomcidredeautoatndto,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.nomcidredeautoatndto) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe a Cidade",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "UF")
                ], -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.ufredeautoatndto,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.ufredeautoatndto) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe a UF",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Subrede")
                ], -1)),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.nomsubredeautoatndto,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.nomsubredeautoatndto) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe a Subrede",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Segmento")
                ], -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.nomsegmmerclocalptoatndto,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.nomsegmmerclocalptoatndto) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Segmento",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Tipo de Máquina")
                ], -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(pcStore).pcFilter.filterPc.nomtpmaqautoatndto,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(pcStore).pcFilter.filterPc.nomtpmaqautoatndto) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Tipo de Máquina",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_c_q_btn, {
                      id: "btn-fetch",
                      propBtnLabel: "Buscar",
                      propBtnFunction: onFetchBtnClick,
                      propBtnTransparent: false,
                      propBtnSize: _unref(EnumButtonSizes).LARGE_1
                    }, null, 8, ["propBtnSize"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_c_q_btn, {
                      id: "btn-continue",
                      propBtnLabel: "Continuar",
                      propBtnFunction: onContinueBtnClick,
                      propBtnTransparent: false,
                      propBtnSize: _unref(EnumButtonSizes).LARGE_1
                    }, null, 8, ["propBtnSize"])
                  ])
                ])
              ]),
              (_unref(pagesUtilStore).showModalPCs)
                ? (_openBlock(), _createBlock(ModalPCs, { key: 0 }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})