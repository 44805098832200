import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "midia-app-content-lg" }

import { useCampaignStore } from '@/stores/campaignStore';
import { Ref, ref }					from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignAgency',
  setup(__props) {

const campaignStore	= useCampaignStore();

const agencyOptions	= [
    'Nacional',
	'Propeg',
	'Ogilvy',
	'Calia',
	'De Brito',
	'Opus Multipla',
	'Mene e Portella',
	'Media Brands',
	'OCTOPUS',
	'Acessooh',
	'ID Publicidade',
	'Nova S/B',
	'Mestiça Propaganda',
	'Babel',
	'WMCCANN',
	'MEDIABRANDS PUBLICIDADE (BE180)',
	'David',
	'Dual Mídia',
	'MOMENTUM',
	'Casa Blanca'
];

const options = ref(agencyOptions);

const filterFN = (val: string, update: (callback: () => void) => void, abort: () => void) => {
	update(() => {
		const needle = val.toLocaleLowerCase();
		options.value = agencyOptions.filter((v: string) => v.toLocaleLowerCase().indexOf(needle) > -1);
	});
};

const createValue = ((
		val: string,
		done: ref<(
			inputValue: string,
			doneFn: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				item?: any,
				mode?: "add" | "add-unique" | "toggle" | undefined
			) => void
		) => void>
	) => {

	if (val.length > 0) {
		if (!agencyOptions.includes(val)) {
			agencyOptions.push(val)
		}
		done(val, 'toggle')
	}
});

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "midia-app-caption-sm" }, "Agência", -1)),
    _createVNode(_component_q_select, {
      modelValue: _unref(campaignStore).focusedCampaign.agency,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).focusedCampaign.agency) = $event)),
      maxlength: "500",
      options: options.value,
      onFilter: filterFN,
      "use-input": "",
      outlined: "",
      "use-chips": "",
      onNewValue: createValue,
      dense: "",
      color: "globalAppPrimaryColor",
      "bg-color": "globalAppDefaultBackgroundColor",
      "label-slot": !_unref(campaignStore).focusedCampaign.agency,
      readonly: _unref(campaignStore).focusedCampaign.iscampaignpublished
    }, {
      label: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "midia-app-placeholder-md" }, "Selecione uma Agência ou digite e tecle Enter", -1)
      ])),
      selected: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(campaignStore).focusedCampaign.agency), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "options", "label-slot", "readonly"])
  ]))
}
}

})