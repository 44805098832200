<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalFilterExcludeIfs"
      :no-backdrop-dismiss="true"
    >
      <q-card
        class="midia-app-padding-modal"
        style="width: 500px; max-width: 80vw"
      >
      
        <div>
          <c-modal-title
            propModalTitle="Filtro de IFs"
            propModalDescription="Filtre as IFs que você deseja Excluir!"
          />

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Código</span>
            </div>

            <div class="row col">
              <q-input
                color="globalAppPrimaryColor"
                v-model="financialInstitutionStore.ifFilter.filterIF.codif"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
                placeholder="Informe o Código do PC"
                input-class="midia-app-content-lg"
                style="width: 100%"
              />
            </div>
          </div>

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Instituição</span>
            </div>

            <div class="row col">
              <q-input
                color="globalAppPrimaryColor"
                v-model="financialInstitutionStore.ifFilter.filterIF.nome"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
                placeholder="Informe o Nome do PC"
                input-class="midia-app-content-lg"
                style="width: 100%"
              />
            </div>
          </div>

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Categoria</span>
            </div>

            <div class="row col">
              <!-- <q-input
                color="globalAppPrimaryColor"
                v-model="financialInstitutionStore.ifFilter.filterIF.nomagrpacionista"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
                placeholder="Informe a Cidade"
                input-class="midia-app-content-lg"
                style="width: 100%"
              /> -->
               <q-select
                  color="globalAppPrimaryColor"
                  v-model="financialInstitutionStore.ifFilter.filterIF.nomagrpacionista"
                  :options="fetchedIfs"
                  outlined
                  dense
                  use-input
                  input-class="midia-app-content-lg"
                  bg-color="globalAppDefaultBackgroundColor"
                  option-label="nomagrpacionista"
                  option-value="nomagrpacionista"
                  label="Selecione uma opção"
                  style="width: 100%"
                  emit-value
                  map-options
                />
            </div>
          </div>

          <div
            class="row fit no-wrap justify-center items-center content-start q-pb-lg"
          >
            <div class="row fit no-wrap justify-end" style="overflow: auto">
              <div class="q-pl-md" style="overflow: auto">
                <c-q-btn
                  id="btn-fetch"
                  propBtnLabel="Buscar"
                  :propBtnFunction="onFetchBtnClick"
                  :propBtnTransparent="false"
                  :propBtnSize="EnumButtonSizes.LARGE_1"
                />
              </div>
              <div class="q-pl-md" style="overflow: auto">
                <c-q-btn
                  id="btn-continue"
                  propBtnLabel="Cancelar"
                  :propBtnFunction="onCancelBtnClick"
                  :propBtnTransparent="false"
                  :propBtnSize="EnumButtonSizes.LARGE_1"
                />
              </div>
            </div>
          </div>

          <ModalIfsExclude v-if="pagesUtilStore.showModalExcludeIfs" />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>
<script setup lang="ts">
import { EnumButtonSizes } from "@/enums/enums";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import {useCampaignStore}         from "@/stores/campaignStore"
import {useFinancialInstitutionStore} from "@/stores/financialInstitutionStore"
import messages from "@/utils/messages";
import ModalIfsExclude from "./ModalIFsExclude"
import { computed, onBeforeMount } from 'vue';

const pagesUtilStore = usePagesUtilStore();
const financialInstitutionStore = useFinancialInstitutionStore();
const campaignStore = useCampaignStore();

const filterOptions = () => {
      // Isso é necessário para garantir que as opções sejam mostradas corretamente
      financialInstitutionStore.selectedFinancialInstitutions = [...financialInstitutionStore.selectedFinancialInstitutions]
}

const onFetchBtnClick = () => {
  financialInstitutionStore.resetState();
  try {
    pagesUtilStore.showLoading = true;
    if (
      !financialInstitutionStore.ifFilter.filterIF.codif &&
      !financialInstitutionStore.ifFilter.filterIF.nome &&
      !financialInstitutionStore.ifFilter.filterIF.nomagrpacionista
    ) {
      messages.showWarningMsg("Pelo menos um filtro deve ser preenchido.");
    } else {
      pagesUtilStore.showModalExcludeIfs = true;
    }
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

const onCancelBtnClick = () => {
  financialInstitutionStore.resetFilterState();
  financialInstitutionStore.resetState();
  pagesUtilStore.showModalFilterExcludeIfs = false;
};

const fetchedIfs = computed(()=>{
  return campaignStore.focusedCampaign.ifs;
})


// onBeforeMount(async() =>{
//   console.log('teste')
//   await financialInstitutionStore.fetchFinancialInstitutions();
//   console.log(financialInstitutionStore.financialInstitutions);
// })

</script>