import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pb-md" }
const _hoisted_2 = { class: "row col" }
const _hoisted_3 = { class: "q-pb-md" }
const _hoisted_4 = { class: "row col" }
const _hoisted_5 = { class: "q-pb-md" }
const _hoisted_6 = { class: "row col" }
const _hoisted_7 = { class: "midia-app-content-lg" }
const _hoisted_8 = { class: "q-pb-md" }
const _hoisted_9 = { class: "row col" }
const _hoisted_10 = { class: "midia-app-content-lg" }
const _hoisted_11 = { class: "row fit no-wrap justify-end" }
const _hoisted_12 = { class: "custom-icon-color" }

import { EnumButtonSizes } from "@/enums/enums";
import { useCampaignStore } from "@/stores/campaignStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { computed, ref } from "vue";

import messages from "@/utils/messages";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFilterCampaignDistributionPcs',
  emits: ["filteredTable"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();

const statusOptions = computed(() => {
  return [
    ...new Set(
      campaignStore.campaignDistribtion.pcsCampaign?.map((pc) => pc.status)
    ),
  ];
});

const reasonOptions = computed(() => {
  return [
    ...new Set(
      campaignStore.campaignDistribtion.pcsCampaign?.map((pc) => pc.motivo)
    ),
  ];
});

const pcCod = ref("");
const pcName = ref("");
const statusFilter = ref(null);
const reasonFilter = ref(null);

const onFetchBtnClick = () => {
  try {
    let filteredTable =
      campaignStore?.campaignDistribtion?.pcsCampaign &&
      campaignStore.campaignDistribtion.pcsCampaign.length > 0
        ? campaignStore.campaignDistribtion.pcsCampaign
        : [];

    const filterStatus = statusFilter?.value
      ? (statusFilter.value as string).toLowerCase()
      : "";
    const filterReason = reasonFilter?.value
      ? (reasonFilter.value as string).toLowerCase()
      : "";

    filteredTable = filteredTable.filter(
      (pc) =>
        pc?.pc?.toString().toLowerCase().includes(pcCod.value.toLowerCase()) &&
        pc?.nome?.toLowerCase().includes(pcName.value.toLowerCase()) &&
        pc?.status?.toLowerCase().includes(filterStatus.toLowerCase()) &&
        pc?.motivo?.toLowerCase().includes(filterReason)
    );
    emit("filteredTable", filteredTable);
  } catch (error) {
    console.log(error);
    messages.showErrorMsg(`Erro inesperado: ${error?.message}`);
  } finally {
    clearFilters();
    pagesUtilStore.showModalFilterCampaignDistributionPcs = false;
  }
};

const clearFilters = () => {
  pcCod.value = "";
  pcName.value = "";
  statusFilter.value = null;
  reasonFilter.value = null;
};

return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalFilterCampaignDistributionPcs,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(pagesUtilStore).showModalFilterCampaignDistributionPcs) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"500px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: "Filtro de  Distribução de Campanha",
                propModalDescription: "Pesquise PCs da Distribuição de Campanhas"
              }),
              _createElementVNode("div", _hoisted_1, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Código do PC")
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    modelValue: pcCod.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((pcCod).value = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Código do PC",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Nome do PC")
                ], -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    modelValue: pcName.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((pcName).value = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe o Nome do PC",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Status")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_select, {
                    modelValue: statusFilter.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((statusFilter).value = $event)),
                    options: statusOptions.value,
                    outlined: "",
                    dense: "",
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    style: {"width":"100%"},
                    "label-slot": !statusFilter.value
                  }, {
                    label: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("span", {
                        class: "midia-app-content-lg",
                        style: {"color":"var(--globalAppSecondaryColor2) !important"}
                      }, "Informe o status", -1)
                    ])),
                    selected: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(statusFilter.value), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options", "label-slot"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Motivo")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_q_select, {
                    modelValue: reasonFilter.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((reasonFilter).value = $event)),
                    options: reasonOptions.value,
                    outlined: "",
                    dense: "",
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    style: {"width":"100%"},
                    "label-slot": !reasonFilter.value
                  }, {
                    label: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("span", {
                        class: "midia-app-content-lg",
                        style: {"color":"var(--globalAppSecondaryColor2) !important"}
                      }, "Informe o motivo", -1)
                    ])),
                    selected: _withCtx(() => [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(reasonFilter.value), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options", "label-slot"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_c_q_btn, {
                  propBtnLabel: "Limpar Filtros",
                  propBtnFunction: clearFilters,
                  propBtnTransparent: true,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1,
                  class: "q-ml-md"
                }, {
                  afterLabel: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_q_icon, {
                        size: "1em",
                        name: "backspace",
                        class: "q-ml-sm"
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["propBtnSize"]),
                _createVNode(_component_c_q_btn, {
                  propBtnLabel: "Filtrar",
                  propBtnFunction: onFetchBtnClick,
                  propBtnTransparent: false,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1,
                  class: "q-ml-md"
                }, null, 8, ["propBtnSize"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})