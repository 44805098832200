import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row fit no-wrap q-pb-lg"
}
const _hoisted_2 = {
  class: "col-grow",
  style: {"overflow":"auto"}
}
const _hoisted_3 = { class: "row fit no-wrap q-pb-lg" }
const _hoisted_4 = {
  class: "col-grow",
  style: {"overflow":"auto"}
}
const _hoisted_5 = {
  class: "row fit flex no-wrap justify-between q-pb-xs",
  style: {"width":"100%"}
}
const _hoisted_6 = { class: "q-pr-sm" }
const _hoisted_7 = { class: "col col-grow q-pl-sm" }
const _hoisted_8 = { class: "col col-grow q-pl-sm" }

import { useCampaignStore } 																	from '@/stores/campaignStore';
import { usePagesUtilStore }																	from '@/stores/pagesUtilStore';
import { ref }																								from 'vue';
import { EnumRequestedAdjustmentsModalType, EnumButtonSizes }	from '@/enums/enums';
import { CONST_CAMPAIGN_STATUS }   														from '@/static/constants';
import messages               																from '@/utils/messages';
import {computed}																							from 'vue'
import { useAuthStore }					from '@/stores/authStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalRequestAdjustments',
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const disableEditAskAdjustmen = computed(() => {
	return campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id
	|| campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id
	|| campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.PUBLISHED.id
	|| campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.APPROVED.id
	|| (campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ON_APPROVAL.id && campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id && !authStore.currentUserCanApproveCampaign)
})

/*const onOkClick = () => {
	if (campaignStore.refRequestedAdjustments) {
		campaignStore.focusedCampaign.commentadjustment = campaignStore.refRequestedAdjustments;
		pagesUtilStore.showModalRequestAdjustments = false;
	} 
};*/

/*const onBeforeShow = () => {
	if (campaignStore.focusedCampaign.commentadjustment) {
		campaignStore.refRequestedAdjustments = campaignStore.focusedCampaign.commentadjustment;

	} 
	else {
		campaignStore.refRequestedAdjustments = '';
	}
};*/

const onSendRequestedAdjustmentsBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.sendRequestedAdjustmentsFocusedCampaign(campaignStore.refRequestedAdjustments.trim());
		
		campaignStore.refRequestedAdjustments = "";

		messages.showSuccessMsg("Solicitação de ajustes realizada com sucesso!");

		pagesUtilStore.showModalRequestAdjustments = false;

  } catch (errorRequestingCampaignAdjustments: any) {
    messages.showErrorMsg(errorRequestingCampaignAdjustments.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};


const onSendRequestedAdjustmentsAgainBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.sendRequestedAdjustmentsAgainFocusedCampaign(campaignStore.refRequestedAdjustments.trim());
		
		campaignStore.refRequestedAdjustments = "";

		messages.showSuccessMsg("Solicitação de reajuste realizada com sucesso!");

		pagesUtilStore.showModalRequestAdjustments = false;

  } catch (errorRequestingCampaignAdjustments: any) {
    messages.showErrorMsg(errorRequestingCampaignAdjustments.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};


return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalRequestAdjustments,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(pagesUtilStore).showModalRequestAdjustments) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"448px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: "Solicitar Ajuste",
                propModalDescription: (
							(_unref(pagesUtilStore).modalRequestedAdjustmentsModalType == _unref(EnumRequestedAdjustmentsModalType).CREATE)	? 'Informe os ajustes necessários:'		:
							(_unref(pagesUtilStore).modalRequestedAdjustmentsModalType == _unref(EnumRequestedAdjustmentsModalType).UPDATE)	? 'Verifique os Ajustes Solicitados'	:
							''
						)
              }, null, 8, ["propModalDescription"]),
              (_unref(campaignStore).focusedCampaign.commentadjustment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_q_input, {
                        style: {"position":"relative"},
                        modelValue: _unref(campaignStore).focusedCampaign.commentadjustment,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).focusedCampaign.commentadjustment) = $event)),
                        type: "textarea",
                        maxlength: "255",
                        color: "globalAppPrimaryColor",
                        "bg-color": "globalAppDefaultBackgroundColor",
                        outlined: "",
                        dense: "",
                        placeholder: "Digite aqui...",
                        "input-class": "midia-app-content-lg",
                        readonly: true
                      }, null, 8, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    style: {"position":"relative"},
                    modelValue: _unref(campaignStore).refRequestedAdjustments,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(campaignStore).refRequestedAdjustments) = $event)),
                    type: "textarea",
                    maxlength: "255",
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Digite aqui...",
                    "input-class": "midia-app-content-lg",
                    disable: disableEditAskAdjustmen.value
                  }, null, 8, ["modelValue", "disable"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createVNode(_component_c_q_btn, {
                    id: "btn-cancel",
                    propBtnLabel: "Cancelar",
                    propBtnTransparent: true,
                    propBtnSize: _unref(EnumButtonSizes).LARGE_1
                  }, null, 8, ["propBtnSize"]), [
                    [_directive_close_popup]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (_unref(campaignStore).focusedCampaign.iscampanhaedit && _unref(campaignStore).focusedCampaign.status !== _unref(CONST_CAMPAIGN_STATUS).ON_APPROVAL.id && _unref(campaignStore).focusedCampaign.status !== _unref(CONST_CAMPAIGN_STATUS).ON_RE_APPROVAL.id)
                    ? _withDirectives((_openBlock(), _createBlock(_component_c_q_btn, {
                        key: 0,
                        id: "btn-adjustments",
                        style: {"width":"100%"},
                        propBtnLabel: "OK",
                        propBtnTransparent: false,
                        propBtnSize: _unref(EnumButtonSizes).LARGE_1
                      }, null, 8, ["propBtnSize"])), [
                        [_directive_close_popup]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (_unref(campaignStore).focusedCampaign.status === _unref(CONST_CAMPAIGN_STATUS).ON_APPROVAL.id)
                    ? (_openBlock(), _createBlock(_component_c_q_btn, {
                        key: 0,
                        id: "btn-send-requested-adjustments",
                        style: {"width":"100%"},
                        propBtnLabel: "Enviar",
                        propBtnFunction: onSendRequestedAdjustmentsBtnClick,
                        propBtnTransparent: false,
                        propBtnSize: _unref(EnumButtonSizes).LARGE_1,
                        disable: (!_unref(campaignStore).refRequestedAdjustments?.trim())
                      }, null, 8, ["propBtnSize", "disable"]))
                    : _createCommentVNode("", true),
                  (_unref(campaignStore).focusedCampaign.status === _unref(CONST_CAMPAIGN_STATUS).ON_RE_APPROVAL.id)
                    ? (_openBlock(), _createBlock(_component_c_q_btn, {
                        key: 1,
                        id: "btn-send-requested-adjustments",
                        style: {"width":"100%"},
                        propBtnLabel: "Enviar",
                        propBtnFunction: onSendRequestedAdjustmentsAgainBtnClick,
                        propBtnTransparent: false,
                        propBtnSize: _unref(EnumButtonSizes).LARGE_1,
                        disable: (!_unref(campaignStore).refRequestedAdjustments?.trim())
                      }, null, 8, ["propBtnSize", "disable"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})