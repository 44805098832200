import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_arrow_back.svg'


import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter, useRoute }        from 'vue-router';



export default /*@__PURE__*/_defineComponent({
  __name: 'BackButton',
  setup(__props) {

const router = useRouter();
const route = useRoute();

const backToPreviousPage = () => {
  
  if(route.name == EnumRouteNames.CAMPAIGN_VIEW) {
    router.push({ name: EnumRouteNames.CAMPAIGN_HOME });
  } 
  else {
    router.back();
  }
  
			
};

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createBlock(_component_c_q_btn, {
    id: "back-button",
    propBtnLabel: "Voltar",
    propBtnFunction: backToPreviousPage,
    propBtnTransparent: true,
    propBtnSize: _unref(EnumButtonSizes).SMALL
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_icon, {
        size: "16px",
        class: "q-mr-xs"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            class: "svg-icon",
            src: _imports_0,
            style: {"width":"18px","height":"24px"}
          }, null, -1)
        ])),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["propBtnSize"]))
}
}

})