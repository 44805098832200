import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineCalendar.svg'


const _hoisted_1 = { class: "row fit no-wrap justify-center items-end content-start q-pb-md" }
const _hoisted_2 = { class: "col-grow q-pr-lg" }
const _hoisted_3 = { style: {"width":"160px"} }
const _hoisted_4 = { class: "row items-center justify-end" }
const _hoisted_5 = { style: {"width":"160px"} }
const _hoisted_6 = { class: "row items-center justify-end" }
const _hoisted_7 = { class: "row q-pb-md no-wrap" }
const _hoisted_8 = {
  class: "col-6 col-md-6",
  style: {"padding-right":"14px"}
}
const _hoisted_9 = {
  class: "col-6 col-md-6",
  style: {"padding-left":"14px"}
}
const _hoisted_10 = { class: "row no-wrap" }
const _hoisted_11 = {
  class: "col-6 col-md-6",
  style: {"padding-right":"14px"}
}
const _hoisted_12 = {
  class: "col-6 col-md-6",
  style: {"padding-left":"14px"}
}

import ButtonCloseModal                 from '@/components/ButtonCloseModal.vue';
import CampaignAdvertiser               from './CampaignAdvertiser.vue';
import CampaignAgency                   from './CampaignAgency.vue';
import CampaignFormat                   from './CampaignFormat.vue';
import { useCampaignStore }             from '@/stores/campaignStore';
import { ref, computed }                from 'vue';
import { convertDateToStringYyyyMmDd }  from '@/utils/commonUtils';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';;
import { CONST_CAMPAIGN_STATUS }        from '@/static/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignIdentificationSectionEdit',
  setup(__props) {

const campaignStore = useCampaignStore();

const qPopupProxyStartDate  : { value: QPopupProxy | null } = ref(null);
const qPopupProxyEndDate    : { value: QPopupProxy | null } = ref(null);

const updateStartDate = () => {
  if (campaignStore.focusedCampaign.startDateDdMmYyyy) {
    campaignStore.focusedCampaign.startDate = campaignStore.focusedCampaign.startDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateStartDateDdMmYyyy = () => {
  if (campaignStore.focusedCampaign.startDate) {
    campaignStore.focusedCampaign.startDateDdMmYyyy = campaignStore.focusedCampaign.startDate.split('-').reverse().join('/');
  }

  if (qPopupProxyStartDate.value) {
    qPopupProxyStartDate.value.hide();
  }
};

const updateEndDate = () => {
  if (campaignStore.focusedCampaign.endDateDdMmYyyy) {
    campaignStore.focusedCampaign.endDate = campaignStore.focusedCampaign.endDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateEndDateDdMmYyyy = () => {
  if (campaignStore.focusedCampaign.endDate) {
    campaignStore.focusedCampaign.endDateDdMmYyyy = campaignStore.focusedCampaign.endDate.split('-').reverse().join('/');
  }

  if (qPopupProxyEndDate.value) {
    qPopupProxyEndDate.value.hide();
  }
};

const startDateMinDateAllowedStringYyyyMmDd = computed(() => {
  const today = new Date();
  
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  return convertDateToStringYyyyMmDd(tomorrow);
});

const endDateMinDateAllowedStringYyyyMmDd = computed(() => {
  const oneDayAfterStartDate = (
    campaignStore.focusedCampaign.startDate
    ? new Date(campaignStore.focusedCampaign.startDate + " 00:00:00")
    : new Date(startDateMinDateAllowedStringYyyyMmDd.value + " 00:00:00")
  );
  
  oneDayAfterStartDate.setDate(oneDayAfterStartDate.getDate() + 1);

  return convertDateToStringYyyyMmDd(oneDayAfterStartDate);
});

const startDateDefaultYearMonth = computed(() => {
  const dateStringYyyyMm = startDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/').substring(0, 7);

  return dateStringYyyyMm;
});

const endDateDefaultYearMonth = computed(() => {
  const dateStringYyyyMm = endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/').substring(0, 7);

  return dateStringYyyyMm;
});

const startDateOptions = (dateStringYyyyMmDd: string) => {
  return (dateStringYyyyMmDd >= startDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/'));
};

const endDateOptions = (dateStringYyyyMmDd: string) => {
  return (dateStringYyyyMmDd >= endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/'));
};

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_separator = _resolveComponent("c-q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "midia-app-caption-sm" }, "Nome da Campanha *", -1)),
        _createVNode(_component_q_input, {
          modelValue: _unref(campaignStore).focusedCampaign.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).focusedCampaign.name) = $event)),
          type: "text",
          maxlength: "500",
          color: "globalAppPrimaryColor",
          "bg-color": "globalAppDefaultBackgroundColor",
          outlined: "",
          dense: "",
          placeholder: "Informe o nome da Campanha",
          "input-class": "midia-app-content-lg",
          readonly: _unref(campaignStore).focusedCampaign.iscampaignpublished || _unref(campaignStore).focusedCampaign.isapproved || _unref(campaignStore).focusedCampaign.ispendingpublication
        }, null, 8, ["modelValue", "readonly"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "midia-app-caption-sm" }, "Período de Vigência *", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_q_input, {
            style: {"outline":"1px solid var(--globalAppSecondaryColor2)"},
            modelValue: _unref(campaignStore).focusedCampaign.startDateDdMmYyyy,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_unref(campaignStore).focusedCampaign.startDateDdMmYyyy) = $event)),
              updateStartDate
            ],
            mask: "##/##/####",
            "bg-color": "globalAppDefaultBackgroundColor",
            borderless: "",
            dense: "",
            "input-class": "midia-app-content-lg",
            readonly: _unref(campaignStore).focusedCampaign.iscampaignpublished
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                class: "cursor-pointer q-pa-sm q-mr-sm",
                style: {"background":"var(--globalAppPrimaryColor)"}
              }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("img", {
                    class: "svg-icon",
                    src: _imports_0
                  }, null, -1)),
                  _createVNode(_unref(QPopupProxy), {
                    ref_key: "qPopupProxyStartDate",
                    ref: qPopupProxyStartDate,
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: _unref(campaignStore).focusedCampaign.startDate,
                        "onUpdate:modelValue": [
                          _cache[1] || (_cache[1] = ($event: any) => ((_unref(campaignStore).focusedCampaign.startDate) = $event)),
                          updateStartDateDdMmYyyy
                        ],
                        mask: "YYYY-MM-DD",
                        minimal: "",
                        options: startDateOptions,
                        "default-year-month": `${startDateDefaultYearMonth.value}`,
                        color: "globalAppPrimaryColor",
                        disable: _unref(campaignStore).focusedCampaign.iscampaignpublished
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(ButtonCloseModal)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "default-year-month", "disable"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "readonly"])
        ])
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "q-px-sm q-pb-sm" }, [
        _createElementVNode("span", { class: "midia-app-caption-sm" }, "até")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_input, {
            style: {"outline":"1px solid var(--globalAppSecondaryColor2)"},
            modelValue: _unref(campaignStore).focusedCampaign.endDateDdMmYyyy,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = ($event: any) => ((_unref(campaignStore).focusedCampaign.endDateDdMmYyyy) = $event)),
              updateEndDate
            ],
            mask: "##/##/####",
            "bg-color": "globalAppDefaultBackgroundColor",
            borderless: "",
            dense: "",
            "input-class": "midia-app-content-lg",
            readonly: _unref(campaignStore).focusedCampaign.iscampaignpublished
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                class: "cursor-pointer q-pa-sm q-mr-sm",
                style: {"background":"var(--globalAppPrimaryColor)"}
              }, {
                default: _withCtx(() => [
                  _cache[9] || (_cache[9] = _createElementVNode("img", {
                    class: "svg-icon",
                    src: _imports_0
                  }, null, -1)),
                  _createVNode(_unref(QPopupProxy), {
                    ref_key: "qPopupProxyEndDate",
                    ref: qPopupProxyEndDate,
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: _unref(campaignStore).focusedCampaign.endDate,
                        "onUpdate:modelValue": [
                          _cache[3] || (_cache[3] = ($event: any) => ((_unref(campaignStore).focusedCampaign.endDate) = $event)),
                          updateEndDateDdMmYyyy
                        ],
                        mask: "YYYY-MM-DD",
                        minimal: "",
                        options: endDateOptions,
                        "default-year-month": `${endDateDefaultYearMonth.value}`,
                        color: "globalAppPrimaryColor",
                        disable: _unref(campaignStore).focusedCampaign.iscampaignpublished
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(ButtonCloseModal)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "default-year-month", "disable"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "readonly"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(CampaignAdvertiser, { "prop-use-input": true })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(CampaignAgency)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "midia-app-caption-sm" }, "Valor do PI", -1)),
        _createVNode(_component_q_input, {
          modelValue: _unref(campaignStore).focusedCampaign.piValue,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(campaignStore).focusedCampaign.piValue) = $event)),
          mask: "#.###.###.###.###,##",
          "unmasked-value": "",
          "reverse-fill-mask": "",
          color: "globalAppPrimaryColor",
          "bg-color": "globalAppDefaultBackgroundColor",
          maxlength: "15",
          outlined: "",
          dense: "",
          placeholder: "Informe o valor do PI",
          "input-class": "midia-app-content-lg",
          prefix: "R$ ",
          readonly: _unref(campaignStore).focusedCampaign.iscampaignpublished
        }, null, 8, ["modelValue", "readonly"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(CampaignFormat)
      ])
    ]),
    (_unref(campaignStore).focusedCampaign.format)
      ? (_openBlock(), _createBlock(_component_c_q_separator, {
          key: 0,
          class: "q-pt-lg q-pb-lg"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})