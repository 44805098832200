<template>
  <div>
    <div class="row q-pb-xs">
      <span class="midia-app-label-sm">Lista de Distribuição</span>
    </div>

    <div class="row fit no-wrap items-center q-pb-xs" style="margin-left: -4px">
      <c-q-btn
        id="btn-icon-download-devices"
        padding="none"
        propBtnLabel=""
        :propBtnFunction="onDownloadDevicesGreenBtnClick"
        :propBtnTransparent="true"
        :propBtnSize="EnumButtonSizes.SMALL"
        :propBorderless="true"
      >
        <template v-slot:default>
          <q-icon size="32px">
            <img
              src="@/assets/icons/icon_HiOutlineDocumentDownload.svg"
              alt="green_arrow_down_icon"
            />
          </q-icon>
        </template>
      </c-q-btn>

      <span class="midia-app-label-lg">{{
        DEFAULT_DISTRIBUTION_EXPORT_FILENAME
      }}</span>
    </div>

    <div class="row q-py-xs">
      <c-q-btn
        id="btn-download-devices"
        propBtnLabel="Download"
        :propBtnFunction="onDownloadDevicesBtnClick"
        :propBtnTransparent="true"
        :propBtnSize="EnumButtonSizes.SMALL"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { EnumButtonSizes } from "@/enums/enums";
import { useCampaignStore } from "@/stores/campaignStore";
import { exportAsCsvFile } from "@/utils/commonUtils";

import messages from "@/utils/messages";

import { DEFAULT_DISTRIBUTION_EXPORT_FILENAME } from "@/static/constants";

const campaignStore = useCampaignStore();

const onDownloadDevicesBtnClick = () => {
  const now = new Date();
  const filename = `${DEFAULT_DISTRIBUTION_EXPORT_FILENAME}_${now.getFullYear()}-${now.getMonth()}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;

  if (!campaignStore?.campaignDistribtion?.pcsCampaign?.length) {
    messages.showErrorMsg("Não há PCs na lista de distribuição");
    return;
  }

  const pcsToExport = campaignStore.campaignDistribtion.pcsCampaign.map(
    (pc) => ({
      CODIGO_PC: pc.pc,
      NOME_PC: pc.nome,
      STATUS: pc.status,
      MOTIVO: pc.motivo,
    })
  );

  exportAsCsvFile(pcsToExport, filename);
};

const onDownloadDevicesGreenBtnClick = () => {
  onDownloadDevicesBtnClick();
};
</script>

<style scoped></style>
