<template>
  <BackButton />
  <div class="row q-mt-md">
    <div class="col-md-5">
      <div class="row">
        <div class="col-6">
          <c-readonly-field
            title="Campanha"
            :description="campaignStore?.focusedCampaign?.name"
          />
        </div>
        <div class="col">
          <c-readonly-field
            title="Total"
            :description="campaignStore.campaignDistribtion.totalPcs ?? 0"
            descriptionColor="globalBlue006BA7"
          />
        </div>
      </div>
      <div class="row q-mt-sm">
        <div class="col-6">
          <c-readonly-field
            title="Ultima atualização"
            :description="
              campaignStore?.campaignDistribtion?.ultimaAtualizacao
                ? new Date(
                    campaignStore.campaignDistribtion.ultimaAtualizacao
                  ).toLocaleString()
                : ''
            "
          />
        </div>
        <div class="col">
          <c-readonly-field
            title="Sucesso"
            :description="campaignStore.campaignDistribtion.totalSucesso ?? 0"
            descriptionColor="globalGreen17B51C"
          />
        </div>
        <div class="col">
          <c-readonly-field
            title="Pendente"
            :description="campaignStore.campaignDistribtion.totalPendentes ?? 0"
            descriptionColor="globalOrangeFF6B00"
          />
        </div>
      </div>
    </div>
    <div class="col offset-md-4">
      <div class="row fit no-wrap items-top">
        <div class="q-pr-xl q-mr-xl">
          <CampaignDistributionSection />
        </div>
      </div>
    </div>
  </div>

  <c-q-separator class="q-my-lg" />

  <div class="row q-pb-lg">
    <div class="q-pb-xs">
      <span class="midia-app-label-lg">Pesquise um Dispositivo</span>
    </div>
    <div class="col-12" style="padding-right: 100px">
      <div class="row fit no-wrap justify-between items-end content-start">
        <div class="col-grow" style="overflow: auto">
          <q-input
            v-model="filterDistributionPc"
            color="globalAppPrimaryColor"
            bg-color="globalAppDefaultBackgroundColor"
            outlined
            dense
            placeholder="Busque um dispositivo"
            input-class="midia-app-content-lg"
            @update:model-value="searchPcs"
            @keyup.enter="searchPcs"
          >
            <template v-slot:prepend>
              <q-icon
                name="search"
                class="q-pa-sm"
                style="
                  background: var(--globalAppSecondaryColor4);
                  padding: 6px;
                  position: relative;
                  left: -11px;
                  border-right: 1px solid var(--globalAppSecondaryColor2);
                "
              />
            </template>
            <template v-slot:append>
              <q-btn
                outlined
                no-caps
                unelevated
                style="left: 11px"
                @click="showFilterModal"
              >
                <template v-slot:default>
                  <q-icon size="16px">
                    <img
                      class="svg-icon"
                      src="@/assets/icons/icon_filter.svg"
                    />
                  </q-icon>

                  <span class="midia-app-label-lg q-pl-sm">Filtrar</span>
                  <!-- <span class="midia-app-label-lg q-pl-sm" :style="(isFilterActive ? ' color: var(--globalAppPrimaryColor) !important; font-style: italic !important; ' : '')">Filtrar</span> -->
                </template>
              </q-btn>
            </template>
          </q-input>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" style="padding-right: 100px">
      <q-table
        ref="qTablePcs"
        :rows="filteredPcs"
        row-key="pc"
        :columns="qTablePcColumns"
        :visible-columns="qTablePcVisibleColumns"
        v-model:pagination="pagination.quasarPagination"
        :rows-per-page-options="[10, 50, 100,300, 500]"
        @update:pagination="updatePagination"
        virtual-scroll
        no-data-label="Nenhum registro encontrado!"
        rows-per-page-label="Linhas por página:"
        selection="multiple"
        dense
        flat
        separator="horizontal"
        class="midia-app-table q-mt-md"
      >
        <template v-slot:header="props">
          <q-tr
            :props="props"
            style="background-color: var(--globalGrayF8F7F7) !important"
          >
            <q-th
              v-for="col in props.cols"
              :key="col.field"
              :props="props"
              style="border: 0; padding: 2px"
            >
              <span class="midia-app-title-header-table-lg">{{
                col.label
              }}</span>
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr class="table-body-rows" :props="props">
            <q-td key="pc" :props="props" style="padding: 2px">
              <span class="midia-app-label-sm">{{ props.row.pc }}</span>
            </q-td>

            <q-td key="nome" :props="props" style="padding: 2px">
              <span class="midia-app-label-sm">{{ props.row.nome }}</span>
            </q-td>

            <q-td key="status" :props="props" style="padding: 2px">
              <span
                class="midia-app-label-sm"
                :style="`${
                  'Sucesso' === props?.row?.status
                    ? 'color: var(--globalGreen17B51C)'
                    : 'color: var(--globalBlue28A5FF)'
                }`"
                >{{ props.row.status }}</span
              >
            </q-td>
            <q-td key="status" :props="props" style="padding: 2px">
              <span
                class="midia-app-label-sm"
                :style="`${
                  'Coleta Pendente' === props?.row?.motivo
                    ? 'color: var(--globalBlue28A5FF)'
                    : 'color: var(--globalGreen17B51C)'
                }`"
                >{{ props.row.motivo }}</span
              >
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
  <ModalFilterCampaignDistributionPcs @filteredTable="onFilter" />
</template>

<script setup lang="ts">
import { useCampaignStore } from "@/stores/campaignStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { quasarCustomQTableColumns } from "@/types/quasarCustomQTableColumn";
import { CampaignDistributionPc, CampaignDistribution } from "@/types/types.d";
import { CampaignDistributionPcModel } from "@/models/CampaignDistributionPcModel";
import { CampaignDistributionModel } from "@/models/CampaignDistributionModel";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import messages from "@/utils/messages";

import BackButton from "@/components/BackButton.vue";
import CampaignDistributionSection from "@/components/campaign/CampaignDistributionSection.vue";
import ModalFilterCampaignDistributionPcs from "@/views/modals/ModalFilterCampaignDistributionPcs.vue";

const qTablePcColumns: quasarCustomQTableColumns[] = [
  {
    name: "pc",
    label: "Código PC",
    field: "pc",
    align: "center",
  },
  {
    name: "nome",
    label: "Nome PC",
    field: "nome",
    align: "left",
  },

  {
    name: "status",
    label: "Status",
    field: "status",
    align: "left",
  },

  {
    name: "motivo",
    label: "Motivo",
    field: "motivo",
    align: "left",
  },
];

const qTablePcVisibleColumns = ref(["pc", "nome", "status", "motivo"]);

const pagination = ref({
  currentPage: 0,
  totalPages: 0,
  totalItems: 0,
  quasarPagination: { page: 1, rowsPerPage: 10 },
});

const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();
const router = useRouter();

const viewvedPcs = ref(0);
const filterDistributionPc = ref("");
const filteredPcs = ref([] as CampaignDistributionPc[]);
const blockPagination = ref(true);

onMounted(async () => {
  const from = sessionStorage.getItem("from");

  // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
  if (!from) {
    router.push({ name: "home" });
  } else {
    sessionStorage.removeItem("from");
  }

  if (
    !campaignStore?.focusedCampaign?.id ||
    campaignStore.focusedCampaign.id === 0
  ) {
    messages.showErrorMsg(
      "Distribuição não encontrada para campanha especificada. Redirecionando..."
    );
    router.push({ name: "home" });
    return;
  }
  campaignStore.resetCampaignDistribution();
  await fetchCampaignDistribution(campaignStore.focusedCampaign.id, 0);
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem("from", "true"); // Mantém o histórico
  next(); // Continua a navegação normalmente
});

const showFilterModal = () => {
  pagesUtilStore.showModalFilterCampaignDistributionPcs = true;
};

const searchPcs = () => {
  filteredPcs.value =
    campaignStore?.campaignDistribtion?.pcsCampaign &&
    campaignStore.campaignDistribtion.pcsCampaign.length > 0
      ? campaignStore.campaignDistribtion.pcsCampaign
      : [];

  if (!filterDistributionPc.value) return;

  filteredPcs.value = filteredPcs.value.filter(
    (pc) =>
      pc?.pc
        ?.toString()
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase()) ||
      pc?.nome
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase()) ||
      pc?.status
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase()) ||
      pc?.motivo
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase())
  );
};

const onFilter = (filteredTable: CampaignDistributionPc[]) => {
  filteredPcs.value = filteredTable;
};

const updatePagination = async (newPagination: object): Promise<void> => {
  if (newPagination && blockPagination.value) {
    blockPagination.value = false;
    return;
  }

  const totalPcs = campaignStore?.campaignDistribtion?.pcsCampaign?.length
      ? campaignStore.campaignDistribtion.pcsCampaign.length
      : 0;

  viewvedPcs.value =
      pagination.value.quasarPagination.rowsPerPage *
      pagination.value.quasarPagination.page;

  if (viewvedPcs.value > 0 && totalPcs > 0 && viewvedPcs.value >= totalPcs - 1)
    await fetchCampaignDistribution(
        campaignStore.focusedCampaign.id,
        pagination.value.currentPage + 1
    );
};

const fetchCampaignDistribution = async (
  campaignId: number | undefined | null,
  page: number | undefined | null
): Promise<void> => {
  pagesUtilStore.showLoading = true;
  try {
    await campaignStore.fetchCampaignDistribution(campaignId, page);
  } catch (error) {
    messages.showErrorMsg(
      `Erro ao tentar obter a distribuição da campanha de id ${campaignId}...`
    );
    pagesUtilStore.showLoading = false;
  } finally {
    if (campaignStore?.campaignDistribtion?.pcsCampaign?.length) {
      filteredPcs.value = campaignStore.campaignDistribtion.pcsCampaign.map(
        (pc) => new CampaignDistributionPcModel(pc)
      );

      pagination.value.currentPage =
        campaignStore.campaignDistribtion.currentPcPage ?? 0;
      pagination.value.totalPages =
        campaignStore.campaignDistribtion.totalPcPages ?? 0;
      pagination.value.totalItems =
        campaignStore.campaignDistribtion.totalPcs ?? 0;
    }

    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped></style>
