import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 midia-app-label-sm" }
const _hoisted_3 = { class: "" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadOnlyField',
  props: {
  title: {
    type: String,
    default: "",
  },

  description: {
    type: String,
    default: "",
  },

  wrap: {
    type: Boolean,
    default: false,
  },

  descriptionColor: {
    type: String,
    default: "globalBlack000000",
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(__props.title), 1)
    ]),
    _createElementVNode("div", {
      class: "col-12 midia-app-label-lg",
      style: _normalizeStyle(
        __props.wrap
          ? `white-space: pre-wrap; color: var(--${__props.descriptionColor})`
          : `color: var(--${__props.descriptionColor})`
      )
    }, _toDisplayString(__props.description), 5)
  ]))
}
}

})