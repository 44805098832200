import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logos/logo_TecBan_MidiaFlow.svg'
import _imports_1 from '@/assets/icons/icon_dashboard_white.svg'
import _imports_2 from '@/assets/icons/icon_dashboard_red.svg'
import _imports_3 from '@/assets/icons/icon_documento_white.svg'
import _imports_4 from '@/assets/icons/icon_documento_red.svg'
import _imports_5 from '@/assets/icons/display_settings_white.svg'
import _imports_6 from '@/assets/icons/display_settings_red.svg'
import _imports_7 from '@/assets/icons/emergency_white.svg'
import _imports_8 from '@/assets/icons/emergency_red.svg'


const _hoisted_1 = {
  id: "div-side-menu-content",
  class: "flex justify-center q-mt-lg"
}
const _hoisted_2 = { class: "flex items-top justify-start" }
const _hoisted_3 = {
  key: 0,
  src: _imports_1
}
const _hoisted_4 = {
  key: 1,
  src: _imports_2
}
const _hoisted_5 = {
  key: 0,
  src: _imports_3
}
const _hoisted_6 = {
  key: 1,
  src: _imports_4
}
const _hoisted_7 = {
  key: 0,
  src: _imports_5
}
const _hoisted_8 = {
  key: 1,
  src: _imports_6
}
const _hoisted_9 = {
  key: 0,
  src: _imports_7
}
const _hoisted_10 = {
  key: 1,
  src: _imports_8
}

import { SIDE_MENU_ITEM } from "@/static/sideMenuItems";
import { EnumRouteNames } from "@/enums/enums";
import { ref, computed } from "vue";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { useAuthStore } from "@/stores/authStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutSideMenu',
  setup(__props) {

const pagesUtilStore = usePagesUtilStore();
const authStore = useAuthStore();

const isMouseOnHome = ref(false);
const isMouseOnCampaign = ref(false);
const isMouseOnMyProfile = ref(false);
const isMouseOnParameter = ref(false);
const isMouseOnContainmentButton = ref(false);

const isHomeActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem == SIDE_MENU_ITEM.HOME.id
);
const isCampaignActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.CAMPAIGNS.id
);
const isMyProfileActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.MY_PROFILE.id
);
const isParameterSlotActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.PARAMETER_SLOT.id
);
const isContainmentButton = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.CONTAINMENT_BUTTON.id
);

return (_ctx: any,_cache: any) => {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_list, { dense: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    width: "195"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_c_q_separator, { class: "q-my-md q-mx-md" }),
          _createVNode(_component_q_item, {
            clickable: "",
            to: { name: _unref(EnumRouteNames).HOME },
            onMouseover: _cache[0] || (_cache[0] = ($event: any) => (isMouseOnHome.value = true)),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (isMouseOnHome.value = false)),
            active: isHomeActive.value,
            "active-class": "q-item-active"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, { avatar: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_avatar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        class: "svg-icon",
                        size: "16px",
                        style: _normalizeStyle(
                  isMouseOnHome.value || isHomeActive.value
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                )
                      }, {
                        default: _withCtx(() => [
                          (isMouseOnHome.value || isHomeActive.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                            : (_openBlock(), _createElementBlock("img", _hoisted_4))
                        ]),
                        _: 1
                      }, 8, ["style"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item_section, {
                class: "midia-app-label-side-menu-item-lg",
                style: {"margin-left":"-10px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(SIDE_MENU_ITEM).HOME.label), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to", "active"]),
          _createVNode(_component_q_item, {
            clickable: "",
            to: { name: _unref(EnumRouteNames).CAMPAIGN_HOME },
            onMouseover: _cache[2] || (_cache[2] = ($event: any) => (isMouseOnCampaign.value = true)),
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (isMouseOnCampaign.value = false)),
            active: isCampaignActive.value,
            "active-class": "q-item-active"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, { avatar: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_avatar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        class: "svg-icon",
                        size: "16px",
                        style: _normalizeStyle(
                  isMouseOnCampaign.value || isCampaignActive.value
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                )
                      }, {
                        default: _withCtx(() => [
                          (isMouseOnCampaign.value || isCampaignActive.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                            : (_openBlock(), _createElementBlock("img", _hoisted_6))
                        ]),
                        _: 1
                      }, 8, ["style"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item_section, {
                class: "midia-app-label-side-menu-item-lg",
                style: {"margin-left":"-10px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(SIDE_MENU_ITEM).CAMPAIGNS.label), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to", "active"]),
          (_unref(authStore).currentUserCanDisableSlot)
            ? (_openBlock(), _createBlock(_component_q_item, {
                key: 0,
                clickable: "",
                to: { name: _unref(EnumRouteNames).PARAMETER_SLOT },
                onMouseover: _cache[4] || (_cache[4] = ($event: any) => (isMouseOnParameter.value = true)),
                onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (isMouseOnParameter.value = false)),
                active: isParameterSlotActive.value,
                "active-class": "q-item-active"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_avatar, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            class: "svg-icon",
                            size: "16px",
                            style: _normalizeStyle(
                  isMouseOnParameter.value || isParameterSlotActive.value
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                )
                          }, {
                            default: _withCtx(() => [
                              (isMouseOnParameter.value || isParameterSlotActive.value)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                                : (_openBlock(), _createElementBlock("img", _hoisted_8))
                            ]),
                            _: 1
                          }, 8, ["style"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_item_section, {
                    class: "midia-app-label-side-menu-item-lg",
                    style: {"margin-left":"-10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(SIDE_MENU_ITEM).PARAMETER_SLOT.label), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to", "active"]))
            : _createCommentVNode("", true),
          (_unref(authStore).currentUserCanManageContainmentButton)
            ? (_openBlock(), _createBlock(_component_q_item, {
                key: 1,
                clickable: "",
                to: { name: _unref(EnumRouteNames).CONTAINMENT_BUTTON },
                onMouseover: _cache[6] || (_cache[6] = ($event: any) => (isMouseOnContainmentButton.value = true)),
                onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (isMouseOnContainmentButton.value = false)),
                active: isContainmentButton.value,
                "active-class": "q-item-active"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_avatar, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            class: "svg-icon",
                            size: "16px",
                            style: _normalizeStyle(
                  isMouseOnContainmentButton.value || isContainmentButton.value
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                )
                          }, {
                            default: _withCtx(() => [
                              (isMouseOnContainmentButton.value || isContainmentButton.value)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                                : (_openBlock(), _createElementBlock("img", _hoisted_10))
                            ]),
                            _: 1
                          }, 8, ["style"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_item_section, {
                    class: "midia-app-label-side-menu-item-lg",
                    style: {"margin-left":"-10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(SIDE_MENU_ITEM).CONTAINMENT_BUTTON.label), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to", "active"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})