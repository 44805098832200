import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row col" }
const _hoisted_2 = { class: "midia-app-content-lg" }

import { useCampaignStore } 		from '@/stores/campaignStore';
import { onBeforeMount }				from 'vue';
import { EnumCampaignFormats }	from '@/enums/enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFilterCampaignCampaignFormat',
  setup(__props) {

const campaignStore	= useCampaignStore();

const formatOptions	= Object.freeze([
	...Object.values(EnumCampaignFormats)
]);

/* onBeforeMount(() => {
	campaignStore.campaignFilterNew.format = "";
}); */

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row q-pb-xs" }, [
      _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Formato")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_select, {
        modelValue: _unref(campaignStore).campaignFilter.filterCampaign.format,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.format) = $event)),
        options: _unref(formatOptions),
        outlined: "",
        dense: "",
        color: "globalAppPrimaryColor",
        style: {"width":"100%"},
        "label-slot": !_unref(campaignStore).campaignFilter.filterCampaign.format
      }, {
        label: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "midia-app-placeholder-md" }, "Selecione o Formato", -1)
        ])),
        selected: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(campaignStore).campaignFilter.filterCampaign.format), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "options", "label-slot"])
    ])
  ]))
}
}

})