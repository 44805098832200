<template>
  <c-q-btn
    v-if="
      authStore.currentUserCanViewCampaign &&
      campaignStore.focusedCampaign.status ==
        CONST_CAMPAIGN_STATUS.PUBLISHED.id 
      || campaignStore.focusedCampaign.status ==
        CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.id
      || campaignStore.focusedCampaign.status ==
      CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.id
    "
    id="btn-view-focused-campaign-distribution"
    propBtnLabel="Distribuição da Campanha"
    :propBtnFunction="onViewCampaignDistributionBtnClick"
    :propBtnTransparent="false"
    :propBtnSize="EnumButtonSizes.LARGE_1"
  />
</template>

<script setup lang="ts">
import { CONST_CAMPAIGN_STATUS } from "@/static/constants";
import { EnumRouteNames } from "@/enums/enums";

import { EnumButtonSizes } from "@/enums/enums";
import { useCampaignStore } from "@/stores/campaignStore";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";

import moment from "moment";

const campaignStore = useCampaignStore();
const authStore = useAuthStore();
const router = useRouter();

const diferancaEntreDatas = (data: string | undefined) => {
  const hoje = moment();

  data = campaignStore.focusedCampaign.startDate;

  const data1 = moment(data);

  const diferenca = data1.diff(hoje, "days");

  return diferenca >= 1;
};

const onViewCampaignDistributionBtnClick = async () => {
  router.push({ name: EnumRouteNames.CAMPAIGN_DISTRIBUTION });
};
</script>

<style scoped></style>
