import { CampaignDistributionPc } from '@/types/types.d';

export class CampaignDistributionPcModel implements CampaignDistributionPc {

	pc: number | undefined | null;
	nome: string | undefined | null;
	status: string | undefined | null;
	motivo: string | undefined | null;

	constructor(
		newCampaignDistributionPc: {
            pc?: number | undefined | null,
            nome?: string | undefined | null,
            status?: string | undefined | null,
            motivo?: string | undefined | null,
		}) {
            this.pc = newCampaignDistributionPc.pc;
            this.nome = newCampaignDistributionPc.nome;
            this.status = newCampaignDistributionPc.status;
            this.motivo = newCampaignDistributionPc.motivo;
	}
}
