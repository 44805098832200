import { financialInstitutionInterface, ifFilterInterface }	from '@/types/types.d';
import { FinancialInstitutionModel }						    from '@/models/FinancialInstitutionModel';

export class FinancialInstitutionFilterModel implements ifFilterInterface {
	filterIF							: financialInstitutionInterface;

	constructor () {
		this.filterIF	= new FinancialInstitutionModel({});
	}
}
