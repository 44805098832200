import {ContainmentButton}	from '@/types/types.d';

export class ContainmentButtonModel implements ContainmentButton {
  id: number | undefined | null;
  activated_at: string | undefined;
  deactivated_at: string | undefined;
  user_id: string | undefined | null;
  username: string | undefined | null;
  is_active: boolean;

  constructor(data: any) {
    this.id = data.id ?? null;
    this.activated_at = data.activated_at;
    this.deactivated_at = data.deactivated_at;
    this.user_id = data.user_id ?? null;
    this.username = data.username ?? null;
    this.is_active = data.is_active ?? false;
  }
}
