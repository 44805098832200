import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row fit no-wrap justify-center q-pb-sm",
  style: {"overflow":"auto"}
}
const _hoisted_2 = {
  class: "row fit no-wrap justify-center",
  style: {"overflow":"auto"}
}

import { useCampaignStore }                         from '@/stores/campaignStore';
import { usePagesUtilStore }                        from '@/stores/pagesUtilStore';
import { EnumMultipleSingleMode, EnumButtonSizes }  from '@/enums/enums';
import messages                                     from '@/utils/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDeleteCampaignConfirmation',
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const onDeleteCampaignConfirmationBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;

    switch (pagesUtilStore.modalDeleteCampaignConfirmationMode) {
      case EnumMultipleSingleMode.SINGLE:
        try {
          await campaignStore.deleteFocusedCampaign();

          messages.showSuccessMsg("Campanha excluída com sucesso!");
          
          await campaignStore.fetchCampaigns();
          
        } catch (errorDeletingCampaign) {
          messages.showErrorMsg("Erro ao tentar excluir a campanha...");
        }
        
        break;

      case EnumMultipleSingleMode.MULTIPLE:
        if (campaignStore.allSelectedCampaignsCanBeDeleted()) {
          try {
            await campaignStore.deleteSelectedCampaigns();

            messages.showSuccessMsg("Campanha(s) excluída(s) com sucesso! Atualizando lista de campanhas...");

            await campaignStore.fetchCampaigns();
            
          } catch (errorDeletingCampaigns) {
            messages.showErrorMsg("Erro ao tentar excluir a(s) campanha(s) selecionada(s)...");
          }
        }

        break;
        
      default:
        break;
    }

  } finally {
    pagesUtilStore.showLoading = false;

    pagesUtilStore.showModalDeleteCampaignConfirmation  = false;
    pagesUtilStore.showModalPageCampaignsTableActions   = false;
  }      
};

return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalDeleteCampaignConfirmation,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pagesUtilStore).showModalDeleteCampaignConfirmation) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"448px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: (
              (_unref(pagesUtilStore).modalDeleteCampaignConfirmationMode == _unref(EnumMultipleSingleMode).SINGLE)   ? 'Excluir Campanha'    :
              (_unref(pagesUtilStore).modalDeleteCampaignConfirmationMode == _unref(EnumMultipleSingleMode).MULTIPLE) ? 'Excluir Campanha(s)' :
              ''
            ),
                propModalDescription: (
              (_unref(pagesUtilStore).modalDeleteCampaignConfirmationMode == _unref(EnumMultipleSingleMode).SINGLE)   ? 'Deseja realmente excluir esta Campanha?'                   :
              (_unref(pagesUtilStore).modalDeleteCampaignConfirmationMode == _unref(EnumMultipleSingleMode).MULTIPLE) ? 'Deseja realmente excluir a(s) Campanha(s) selecionada(s)?' :
              ''
            )
              }, null, 8, ["propModalTitle", "propModalDescription"]),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_c_q_btn, {
                  id: "btn-excluir",
                  style: {"width":"100%"},
                  propBtnLabel: "EXCLUIR",
                  propBtnFunction: onDeleteCampaignConfirmationBtnClick,
                  propBtnTransparent: false,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1
                }, null, 8, ["propBtnSize"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_component_c_q_btn, {
                  id: "btn-cancelar",
                  style: {"width":"100%"},
                  propBtnLabel: "CANCELAR",
                  propBtnTransparent: true,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1
                }, null, 8, ["propBtnSize"]), [
                  [_directive_close_popup]
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})