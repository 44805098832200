import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","gap":"10px"} }

import BtnViewFocusedCampaign		from '@/components/campaign/actionButtons/BtnViewFocusedCampaign.vue';
import BtnEditFocusedCampaign		from '@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue';
import BtnDeleteFocusedCampaign	from '@/components/campaign/actionButtons/BtnDeleteFocusedCampaign.vue';
import { EnumButtonSizes }      from '@/enums/enums';
import { usePagesUtilStore }    from '@/stores/pagesUtilStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalPageCampaignsTableActions',
  props: {
  propShowBtnViewFocusedCampaign: {
    type      : Boolean,
    required  : true
  },

  propShowBtnEditFocusedCampaign: {
    type      : Boolean,
    required  : true
  },

  propShowBtnDeleteFocusedCampaign: {
    type      : Boolean,
    required  : true
  }
},
  setup(__props) {

const pagesUtilStore  = usePagesUtilStore();

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalPageCampaignsTableActions,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pagesUtilStore).showModalPageCampaignsTableActions) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { style: {"width":"127px","padding":"8px"} }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (props.propShowBtnViewFocusedCampaign)
                ? (_openBlock(), _createBlock(BtnViewFocusedCampaign, {
                    key: 0,
                    style: {"width":"100%"},
                    propBtnSize: _unref(EnumButtonSizes).SMALL
                  }, null, 8, ["propBtnSize"]))
                : _createCommentVNode("", true),
              (props.propShowBtnDeleteFocusedCampaign)
                ? (_openBlock(), _createBlock(BtnDeleteFocusedCampaign, {
                    key: 1,
                    style: {"width":"100%"},
                    propBtnSize: _unref(EnumButtonSizes).SMALL
                  }, null, 8, ["propBtnSize"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})