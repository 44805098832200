<template>
  <div id="div-side-menu-content" class="flex justify-center q-mt-lg">
    <div class="flex items-top justify-start">
      <q-list dense>
        <q-item>
          <q-item-section>
            <img src="@/assets/logos/logo_TecBan_MidiaFlow.svg" width="195" />
          </q-item-section>
        </q-item>

        <c-q-separator class="q-my-md q-mx-md" />

        <q-item
          clickable
          :to="{ name: EnumRouteNames.HOME }"
          @mouseover="isMouseOnHome = true"
          @mouseleave="isMouseOnHome = false"
          :active="isHomeActive"
          active-class="q-item-active"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-icon
                class="svg-icon"
                size="16px"
                :style="
                  isMouseOnHome || isHomeActive
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                "
              >
                <img
                  v-if="isMouseOnHome || isHomeActive"
                  src="@/assets/icons/icon_dashboard_white.svg"
                />
                <img v-else src="@/assets/icons/icon_dashboard_red.svg" />
              </q-icon>
            </q-avatar>
          </q-item-section>

          <q-item-section
            class="midia-app-label-side-menu-item-lg"
            style="margin-left: -10px"
          >
            <q-item-label>{{ SIDE_MENU_ITEM.HOME.label }}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          clickable
          :to="{ name: EnumRouteNames.CAMPAIGN_HOME }"
          @mouseover="isMouseOnCampaign = true"
          @mouseleave="isMouseOnCampaign = false"
          :active="isCampaignActive"
          active-class="q-item-active"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-icon
                class="svg-icon"
                size="16px"
                :style="
                  isMouseOnCampaign || isCampaignActive
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                "
              >
                <img
                  v-if="isMouseOnCampaign || isCampaignActive"
                  src="@/assets/icons/icon_documento_white.svg"
                />
                <img v-else src="@/assets/icons/icon_documento_red.svg" />
              </q-icon>
            </q-avatar>
          </q-item-section>

          <q-item-section
            class="midia-app-label-side-menu-item-lg"
            style="margin-left: -10px"
          >
            <q-item-label>{{ SIDE_MENU_ITEM.CAMPAIGNS.label }}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          clickable
          :to="{ name: EnumRouteNames.PARAMETER_SLOT }"
          @mouseover="isMouseOnParameter = true"
          @mouseleave="isMouseOnParameter = false"
          :active="isParameterSlotActive"
          active-class="q-item-active"
          v-if="authStore.currentUserCanDisableSlot"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-icon
                class="svg-icon"
                size="16px"
                :style="
                  isMouseOnParameter || isParameterSlotActive
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                "
              >
                <img
                  v-if="isMouseOnParameter || isParameterSlotActive"
                  src="@/assets/icons/display_settings_white.svg"
                />
                <img v-else src="@/assets/icons/display_settings_red.svg" />
              </q-icon>
            </q-avatar>
          </q-item-section>

          <q-item-section
            class="midia-app-label-side-menu-item-lg"
            style="margin-left: -10px"
          >
            <q-item-label>{{
              SIDE_MENU_ITEM.PARAMETER_SLOT.label
            }}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          clickable
          :to="{ name: EnumRouteNames.CONTAINMENT_BUTTON }"
          @mouseover="isMouseOnContainmentButton = true"
          @mouseleave="isMouseOnContainmentButton = false"
          :active="isContainmentButton"
          active-class="q-item-active"
          v-if="authStore.currentUserCanManageContainmentButton"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-icon
                class="svg-icon"
                size="16px"
                :style="
                  isMouseOnContainmentButton || isContainmentButton
                    ? ' background-color: var(--globalAppPrimaryColor) '
                    : ''
                "
              >
                <img
                  v-if="isMouseOnContainmentButton || isContainmentButton"
                  src="@/assets/icons/emergency_white.svg"
                />
                <img v-else src="@/assets/icons/emergency_red.svg" />
              </q-icon>
            </q-avatar>
          </q-item-section>

          <q-item-section
            class="midia-app-label-side-menu-item-lg"
            style="margin-left: -10px"
          >
            <q-item-label>{{
              SIDE_MENU_ITEM.CONTAINMENT_BUTTON.label
            }}</q-item-label>
          </q-item-section>
        </q-item>

        <!-- "Meu Perfil" comentado nesta fase; ainda nao ha definicao do conteudo da pagina para exibir..."
        <c-q-separator class="q-my-md q-mx-md" />

        <q-item
          clickable
          :to="{ name: EnumRouteNames.MY_PROFILE }"
          @mouseover="isMouseOnMyProfile = true"
          @mouseleave="isMouseOnMyProfile = false"
          :active="isMyProfileActive"
          active-class="q-item-active"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-icon
                class="svg-icon"
                size="16px"
                :style="((isMouseOnMyProfile || isMyProfileActive) ? ' background-color: var(--globalAppPrimaryColor) ' : '')"
              >
                <img v-if="(isMouseOnMyProfile || isMyProfileActive)" src="@/assets/icons/icon_perfil_white.svg" />
                <img v-else src="@/assets/icons/icon_perfil_red.svg" />
              </q-icon>
            </q-avatar>
          </q-item-section>

          <q-item-section class="midia-app-label-side-menu-item-lg" style=" margin-left: -10px; ">
            <q-item-label>{{ SIDE_MENU_ITEM.MY_PROFILE.label }}</q-item-label>
          </q-item-section>
        </q-item>
        -->
      </q-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SIDE_MENU_ITEM } from "@/static/sideMenuItems";
import { EnumRouteNames } from "@/enums/enums";
import { ref, computed } from "vue";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { useAuthStore } from "@/stores/authStore";

const pagesUtilStore = usePagesUtilStore();
const authStore = useAuthStore();

const isMouseOnHome = ref(false);
const isMouseOnCampaign = ref(false);
const isMouseOnMyProfile = ref(false);
const isMouseOnParameter = ref(false);
const isMouseOnContainmentButton = ref(false);

const isHomeActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem == SIDE_MENU_ITEM.HOME.id
);
const isCampaignActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.CAMPAIGNS.id
);
const isMyProfileActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.MY_PROFILE.id
);
const isParameterSlotActive = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.PARAMETER_SLOT.id
);
const isContainmentButton = computed(
  () =>
    pagesUtilStore.currentSelectedLeftSidePanelItem ==
    SIDE_MENU_ITEM.CONTAINMENT_BUTTON.id
);
</script>

<style scoped>
.svg-icon {
  padding: 6px;
  border: 1px solid var(--globalAppSecondaryColor2);
  border-radius: 10px;
}

.q-item-active {
  background-color: var(--globalAppSecondaryColor2);
}
</style>
