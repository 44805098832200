import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineOfficeBuilding.svg'


const _hoisted_1 = { class: "row fit no-wrap justify-center items-center content-start q-pb-lg" }
const _hoisted_2 = {
  class: "col-grow",
  style: {"overflow":"auto"}
}
const _hoisted_3 = {
  class: "q-pl-md",
  style: {"overflow":"auto"}
}
const _hoisted_4 = {
  id: "div-table-financial-institutions",
  class: "row justify-center q-gutter-sm"
}
const _hoisted_5 = { style: {"width":"100%"} }
const _hoisted_6 = { class: "midia-app-title-header-table-lg" }
const _hoisted_7 = { class: "row items-center no-wrap" }
const _hoisted_8 = { class: "midia-app-label-lg q-pl-md" }
const _hoisted_9 = { class: "midia-app-label-lg" }
const _hoisted_10 = { class: "midia-app-label-lg" }
const _hoisted_11 = {
  class: "row fit no-wrap justify-end",
  style: {"overflow":"auto"}
}

import { EnumButtonSizes }	              from '@/enums/enums';
import { useFinancialInstitutionStore }   from '@/stores/financialInstitutionStore';
import { useCampaignStore }               from '@/stores/campaignStore';
import { usePagesUtilStore }              from '@/stores/pagesUtilStore';
import { computed, ref }                  from 'vue';
import { quasarCustomQTableColumns }      from '@/types/quasarCustomQTableColumn';
import { FinancialInstitutionModel }      from '@/models/FinancialInstitutionModel';
  

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFinancialInstitutions',
  setup(__props) {

const financialInstitutionStore   = useFinancialInstitutionStore();
const campaignStore               = useCampaignStore();
const pagesUtilStore              = usePagesUtilStore();
  
const filterTerms = ref('');
  
const qTableFinancialInstitutionsColumns:quasarCustomQTableColumns[] = [
  {
    name  : 'codif',
    label : 'Código',
    field : 'codif',
    align : 'left'
  },
  
  {
    name  : 'nome',
    label : 'Instituição',
    field : 'nome',
    align : 'left'
  },

  {
    name  : 'nomagrpacionista',
    label : 'Categoria',
    field : 'nomagrpacionista',
    align : 'left'
  }
];
  
const qTableFinancialInstitutionsVisibleColumns = [
  'codif',
  'nome',
  'nomagrpacionista'
];

const onFetchBtnClick = async () => {
  financialInstitutionStore.resetState();

  const { slot1, slot2, slot3 } = campaignStore.focusedCampaign;

	// Determina qual slot foi selecionado
  const selectedSlot = slot1 ? 'slot1' : slot2 ? 'slot2' : slot3 ? 'slot3' : null;

  try {
    pagesUtilStore.showLoading = true;
  
    await financialInstitutionStore.fetchFinancialInstitutions(filterTerms.value, selectedSlot );

  } finally {
    pagesUtilStore.showLoading = false;
  }      
};

const onContinueBtnClick = () => {
  try {
    campaignStore.focusedCampaign.ifs.push( ...selectedFinancialInstitutionsNotYetOnTheCampaign.value.map(data => new FinancialInstitutionModel(data)) );
    
    campaignStore.focusedCampaign.ifsIds.length = 0;
    campaignStore.focusedCampaign.ifsIds.push( ...campaignStore.focusedCampaign.ifs.map(data => data.codif) );

    financialInstitutionStore.financialInstitutions.length = 0;

  } finally {
    pagesUtilStore.showModalFinancialInstitutions = false;
  }      
};

const fetchedFinancialInstitutionsFiltered = computed(() => {
  if (filterTerms.value) {
    return financialInstitutionStore.financialInstitutions.filter(f => (
      f.codif?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || f.nome?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || f.nomagrpacionista?.toLowerCase().includes(filterTerms.value.toLowerCase())
    ));
  
  } else {
    return financialInstitutionStore.financialInstitutions;
  }
});

const selectedFinancialInstitutionsNotYetOnTheCampaign = computed(() => {
  return financialInstitutionStore.selectedFinancialInstitutions.filter(
    financialInstitution => !campaignStore.focusedCampaign.ifs.some(
      campaignIf => (campaignIf.codif == financialInstitution.codif)
    )
  );
});

const onRequest = async () => {
  console.log('onRequest');
};

return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalFinancialInstitutions,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(pagesUtilStore).showModalFinancialInstitutions) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"900px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: "Instituições",
                propModalDescription: "Pesquise uma Instituição"
              }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    id: "searchbar",
                    style: {"position":"relative"},
                    modelValue: filterTerms.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterTerms).value = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Pesquisar",
                    "input-class": "midia-app-content-lg",
                    onKeyup: _withKeys(onFetchBtnClick, ["enter"]),
                    autofocus: ""
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "search",
                        class: "q-pa-sm",
                        style: {"background":"var(--globalAppSecondaryColor4)","padding":"6px","position":"relative","left":"-11px","border-right":"1px solid var(--globalAppSecondaryColor2)"}
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_c_q_btn, {
                    id: "btn-fetch",
                    propBtnLabel: "Buscar",
                    propBtnFunction: onFetchBtnClick,
                    propBtnTransparent: false,
                    propBtnSize: _unref(EnumButtonSizes).LARGE_1
                  }, null, 8, ["propBtnSize"])
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_table, {
                    ref: "qTableFinancialInstitutions",
                    rows: fetchedFinancialInstitutionsFiltered.value,
                    "row-key": "codif",
                    columns: qTableFinancialInstitutionsColumns,
                    "visible-columns": qTableFinancialInstitutionsVisibleColumns,
                    "virtual-scroll": "",
                    "no-data-label": "Nenhum registro encontrado!",
                    "rows-per-page-label": "Linhas por página:",
                    selection: "multiple",
                    selected: _unref(financialInstitutionStore).selectedFinancialInstitutions,
                    "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => ((_unref(financialInstitutionStore).selectedFinancialInstitutions) = $event)),
                    dense: "",
                    flat: "",
                    separator: "horizontal",
                    class: "midia-app-table"
                  }, {
                    header: _withCtx((props) => [
                      _createVNode(_component_q_tr, { props: props }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_th, {
                            style: {"text-align":"center","border":"0"},
                            "auto-width": true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_checkbox, {
                                modelValue: props.selected,
                                "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                                color: (props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2'),
                                "keep-color": (!props.selected)
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                            ]),
                            _: 2
                          }, 1024),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                            return (_openBlock(), _createBlock(_component_q_th, {
                              key: col.field,
                              props: props,
                              style: {"border":"0"}
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_6, _toDisplayString(col.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    body: _withCtx((props) => [
                      _createVNode(_component_q_tr, {
                        class: "table-body-rows",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_td, {
                            key: "selected",
                            style: {"text-align":"center"},
                            "auto-width": true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_checkbox, {
                                modelValue: props.selected,
                                "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                                color: (props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2'),
                                "keep-color": (!props.selected)
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_q_td, {
                            key: "codif",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_q_icon, { size: "24px" }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createElementVNode("img", {
                                      class: "svg-icon",
                                      src: _imports_0
                                    }, null, -1)
                                  ])),
                                  _: 1
                                }),
                                _createElementVNode("span", _hoisted_8, _toDisplayString(props.row.codif), 1)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nome",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_9, _toDisplayString(props.row.nome), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nomagrpacionista",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_10, _toDisplayString(props.row.nomagrpacionista), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"])
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    _: 1
                  }, 8, ["rows", "selected"])
                ])
              ]),
              _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-md" }),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_c_q_btn, {
                  id: "btn-continue",
                  propBtnLabel: "Continuar",
                  propBtnFunction: onContinueBtnClick,
                  propBtnTransparent: false,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1
                }, null, 8, ["propBtnSize"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})