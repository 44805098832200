<template>
	<c-q-btn
		v-if="authStore.currentUserCanViewCampaign"
		id="btn-view-campaign"
		propBtnLabel="Visualizar"
		:propBtnFunction="onRequestCampaignBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="props.propBtnSize"
		:disable="disableViewSelectedCampaignsBtn"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter }												from 'vue-router';
import { useCampaignStore }													from '@/stores/campaignStore';
import { useAuthStore }															from '@/stores/authStore';
import { computed }													from 'vue';
import { usePagesUtilStore }                  from '@/stores/pagesUtilStore';

const router = useRouter();
const authStore		= useAuthStore();
const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const props = defineProps({
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: false,
		default		: EnumButtonSizes.LARGE_1
	}
});

const onRequestCampaignBtnClick = async () => {

	try {
    pagesUtilStore.showLoading = true;
  
    await campaignStore.onFetchCampaignById();
		router.push({ name: EnumRouteNames.CAMPAIGN_VIEW });

  } catch (errorFetchingCampaigns) {
    console.error(errorFetchingCampaigns);
    pagesUtilStore.showLoading = false;

  } finally {
    pagesUtilStore.showLoading = false;
  }

};

const disableViewSelectedCampaignsBtn = computed(() => {
	try {
		return campaignStore.campaignCanBeViewed(campaignStore.focusedCampaign);
		
	} catch (error) {
		return true;
	}
})
</script>

<style scoped>

</style>
