<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalExcludePCs"
      :no-backdrop-dismiss="true"
    >
      <q-card class="midia-app-padding-modal" style=" width: 900px; max-width: 80vw; ">
        <div>
          <div id="div-table-pcs" class="row justify-center q-gutter-sm">
            <div style=" width: 100%; ">
              <q-table
                ref="qTablePCs" 
                :rows="filterSelectedPCs"
                row-key="codpontocaptura"
                :columns="qTablePCsColumns"
                :visible-columns="qTablePCsVisibleColumns"
                virtual-scroll
                no-data-label="Nenhum registro encontrado!"
                rows-per-page-label="Linhas por página:"
                :rows-per-page-options="[10, 50, 100, 500, 1000]"
                selection="multiple"
                v-model:selected="pcStore.selectedPCs"
                dense
                flat
                separator="horizontal"
                class="midia-app-table"
              >
                <template v-slot:header="props">
                  <q-tr :props="props">
                    <q-th
                      style="
                        text-align: center;
                        border: 0;
                      "
                      :auto-width="true"
                    >
                      <q-checkbox
                        v-model="props.selected"
                        :color="(props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2')"
                        :keep-color="(!props.selected)"
                      />
                    </q-th>
                    
                    <q-th
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                      style="
                        border: 0;
                      "
                    >
                    <span class="midia-app-title-header-table-lg">{{ col.label }}</span>
                    </q-th>
                  </q-tr>
                </template>

                <template v-slot:body="props">
                  <q-tr class="table-body-rows" :props="props">
                    <q-td key="selected" style=" text-align: center; " :auto-width="true">
                      <q-checkbox
                        v-model="props.selected"
                        :color="(props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2')"
                        :keep-color="(!props.selected)"
                      />
                    </q-td>

                    <q-td key="codpontocaptura" :props="props">
                      <div class="row items-center no-wrap">
                        <q-icon size="24px">
                          <img class="svg-icon" src="@/assets/icons/icon_atm_Group 9256.svg" />
                        </q-icon>

                        <span class="midia-app-label-lg q-pl-md">{{ props.row.codpontocaptura }}</span>
                      </div>
                    </q-td>

                    <q-td key="nompontocaptura" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nompontocaptura }}</span>
                    </q-td>

                    <q-td key="nomcidredeautoatndto" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nomcidredeautoatndto }}</span>
                    </q-td>

                    <q-td key="ufredeautoatndto" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.ufredeautoatndto }}</span>
                    </q-td>

                    <q-td key="nomsubredeautoatndto" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nomsubredeautoatndto }}</span>
                    </q-td>

                    <q-td key="nomsegmmerclocalptoatndto" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nomsegmmerclocalptoatndto }}</span>
                    </q-td>

                    <q-td key="nomtpmaqautoatndto" :props="props">
                      <span class="midia-app-label-lg">{{ props.row.nomtpmaqautoatndto }}</span>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </div>
          <c-q-separator class="q-pt-lg q-pb-md" />

          <div class="row fit no-wrap justify-end" style=" overflow: auto; ">
            <c-q-btn
              id="btn-continue"
              propBtnLabel="Continuar"
              :propBtnFunction="onContinueBtnClick"
              :propBtnTransparent="false"
              :propBtnSize="EnumButtonSizes.LARGE_1"
            />
          </div>

          <q-inner-loading
						:showing="pagesUtilStore.showLoading"
						label="aguarde..." 
						/>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { EnumButtonSizes }	            from '@/enums/enums';
import { usePCStore }                   from '@/stores/pcStore';
import { useCampaignStore }             from '@/stores/campaignStore';
import { usePagesUtilStore }            from '@/stores/pagesUtilStore';
import { computed, ref, onBeforeMount } from 'vue';
import { quasarCustomQTableColumns }    from '@/types/quasarCustomQTableColumn';
import { PcModel }                      from '@/models/PcModel';

const pcStore         = usePCStore();
const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const loadingTableContents  = ref(false);
const pagination            = ref({
  page        : 1,
	rowsPerPage : 10, // match your API's initial page size
  rowsNumber  : 0,
  size        : 10,
	sortBy      : '',
	descending  : false,
});

const initialPagination = ref({
        sortBy: 'desc',
        descending: false,
        page: 0,
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
}); 

const filterTerms = ref('');

const qTablePCsColumns:quasarCustomQTableColumns[] = [
  {
    name  : 'codpontocaptura',
    label : 'Código',
    field : 'codpontocaptura',
    align : 'left'
  },

  {
    name  : 'nompontocaptura',
    label : 'Nome PC',
    field : 'nompontocaptura',
    align : 'left'
  },
  
  {
    name  : 'nomcidredeautoatndto',
    label : 'Cidade',
    field : 'nomcidredeautoatndto',
    align : 'left'
  },

  {
    name  : 'ufredeautoatndto',
    label : 'UF',
    field : 'ufredeautoatndto',
    align : 'left'
  },

  {
    name  : 'nomsubredeautoatndto',
    label : 'Subrede',
    field : 'nomsubredeautoatndto',
    align : 'left'
  },

  {
    name  : 'nomsegmmerclocalptoatndto',
    label : 'Segmento',
    field : 'nomsegmmerclocalptoatndto',
    align : 'left'
  },

  {
    name  : 'nomtpmaqautoatndto',
    label : 'Tipo de Máquina',
    field : 'nomtpmaqautoatndto',
    align : 'left'
  }
];

const qTablePCsVisibleColumns = [
  'codpontocaptura',
  'nompontocaptura',
  'nomcidredeautoatndto',
  'ufredeautoatndto',
  'nomsubredeautoatndto',
  'nomsegmmerclocalptoatndto',
  'nomtpmaqautoatndto'
];

const onContinueBtnClick = () => {
  try {
    // Remove selected PCs from the campaign
    campaignStore.focusedCampaign.pcs = campaignStore.focusedCampaign.pcs.filter(pc => !pcStore.selectedPCs.includes(pc));

    // Update pcsIds
    campaignStore.focusedCampaign.pcsIds.length = 0;
    campaignStore.focusedCampaign.pcsIds.push( ...campaignStore.focusedCampaign.pcs.map(data => data.codpontocaptura) );

    // Clear the pcs store
    pcStore.pcs.length = 0;

  } finally {
    pagesUtilStore.showModalExcludePCs = false;
  }      
};

const filterSelectedPCs = computed(()=>{
  console.log(pcStore.pcFilter.filterPc.codpontocaptura);
  return campaignStore.focusedCampaign.pcs.filter(pc => (
    (!pcStore.pcFilter.filterPc.codpontocaptura || pc.codpontocaptura == pcStore.pcFilter.filterPc.codpontocaptura) &&
      (!pcStore.pcFilter.filterPc.nompontocaptura || pc.nompontocaptura.toUpperCase() == pcStore.pcFilter.filterPc.nompontocaptura.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomcidredeautoatndto || pc.nomcidredeautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomcidredeautoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.ufredeautoatndto || pc.ufredeautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.ufredeautoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomsubredeautoatndto || pc.nomsubredeautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomsubredeautoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto || pc.nomsegmmerclocalptoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomtpmaqautoatndto || pc.nomtpmaqautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomtpmaqautoatndto.toUpperCase())
    ));
})

</script>