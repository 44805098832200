import { listCampaignInterface, pcInterface, financialInstitutionInterface, ImageInfo }	from '@/types/types.d';
import { PcModel }																												from '@/models/PcModel';
import { FinancialInstitutionModel }																			from '@/models/FinancialInstitutionModel';
import { formatDateStringYyyyMmDdAsDdMmYyyy }															from '@/utils/commonUtils';
import { CONST_CAMPAIGN_STATUS }																					from '@/static/constants';
import { useCampaignStore }																								from '@/stores/campaignStore';


export class ListCampaignModel implements listCampaignInterface {
	id														: number | undefined;
	name													: string | undefined;
	startDate											: string | undefined;
	startDateDdMmYyyy							: string;
	endDate												: string | undefined;
	endDateDdMmYyyy								: string;
	advertiser										: string | undefined;
	agency												: string | undefined;
	format												: string | undefined;
	status												: number | undefined;

	constructor (
		newCampaign: {
			id?														: number,
			name?													: string,
			startDate?										: string,
			endDate?											: string,
			advertiser?										: string,
			agency?												: string,
			format?												: string,
			status?												: number
		
		}
	) {
		this.id														= newCampaign.id;
		this.name													= newCampaign.name;
		this.startDate										= newCampaign.startDate;
		this.startDateDdMmYyyy						= formatDateStringYyyyMmDdAsDdMmYyyy(this.startDate);
		this.endDate											= newCampaign.endDate;
		this.endDateDdMmYyyy							= formatDateStringYyyyMmDdAsDdMmYyyy(this.endDate);
		this.advertiser										= newCampaign.advertiser;
		this.agency												= newCampaign.agency;
		this.format												= newCampaign.format;
		this.status												= newCampaign.status;
	};

	get statusDescription(): string {
		switch (this.status) {
			case CONST_CAMPAIGN_STATUS.ON_EDITING.id:
				
				return CONST_CAMPAIGN_STATUS.ON_EDITING.description;
		
			case CONST_CAMPAIGN_STATUS.DRAFT.id:
				
				return CONST_CAMPAIGN_STATUS.DRAFT.description;
		
			case CONST_CAMPAIGN_STATUS.ON_APPROVAL.id:
				
				return CONST_CAMPAIGN_STATUS.ON_APPROVAL.description;
		
			case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id:
				
				return CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.description;
		
			case CONST_CAMPAIGN_STATUS.APPROVED.id:
				
				return CONST_CAMPAIGN_STATUS.APPROVED.description;
		
			case CONST_CAMPAIGN_STATUS.PUBLISHED.id:
				
				return CONST_CAMPAIGN_STATUS.PUBLISHED.description;
		
			case CONST_CAMPAIGN_STATUS.CANCELED.id:
				
				return CONST_CAMPAIGN_STATUS.CANCELED.description;

			case CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.id:
				
				return CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.description;

			case CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.id:
				
				return CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.description;
				
		    case CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.id:
				
				return CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.description;

			case CONST_CAMPAIGN_STATUS.ON_REISSUE.id:
				
				return CONST_CAMPAIGN_STATUS.ON_REISSUE.description;

			case CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id:
				
				return CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.description;

			case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id:
				
				return CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.description;

			case CONST_CAMPAIGN_STATUS.REAPPROVED.id:
				
				return CONST_CAMPAIGN_STATUS.REAPPROVED.description;
		
			default:
				return "";
		}
	};
}