import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineDocumentDownload.svg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row q-pb-xs" }
const _hoisted_3 = { class: "midia-app-label-sm" }
const _hoisted_4 = { class: "row q-pb-xs" }
const _hoisted_5 = { class: "midia-app-label-sm" }
const _hoisted_6 = {
  class: "row fit no-wrap items-center q-pb-xs",
  style: {"margin-left":"-4px"}
}
const _hoisted_7 = { class: "midia-app-label-lg" }
const _hoisted_8 = { class: "row q-py-xs" }

import { EnumButtonSizes } from "@/enums/enums";
import {computed} from 'vue';
import { useCampaignStore } from "@/stores/campaignStore";
import { exportAsCsvFile } from "@/utils/commonUtils";
import { DEFAULT_PC_GROUP_EXPORT_FILENAME } from "@/static/constants";
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignDevicesDistributionSection',
  setup(__props) {

const campaignStore = useCampaignStore();

const pcsPorGrupo = computed(() => {
  return campaignStore.focusedCampaign.pcsPorGrupo;
});

const addDaysToDate = (date: string, days: number): string => {
  return moment(date).add(days, 'days').format('DD-MM-YYYY');
};

const getDaysToAdd = (group: string): number => {
  switch (group) {
    case 'D0':
      return 0; // Início na data cadastrada
    case 'D1':
      return 1; // 1 dia após a data cadastrada
    case 'D2':
      return 2; // 2 dias após a data cadastrada
    case 'D3':
      return 3; // 3 dias após a data cadastrada
    default:
      return 0;
  }
}

const getAdjustedStartDate = (group: string): string => {
  const startDate = campaignStore.focusedCampaign.startDate;
  const daysToAdd = getDaysToAdd(group);
  return addDaysToDate(startDate as string, daysToAdd);
}

const onDownloadDevicesBtnClick = (groupName: string) => {
  const filename = `Download ${DEFAULT_PC_GROUP_EXPORT_FILENAME.replaceAll(
    "{0}",
    String(groupName)
  )}`;

  // Itera sobre as entradas do objeto pcsPorGrupo usando forEach
  Object.entries(pcsPorGrupo.value).forEach(([group, pcs]) => {
    if (group === groupName) {
      exportAsCsvFile(pcs, filename);
    }
  });
};

const onDownloadDevicesGreenBtnClick = (groupName: string) => {
  onDownloadDevicesBtnClick(groupName);
};

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pcsPorGrupo.value, (pcs, group) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col q-mx-sm",
        key: group
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, "Dispositivos " + _toDisplayString(`${group}`), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, "Inicio " + _toDisplayString(getAdjustedStartDate(group as string)), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_c_q_btn, {
              id: "btn-icon-download-devices",
              padding: "none",
              propBtnLabel: "",
              propBtnFunction: () => onDownloadDevicesGreenBtnClick(group as string),
              propBtnTransparent: true,
              propBtnSize: _unref(EnumButtonSizes).SMALL,
              propBorderless: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, { size: "32px" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "green_arrow_down_icon"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["propBtnFunction", "propBtnSize"]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(DEFAULT_PC_GROUP_EXPORT_FILENAME).replaceAll("{0}", String(group))), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_c_q_btn, {
              id: "btn-download-devices",
              propBtnLabel: "Download",
              propBtnFunction: () => onDownloadDevicesBtnClick(group as string),
              propBtnTransparent: true,
              propBtnSize: _unref(EnumButtonSizes).SMALL
            }, null, 8, ["propBtnFunction", "propBtnSize"])
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})