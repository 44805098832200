import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-pr-sm" }
const _hoisted_2 = { class: "q-pl-sm" }

import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { EnumButtonSizes } from "@/enums/enums";
//NOVO
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalConfirmationDialog',
  props: {
  propDialogTitle: {
    type: String,
    required: false,
    default: "Confirmação",
  },

  propConfirmationMsg: String,

  propCancelBtnLabel: {
    type: String,
    required: false,
    default: "Não",
  },

  propConfirmBtnLabel: {
    type: String,
    required: false,
    default: "Sim",
  },

  propDialogType: String,
},
  emits: ["confirm"],
  setup(__props, { emit: __emit }) {

const pagesUtilStore = usePagesUtilStore();

const props = __props;

const emit = __emit;

/*const handleConfirm = () => {
  pagesUtilStore.showModalConfirmationDialog = false
  
  emit('confirm');
} */

//NOVO
const dialogVisible = computed(() => {
  if (props.propDialogType === "institutions") {
    return pagesUtilStore.showModalConfirmationDialogIfs;
  } else if (props.propDialogType === "pcs") {
    return pagesUtilStore.showModalConfirmationDialogPcs;
  } else if (props.propDialogType === "editar") {
    return pagesUtilStore.showModalConfirmationEditCampaign;
  } else if (props.propDialogType === "cancelar") {
    return pagesUtilStore.showModalConfirmationCancelCampaign;
  } else if (props.propDialogType === "slots") {
    return pagesUtilStore.showModalConfirmationDialogSlots;
  }
  return false;
});

const handleCancel = () => {
  console.log("cancel")
  if (props.propDialogType === "institutions") {
    pagesUtilStore.showModalConfirmationDialogIfs = false;
  } else if (props.propDialogType === "pcs") {
    pagesUtilStore.showModalConfirmationDialogPcs = false;
  } else if (props.propDialogType === "editar") {
    pagesUtilStore.showModalConfirmationEditCampaign = false;
  } else if (props.propDialogType === "cancelar") {
    return pagesUtilStore.showModalConfirmationCancelCampaign = false;
  } else if (props.propDialogType === "slots") {
    pagesUtilStore.showModalConfirmationDialogSlots = false;
  }
};

const handleConfirm = () => {
  console.log("confirm")
  if (props.propDialogType === "institutions") {
    pagesUtilStore.showModalConfirmationDialogIfs = false;
  } else if (props.propDialogType === "pcs") {
    pagesUtilStore.showModalConfirmationDialogPcs = false;
  } else if (props.propDialogType === "editar") {
    pagesUtilStore.showModalConfirmationEditCampaign = false;
  } else if (props.propDialogType === "cancelar") {
    pagesUtilStore.showModalConfirmationCancelCampaign = false;
  } else if (props.propDialogType === "slots") {
    pagesUtilStore.showModalConfirmationDialogSlots = false;
  }
  console.log("emit-confirm");
  emit("confirm");
  // emit("cancel");
};

return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: dialogVisible.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialogVisible).value = $event)),
    "no-backdrop-dismiss": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "midia-app-padding-modal" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_c_modal_title, {
                propModalTitle: props.propDialogTitle,
                propModalDescription: props.propConfirmationMsg
              }, null, 8, ["propModalTitle", "propModalDescription"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_c_q_btn, {
                  id: "btn-cancel",
                  propBtnLabel: props.propCancelBtnLabel,
                  propBtnTransparent: true,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1,
                  onClick: handleCancel
                }, null, 8, ["propBtnLabel", "propBtnSize"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_c_q_btn, {
                  id: "btn-confirm",
                  propBtnLabel: props.propConfirmBtnLabel,
                  propBtnTransparent: false,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1,
                  onClick: handleConfirm
                }, null, 8, ["propBtnLabel", "propBtnSize"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})