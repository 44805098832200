<template>
	<div>
		<div class="row items-center q-pb-xs">
			<span id="span-01" class="midia-app-label-sm q-pr-sm">PCs *</span>

			<div class="q-pr-sm">
				<c-q-btn
					id="btn-clear"
					propBtnLabel="Limpar"
					:propBtnFunction="onClearPcsBtnClick"
					:propBtnTransparent="true"
					:propBtnSize="EnumButtonSizes.SMALL"
					:disable="disableClearBtn"
				/>
			</div>
			<div>
				<c-q-btn
					id="btn-clear"
					propBtnLabel="Excluir"
					:propBtnFunction="onFilterExcludeBtnClick"
					:propBtnTransparent="true"
					:propBtnSize="EnumButtonSizes.SMALL"
					:disable="disableClearBtn"
				/>
			</div>
		</div>

		<div id="div-01" class="row no-wrap items-center">
			<!-- <div id="div-02" class="q-pr-md col" style=" height: 67px; overflow: hidden; "> -->
				<div style="max-height: 100px; overflow-y: scroll; width:100%;">
					<q-select
						v-model="campaignStore.focusedCampaign.pcs"
						color="globalAppPrimaryColor"
						bg-color="globalAppDefaultBackgroundColor"
						outlined
						dense
						hide-dropdown-icon
						use-chips
						multiple
					>
						<template v-slot:selected-item="scope">
							<q-chip
								removable
								@remove="onRemove(scope)"
								:tabindex="scope.tabindex"
								color="globalAppPrimaryColor"
								text-color="globalAppDefaultBackgroundColor"
								class="q-ma-xs"
								size="sm"
							>
								{{ scope.opt.codpontocaptura }} - {{ scope.opt.nompontocaptura }}
							</q-chip>
						</template>
					</q-select>
				</div>

			<div id="div-03" class="col-auto">
				<c-q-btn
					id="btn-filtrar"
					propBtnLabel="Filtrar"
					:propBtnFunction="onFilterBtnClick"
					:propBtnTransparent="true"
					:propBtnSize="EnumButtonSizes.LARGE_1"
				/>
			</div>
		</div>

		<ModalFilterPcs />
		<ModalFilterExcludePcs />

		<ModalConfirmationDialog
			propDialogTitle="Atenção!"
			propConfirmationMsg="Tem certeza de que deseja excluir todos os PCs selecionados?"
			propDialogType="pcs"
			@confirm="onClearSelectedPcsConfirmation"
		/>
	</div>
</template>

<script setup lang="ts">
import ModalFilterPcs						from '@/views/modals/ModalFilterPcs.vue';
import ModalFilterExcludePcs						from '@/views/modals/ModalFilterExcludePcs.vue';
import ModalConfirmationDialog 	from '@/views/modals/ModalConfirmationDialog.vue';
import { EnumButtonSizes }			from '@/enums/enums';
import { useCampaignStore }			from '@/stores/campaignStore';
import { usePagesUtilStore }		from '@/stores/pagesUtilStore';
import { computed }							from 'vue';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const removePcIdFromPcsIds = (pcIdToRemove: number) => {
	const index = campaignStore.focusedCampaign.pcsIds.indexOf(pcIdToRemove, 0);

	if (index > -1) {
		campaignStore.focusedCampaign.pcsIds.splice(index, 1);
	}
};

const onRemove = (
	scope: {
    index: number;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
    opt: any;
    selected: boolean;
    removeAtIndex: (index: number) => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleOption: (opt: any) => void;
    tabindex: number;
	}
) => {
	scope.removeAtIndex(scope.index);

	removePcIdFromPcsIds(scope.opt.codpontocaptura);
};

const disableClearBtn = computed(() => {
	try {
		return (campaignStore.focusedCampaign.pcs.length == 0);
		
	} catch (error) {
		return false;
	}
});

/*const onClearBtnClick = () => {
  pagesUtilStore.showModalConfirmationDialog = true;
};*/

//NOVO
const onClearPcsBtnClick = () => {
  pagesUtilStore.showModalConfirmationDialogPcs = true;
};

const onClearSelectedPcsConfirmation = () => {
	campaignStore.focusedCampaign.pcs.length = 0;
};

const onFilterBtnClick = () => {
  //pagesUtilStore.showModalPCs = true;
  pagesUtilStore.showModalFilterPCs = true;

};

const onFilterExcludeBtnClick = () => {
	console.log("teste")
  pagesUtilStore.showModalFilterExcludePCs = true;

};
</script>

<style scoped>
.q-field--auto-height .q-field__control {
	height: 46px !important;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid purple;
}
</style>