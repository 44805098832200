import { defineStore }          from 'pinia';
import { Ref, ref, computed }   from 'vue';
import { ContainmentButton }    from '@/types/types.d';
import {ContainmentButtonModel} from '@/models/ContainmentButtonModel'

import messages                 from '@/utils/messages';

import { USER_ROLES }           from '@/static/constants';

import ApiService               from '@/utils/ApiService';


export const useContainmentStore = defineStore('containment', () => {
//State
const containmentData : Ref<ContainmentButton> = ref({} as ContainmentButton);




const onFetchContainmentData = async () => {
  try{

    const responseData = await ApiService.get(
      process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
      process.env.VUE_APP_API_CONTAINMENT_ROUTE_GET_STATUSDATA
    );

    // Atribuindo os dados recebidos à propriedade value de containmentData
    containmentData.value = new ContainmentButtonModel(responseData);

  }catch (error) {
    console.error(error);
  }
}

const onFetchContainmentActivate = async() =>{
  try{

    const responseData : any = await ApiService.post(
      process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
      process.env.VUE_APP_API_CONTAINMENT_ROUTE_POST_ACTIVATE,
      {}
    );
    console.log(responseData);
    messages.showSuccessMsg(responseData);

  }catch (error) {
    console.error(error);
    messages.showErrorMsg("Erro ao tentar ativar o Botão de Contenção");
  }
  // VUE_APP_API_CONTAINMENT_ROUTE_POST_ACTIVATE

}

const onFetchContainmentDeactivate = async() =>{
  try{

    const responseData : any = await ApiService.post(
      process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
      process.env.VUE_APP_API_CONTAINMENT_ROUTE_POST_DEACTIVATE,
      {}
    );
    console.log(responseData);
    messages.showSuccessMsg(responseData);

  }catch (error) {
    console.error(error);
    messages.showErrorMsg("Erro ao tentar desativar o Botão de Contenção");
  }
  // VUE_APP_API_CONTAINMENT_ROUTE_POST_ACTIVATE

}



return{
  //state
  containmentData,

  //actions
  onFetchContainmentData,
  onFetchContainmentActivate,
  onFetchContainmentDeactivate
}

})

