import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { EnumMultipleSingleMode, EnumButtonSizes }	from '@/enums/enums';
import { useCampaignStore }													from '@/stores/campaignStore';
import { usePagesUtilStore }												from '@/stores/pagesUtilStore';
import { useAuthStore }															from '@/stores/authStore';
import { computed }																	from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnDeleteSelectedCampaigns',
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore				= useAuthStore();

const onDeleteSelectedCampaignsBtnClick = () => {
  pagesUtilStore.modalDeleteCampaignConfirmationMode = EnumMultipleSingleMode.MULTIPLE;
  pagesUtilStore.showModalDeleteCampaignConfirmation = true;
};

const disableDeleteSelectedCampaignsBtn = computed(() => {
	try {
		return (
			(campaignStore.selectedCampaigns.length == 0)
			|| !authStore.currentUserCanDeleteCampaign
		);
		
	} catch (error) {
		return true;
	}
});

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createBlock(_component_c_q_btn, {
    id: "btn-delete-selected-campaigns",
    propBtnLabel: "Excluir",
    propBtnFunction: onDeleteSelectedCampaignsBtnClick,
    propBtnTransparent: true,
    propBtnSize: _unref(EnumButtonSizes).LARGE_1,
    disable: disableDeleteSelectedCampaignsBtn.value
  }, null, 8, ["propBtnSize", "disable"]))
}
}

})