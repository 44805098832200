<template>
	<div>
		<span class="midia-app-caption-sm">Formato *</span>

		<q-select
			v-model="campaignStore.focusedCampaign.format"
			:options="formatOptions"
			outlined
			dense
			color="globalAppPrimaryColor"
			bg-color="globalAppDefaultBackgroundColor"
			:label-slot="!campaignStore.focusedCampaign.format"
			:readonly="campaignStore.focusedCampaign.iscampaignpublished"
		>
			<template v-slot:label>
				<span class="midia-app-placeholder-md">Informe o Formato</span>
			</template>

			<template v-slot:selected>
				<span class="midia-app-content-lg">{{ campaignStore.focusedCampaign.format }}</span>
			</template>
		</q-select>
	</div>
</template>

<script setup lang="ts">
import { useCampaignStore } 		from '@/stores/campaignStore';
import { EnumCampaignFormats }	from '@/enums/enums';

const campaignStore	= useCampaignStore();

const formatOptions	= Object.freeze([
	...Object.values(EnumCampaignFormats)
]);
</script>

<style scoped>

</style>