import { defineStore }                                from 'pinia';
import { Ref, ref }                                   from 'vue';
import { pcInterface, pcFilterInterface, responseDataPageableInterface } from '@/types/types.d';
import { PcModel }                                    from '@/models/PcModel';
import ApiService                                     from '@/utils/ApiService';
import { exportAsCsvFile }                            from '@/utils/commonUtils';
import { DEFAULT_PCS_EXPORT_FILENAME }                from '@/static/constants';
import staticPcs                                      from '@/static/testes/staticPcs.json';
import { watch } from 'vue';
import { PcFilterModel }                                                    from '@/models/PcFilterModel';

export const usePCStore = defineStore('pc', () => {
  const pcs         : Ref<pcInterface[]> = ref([] as pcInterface[]);
  const selectedPCs : Ref<pcInterface[]> = ref([] as pcInterface[]);
  const pcFilter    : Ref<pcFilterInterface>  = ref(new PcFilterModel());
  
  const fetchPCs = async (page: number, size: number | null) => {
    const defaultSize = 10;
    
    try {
      const params = {
        codpontocaptura           : (pcFilter.value.filterPc.codpontocaptura   ? pcFilter.value.filterPc.codpontocaptura : undefined),
        nompontocaptura           : (pcFilter.value.filterPc.nompontocaptura   ? pcFilter.value.filterPc.nompontocaptura : undefined),
        nomcidredeautoatndto      : (pcFilter.value.filterPc.nomcidredeautoatndto   ? pcFilter.value.filterPc.nomcidredeautoatndto : undefined),
        ufredeautoatndto          : (pcFilter.value.filterPc.ufredeautoatndto   ? pcFilter.value.filterPc.ufredeautoatndto : undefined),
        nomsubredeautoatndto      : (pcFilter.value.filterPc.nomsubredeautoatndto   ? pcFilter.value.filterPc.nomsubredeautoatndto : undefined),
        nomsegmmerclocalptoatndto : (pcFilter.value.filterPc.nomsegmmerclocalptoatndto   ? pcFilter.value.filterPc.nomsegmmerclocalptoatndto : undefined),
        nomtpmaqautoatndto        : (pcFilter.value.filterPc.nomtpmaqautoatndto   ? pcFilter.value.filterPc.nomtpmaqautoatndto : undefined),
        page                      : page,
        size                      : (size ? size : defaultSize),
      }

      const responseData: responseDataPageableInterface<pcInterface> = await ApiService.get(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_PCS,
        params
      );

      pcs.value.length = 0;
      if (responseData?.content) {
        pcs.value.push( ...responseData.content.map(data => new PcModel(data)) );
      }
      return responseData;

    } catch (error) {
      console.error(error);

      throw error;
    };
  };

  const resetState = () => {
    pcs.value.length          = 0;
    selectedPCs.value.length  = 0;
  };

  const resetFilterState =() => {
  pcFilter.value.filterPc.codpontocaptura = undefined
  pcFilter.value.filterPc.nompontocaptura = ""
  pcFilter.value.filterPc.nomcidredeautoatndto = ""
  pcFilter.value.filterPc.ufredeautoatndto = ""
  pcFilter.value.filterPc.nomsubredeautoatndto = ""
  pcFilter.value.filterPc.nomsegmmerclocalptoatndto = ""
  pcFilter.value.filterPc.nomtpmaqautoatndto = ""
  }

  const exportPcsAsCsv = (paramPcsToExport: Array<pcInterface>, paramFilename: string = DEFAULT_PCS_EXPORT_FILENAME) => {
    if (paramPcsToExport) {
      const pcsToExport = paramPcsToExport.map(p => ({
        "Código"          : p.codpontocaptura,
        "Nome PC"         : p.nompontocaptura,
        "Cidade"          : p.nomcidredeautoatndto,
        "UF"              : p.ufredeautoatndto,
        "Subrede"         : p.nomsubredeautoatndto,
        "Segmento"        : p.nomsegmmerclocalptoatndto,
        "Tipo de Máquina" : p.nomtpmaqautoatndto
      }));
  
      exportAsCsvFile(pcsToExport, paramFilename);
    }
  }
  return {
    pcs,
    selectedPCs,
    pcFilter,
    fetchPCs,
    resetState,
    resetFilterState,
    exportPcsAsCsv
  }
})