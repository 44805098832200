<template>
	<div>
		<!--<q-dialog
			v-model="pagesUtilStore.showModalRequestAdjustments"
			:no-backdrop-dismiss="true"
			@before-show="onBeforeShow"
		> -->

		<q-dialog
			v-model="pagesUtilStore.showModalRequestAdjustments"
			:no-backdrop-dismiss="true"
		>
			<q-card class="midia-app-padding-modal" style=" width: 448px; ">
				<div>
					<c-modal-title
						propModalTitle="Solicitar Ajuste"
						:propModalDescription="(
							(pagesUtilStore.modalRequestedAdjustmentsModalType == EnumRequestedAdjustmentsModalType.CREATE)	? 'Informe os ajustes necessários:'		:
							(pagesUtilStore.modalRequestedAdjustmentsModalType == EnumRequestedAdjustmentsModalType.UPDATE)	? 'Verifique os Ajustes Solicitados'	:
							''
						)"
					/>

					<div v-if="campaignStore.focusedCampaign.commentadjustment" class="row fit no-wrap q-pb-lg">
						<div class="col-grow" style=" overflow: auto; ">
							<q-input
								style=" position: relative; "
								v-model="campaignStore.focusedCampaign.commentadjustment"
								type="textarea"
								maxlength="255"
								color="globalAppPrimaryColor"
								bg-color="globalAppDefaultBackgroundColor"
								outlined
								dense
								placeholder="Digite aqui..."
								input-class="midia-app-content-lg"
								:readonly="true"
							/>
						</div>
					</div>

					<div class="row fit no-wrap q-pb-lg">
						<div class="col-grow" style=" overflow: auto; ">
							<q-input
								style=" position: relative; "
								v-model="campaignStore.refRequestedAdjustments"
								type="textarea"
								maxlength="255"
								color="globalAppPrimaryColor"
								bg-color="globalAppDefaultBackgroundColor"
								outlined
								dense
								placeholder="Digite aqui..."
								input-class="midia-app-content-lg"
								:disable="disableEditAskAdjustmen"
							/>
						</div>
					</div>			

					<div class="row fit flex no-wrap justify-between q-pb-xs" style=" width: 100%; ">
						<div class="q-pr-sm">
							<c-q-btn
								id="btn-cancel"
								propBtnLabel="Cancelar"
								:propBtnTransparent="true"
								:propBtnSize="EnumButtonSizes.LARGE_1"
								v-close-popup
							/>
						</div>

						<div class="col col-grow q-pl-sm">
							<c-q-btn
								v-if="campaignStore.focusedCampaign.iscampanhaedit && campaignStore.focusedCampaign.status !== CONST_CAMPAIGN_STATUS.ON_APPROVAL.id && campaignStore.focusedCampaign.status !== CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id"
								id="btn-adjustments"
								style=" width: 100%; "
								propBtnLabel="OK"
								:propBtnTransparent="false"
								:propBtnSize="EnumButtonSizes.LARGE_1"
								v-close-popup
								
							/>
						</div>

						<div class="col col-grow q-pl-sm">
							<c-q-btn
								v-if="campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ON_APPROVAL.id"
								id="btn-send-requested-adjustments"
								style=" width: 100%; "
								propBtnLabel="Enviar"
								:propBtnFunction="onSendRequestedAdjustmentsBtnClick"
								:propBtnTransparent="false"
								:propBtnSize="EnumButtonSizes.LARGE_1"
								:disable="(!campaignStore.refRequestedAdjustments?.trim())"
							/>

							<c-q-btn
								v-if="campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id"
								id="btn-send-requested-adjustments"
								style=" width: 100%; "
								propBtnLabel="Enviar"
								:propBtnFunction="onSendRequestedAdjustmentsAgainBtnClick"
								:propBtnTransparent="false"
								:propBtnSize="EnumButtonSizes.LARGE_1"
								:disable="(!campaignStore.refRequestedAdjustments?.trim())"
							/>
						</div>
					</div>
				</div>
			</q-card>
		</q-dialog>
	</div>
</template>

<script setup lang="ts">
import { useCampaignStore } 																	from '@/stores/campaignStore';
import { usePagesUtilStore }																	from '@/stores/pagesUtilStore';
import { ref }																								from 'vue';
import { EnumRequestedAdjustmentsModalType, EnumButtonSizes }	from '@/enums/enums';
import { CONST_CAMPAIGN_STATUS }   														from '@/static/constants';
import messages               																from '@/utils/messages';
import {computed}																							from 'vue'
import { useAuthStore }					from '@/stores/authStore'

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const disableEditAskAdjustmen = computed(() => {
	return campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id
	|| campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id
	|| campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.PUBLISHED.id
	|| campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.APPROVED.id
	|| (campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ON_APPROVAL.id && campaignStore.focusedCampaign.status === CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id && !authStore.currentUserCanApproveCampaign)
})

/*const onOkClick = () => {
	if (campaignStore.refRequestedAdjustments) {
		campaignStore.focusedCampaign.commentadjustment = campaignStore.refRequestedAdjustments;
		pagesUtilStore.showModalRequestAdjustments = false;
	} 
};*/

/*const onBeforeShow = () => {
	if (campaignStore.focusedCampaign.commentadjustment) {
		campaignStore.refRequestedAdjustments = campaignStore.focusedCampaign.commentadjustment;

	} 
	else {
		campaignStore.refRequestedAdjustments = '';
	}
};*/

const onSendRequestedAdjustmentsBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.sendRequestedAdjustmentsFocusedCampaign(campaignStore.refRequestedAdjustments.trim());
		
		campaignStore.refRequestedAdjustments = "";

		messages.showSuccessMsg("Solicitação de ajustes realizada com sucesso!");

		pagesUtilStore.showModalRequestAdjustments = false;

  } catch (errorRequestingCampaignAdjustments: any) {
    messages.showErrorMsg(errorRequestingCampaignAdjustments.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};


const onSendRequestedAdjustmentsAgainBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.sendRequestedAdjustmentsAgainFocusedCampaign(campaignStore.refRequestedAdjustments.trim());
		
		campaignStore.refRequestedAdjustments = "";

		messages.showSuccessMsg("Solicitação de reajuste realizada com sucesso!");

		pagesUtilStore.showModalRequestAdjustments = false;

  } catch (errorRequestingCampaignAdjustments: any) {
    messages.showErrorMsg(errorRequestingCampaignAdjustments.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

</script>

<style scoped>

</style>