import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { EnumButtonSizes, EnumRouteNames  }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';
import { useRouter }												from 'vue-router';

import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';



export default /*@__PURE__*/_defineComponent({
  __name: 'BtnSendFocusedCampaignToApprove',
  setup(__props) {

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();
const router = useRouter();

const onSendFocusedCampaignToApproveBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
    
    try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }
  
   	await campaignStore.sendToApproveFocusedCampaign();
		
		messages.showSuccessMsg("Campanha enviada para aprovação com sucesso!");

    router.push({ name: EnumRouteNames.CAMPAIGN_VIEW });

  } catch (ErrorException: any) {
    messages.showErrorMsg(ErrorException.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_unref(authStore).currentUserCanSendCampaignToApprove
    && !_unref(campaignStore).focusedCampaign.iscampanhaedit
    && (
				_unref(campaignStore).focusedCampaign.status == undefined
        //||campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id
        || _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).DRAFT.id
        || _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_EDITING.id
    ))
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-send-campaign-to-approve",
        propBtnLabel: "Enviar para Aprovação",
        propBtnFunction: onSendFocusedCampaignToApproveBtnClick,
        propBtnTransparent: false,
        propBtnSize: _unref(EnumButtonSizes).LARGE_1
      }, null, 8, ["propBtnSize"]))
    : _createCommentVNode("", true)
}
}

})