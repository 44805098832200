import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineOfficeBuilding.svg'


const _hoisted_1 = { class: "row items-center q-pb-md" }
const _hoisted_2 = { class: "q-pl-lg col-6" }
const _hoisted_3 = { class: "row items-center q-pb-md" }
const _hoisted_4 = { class: "q-pl-lg col-6" }
const _hoisted_5 = { class: "row q-mt-md q-pl-lg" }
const _hoisted_6 = { class: "col-5" }
const _hoisted_7 = { class: "row justify-left" }
const _hoisted_8 = { class: "q-my-sm" }
const _hoisted_9 = { class: "row justify-left" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "midia-app-title-header-table-lg" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "midia-app-title-header-table-lg" }
const _hoisted_14 = { class: "row items-center no-wrap" }
const _hoisted_15 = { class: "midia-app-label-sm q-pl-sm" }
const _hoisted_16 = { class: "midia-app-label-sm" }
const _hoisted_17 = { class: "midia-app-label-sm" }
const _hoisted_18 = { class: "buttonManageIF collumn q-pa-md" }
const _hoisted_19 = { class: "col-auto" }
const _hoisted_20 = { class: "col-auto" }
const _hoisted_21 = { class: "col-5" }
const _hoisted_22 = { class: "row justify-left" }
const _hoisted_23 = { class: "q-my-sm" }
const _hoisted_24 = { class: "row justify-left" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "midia-app-title-header-table-lg" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "midia-app-title-header-table-lg" }
const _hoisted_29 = { class: "row items-center no-wrap" }
const _hoisted_30 = { class: "midia-app-label-sm q-pl-sm" }
const _hoisted_31 = { class: "midia-app-label-sm" }
const _hoisted_32 = { class: "midia-app-label-sm" }
const _hoisted_33 = { class: "col-12 q-my-md" }
const _hoisted_34 = {
  class: "row justify-end",
  style: {"margin-right":"78px"}
}

import { ref, onMounted, onBeforeMount, computed } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useCampaignStore } from "@/stores/campaignStore";
import { useFinancialInstitutionStore } from "@/stores/financialInstitutionStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { quasarCustomQTableColumns } from "@/types/quasarCustomQTableColumn";
import { EnumButtonSizes } from "@/enums/enums";
import { financialInstitutionInterface } from "@/types/types.d";

import PageCaption from "@/components/PageCaption.vue";
import CampaignFormat from "@/components/campaign/CampaignFormat.vue";
import ModalFinancialInstitutions from "@/views/modals/ModalFinancialInstitutions.vue";
import ModalConfirmationDialog from "@/views/modals/ModalConfirmationDialog.vue";
import {useContainmentStore} from '@/stores/containmentStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'PageParameterSlot',
  setup(__props) {

const $q = useQuasar();
const router = useRouter();
const authStore = useAuthStore();
const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();
const financialInstitutionStore = useFinancialInstitutionStore();
const containmentStore = useContainmentStore();

const selectedSlot = ref({});
const categoryFilterLinked = ref("");
const categoryFilterUnlinked = ref("");
const filterTermsIFLinked = ref("");
const filterTermsIFUnlinked = ref("");
const financialInstitutions = ref([]);
const filteredFinancialInstitutions = ref<financialInstitutionInterface[]>([]);
const selectedFinancialInstitutions = ref([]);
const unlinkedFinancialInstitutions = ref([]);
const filteredUnlinkedFinancialInstitutions = ref<
  financialInstitutionInterface[]
>([]);
const selectedUnlinkedFinancialInstitutions = ref([]);

const slots = ref([
  { id: "A1", description: "A1 - Abertura" },
  { id: "A2", description: "A2 - PropagIF" },
  { id: "A3", description: "A3 - Retire o seu Dinheiro" },
]);

const qTableFinancialInstitutionsColumns: quasarCustomQTableColumns[] = [
  {
    name: "codif",
    label: "Código",
    field: "codif",
    align: "left",
  },
  {
    name: "nome",
    label: "Instituição",
    field: "nome",
    align: "left",
  },

  {
    name: "nomagrpacionista",
    label: "Categoria",
    field: "nomagrpacionista",
    align: "left",
  },
];

const qTableFinancialInstitutionsVisibleColumns = [
  "codif",
  "nome",
  "nomagrpacionista",
];

const categoryOptionsUnlinked = computed(() => {
  return [
    ...new Set(
      unlinkedFinancialInstitutions.value.map((row) => row?.nomagrpacionista)
    ),
  ];
});

const categoryOptionsLinked = computed(() => {
  return [
    ...new Set(financialInstitutions.value.map((row) => row?.nomagrpacionista)),
  ];
});

onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});

onMounted(async () => {
  if (!authStore.currentUserCanDisableSlot) {
    showError(
      "Usuário não possuí acesso a esta funcionalidade. Redirecionando..."
    );

    router.back();
    return;
  }

  await searchInstitutions();
});

const showError = (message: string): void => {
  if (!message) return;

  $q.notify({
    type: "negative",
    position: "top",
    message: message,
  });
};

const showMessage = (message: string): void => {
  if (!message) return;

  $q.notify({
    type: "positive",
    position: "top",
    message: message,
  });
};

const sortByIfName = (arr) =>
  arr.sort((a, b) => a?.nome?.localeCompare(b?.nome));

const showFinancialInstitutionsFilter = (filterType: number) => {
  pagesUtilStore.showModalFinancialInstitutions = true;
};

const changeSelectedSlot = async () => {
  await searchInstitutions();
};

const filterInstitutions = async (
  ifs: financialInstitutionInterface[],
  filterTerm: string
): Promise<financialInstitutionInterface[]> => {
  if (!filterTerm) return ifs;

  return ifs.filter(
    (f) =>
      f?.codif?.toLowerCase().includes(filterTerm.toLowerCase()) ||
      f?.nome?.toLowerCase().includes(filterTerm.toLowerCase()) ||
      f?.nomagrpacionista?.toLowerCase().includes(filterTerm.toLowerCase())
  );
};

const filterInstitutionsCategory = async (
  ifs: financialInstitutionInterface[],
  category: string
): Promise<financialInstitutionInterface[]> => {
  if (!category) return ifs;

  return ifs.filter((f) =>
    f?.nomagrpacionista?.toLowerCase().includes(category.toLowerCase())
  );
};

const searchInstitutions = async (filterTerm?: string) => {
  pagesUtilStore.showLoading = true;
  try {
    await financialInstitutionStore.fetchFinancialInstitutions(filterTerm);
  } catch (error) {
    showError("Erro ao buscar as IFs");
    pagesUtilStore.showLoading = false;
  } finally {
    pagesUtilStore.showLoading = false;
    classifyInstitutions(financialInstitutionStore.financialInstitutions);
  }
};

const showDialogSaveAll = () => {
  pagesUtilStore.showModalConfirmationDialogSlots = true;
};

const saveAll = async () => {
  pagesUtilStore.showLoading = true;
  try {
    await financialInstitutionStore.updateFinancialInstitutions(
      "L",
      selectedSlot.value.id,
      filteredFinancialInstitutions.value
    );
    await financialInstitutionStore.updateFinancialInstitutions(
      "UL",
      selectedSlot.value.id,
      filteredUnlinkedFinancialInstitutions.value
    );
  } catch (error) {
    showError("Erro ao salvar alterações as IFs");
    pagesUtilStore.showLoading = false;
  } finally {
    pagesUtilStore.showLoading = false;
    showMessage("IFs atualizadas com sucesso!");
  }
};

const searchLinkedInstitutions = async () => {
  filteredFinancialInstitutions.value = financialInstitutions.value;

  filteredFinancialInstitutions.value = await filterInstitutions(
    financialInstitutions.value,
    filterTermsIFLinked.value
  );
};

const filterLinkedInstitutions = async () => {
  filteredFinancialInstitutions.value = financialInstitutions.value;

  filteredFinancialInstitutions.value = await filterInstitutionsCategory(
    financialInstitutions.value,
    categoryFilterLinked.value
  );
};

const searchUnlinkedInstitutions = async () => {
  filteredUnlinkedFinancialInstitutions.value =
    unlinkedFinancialInstitutions.value;

  filteredUnlinkedFinancialInstitutions.value = await filterInstitutions(
    unlinkedFinancialInstitutions.value,
    filterTermsIFUnlinked.value
  );
};

const filterUnlinkedInstitutions = async () => {
  filteredUnlinkedFinancialInstitutions.value =
    unlinkedFinancialInstitutions.value;

  filteredUnlinkedFinancialInstitutions.value =
    await filterInstitutionsCategory(
      unlinkedFinancialInstitutions.value,
      categoryFilterUnlinked.value
    );
};

const getFisToAdd = (
  selected: financialInstitutionInterface[]
): financialInstitutionInterface[] => {
  if (!selected?.length) return selected;

  const fisToAdd: financialInstitutionInterface[] = [];
  for (let ufi of selected) fisToAdd.push(ufi);

  return fisToAdd;
};

const unlinkFi = () => {
  const fisToAdd = getFisToAdd(selectedFinancialInstitutions.value);

  unlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value.concat(fisToAdd)
  );

  financialInstitutions.value = sortByIfName(
    financialInstitutions.value.filter((fi) => !fisToAdd.includes(fi))
  );

  filteredFinancialInstitutions.value = sortByIfName(
    financialInstitutions.value
  );
  filteredUnlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value
  );
  selectedFinancialInstitutions.value = [];
  console.log(categoryFilterLinked.value);
  categoryFilterLinked.value="";
  console.log(categoryFilterLinked.value);
};

const linkFi = () => {
  const fisToAdd = getFisToAdd(selectedUnlinkedFinancialInstitutions.value);

  financialInstitutions.value = sortByIfName(
    financialInstitutions.value.concat(fisToAdd)
  );

  unlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value.filter((fi) => !fisToAdd.includes(fi))
  );

  filteredFinancialInstitutions.value = sortByIfName(
    financialInstitutions.value
  );
  filteredUnlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value
  );
  selectedUnlinkedFinancialInstitutions.value = [];
  categoryFilterUnlinked.value="";
};

const classifyInstitutions = (ifs: financialInstitutionInterface[]): void => {
  financialInstitutions.value = [];
  unlinkedFinancialInstitutions.value = [];

  if (!ifs?.length) return;

  for (let i of ifs) {
    switch (selectedSlot.value.id) {
      case "A1": {
        i?.slot1
          ? financialInstitutions.value.push(i)
          : unlinkedFinancialInstitutions.value.push(i);

        break;
      }
      case "A2": {
        i?.slot2
          ? financialInstitutions.value.push(i)
          : unlinkedFinancialInstitutions.value.push(i);

        break;
      }
      case "A3": {
        i?.slot3
          ? financialInstitutions.value.push(i)
          : unlinkedFinancialInstitutions.value.push(i);

        break;
      }
      default: {
        financialInstitutions.value.push(i);

        break;
      }
    }
  }

  filteredFinancialInstitutions.value = financialInstitutions.value;
  filteredUnlinkedFinancialInstitutions.value =
    unlinkedFinancialInstitutions.value;
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_c_q_separator, {
        class: "q-pb-lg q-pt-md q-pl-lg",
        withBackButton: true
      }),
      _createVNode(PageCaption, {
        class: "q-ma-lg",
        pageCaptionTitle: "Desvincular Instituições por Slot",
        pageCaptionDescription: "Desvincule as instituições por slot de acordo com as regras atuais"
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(CampaignFormat)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "midia-app-caption-sm" }, "Slot ", -1)),
            _createVNode(_component_q_select, {
              modelValue: selectedSlot.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((selectedSlot).value = $event)),
                changeSelectedSlot
              ],
              options: slots.value,
              outlined: "",
              dense: "",
              color: "globalAppPrimaryColor",
              "bg-color": "globalAppDefaultBackgroundColor",
              "option-value": "id",
              "option-label": "description",
              disable: !_unref(campaignStore)?.focusedCampaign?.format
            }, {
              selected: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(
                  selectedSlot.value?.description
                    ? 'midia-app-content-lg'
                    : 'midia-app-placeholder-md'
                )
                }, _toDisplayString(selectedSlot.value?.description
                    ? selectedSlot.value.description
                    : "Escolha o Slot"), 3)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "disable"])
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, " Slot " + _toDisplayString(selectedSlot.value.id) + " - Instituições Vinculadas ", 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_q_input, {
              id: "searchbar",
              style: {"position":"relative"},
              modelValue: filterTermsIFLinked.value,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((filterTermsIFLinked).value = $event)),
                searchLinkedInstitutions
              ],
              color: "globalAppPrimaryColor",
              "bg-color": "globalAppDefaultBackgroundColor",
              outlined: "",
              dense: "",
              placeholder: "Pesquisar",
              "input-class": "midia-app-content-lg",
              onKeyup: _withKeys(searchLinkedInstitutions, ["enter"]),
              autofocus: ""
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "search",
                  class: "q-pa-sm",
                  style: {"background":"var(--globalAppSecondaryColor4)","padding":"6px","position":"relative","left":"-11px","border-right":"1px solid var(--globalAppSecondaryColor2)"}
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_c_q_btn, {
              id: "btn-fetch",
              propBtnLabel: "Filtrar",
              propBtnFunction: searchLinkedInstitutions,
              propBtnTransparent: false,
              propBtnSize: _unref(EnumButtonSizes).MEDIUM,
              class: "q-ml-sm"
            }, null, 8, ["propBtnSize"])
          ]),
          _createVNode(_component_q_table, {
            ref: "qTableFinancialInstitutions",
            rows: filteredFinancialInstitutions.value,
            "row-key": "codif",
            columns: qTableFinancialInstitutionsColumns,
            "visible-columns": qTableFinancialInstitutionsVisibleColumns,
            selected: selectedFinancialInstitutions.value,
            "onUpdate:selected": _cache[4] || (_cache[4] = ($event: any) => ((selectedFinancialInstitutions).value = $event)),
            "virtual-scroll": "",
            "no-data-label": "Nenhuma Instituição Vinculada",
            "rows-per-page-label": "Linhas por página:",
            selection: "multiple",
            dense: "",
            flat: "",
            separator: "horizontal",
            class: "midia-app-table q-mt-md"
          }, {
            header: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_th, {
                    style: {"text-align":"center","border":"0","padding":"2px"},
                    "auto-width": true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_checkbox, {
                        modelValue: props.selected,
                        "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                        color: 
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  ,
                        "keep-color": !props.selected,
                        size: "xs"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                    ]),
                    _: 2
                  }, 1024),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_th, {
                      key: col.field,
                      props: props,
                      style: {"border":"0","padding":"2px"}
                    }, {
                      default: _withCtx(() => [
                        (col.name === 'nomagrpacionista')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(_component_q_select, {
                                modelValue: categoryFilterLinked.value,
                                "onUpdate:modelValue": [
                                  _cache[3] || (_cache[3] = ($event: any) => ((categoryFilterLinked).value = $event)),
                                  filterLinkedInstitutions
                                ],
                                options: categoryOptionsLinked.value,
                                style: {"width":"100%"},
                                "popup-content-class": "midia-app-title-header-table-lg",
                                "stack-label": "",
                                "hide-dropdown-icon": "",
                                borderless: "",
                                dense: ""
                              }, {
                                after: _withCtx(() => _cache[10] || (_cache[10] = [])),
                                selected: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("span", null, _toDisplayString(categoryFilterLinked.value
                            ? categoryFilterLinked.value
                            : "Categoria"), 1),
                                    (categoryFilterLinked.value)
                                      ? (_openBlock(), _createBlock(_component_q_icon, {
                                          key: 0,
                                          size: "xs",
                                          name: "cancel",
                                          style: {"cursor":"pointer"},
                                          onClick: _cache[2] || (_cache[2] = ($event: any) => {
                            categoryFilterLinked.value = '';
                            filterLinkedInstitutions();
                          }),
                                          class: "q-ml-sm red"
                                        }))
                                      : (_openBlock(), _createBlock(_component_q_icon, {
                                          key: 1,
                                          size: "xs",
                                          name: "filter_list",
                                          style: {"cursor":"pointer"},
                                          class: "q-ml-sm"
                                        }))
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue", "options"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(col.label), 1)
                            ]))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, {
                class: "table-body-rows",
                props: props
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, {
                    key: "selected",
                    style: {"text-align":"center","padding":"2px"},
                    "auto-width": true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_checkbox, {
                        modelValue: props.selected,
                        "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                        color: 
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  ,
                        "keep-color": !props.selected,
                        size: "xs"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_td, {
                    key: "codif",
                    props: props,
                    style: {"padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_q_icon, { size: "18px" }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createElementVNode("img", {
                              class: "svg-icon",
                              src: _imports_0
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createElementVNode("span", _hoisted_15, _toDisplayString(props.row.codif), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  _createVNode(_component_q_td, {
                    key: "nome",
                    props: props,
                    style: {"padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(props.row?.nome?.length <= 25
                    ? props.row.nome
                    : `${props.row.nome.slice(0, 25)}...`), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  _createVNode(_component_q_td, {
                    key: "nomagrpacionista",
                    props: props,
                    style: {"padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(props.row.nomagrpacionista), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "selected"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_q_btn, {
              flat: "",
              color: "grey",
              icon: "navigate_next",
              size: "1.2rem",
              onClick: unlinkFi
            })
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_q_btn, {
              flat: "",
              color: "grey",
              icon: "navigate_before",
              size: "1.2rem",
              onClick: linkFi
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("span", _hoisted_23, " Slot " + _toDisplayString(selectedSlot.value.id) + " - Instituições Desvinculadas ", 1)
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_q_input, {
              id: "searchbar",
              style: {"position":"relative"},
              modelValue: filterTermsIFUnlinked.value,
              "onUpdate:modelValue": [
                _cache[5] || (_cache[5] = ($event: any) => ((filterTermsIFUnlinked).value = $event)),
                searchUnlinkedInstitutions
              ],
              color: "globalAppPrimaryColor",
              "bg-color": "globalAppDefaultBackgroundColor",
              outlined: "",
              dense: "",
              placeholder: "Pesquisar",
              "input-class": "midia-app-content-lg",
              onKeyup: _withKeys(searchUnlinkedInstitutions, ["enter"]),
              autofocus: ""
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "search",
                  class: "q-pa-sm",
                  style: {"background":"var(--globalAppSecondaryColor4)","padding":"6px","position":"relative","left":"-11px","border-right":"1px solid var(--globalAppSecondaryColor2)"}
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_c_q_btn, {
              id: "btn-fetch",
              propBtnLabel: "Filtrar",
              propBtnFunction: searchUnlinkedInstitutions,
              propBtnTransparent: false,
              propBtnSize: _unref(EnumButtonSizes).MEDIUM,
              class: "q-ml-sm"
            }, null, 8, ["propBtnSize"])
          ]),
          _createVNode(_component_q_table, {
            ref: "qTableUnlinkedFinancialInstitutions",
            rows: filteredUnlinkedFinancialInstitutions.value,
            "row-key": "codif",
            columns: qTableFinancialInstitutionsColumns,
            "visible-columns": qTableFinancialInstitutionsVisibleColumns,
            selected: selectedUnlinkedFinancialInstitutions.value,
            "onUpdate:selected": _cache[8] || (_cache[8] = ($event: any) => ((selectedUnlinkedFinancialInstitutions).value = $event)),
            "virtual-scroll": "",
            "no-data-label": "Nenhuma Instituição Desvinculada",
            "rows-per-page-label": "Linhas por página:",
            selection: "multiple",
            dense: "",
            flat: "",
            separator: "horizontal",
            class: "midia-app-table q-mt-md"
          }, {
            header: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_th, {
                    style: {"text-align":"center","border":"0","padding":"2px"},
                    "auto-width": true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_checkbox, {
                        modelValue: props.selected,
                        "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                        color: 
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  ,
                        "keep-color": !props.selected,
                        size: "xs"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                    ]),
                    _: 2
                  }, 1024),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_th, {
                      key: col.field,
                      props: props,
                      style: {"border":"0","padding":"2px"}
                    }, {
                      default: _withCtx(() => [
                        (col.name === 'nomagrpacionista')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                              _createVNode(_component_q_select, {
                                modelValue: categoryFilterUnlinked.value,
                                "onUpdate:modelValue": [
                                  _cache[7] || (_cache[7] = ($event: any) => ((categoryFilterUnlinked).value = $event)),
                                  filterUnlinkedInstitutions
                                ],
                                options: categoryOptionsUnlinked.value,
                                style: {"width":"100%"},
                                "popup-content-class": "midia-app-title-header-table-lg",
                                "hide-dropdown-icon": "",
                                "stack-label": "",
                                borderless: "",
                                dense: ""
                              }, {
                                after: _withCtx(() => _cache[12] || (_cache[12] = [])),
                                selected: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("span", null, _toDisplayString(categoryFilterUnlinked.value
                            ? categoryFilterUnlinked.value
                            : "Categoria"), 1),
                                    (categoryFilterUnlinked.value)
                                      ? (_openBlock(), _createBlock(_component_q_icon, {
                                          key: 0,
                                          size: "xs",
                                          name: "cancel",
                                          style: {"cursor":"pointer"},
                                          onClick: _cache[6] || (_cache[6] = ($event: any) => {
                            categoryFilterUnlinked.value = '';
                            filterUnlinkedInstitutions();
                          }),
                                          class: "q-ml-sm red"
                                        }))
                                      : (_openBlock(), _createBlock(_component_q_icon, {
                                          key: 1,
                                          size: "xs",
                                          name: "filter_list",
                                          style: {"cursor":"pointer"},
                                          class: "q-ml-sm"
                                        }))
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue", "options"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                              _createElementVNode("span", _hoisted_28, _toDisplayString(col.label), 1)
                            ]))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, {
                class: "table-body-rows",
                props: props
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, {
                    key: "selected",
                    style: {"text-align":"center","padding":"2px","margin-right":"5px"},
                    "auto-width": true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_checkbox, {
                        modelValue: props.selected,
                        "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                        color: 
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  ,
                        "keep-color": !props.selected,
                        size: "xs"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_td, {
                    key: "codif",
                    props: props,
                    style: {"padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_q_icon, { size: "18px" }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createElementVNode("img", {
                              class: "svg-icon",
                              src: _imports_0
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createElementVNode("span", _hoisted_30, _toDisplayString(props.row.codif), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  _createVNode(_component_q_td, {
                    key: "nome",
                    props: props,
                    style: {"padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_31, _toDisplayString(props.row?.nome?.length <= 25
                    ? props.row.nome
                    : `${props.row.nome.slice(0, 25)}...`), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  _createVNode(_component_q_td, {
                    key: "nomagrpacionista",
                    props: props,
                    style: {"padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_32, _toDisplayString(props.row.nomagrpacionista), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "selected"])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createVNode(_component_c_q_btn, {
              id: "btn-save-all",
              propBtnLabel: "Salvar Alterações",
              propBtnFunction: showDialogSaveAll,
              propBtnTransparent: false,
              propBtnSize: _unref(EnumButtonSizes).MEDIUM
            }, null, 8, ["propBtnSize"])
          ])
        ])
      ], 512), [
        [_vShow, selectedSlot.value?.id]
      ])
    ]),
    _createVNode(ModalFinancialInstitutions),
    _createVNode(ModalConfirmationDialog, {
      propDialogType: "slots",
      propDialogTitle: "Salvar alterações",
      propConfirmationMsg: `Deseja confirmar as alterações no Slot ${selectedSlot.value?.description}?`,
      onConfirm: saveAll
    }, null, 8, ["propConfirmationMsg"])
  ], 64))
}
}

})