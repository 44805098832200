import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/icon_perfil_white.svg'
import _imports_1 from '@/assets/icons/icon_sair.svg'


const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  id: "div-header-layout",
  class: "q-mx-xl q-mt-lg q-pb-md"
}
const _hoisted_4 = { class: "flex align-top justify-between q-pb-xs" }
const _hoisted_5 = { class: "midia-app-title-header-xl" }
const _hoisted_6 = { class: "flex items-baseline" }
const _hoisted_7 = { class: "midia-app-label-user-logged-in-email-lg q-pr-sm" }
const _hoisted_8 = {
  class: "q-pa-md",
  style: {"max-width":"250px","align-items":"end"}
}
const _hoisted_9 = { style: {"width":"70%","min-height":"20px"} }
const _hoisted_10 = { class: "midia-app-label-lg q-pr-sm" }

import { EnumRouteNames }  		from '@/enums/enums';
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';
import { useAuthStore }       from '@/stores/authStore';
import { computed, onMounted }						from 'vue';
import { useRouter }					from 'vue-router';
import {useContainmentStore} from '@/stores/containmentStore'
import moment from 'moment'


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutHeader',
  setup(__props) {

const pagesUtilStore  = usePagesUtilStore();
const authStore       = useAuthStore();
const router 					= useRouter();
const containmentStore = useContainmentStore();

const containmentData = computed(() =>{
	return containmentStore.containmentData;
})

const activatedAtButtonContainment = computed(() =>{
	return moment(containmentData.value.activated_at).format('DD/MM/YYYY HH:mm:ss');
})

const computedCurrentPageTitle = computed(() => {
	return pagesUtilStore.currentPageTitle;
})

const computedCurrentPageDescription = computed(() => {
	return pagesUtilStore.currentPageDescription;
})

const computedCurrentLoggedInUserEmail = computed(() => {
	return authStore.currentLoggedInUserEmail;
})

const onLogoutBtnClick = () => {
  authStore.clear();
  router.push({ name: EnumRouteNames.ROOT });
}


return (_ctx: any,_cache: any) => {
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (containmentData.value.is_active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_toolbar, { class: "bg-negative text-white" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, "Botão de Contenção Ativado – Desde: " + _toDisplayString(activatedAtButtonContainment.value) + " - " + _toDisplayString(containmentData.value.user_id), 1)
                ])
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(computedCurrentPageTitle.value), 1),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(computedCurrentLoggedInUserEmail.value), 1),
            _createVNode(_component_q_icon, {
              size: "14px",
              class: "cursor-pointer",
              style: {"background-color":"var(--globalAppPrimaryColor)","padding":"5px","border-radius":"50%"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("img", { src: _imports_0 }, null, -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_menu, {
              onBeforeShow: _cache[0] || (_cache[0] = ($event: any) => (_unref(authStore).showMenu = true)),
              onBeforeHide: _cache[1] || (_cache[1] = ($event: any) => (_unref(authStore).showMenu = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_list, {
                    dense: "",
                    bordered: "",
                    padding: "",
                    class: "rounded-borders text-red"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        class: "user-options exit",
                        "active-class": "user-options",
                        clickable: "",
                        onClick: onLogoutBtnClick
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, { avatar: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_avatar, { size: "sm" }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createElementVNode("img", {
                                    class: "svg-icon",
                                    src: _imports_1
                                  }, null, -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_label, null, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("Sair")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })), [
                        [_directive_close_popup]
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(computedCurrentPageDescription.value), 1)
      ])
    ])
  ], 64))
}
}

})