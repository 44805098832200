import { defineStore }  from 'pinia';
import { ref }          from 'vue';
import {
  EnumMultipleSingleMode,
  EnumRequestedAdjustmentsModalType
}                       from '@/enums/enums';

export const usePagesUtilStore = defineStore('pagesUtil', () => {
  const currentPageTitle                    = ref<string>('');
  const currentPageDescription              = ref<string>('');
  const currentLoggedInUserEmail            = ref<string>('');

  const showLayoutHeader                    = ref<boolean>(false);
  const showLayoutLeftSidePanel             = ref<boolean>(true);
  const showLayoutFooter                    = ref<boolean>(true);

  const currentSelectedLeftSidePanelItem    = ref<number>();

  const showLoading                         = ref<boolean>(false);
  const showLoadingModal                    = ref<boolean>(false);

  const showModalPageCampaignsTableActions     = ref<boolean>(false);
  const showModalDeleteCampaignConfirmation    = ref<boolean>(false);
  const showModalPCs                           = ref<boolean>(false);
  const showModalFilterCampaignDistributionPcs = ref<boolean>(false);
  const showModalExcludeIfs                    = ref<boolean>(false);
  const showModalExcludePCs                    = ref<boolean>(false);
  const showModalFilterPCs                     = ref<boolean>(false);
  const showModalFilterExcludePCs              = ref<boolean>(false);
  const showModalFinancialInstitutions         = ref<boolean>(false);
  const showModalRequestAdjustments            = ref<boolean>(false);
  const showModalImagePreview                  = ref<boolean>(false);
  const showModalFilterCampaign                = ref<boolean>(false);
  //const showModalConfirmationDialog           = ref<boolean>(false);

  //NOVO
  const showModalConfirmationDialogSlots       = ref<boolean>(false);
  const showModalConfirmationDialogPcs         = ref<boolean>(false);
  const showModalConfirmationDialogIfs         = ref<boolean>(false);
  const showModalConfirmationEditCampaign      = ref<boolean>(false);
  const showModalConfirmationCancelCampaign      = ref<boolean>(false);

  const modalDeleteCampaignConfirmationMode = ref<EnumMultipleSingleMode>(EnumMultipleSingleMode.SINGLE);
  const modalRequestedAdjustmentsModalType  = ref<EnumRequestedAdjustmentsModalType>(EnumRequestedAdjustmentsModalType.CREATE);

  const setCurrentPageTitleDescription = (
    newCurrentPageTitle: string,
    newCurrentPageDescription: string
  ) => {
    currentPageTitle.value        = newCurrentPageTitle;
    currentPageDescription.value  = newCurrentPageDescription;
  }

  const setCurrentLoggedInUserEmail = (newCurrentLoggedInUserEmail: string) => {
    currentLoggedInUserEmail.value = newCurrentLoggedInUserEmail;
  }

  return {
    currentPageTitle,
    currentPageDescription,
    currentLoggedInUserEmail,
    showLayoutHeader,
    showLayoutLeftSidePanel,
    showLayoutFooter,
    currentSelectedLeftSidePanelItem,
    showLoading,
    showLoadingModal,
    showModalPageCampaignsTableActions,
    showModalDeleteCampaignConfirmation,
    showModalPCs,
    showModalFilterCampaignDistributionPcs,
    showModalExcludeIfs,
    showModalExcludePCs,
    showModalFilterPCs,
    showModalFilterExcludePCs,
    showModalFinancialInstitutions,
    showModalRequestAdjustments,
    showModalImagePreview,
    showModalFilterCampaign,
    //showModalConfirmationDialog,

    //NOVOS
    showModalConfirmationDialogSlots,
    showModalConfirmationDialogPcs,
    showModalConfirmationDialogIfs,
    showModalConfirmationEditCampaign,
    showModalConfirmationCancelCampaign,
    

    modalDeleteCampaignConfirmationMode,
    modalRequestedAdjustmentsModalType,

    setCurrentPageTitleDescription,
    setCurrentLoggedInUserEmail
  }
})
