<template>
	<div class="row" v-if=containmentData.is_active>
		<div class="col-12">
      <q-toolbar class="bg-negative text-white">
				<div>
					<span>Botão de Contenção Ativado – Desde: {{activatedAtButtonContainment}} - {{containmentData.user_id}}</span>
				</div>
      </q-toolbar>
		</div>
	</div>
	<div id="div-header-layout" class="q-mx-xl q-mt-lg q-pb-md">
		<div class="flex align-top justify-between q-pb-xs">
			<span class="midia-app-title-header-xl">{{ computedCurrentPageTitle }}</span>

			<div>
				<div class="flex items-baseline">
					<span class="midia-app-label-user-logged-in-email-lg q-pr-sm">{{ computedCurrentLoggedInUserEmail }}</span>

					<q-icon size="14px" class="cursor-pointer" style=" background-color: var(--globalAppPrimaryColor); padding: 5px; border-radius: 50%; ">
						<img src="@/assets/icons/icon_perfil_white.svg" />
					</q-icon>
					<q-menu @before-show="authStore.showMenu = true" @before-hide="authStore.showMenu = false">
            <div class="q-pa-md" style="max-width: 250px; align-items:end;">
							<q-list dense bordered padding class="rounded-borders text-red">
								<q-item
                  class="user-options exit"
                  active-class="user-options"
                  clickable 
                  v-close-popup
									@click="onLogoutBtnClick"
                >
                  <q-item-section avatar>
                      <q-avatar size="sm"><img class="svg-icon" src="@/assets/icons/icon_sair.svg"></q-avatar>
                  </q-item-section>
                  <q-item-section>
                      <q-item-label>Sair</q-item-label>
                  </q-item-section>
                </q-item>
							</q-list>
						</div>
          </q-menu>
				</div>
			</div>
		</div>

		<div style=" width: 70%; min-height: 20px; ">
			<span class="midia-app-label-lg q-pr-sm">{{ computedCurrentPageDescription }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { EnumRouteNames }  		from '@/enums/enums';
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';
import { useAuthStore }       from '@/stores/authStore';
import { computed, onMounted }						from 'vue';
import { useRouter }					from 'vue-router';
import {useContainmentStore} from '@/stores/containmentStore'
import moment from 'moment'

const pagesUtilStore  = usePagesUtilStore();
const authStore       = useAuthStore();
const router 					= useRouter();
const containmentStore = useContainmentStore();

const containmentData = computed(() =>{
	return containmentStore.containmentData;
})

const activatedAtButtonContainment = computed(() =>{
	return moment(containmentData.value.activated_at).format('DD/MM/YYYY HH:mm:ss');
})

const computedCurrentPageTitle = computed(() => {
	return pagesUtilStore.currentPageTitle;
})

const computedCurrentPageDescription = computed(() => {
	return pagesUtilStore.currentPageDescription;
})

const computedCurrentLoggedInUserEmail = computed(() => {
	return authStore.currentLoggedInUserEmail;
})

const onLogoutBtnClick = () => {
  authStore.clear();
  router.push({ name: EnumRouteNames.ROOT });
}

</script>

<style scoped>

</style>
