import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row col q-pb-sm" }
const _hoisted_2 = { class: "row col q-pb-sm" }
const _hoisted_3 = { class: "row col" }

import { useCampaignStore }																	from '@/stores/campaignStore';
import { useFinancialInstitutionStore }											from '@/stores/financialInstitutionStore';
import { usePagesUtilStore }																from '@/stores/pagesUtilStore';
import { CONST_CAMPAIGN_ATM_SCREEN_FORMAT_SCREEN_OPTIONS }	from '@/static/constants';
import { computed, onBeforeMount }													from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFilterCampaignCampaignFormatATMScreen',
  setup(__props) {

const campaignStore							= useCampaignStore();
const financialInstitutionStore	= useFinancialInstitutionStore();
const pagesUtilStore						= usePagesUtilStore();

const optionsScreen				= computed(() => {
	return CONST_CAMPAIGN_ATM_SCREEN_FORMAT_SCREEN_OPTIONS;
});

const optionsInstitutions	= computed(() => {
	return financialInstitutionStore.financialInstitutions;
});

const sanitizeHTML = (html:any) => {
      return html; // For demonstration purpose only, please sanitize the HTML properly
    }



onBeforeMount(async () => {
  financialInstitutionStore.resetState();
  try {
    pagesUtilStore.showLoading = true;
  
    await financialInstitutionStore.fetchFinancialInstitutions();

  } finally {
    pagesUtilStore.showLoading = false;
  }      
});

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_select, {
        modelValue: _unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.screen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.screen) = $event)),
        options: optionsScreen.value,
        outlined: "",
        "option-label": "screenDescription",
        "option-value": "screenId",
        "map-options": "",
        "emit-value": "",
        dense: "",
        color: "globalAppPrimaryColor",
        style: {"width":"100%"},
        "label-slot": !_unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.screen
      }, {
        label: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "midia-app-placeholder-lg" }, "Tela", -1)
        ])),
        option: _withCtx(({ itemProps, opt }) => [
          _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(itemProps)), {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(sanitizeHTML(opt.screenDescription)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1040)
        ]),
        _: 1
      }, 8, ["modelValue", "options", "label-slot"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_input, {
        modelValue: _unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.pcs,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.pcs) = $event)),
        color: "globalAppPrimaryColor",
        "bg-color": "globalAppDefaultBackgroundColor",
        outlined: "",
        dense: "",
        placeholder: "PCs",
        "input-class": "midia-app-content-lg",
        style: {"width":"100%"}
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_select, {
        modelValue: _unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.institutions,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.institutions) = $event)),
        options: optionsInstitutions.value,
        "option-label": "nome",
        "option-value": "codif",
        "emit-value": "",
        "map-options": "",
        outlined: "",
        dense: "",
        color: "globalAppPrimaryColor",
        style: {"width":"100%"},
        "label-slot": !_unref(campaignStore).campaignFilter.filterCampaignFormatATMScreen.institutions
      }, {
        label: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "midia-app-placeholder-md" }, "Instituições", -1)
        ])),
        option: _withCtx(({ itemProps, opt }) => [
          _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(itemProps)), {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(sanitizeHTML(opt.nome)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1040)
        ]),
        _: 1
      }, 8, ["modelValue", "options", "label-slot"])
    ])
  ]))
}
}

})