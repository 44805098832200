import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-mx-xl q-pb-md" }

import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import {useContainmentStore} from '@/stores/containmentStore'
import {onMounted, onBeforeMount}                           from 'vue'



export default /*@__PURE__*/_defineComponent({
  __name: 'PageCampaignHome',
  setup(__props) {

const containmentStore = useContainmentStore();
const pagesUtilStore  = usePagesUtilStore();

onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "bg-globalAppSecondaryColor4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view, { id: "router-view-campaign-routes" })
      ])
    ]),
    _: 1
  }))
}
}

})