import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-pb-xs" }
const _hoisted_2 = { class: "midia-app-label-sm" }
const _hoisted_3 = { class: "row fit no-wrap items-center content-start q-pb-md" }

import CampaignFormatSlotIcon               from '@/components/campaign/CampaignFormatSlotIcon.vue';
import CampaignImageInformationDisplayCard  from '@/components/campaign/CampaignImageInformationDisplayCard.vue';
import { useCampaignStore }                 from '@/stores/campaignStore';
import { EnumATMScreenFormatSlotTypes }     from '@/enums/enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignFormatATMScreenView',
  setup(__props) {

const campaignStore = useCampaignStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, "Formato: " + _toDisplayString(_unref(campaignStore).focusedCampaign.format), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_unref(campaignStore).focusedCampaign.slot1)
        ? (_openBlock(), _createBlock(CampaignFormatSlotIcon, {
            key: 0,
            propSlotType: _unref(EnumATMScreenFormatSlotTypes).A1,
            propSlotLabel: "Abertura",
            propShowSlotTypeDescriptionBelowIcon: true
          }, null, 8, ["propSlotType"]))
        : _createCommentVNode("", true),
      (_unref(campaignStore).focusedCampaign.slot2)
        ? (_openBlock(), _createBlock(CampaignFormatSlotIcon, {
            key: 1,
            propSlotType: _unref(EnumATMScreenFormatSlotTypes).A2,
            propSlotLabel: "PropagIF",
            propShowSlotTypeDescriptionBelowIcon: true
          }, null, 8, ["propSlotType"]))
        : _createCommentVNode("", true),
      (_unref(campaignStore).focusedCampaign.slot3)
        ? (_openBlock(), _createBlock(CampaignFormatSlotIcon, {
            key: 2,
            propSlotType: _unref(EnumATMScreenFormatSlotTypes).A3,
            propSlotLabel: "Retire o seu Dinheiro",
            propShowSlotTypeDescriptionBelowIcon: true
          }, null, 8, ["propSlotType"]))
        : _createCommentVNode("", true),
      _createVNode(CampaignImageInformationDisplayCard)
    ])
  ]))
}
}

})