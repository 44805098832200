import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/icon_slot_Group_9251.svg'


const _hoisted_1 = {
  id: "slot-icon",
  class: "q-pr-md"
}
const _hoisted_2 = { id: "slot-type-name-inside-icon" }
const _hoisted_3 = {
  key: 0,
  id: "slot-type-description-below-icon",
  class: "midia-app-label-format-atm-slot-lg"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignFormatSlotIcon',
  props: {
  propSlotType: {
    type      : String,
    required  : true
  },

  propSlotLabel: {
    type      : String,
    required  : false
  },

  propShowSlotTypeDescriptionBelowIcon: {
    type      : Boolean,
    required  : false
  }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_icon, { size: "100px" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "slot_image"
        }, null, -1)
      ])),
      _: 1
    }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(props.propSlotType), 1),
    (props.propShowSlotTypeDescriptionBelowIcon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.propSlotLabel), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})