<template>
	<c-q-btn
		v-if="authStore.currentUserCanApproveCampaign 
		&& campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_APPROVAL.id"
		id="btn-approve-campaign"
		propBtnLabel="Aprovar"
		:propBtnFunction="onApproveFocusedCampaignBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>

	<c-q-btn
		v-if="authStore.currentUserCanApproveCampaign 
		&& campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id"
		id="btn-approve-campaign"
		propBtnLabel="Reaprovar"
		:propBtnFunction="onReapproveFocusedCampaignBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';


const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onApproveFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
   	await campaignStore.approveFocusedCampaign();
		
		messages.showSuccessMsg("Campanha aprovada com sucesso!");

  } catch (ErrorException:any) {
    messages.showErrorMsg(ErrorException.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};


const onReapproveFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
   	await campaignStore.reapproveFocusedCampaign();
		
		messages.showSuccessMsg("Campanha reaprovada com sucesso!");

  } catch (ErrorException:any) {
    messages.showErrorMsg(ErrorException.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
