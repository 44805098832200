import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "midia-app-content-lg" }

import { useCampaignStore } from '@/stores/campaignStore';
import { Ref, ref }					from 'vue';




export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignAdvertiser',
  props: {
  propUseInput: {
    type    : Boolean,
    default : false
  }
},
  setup(__props) {

const props = __props;

const campaignStore			= useCampaignStore();

const advertiserOptions = [
'Governo Federal',
	'Ministério da Saúde',
	'Sebrae',
	'Ministério da Cidadania',
	'Ministério das Comunicações',
	'SECOM',
	'Detran',
	'GDF',
	'Johnson&Johnson',
	'JBS',
	'Polishop',
	'Grupo Petrópolis',
	'Havaianas',
	'Avon',
	'Qualy',
	'Nivea',
	'Neutrogena',
	'O Boticário',
	'Netflix',
	'EAF'
];

// const advertiserOptions : Ref<string[]>	= ref([
// 	'Governo Federal',
// 	'Ministério da Saúde',
// 	'Sebrae',
// 	'Ministério da Cidadania',
// 	'Ministério das Comunicações',
// 	'SECOM',
// 	'Detran',
// 	'GDF',
// 	'Johnson&Johnson',
// 	'JBS',
// 	'Polishop',
// 	'Grupo Petrópolis',
// 	'Havaianas',
// 	'Avon',
// 	'Qualy',
// 	'Nivea',
// 	'Neutrogena',
// 	'O Boticário',
// 	'Netflix',
// 	'EAF'
// ]);

const options = ref(advertiserOptions);

const filterFN = (val: string, update: (callback: () => void) => void, abort: () => void) => {
	update(() => {
		const needle = val.toLocaleLowerCase();
		options.value = advertiserOptions.filter((v: string) => v.toLocaleLowerCase().indexOf(needle) > -1);
	});
};

const createValue = ((
		val: string,
		done: ref<(
			inputValue: string,
			doneFn: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				item?: any,
				mode?: "add" | "add-unique" | "toggle" | undefined
			) => void
		) => void>
	) => {

	if (val.length > 0) {
		if (!advertiserOptions.includes(val)) {
			advertiserOptions.push(val)
		}
		done(val, 'toggle')
	}
});

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "midia-app-caption-sm" }, "Anunciante *", -1)),
    _createVNode(_component_q_select, {
      modelValue: _unref(campaignStore).focusedCampaign.advertiser,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).focusedCampaign.advertiser) = $event)),
      maxlength: "500",
      "use-input": "",
      onFilter: filterFN,
      options: options.value,
      outlined: "",
      dense: "",
      color: "globalAppPrimaryColor",
      "bg-color": "globalAppDefaultBackgroundColor",
      readonly: _unref(campaignStore).focusedCampaign.iscampaignpublished || _unref(campaignStore).focusedCampaign.isapproved || _unref(campaignStore).focusedCampaign.ispendingpublication,
      "use-chips": "",
      "input-debounce": "0",
      onNewValue: createValue,
      "label-slot": !_unref(campaignStore).focusedCampaign.advertiser
    }, {
      label: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "midia-app-placeholder-md" }, "Selecione um Anunciante ou digite e tecle Enter", -1)
      ])),
      selected: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(campaignStore).focusedCampaign.advertiser), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "options", "readonly", "label-slot"])
  ]))
}
}

})