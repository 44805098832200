import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/icons/icon_filter.svg'


const _hoisted_1 = { class: "row q-mt-md" }
const _hoisted_2 = { class: "col-md-5" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "row q-mt-sm" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col offset-md-4" }
const _hoisted_11 = { class: "row fit no-wrap items-top" }
const _hoisted_12 = { class: "q-pr-xl q-mr-xl" }
const _hoisted_13 = { class: "row q-pb-lg" }
const _hoisted_14 = {
  class: "col-12",
  style: {"padding-right":"100px"}
}
const _hoisted_15 = { class: "row fit no-wrap justify-between items-end content-start" }
const _hoisted_16 = {
  class: "col-grow",
  style: {"overflow":"auto"}
}
const _hoisted_17 = { class: "row" }
const _hoisted_18 = {
  class: "col-12",
  style: {"padding-right":"100px"}
}
const _hoisted_19 = { class: "midia-app-title-header-table-lg" }
const _hoisted_20 = { class: "midia-app-label-sm" }
const _hoisted_21 = { class: "midia-app-label-sm" }

import { useCampaignStore } from "@/stores/campaignStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { quasarCustomQTableColumns } from "@/types/quasarCustomQTableColumn";
import { CampaignDistributionPc, CampaignDistribution } from "@/types/types.d";
import { CampaignDistributionPcModel } from "@/models/CampaignDistributionPcModel";
import { CampaignDistributionModel } from "@/models/CampaignDistributionModel";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import messages from "@/utils/messages";

import BackButton from "@/components/BackButton.vue";
import CampaignDistributionSection from "@/components/campaign/CampaignDistributionSection.vue";
import ModalFilterCampaignDistributionPcs from "@/views/modals/ModalFilterCampaignDistributionPcs.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PageCampaignDistribution',
  setup(__props) {

const qTablePcColumns: quasarCustomQTableColumns[] = [
  {
    name: "pc",
    label: "Código PC",
    field: "pc",
    align: "center",
  },
  {
    name: "nome",
    label: "Nome PC",
    field: "nome",
    align: "left",
  },

  {
    name: "status",
    label: "Status",
    field: "status",
    align: "left",
  },

  {
    name: "motivo",
    label: "Motivo",
    field: "motivo",
    align: "left",
  },
];

const qTablePcVisibleColumns = ref(["pc", "nome", "status", "motivo"]);

const pagination = ref({
  currentPage: 0,
  totalPages: 0,
  totalItems: 0,
  quasarPagination: { page: 1, rowsPerPage: 10 },
});

const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();
const router = useRouter();

const viewvedPcs = ref(0);
const filterDistributionPc = ref("");
const filteredPcs = ref([] as CampaignDistributionPc[]);
const blockPagination = ref(true);

onMounted(async () => {
  const from = sessionStorage.getItem("from");

  // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
  if (!from) {
    router.push({ name: "home" });
  } else {
    sessionStorage.removeItem("from");
  }

  if (
    !campaignStore?.focusedCampaign?.id ||
    campaignStore.focusedCampaign.id === 0
  ) {
    messages.showErrorMsg(
      "Distribuição não encontrada para campanha especificada. Redirecionando..."
    );
    router.push({ name: "home" });
    return;
  }
  campaignStore.resetCampaignDistribution();
  await fetchCampaignDistribution(campaignStore.focusedCampaign.id, 0);
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem("from", "true"); // Mantém o histórico
  next(); // Continua a navegação normalmente
});

const showFilterModal = () => {
  pagesUtilStore.showModalFilterCampaignDistributionPcs = true;
};

const searchPcs = () => {
  filteredPcs.value =
    campaignStore?.campaignDistribtion?.pcsCampaign &&
    campaignStore.campaignDistribtion.pcsCampaign.length > 0
      ? campaignStore.campaignDistribtion.pcsCampaign
      : [];

  if (!filterDistributionPc.value) return;

  filteredPcs.value = filteredPcs.value.filter(
    (pc) =>
      pc?.pc
        ?.toString()
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase()) ||
      pc?.nome
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase()) ||
      pc?.status
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase()) ||
      pc?.motivo
        ?.toLowerCase()
        .includes(filterDistributionPc.value.toLowerCase())
  );
};

const onFilter = (filteredTable: CampaignDistributionPc[]) => {
  filteredPcs.value = filteredTable;
};

const updatePagination = async (newPagination: object): Promise<void> => {
  if (newPagination && blockPagination.value) {
    blockPagination.value = false;
    return;
  }

  const totalPcs = campaignStore?.campaignDistribtion?.pcsCampaign?.length
      ? campaignStore.campaignDistribtion.pcsCampaign.length
      : 0;

  viewvedPcs.value =
      pagination.value.quasarPagination.rowsPerPage *
      pagination.value.quasarPagination.page;

  if (viewvedPcs.value > 0 && totalPcs > 0 && viewvedPcs.value >= totalPcs - 1)
    await fetchCampaignDistribution(
        campaignStore.focusedCampaign.id,
        pagination.value.currentPage + 1
    );
};

const fetchCampaignDistribution = async (
  campaignId: number | undefined | null,
  page: number | undefined | null
): Promise<void> => {
  pagesUtilStore.showLoading = true;
  try {
    await campaignStore.fetchCampaignDistribution(campaignId, page);
  } catch (error) {
    messages.showErrorMsg(
      `Erro ao tentar obter a distribuição da campanha de id ${campaignId}...`
    );
    pagesUtilStore.showLoading = false;
  } finally {
    if (campaignStore?.campaignDistribtion?.pcsCampaign?.length) {
      filteredPcs.value = campaignStore.campaignDistribtion.pcsCampaign.map(
        (pc) => new CampaignDistributionPcModel(pc)
      );

      pagination.value.currentPage =
        campaignStore.campaignDistribtion.currentPcPage ?? 0;
      pagination.value.totalPages =
        campaignStore.campaignDistribtion.totalPcPages ?? 0;
      pagination.value.totalItems =
        campaignStore.campaignDistribtion.totalPcs ?? 0;
    }

    pagesUtilStore.showLoading = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_c_readonly_field = _resolveComponent("c-readonly-field")!
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BackButton),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_c_readonly_field, {
              title: "Campanha",
              description: _unref(campaignStore)?.focusedCampaign?.name
            }, null, 8, ["description"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_c_readonly_field, {
              title: "Total",
              description: _unref(campaignStore).campaignDistribtion.totalPcs ?? 0,
              descriptionColor: "globalBlue006BA7"
            }, null, 8, ["description"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_c_readonly_field, {
              title: "Ultima atualização",
              description: 
              _unref(campaignStore)?.campaignDistribtion?.ultimaAtualizacao
                ? new Date(
                    (_unref(campaignStore)).campaignDistribtion.ultimaAtualizacao
                  ).toLocaleString()
                : ''
            
            }, null, 8, ["description"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_c_readonly_field, {
              title: "Sucesso",
              description: _unref(campaignStore).campaignDistribtion.totalSucesso ?? 0,
              descriptionColor: "globalGreen17B51C"
            }, null, 8, ["description"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_c_readonly_field, {
              title: "Pendente",
              description: _unref(campaignStore).campaignDistribtion.totalPendentes ?? 0,
              descriptionColor: "globalOrangeFF6B00"
            }, null, 8, ["description"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(CampaignDistributionSection)
          ])
        ])
      ])
    ]),
    _createVNode(_component_c_q_separator, { class: "q-my-lg" }),
    _createElementVNode("div", _hoisted_13, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "q-pb-xs" }, [
        _createElementVNode("span", { class: "midia-app-label-lg" }, "Pesquise um Dispositivo")
      ], -1)),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_q_input, {
              modelValue: filterDistributionPc.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((filterDistributionPc).value = $event)),
                searchPcs
              ],
              color: "globalAppPrimaryColor",
              "bg-color": "globalAppDefaultBackgroundColor",
              outlined: "",
              dense: "",
              placeholder: "Busque um dispositivo",
              "input-class": "midia-app-content-lg",
              onKeyup: _withKeys(searchPcs, ["enter"])
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "search",
                  class: "q-pa-sm",
                  style: {"background":"var(--globalAppSecondaryColor4)","padding":"6px","position":"relative","left":"-11px","border-right":"1px solid var(--globalAppSecondaryColor2)"}
                })
              ]),
              append: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  outlined: "",
                  "no-caps": "",
                  unelevated: "",
                  style: {"left":"11px"},
                  onClick: showFilterModal
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, { size: "16px" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createElementVNode("img", {
                          class: "svg-icon",
                          src: _imports_0
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "midia-app-label-lg q-pl-sm" }, "Filtrar", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_q_table, {
          ref: "qTablePcs",
          rows: filteredPcs.value,
          "row-key": "pc",
          columns: qTablePcColumns,
          "visible-columns": qTablePcVisibleColumns.value,
          pagination: pagination.value.quasarPagination,
          "onUpdate:pagination": [
            _cache[1] || (_cache[1] = ($event: any) => ((pagination.value.quasarPagination) = $event)),
            updatePagination
          ],
          "rows-per-page-options": [10, 50, 100,300, 500],
          "virtual-scroll": "",
          "no-data-label": "Nenhum registro encontrado!",
          "rows-per-page-label": "Linhas por página:",
          selection: "multiple",
          dense: "",
          flat: "",
          separator: "horizontal",
          class: "midia-app-table q-mt-md"
        }, {
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, {
              props: props,
              style: {"background-color":"var(--globalGrayF8F7F7) !important"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.field,
                    props: props,
                    style: {"border":"0","padding":"2px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(col.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, {
              class: "table-body-rows",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "pc",
                  props: props,
                  style: {"padding":"2px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(props.row.pc), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "nome",
                  props: props,
                  style: {"padding":"2px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(props.row.nome), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "status",
                  props: props,
                  style: {"padding":"2px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "midia-app-label-sm",
                      style: _normalizeStyle(`${
                  'Sucesso' === props?.row?.status
                    ? 'color: var(--globalGreen17B51C)'
                    : 'color: var(--globalBlue28A5FF)'
                }`)
                    }, _toDisplayString(props.row.status), 5)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "status",
                  props: props,
                  style: {"padding":"2px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "midia-app-label-sm",
                      style: _normalizeStyle(`${
                  'Coleta Pendente' === props?.row?.motivo
                    ? 'color: var(--globalBlue28A5FF)'
                    : 'color: var(--globalGreen17B51C)'
                }`)
                    }, _toDisplayString(props.row.motivo), 5)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "visible-columns", "pagination"])
      ])
    ]),
    _createVNode(ModalFilterCampaignDistributionPcs, { onFilteredTable: onFilter })
  ], 64))
}
}

})