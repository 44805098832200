import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnSaveFocusedCampaign',
  setup(__props) {

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onSaveCampaignBtnClick = async () => {
	try {
		pagesUtilStore.showLoading = true;

		try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }
	
		await campaignStore.saveFocusedCampaign();

		messages.showSuccessMsg("Campanha salva com sucesso.");

	} catch (errorSavingCampaign) {
		
		messages.showErrorMsg(errorSavingCampaign.message);
		// messages.showErrorMsg("Erro ao tentar salvar a campanha...");
	
	} finally {
		pagesUtilStore.showLoading = false;
	}
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_unref(authStore).currentUserCanSaveCampaign 
		&& _unref(campaignStore).focusedCampaign.status == undefined)
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-save-campaign",
        propBtnLabel: "Salvar",
        propBtnFunction: onSaveCampaignBtnClick,
        propBtnTransparent: true,
        propBtnSize: _unref(EnumButtonSizes).LARGE_1
      }, null, 8, ["propBtnSize"]))
    : _createCommentVNode("", true)
}
}

})