import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row fit no-wrap items-center content-start" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "q-pr-sm" }
const _hoisted_4 = { class: "row q-pb-sm" }
const _hoisted_5 = { class: "midia-app-label-lg" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-6 col-md-6 q-pr-sm" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "midia-app-label-md" }
const _hoisted_10 = { class: "col-6 col-md-6 q-pl-sm" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "midia-app-label-md" }
const _hoisted_13 = {
  key: 0,
  class: "q-ml-lg q-mb-sm",
  style: {"align-self":"flex-end"}
}

import ModalImagePreview							from '@/views/modals/ModalImagePreview.vue';
import { EnumButtonSizes,}						from '@/enums/enums';
import { useCampaignStore }   				from '@/stores/campaignStore';
import { usePagesUtilStore }					from '@/stores/pagesUtilStore';
import { computed }           				from 'vue';
import { campaignJsonSlotInterface }	from '@/types/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignImageInformationDisplayCard',
  setup(__props) {

const campaignStore         = useCampaignStore();
const pagesUtilStore        = usePagesUtilStore();

const onImageDivClick = () => {
  pagesUtilStore.showModalImagePreview = true;
};

const onDownloadCampaignImgBtnClick = () => {
	const urlInfo = campaignStore.focusedCampaign.formatAtmScreenImageSlotS3Url();
	if(urlInfo){
		const {imageUrl} = urlInfo
		window.location.href = imageUrl;
	}else {
  // Handle the case where the URL is undefined
  console.error("URL is undefined");
}
};

const computedCampaignJsonSlot = computed(() => {
	if (campaignStore.focusedCampaign.slot1) {
		return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot1);

	} else if (campaignStore.focusedCampaign.slot2) {
		return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot2);

	} else if (campaignStore.focusedCampaign.slot3) {
		return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot3);

	} else {
		return {
			imageObjectUrl	: "",
			imageS3Url      : undefined,
			imageFileName 	: "",
			imageWidth			: undefined,
			imageHeight			: undefined,
			imageSize				: undefined
		};
	}
});

const computedImgUrl = computed(() => {
	if (campaignStore.focusedCampaign.id) {

		return campaignStore.focusedCampaign.formatAtmScreenImageSlotS3Url()?.imageUrl;

	} else {
		if (campaignStore.focusedCampaign.slot1) {
			return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot1).imageObjectUrl;

		} else if (campaignStore.focusedCampaign.slot2) {
			return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot2).imageObjectUrl;

		} else if (campaignStore.focusedCampaign.slot3) {
			return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot3).imageObjectUrl;

		} else {
			return {
				imageObjectUrl	: "",
				imageS3Url      : undefined,
				imageFileName 	: "",
				imageWidth			: undefined,
				imageHeight			: undefined,
				imageSize				: undefined
			};
		}
	}
});

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "q-px-lg",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (onImageDivClick()))
    }, [
      _createElementVNode("img", {
        class: "cursor-pointer",
        src: computedImgUrl.value,
        alt: "img_campanha",
        style: {"width":"200px","height":"112.5px","object-fit":"contain"}
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "row" }, [
        _createElementVNode("span", { class: "midia-app-label-sm" }, "Arquivo da Campanha")
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(computedCampaignJsonSlot.value.imageFileName), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("span", { class: "midia-app-label-sm" }, "Dimensões")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(`${computedCampaignJsonSlot.value.imageWidth} x ${computedCampaignJsonSlot.value.imageHeight}px`), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("span", { class: "midia-app-label-sm" }, "Tamanho")
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString((computedCampaignJsonSlot.value.imageSize ? `${Math.ceil(computedCampaignJsonSlot.value.imageSize / 1024)} KB` : "")), 1)
          ])
        ])
      ])
    ]),
    (_unref(campaignStore).focusedCampaign.status)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_c_q_btn, {
            id: "btn-download-campaign-image",
            propBtnLabel: "Download",
            propBtnFunction: onDownloadCampaignImgBtnClick,
            propBtnTransparent: true,
            propBtnSize: _unref(EnumButtonSizes).SMALL
          }, null, 8, ["propBtnSize"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(ModalImagePreview, {
      propImgObjectUrl: computedCampaignJsonSlot.value.imageObjectUrl ? computedCampaignJsonSlot.value.imageObjectUrl : '',
      propShowDownloadButton: false
    }, null, 8, ["propImgObjectUrl"])
  ]))
}
}

})