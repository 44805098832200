<template>
  <div class="row fit no-wrap justify-end" style="overflow: auto; gap: 20px">
    <BtnBackEdit v-if="props.propShowBtnBackEdit" />

    <BtnCancelFocusedCampaign v-if="props.propShowBtnCancelFocusedCampaign" />

    <BtnCancelCreateNewCampaign
      v-if="props.propShowBtnCancelCreateNewCampaign"
    />

    <BtnEditFocusedCampaign v-if="props.propShowBtnEditFocusedCampaign" />

    <BtnDiscardChanges v-if="props.propShowBtnDiscardChanges" />

    <BtnSaveFocusedCampaign v-if="props.propShowBtnSaveFocusedCampaign" />

    <BtnDeleteSelectedCampaigns
      v-if="props.propShowBtnDeleteSelectedCampaigns"
    />

    <BtnReviewFocusedCampaign v-if="props.propShowBtnReviewFocusedCampaign" />

    <BtnRequestAdjustmentsToFocusedCampaign
      v-if="props.propShowBtnRequestAdjustmentsToFocusedCampaign"
    />

    <BtnSendFocusedCampaignToApprove
      v-if="props.propShowBtnSendFocusedCampaignToApprove"
    />

    <BtnResendFocusedCampaignToApprove
      v-if="props.propShowBtnResendFocusedCampaignToApprove"
    />

    <BtnApproveFocusedCampaign v-if="props.propShowBtnApproveFocusedCampaign" />

    <BtnPublishFocusedCampaign v-if="props.propShowBtnApproveFocusedCampaign" />
    <BtnViewCampaignDistribution
      v-if="props.propShowBtnViewCampaignDistribution"
    />
  </div>
</template>

<script setup lang="ts">
import BtnCancelFocusedCampaign from "@/components/campaign/actionButtons/BtnCancelFocusedCampaign.vue";
import BtnViewCampaignDistribution from "@/components/campaign/actionButtons/BtnViewCampaignDistribution.vue";
import BtnCancelCreateNewCampaign from "@/components/campaign/actionButtons/BtnCancelCreateNewCampaign.vue";
import BtnEditFocusedCampaign from "@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue";
import BtnSaveFocusedCampaign from "@/components/campaign/actionButtons/BtnSaveFocusedCampaign.vue";
import BtnDeleteSelectedCampaigns from "@/components/campaign/actionButtons/BtnDeleteSelectedCampaigns.vue";
import BtnReviewFocusedCampaign from "@/components/campaign/actionButtons/BtnReviewFocusedCampaign.vue";
import BtnRequestAdjustmentsToFocusedCampaign from "@/components/campaign/actionButtons/BtnRequestAdjustmentsToFocusedCampaign.vue";
import BtnSendFocusedCampaignToApprove from "@/components/campaign/actionButtons/BtnSendFocusedCampaignToApprove.vue";
import BtnResendFocusedCampaignToApprove from "@/components/campaign/actionButtons/BtnResendFocusedCampaignToApprove.vue";
import BtnApproveFocusedCampaign from "@/components/campaign/actionButtons/BtnApproveFocusedCampaign.vue";
import BtnPublishFocusedCampaign from "@/components/campaign/actionButtons/BtnPublishFocusedCampaign.vue";
import BtnBackEdit from "@/components/campaign/actionButtons/BtnBackEdit.vue";
import BtnDiscardChanges from "@/components/campaign/actionButtons/BtnDiscardChanges.vue";

const props = defineProps({
  propShowBtnCancelFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnViewCampaignDistribution: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnCancelCreateNewCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnEditFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnSaveFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnDeleteSelectedCampaigns: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnReviewFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnRequestAdjustmentsToFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnSendFocusedCampaignToApprove: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnResendFocusedCampaignToApprove: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnApproveFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnBackEdit: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnDiscardChanges: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<style scoped></style>
