import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter }												from 'vue-router';
import { useCampaignStore }									from '@/stores/campaignStore';
import { useAuthStore }					from '@/stores/authStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnReviewFocusedCampaign',
  setup(__props) {

const router = useRouter();

const campaignStore = useCampaignStore();
const authStore 			= useAuthStore();

const onReviewCampaignBtnClick = () => {
	if (campaignStore.validateFocusedCampaignFields()) {
		router.push({ name: EnumRouteNames.CAMPAIGN_REVIEW });
	}
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_unref(authStore).currentUserCanReviewCampaign)
    ? (_openBlock(), _createBlock(_component_c_q_btn, {
        key: 0,
        id: "btn-review-campaign",
        propBtnLabel: "Revisar Campanha",
        propBtnFunction: onReviewCampaignBtnClick,
        propBtnTransparent: false,
        propBtnSize: _unref(EnumButtonSizes).LARGE_1
      }, null, 8, ["propBtnSize"]))
    : _createCommentVNode("", true)
}
}

})