<template>
  <q-page class="bg-globalAppSecondaryColor4">
    <div class="q-mx-xl q-pb-md">
      <router-view id="router-view-campaign-routes" />
    </div>
  </q-page>
</template>

<script setup lang="ts">
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import {useContainmentStore} from '@/stores/containmentStore'
import {onMounted, onBeforeMount}                           from 'vue'


const containmentStore = useContainmentStore();
const pagesUtilStore  = usePagesUtilStore();

onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});

</script>

<style scoped>

</style>