import { createApp, markRaw }      from "vue";
import App                from "./App.vue";
import { createPinia }    from 'pinia';
import router             from './router/router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions  from '@/quasar-user-options';
import CustomModalTitle   from '@/components/generic/CustomModalTitle.vue';
import CustomQBtn     	  from '@/components/generic/CustomQBtn.vue';
import CustomQSeparator   from '@/components/generic/CustomQSeparator.vue';
import ReadOnlyField      from '@/components/generic/ReadOnlyField.vue';
import { useAuthStore }   from '@/stores/authStore';
import { useRouter } from 'vue-router'
import { EnumRouteNames }  from '@/enums/enums';

// Stop error resizeObserver - inicio
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = (callback: (...args: any[]) => void, delay: number) => {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  let tid: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _ = (window as any).ResizeObserver;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).ResizeObserver = class ResizeObserver extends _ {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
constructor(callback: (...args: any[]) => void) {
    callback = debounce (callback, 20);
    super(callback);
  }
};
// Stop error resizeObserver - fim
const pinia = createPinia();
pinia.use(({ store }) => { store.router = markRaw(router) });

const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(pinia)
  .use(router);
  

  if(localStorage.getItem('token')){
    //iife
    (async () =>{
      const auth = useAuthStore();
      try{
        auth.setIsAuth(true);
        await auth.checkToken();
      }catch(error){
        auth.clear();
        console.error(error);
      }
    })()
  }

app.component("c-modal-title", CustomModalTitle);
app.component("c-q-btn", CustomQBtn);
app.component("c-q-separator", CustomQSeparator);
app.component("c-readonly-field", ReadOnlyField);

app.mount("#app");
