import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "div-vue-app" }

import "@/assets/css/global.css";
import "@/assets/css/fonts.css";
import "@/assets/css/quasarColorClasses.css";
import "@/assets/css/util.css";
import "@/assets/scss/layoutDefinitionClasses.scss";

import LayoutApp from '@/views/layout/LayoutApp.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LayoutApp)
  ]))
}
}

})