import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "col-2 justify-start" }
const _hoisted_3 = { class: "col-8 justify-center" }
const _hoisted_4 = {
  key: 1,
  class: "row items-center justify-center",
  style: {"width":"100%"}
}
const _hoisted_5 = { class: "col-10 justify-center" }

import BackButton from '@/components/BackButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomQSeparator',
  props: {
  withBackButton: {
    type    : Boolean,
    default : false
  }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    (props.withBackButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(BackButton)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_separator)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_separator)
          ])
        ]))
  ]))
}
}

})