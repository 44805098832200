import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { pcInterface } from '@/types/types.d'
import ModalConfirmationDialog		from '@/views/modals/ModalConfirmationDialog.vue';
import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useCampaignStore }                 from '@/stores/campaignStore';
import { usePagesUtilStore }                from '@/stores/pagesUtilStore';
import { useRouter }												from 'vue-router';
import messages                             from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }						from '@/static/constants';
import { useAuthStore }															from '@/stores/authStore';
import {computed }													from 'vue'
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnEditFocusedCampaign',
  props: {
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: false,
		default		: EnumButtonSizes.LARGE_1
	}
},
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore				= useAuthStore();

const router = useRouter();

const props = __props;

const diferancaEntreDatas  = ( data : string | undefined ) => {

	const hoje = moment();

	data = campaignStore.focusedCampaign.startDate;

	const data1 = moment(data);

	const diferenca = data1.diff(hoje, 'days');

	return diferenca >= 1;
	
}

const pcsPorGrupo = computed(() => {
  return campaignStore.focusedCampaign.pcsPorGrupo;
});

const disableEditFocusedCampaignsBtn = computed(() => {
	try {
		return campaignStore.campaignCanBeEdited(campaignStore.focusedCampaign);
		
	} catch (error) {
		return true;
	}
})

//NOVO
const abreDialogo = () => {
  pagesUtilStore.showModalConfirmationEditCampaign = true;
}

const onEditCampaignBtnClick = async () => {
	try {
    pagesUtilStore.showLoading = true;
  
		if (campaignStore.focusedCampaign?.status != CONST_CAMPAIGN_STATUS.ON_EDITING.id && campaignStore.focusedCampaign?.status != CONST_CAMPAIGN_STATUS.ON_REISSUE.id) {
    	await campaignStore.editFocusedCampaign();
		}
		
		let allPcs:pcInterface[] = [];

		Object.values(pcsPorGrupo.value).forEach(pcs =>{
      allPcs = allPcs.concat(pcs);
    })

		// Atualiza o campo pcs na store
    campaignStore.setFocusedCampaignPcs(allPcs);

		router.push({ name: EnumRouteNames.CAMPAIGN_EDIT });

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar abrir a campanha para edição...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

//NOVO


return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(authStore).currentUserCanEditCampaign 
		&& (_unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).APPROVED.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ADJUSTMENT_REQUESTED_AGAIN.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_REISSUE.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ACTIVE_CAMPAIGN.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).REAPPROVED.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).DRAFT.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_EDITING.id
		|| _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).PENDING_PUBLICATION.id
		|| 
		(_unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).PUBLISHED.id
		)))
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 0,
          id: "btn-edit-campaign",
          propBtnLabel: "Editar",
          propBtnFunction: _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).APPROVED.id || _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).REAPPROVED.id ? abreDialogo : onEditCampaignBtnClick,
          propBtnTransparent: true,
          propBtnSize: props.propBtnSize,
          disable: disableEditFocusedCampaignsBtn.value
        }, null, 8, ["propBtnFunction", "propBtnSize", "disable"]))
      : _createCommentVNode("", true),
    _createVNode(ModalConfirmationDialog, {
      propDialogTitle: "Atenção!",
      propConfirmationMsg: "Essa campanha foi aprovada! Caso seja editada, ela precisará ser novamente enviada para aprovação! Deseja continuar?",
      propDialogType: "editar",
      onConfirm: onEditCampaignBtnClick
    })
  ], 64))
}
}

})