<template>
  <q-btn
    :style="computedMidiaAppButtonStyle"
    :size="props.propBtnSize"
    :disable="props.propDisable"
    unelevated
    flat
    no-caps
    @click="onBtnClick()"
  >
    <template v-slot:default>
      <slot />

      <span
        v-if="props.propBtnLabel"
        class="m-pl-md"
        :style="computedMidiaAppButtonLabelStyle"
        >{{ props.propBtnLabel }}</span
      >

      <slot name="afterLabel" />
    </template>
  </q-btn>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { EnumButtonSizes } from "@/enums/enums";
import { cssStyleObjectToStyleString } from "@/utils/commonUtils";

const props = defineProps({
  propBtnLabel: {
    type: String,
    required: true,
  },

  propBtnFunction: {
    type: Function as PropType<() => void>,
    required: false,
  },

  propBtnTransparent: {
    type: Boolean,
    required: false,
    default: false,
  },

  propBtnSize: {
    type: String as () => keyof typeof EnumButtonSizes,
    required: false,
    default: EnumButtonSizes.MEDIUM,
  },

  propBorderless: {
    type: Boolean,
    required: false,
    default: false,
  },

  propDisable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const onBtnClick = () => {
  if (props.propBtnFunction) {
    props.propBtnFunction();
  }
};

const computedMidiaAppButtonStyle = computed(() => {
  const midiaAppButtonCssStyleObject = {
    border: props.propBorderless
      ? "none"
      : "1px solid var(--globalAppPrimaryColor) !important",
    "border-radius": "6px !important",
    "background-color": props.propBtnTransparent
      ? "transparent"
      : "var(--globalAppPrimaryColor) !important",
  };

  const midiaAppButtonCssStyleString = cssStyleObjectToStyleString(
    midiaAppButtonCssStyleObject
  );

  return midiaAppButtonCssStyleString;
});

const computedFontSize = computed(() => {
  return props.propBtnSize == EnumButtonSizes.SMALL.toString()
    ? "12px"
    : props.propBtnSize == EnumButtonSizes.MEDIUM.toString()
    ? "14px"
    : props.propBtnSize == EnumButtonSizes.LARGE_1.toString()
    ? "16px"
    : props.propBtnSize == EnumButtonSizes.LARGE_2.toString()
    ? "22px"
    : "22px";
});

const computedLineHeight = computed(() => {
  return props.propBtnSize == EnumButtonSizes.SMALL.toString()
    ? "16px"
    : props.propBtnSize == EnumButtonSizes.MEDIUM.toString()
    ? "20px"
    : props.propBtnSize == EnumButtonSizes.LARGE_1.toString()
    ? "24px"
    : props.propBtnSize == EnumButtonSizes.LARGE_2.toString()
    ? "30px"
    : "30px";
});

const computedMidiaAppButtonLabelStyle = computed(() => {
  const midiaAppButtonLabelCssStyleObject = {
    color: props.propBtnTransparent
      ? "var(--globalAppPrimaryColor) !important"
      : "var(--globalAppDefaultBackgroundColor) !important",
    "font-family": "Frutiger",
    "font-weight": "300",
    "font-size": computedFontSize.value,
    "line-height": computedLineHeight.value,
  };

  const midiaAppButtonLabelCssStyleString = cssStyleObjectToStyleString(
    midiaAppButtonLabelCssStyleObject
  );

  return midiaAppButtonLabelCssStyleString;
});
</script>

<style scoped></style>
