<template>
  <div class="flex items-center justify-center" style=" min-height: inherit; background-color: var(--globalAppSecondaryColor4); ">

  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import {useContainmentStore} from '@/stores/containmentStore'
import { onBeforeMount }     from 'vue';

const router = useRouter();
const containmentStore = useContainmentStore();
const pagesUtilStore  = usePagesUtilStore();

onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});

router.beforeEach((to, from, next) => {
    sessionStorage.setItem('from', 'true'); // Mantém o histórico

    next(); // Continua a navegação normalmente
  });

</script>

<style scoped>

</style>