<template>
  <div id="div-vue-app-layout">
    <q-layout view="lHh lpR fFf">
      <q-header v-model="pagesUtilStore.showLayoutHeader" class="bg-globalAppSecondaryColor4 text-globalAppDefaultTextColor">
        <LayoutHeader />
        <!-- <HeaderContainment /> -->
      </q-header>

      <q-drawer
      side="left"
      v-model="pagesUtilStore.showLayoutLeftSidePanel"
      show-if-above
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
      :width="270"
      :breakpoint="700"
      bordered
      >
        <!-- <div class="flex items-center" style=" position: fixed; margin-left: auto; margin-right: auto; top: 30px; "> -->
        <div class="flex items-center" style=" position: sticky; margin-left: auto; margin-right: auto; top: 30px; ">
          <LayoutSideMenu />
        </div>
      </q-drawer>

      <q-page-container>
        <q-page>
          <LayoutContent />
        </q-page>
      </q-page-container>

      <q-footer id="qFooter" v-model="pagesUtilStore.showLayoutFooter" class="bg-globalAppSecondaryColor4">
        <LayoutFooter />
      </q-footer>
    </q-layout>
  </div>
</template>

<script setup lang="ts">
import { ref }      from 'vue';
import LayoutHeader           from '@/views/layout/LayoutHeader.vue';
import HeaderContainment           from '@/views/layout/HeaderContainment.vue';
import LayoutSideMenu         from '@/views/layout/LayoutSideMenu.vue';
import LayoutContent			    from '@/views/layout/LayoutContent.vue';
import LayoutFooter           from '@/views/layout/LayoutFooter.vue';
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';

const pagesUtilStore = usePagesUtilStore();

const miniState = ref(true)
</script>

<style scoped>
#qFooter {
  height: 210px; 
}
</style>
