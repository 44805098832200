import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center justify-center",
  style: {"min-height":"inherit","background-color":"var(--globalAppSecondaryColor4)"}
}

import { useRouter } from 'vue-router';
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import {useContainmentStore} from '@/stores/containmentStore'
import { onBeforeMount }     from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageHome',
  setup(__props) {

const router = useRouter();
const containmentStore = useContainmentStore();
const pagesUtilStore  = usePagesUtilStore();

onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});

router.beforeEach((to, from, next) => {
    sessionStorage.setItem('from', 'true'); // Mantém o histórico

    next(); // Continua a navegação normalmente
  });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})