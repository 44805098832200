import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_atm_Group 9256.svg'


const _hoisted_1 = {
  id: "div-table-pcs",
  class: "row justify-center q-gutter-sm"
}
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { class: "midia-app-title-header-table-lg" }
const _hoisted_4 = { class: "row items-center no-wrap" }
const _hoisted_5 = { class: "midia-app-label-lg q-pl-md" }
const _hoisted_6 = { class: "midia-app-label-lg" }
const _hoisted_7 = { class: "midia-app-label-lg" }
const _hoisted_8 = { class: "midia-app-label-lg" }
const _hoisted_9 = { class: "midia-app-label-lg" }
const _hoisted_10 = { class: "midia-app-label-lg" }
const _hoisted_11 = { class: "midia-app-label-lg" }
const _hoisted_12 = {
  class: "row fit no-wrap justify-end",
  style: {"overflow":"auto"}
}

import { EnumButtonSizes }	            from '@/enums/enums';
import { usePCStore }                   from '@/stores/pcStore';
import { useCampaignStore }             from '@/stores/campaignStore';
import { usePagesUtilStore }            from '@/stores/pagesUtilStore';
import { computed, ref, onBeforeMount } from 'vue';
import { quasarCustomQTableColumns }    from '@/types/quasarCustomQTableColumn';
import { PcModel }                      from '@/models/PcModel';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalPcsExclude',
  setup(__props) {

const pcStore         = usePCStore();
const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const loadingTableContents  = ref(false);
const pagination            = ref({
  page        : 1,
	rowsPerPage : 10, // match your API's initial page size
  rowsNumber  : 0,
  size        : 10,
	sortBy      : '',
	descending  : false,
});

const initialPagination = ref({
        sortBy: 'desc',
        descending: false,
        page: 0,
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
}); 

const filterTerms = ref('');

const qTablePCsColumns:quasarCustomQTableColumns[] = [
  {
    name  : 'codpontocaptura',
    label : 'Código',
    field : 'codpontocaptura',
    align : 'left'
  },

  {
    name  : 'nompontocaptura',
    label : 'Nome PC',
    field : 'nompontocaptura',
    align : 'left'
  },
  
  {
    name  : 'nomcidredeautoatndto',
    label : 'Cidade',
    field : 'nomcidredeautoatndto',
    align : 'left'
  },

  {
    name  : 'ufredeautoatndto',
    label : 'UF',
    field : 'ufredeautoatndto',
    align : 'left'
  },

  {
    name  : 'nomsubredeautoatndto',
    label : 'Subrede',
    field : 'nomsubredeautoatndto',
    align : 'left'
  },

  {
    name  : 'nomsegmmerclocalptoatndto',
    label : 'Segmento',
    field : 'nomsegmmerclocalptoatndto',
    align : 'left'
  },

  {
    name  : 'nomtpmaqautoatndto',
    label : 'Tipo de Máquina',
    field : 'nomtpmaqautoatndto',
    align : 'left'
  }
];

const qTablePCsVisibleColumns = [
  'codpontocaptura',
  'nompontocaptura',
  'nomcidredeautoatndto',
  'ufredeautoatndto',
  'nomsubredeautoatndto',
  'nomsegmmerclocalptoatndto',
  'nomtpmaqautoatndto'
];

const onContinueBtnClick = () => {
  try {
    // Remove selected PCs from the campaign
    campaignStore.focusedCampaign.pcs = campaignStore.focusedCampaign.pcs.filter(pc => !pcStore.selectedPCs.includes(pc));

    // Update pcsIds
    campaignStore.focusedCampaign.pcsIds.length = 0;
    campaignStore.focusedCampaign.pcsIds.push( ...campaignStore.focusedCampaign.pcs.map(data => data.codpontocaptura) );

    // Clear the pcs store
    pcStore.pcs.length = 0;

  } finally {
    pagesUtilStore.showModalExcludePCs = false;
  }      
};

const filterSelectedPCs = computed(()=>{
  console.log(pcStore.pcFilter.filterPc.codpontocaptura);
  return campaignStore.focusedCampaign.pcs.filter(pc => (
    (!pcStore.pcFilter.filterPc.codpontocaptura || pc.codpontocaptura == pcStore.pcFilter.filterPc.codpontocaptura) &&
      (!pcStore.pcFilter.filterPc.nompontocaptura || pc.nompontocaptura.toUpperCase() == pcStore.pcFilter.filterPc.nompontocaptura.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomcidredeautoatndto || pc.nomcidredeautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomcidredeautoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.ufredeautoatndto || pc.ufredeautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.ufredeautoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomsubredeautoatndto || pc.nomsubredeautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomsubredeautoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto || pc.nomsegmmerclocalptoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto.toUpperCase()) &&
      (!pcStore.pcFilter.filterPc.nomtpmaqautoatndto || pc.nomtpmaqautoatndto.toUpperCase() == pcStore.pcFilter.filterPc.nomtpmaqautoatndto.toUpperCase())
    ));
})


return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalExcludePCs,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(pagesUtilStore).showModalExcludePCs) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"900px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_table, {
                    ref: "qTablePCs",
                    rows: filterSelectedPCs.value,
                    "row-key": "codpontocaptura",
                    columns: qTablePCsColumns,
                    "visible-columns": qTablePCsVisibleColumns,
                    "virtual-scroll": "",
                    "no-data-label": "Nenhum registro encontrado!",
                    "rows-per-page-label": "Linhas por página:",
                    "rows-per-page-options": [10, 50, 100, 500, 1000],
                    selection: "multiple",
                    selected: _unref(pcStore).selectedPCs,
                    "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pcStore).selectedPCs) = $event)),
                    dense: "",
                    flat: "",
                    separator: "horizontal",
                    class: "midia-app-table"
                  }, {
                    header: _withCtx((props) => [
                      _createVNode(_component_q_tr, { props: props }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_th, {
                            style: {"text-align":"center","border":"0"},
                            "auto-width": true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_checkbox, {
                                modelValue: props.selected,
                                "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                                color: (props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2'),
                                "keep-color": (!props.selected)
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                            ]),
                            _: 2
                          }, 1024),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                            return (_openBlock(), _createBlock(_component_q_th, {
                              key: col.name,
                              props: props,
                              style: {"border":"0"}
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_3, _toDisplayString(col.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    body: _withCtx((props) => [
                      _createVNode(_component_q_tr, {
                        class: "table-body-rows",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_td, {
                            key: "selected",
                            style: {"text-align":"center"},
                            "auto-width": true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_checkbox, {
                                modelValue: props.selected,
                                "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                                color: (props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2'),
                                "keep-color": (!props.selected)
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "color", "keep-color"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_q_td, {
                            key: "codpontocaptura",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_q_icon, { size: "24px" }, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createElementVNode("img", {
                                      class: "svg-icon",
                                      src: _imports_0
                                    }, null, -1)
                                  ])),
                                  _: 1
                                }),
                                _createElementVNode("span", _hoisted_5, _toDisplayString(props.row.codpontocaptura), 1)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nompontocaptura",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(props.row.nompontocaptura), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nomcidredeautoatndto",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_7, _toDisplayString(props.row.nomcidredeautoatndto), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "ufredeautoatndto",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_8, _toDisplayString(props.row.ufredeautoatndto), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nomsubredeautoatndto",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_9, _toDisplayString(props.row.nomsubredeautoatndto), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nomsegmmerclocalptoatndto",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_10, _toDisplayString(props.row.nomsegmmerclocalptoatndto), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]),
                          _createVNode(_component_q_td, {
                            key: "nomtpmaqautoatndto",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(props.row.nomtpmaqautoatndto), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"])
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    _: 1
                  }, 8, ["rows", "selected"])
                ])
              ]),
              _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-md" }),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_c_q_btn, {
                  id: "btn-continue",
                  propBtnLabel: "Continuar",
                  propBtnFunction: onContinueBtnClick,
                  propBtnTransparent: false,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1
                }, null, 8, ["propBtnSize"])
              ]),
              _createVNode(_component_q_inner_loading, {
                showing: _unref(pagesUtilStore).showLoading,
                label: "aguarde..."
              }, null, 8, ["showing"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})