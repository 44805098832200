import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineDocumentDownload.svg'


const _hoisted_1 = { class: "row fit no-wrap items-center content-start q-pb-md" }
const _hoisted_2 = { class: "col-6 col-md-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "midia-app-content-lg" }
const _hoisted_5 = { class: "col-6 col-md-6" }
const _hoisted_6 = {
  key: 0,
  class: "row"
}
const _hoisted_7 = { class: "midia-app-content-lg status-value" }
const _hoisted_8 = { class: "row fit no-wrap content-start q-pb-md" }
const _hoisted_9 = { class: "col-6 col-md-6" }
const _hoisted_10 = { class: "q-pb-md" }
const _hoisted_11 = {
  class: "row",
  style: {"min-height":"20px"}
}
const _hoisted_12 = { class: "midia-app-content-lg" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-4 col-md-4" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "midia-app-content-lg" }
const _hoisted_17 = { class: "col-4 col-md-4" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "midia-app-content-lg" }
const _hoisted_20 = { class: "col-6 col-md-6" }
const _hoisted_21 = {
  class: "row fit no-wrap items-center q-pb-xs",
  style: {"margin-left":"-4px"}
}
const _hoisted_22 = { class: "midia-app-label-lg" }
const _hoisted_23 = { class: "row q-py-xs" }

import { EnumButtonSizes }              from '@/enums/enums';
import { useFinancialInstitutionStore } from '@/stores/financialInstitutionStore';
import { CampaignModel }                from '@/models/CampaignModel';
import { DEFAULT_IFS_EXPORT_FILENAME }	from '@/static/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignIdentificationSectionView',
  props: {
  propCampaignToView: {
    type      : CampaignModel,
    required  : true
  }
},
  setup(__props) {

const financialInstitutionStore = useFinancialInstitutionStore();

const props = __props;

const CONST_CAMPAIGN_TO_VIEW = Object.freeze(new CampaignModel(props.propCampaignToView));

const onDownloadInstitutionsBtnClick = () => {
  if (CONST_CAMPAIGN_TO_VIEW.ifs) {
    financialInstitutionStore.exportIfsAsCsv(CONST_CAMPAIGN_TO_VIEW.ifs);
  }
}
const onDownloadInstitutionsGreenBtnClick = () => {
	onDownloadInstitutionsBtnClick();
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row" }, [
          _createElementVNode("span", { class: "midia-app-caption-sm" }, "Anunciante")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(CONST_CAMPAIGN_TO_VIEW).advertiser), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (props.propCampaignToView.statusDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "midia-app-label-lg q-pr-sm" }, "Status:", -1)),
              _createElementVNode("span", _hoisted_7, _toDisplayString(props.propCampaignToView.statusDescription), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("span", { class: "midia-app-caption-sm" }, "Campanha")
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(CONST_CAMPAIGN_TO_VIEW).name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "row" }, [
              _createElementVNode("span", { class: "midia-app-caption-sm" }, "Data Inicial")
            ], -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(CONST_CAMPAIGN_TO_VIEW).startDateDdMmYyyy), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row" }, [
              _createElementVNode("span", { class: "midia-app-caption-sm" }, "Data Final")
            ], -1)),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(CONST_CAMPAIGN_TO_VIEW).endDateDdMmYyyy), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", null, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row q-pb-xs" }, [
            _createElementVNode("span", { class: "midia-app-label-sm" }, "Instituição")
          ], -1)),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_c_q_btn, {
              id: "btn-icon-download-institutions",
              padding: "none",
              propBtnLabel: "",
              propBtnFunction: onDownloadInstitutionsGreenBtnClick,
              propBtnTransparent: true,
              propBtnSize: _unref(EnumButtonSizes).SMALL,
              propBorderless: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, { size: "32px" }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "green_arrow_down_icon"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["propBtnSize"]),
            _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(DEFAULT_IFS_EXPORT_FILENAME)), 1)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_c_q_btn, {
              id: "btn-download-institutions",
              propBtnLabel: "Download",
              propBtnFunction: onDownloadInstitutionsBtnClick,
              propBtnTransparent: true,
              propBtnSize: _unref(EnumButtonSizes).SMALL
            }, null, 8, ["propBtnSize"])
          ])
        ])
      ])
    ])
  ]))
}
}

})