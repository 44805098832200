<template>
  <div>
    <c-q-separator class="q-pb-lg" :withBackButton="true" />

    <PageCaption
      pageCaptionTitle="Visualizar"
      pageCaptionDescription="Revise a Campanha e Realize a Aprovação"
    />

    <CampaignIdentificationSectionView
      :propCampaignToView="campaignStore.focusedCampaign"
    />

    <CampaignFormatSectionView />

    <c-q-separator class="q-pb-md" />

    <div class="row fit no-wrap items-top">
      <div class="q-pr-xl q-mr-xl">
        <CampaignDevicesSection />
      </div>

      <div class="q-pl-xl q-ml-md">
        <CampaignRequestedAdjustmentsSection
          v-if="showSectionViewAskAdjustment"
        />
      </div>
    </div>

    <c-q-separator class="q-pt-xl q-pb-md" />

    <div class="row fit no-wrap items-top">
      <div class="q-pr-xl q-mr-xl">
        <CampaignDevicesDistributionSection />
      </div>
    </div>

    <c-q-separator class="q-pt-xl q-pb-md" />

    <CampaignActionButtonsSection
      :propShowBtnCancelFocusedCampaign="true"
      :propShowBtnViewCampaignDistribution="true"
      :propShowBtnRequestAdjustmentsToFocusedCampaign="true"
      :propShowBtnSendFocusedCampaignToApprove="true"
      :propShowBtnApproveFocusedCampaign="true"
      :propShowBtnResendFocusedCampaignToApprove="true"
      :propShowBtnEditFocusedCampaign="true"
      :propShowBtnDiscardChanges="true"
    />

    <ModalRequestAdjustments />
  </div>
</template>

<script setup lang="ts">
import { CONST_CAMPAIGN_STATUS } from "@/static/constants";
import { useCampaignStore } from "@/stores/campaignStore";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import PageCaption from "@/components/PageCaption.vue";
import CampaignIdentificationSectionView from "@/components/campaign/CampaignIdentificationSectionView.vue";
import CampaignFormatSectionView from "@/components/campaign/CampaignFormatSectionView.vue";
import CampaignDevicesSection from "@/components/campaign/CampaignDevicesSection.vue";
import CampaignDevicesDistributionSection from "@/components/campaign/CampaignDevicesDistributionSection.vue";
import CampaignActionButtonsSection from "@/components/campaign/CampaignActionButtonsSection.vue";
import ModalRequestAdjustments from "@/views/modals/ModalRequestAdjustments.vue";
import CampaignRequestedAdjustmentsSection from "@/components/campaign/CampaignRequestedAdjustmentsSection.vue";
import BtnEditFocusedCampaign from "@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue";

const campaignStore = useCampaignStore();
const router = useRouter();

const showSectionViewAskAdjustment = computed(() => {
  return (
    campaignStore.focusedCampaign.commentadjustment &&
    (campaignStore.focusedCampaign.status ===
      CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.ON_EDITING.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.APPROVED.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.ON_APPROVAL.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.ON_REISSUE.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id ||
      campaignStore.focusedCampaign.status ===
        CONST_CAMPAIGN_STATUS.PUBLISHED.id)
  );
});

onMounted(() => {
  const from = sessionStorage.getItem("from");

  // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
  if (!from) {
    router.push({ name: "home" });
  } else {
    sessionStorage.removeItem("from");
  }
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem("from", "true"); // Mantém o histórico

  next(); // Continua a navegação normalmente
});
</script>

<style scoped></style>
