<template>
  <div>
    <c-q-separator class="q-pb-lg" :withBackButton="true" />

    <PageCaption
      pageCaptionTitle="Revisão"
      pageCaptionDescription="Revise a Campanha e Envie para Aprovação"
    />

    <CampaignIdentificationSectionView :propCampaignToView="campaignStore.focusedCampaign" />

    <CampaignFormatSectionView />

    <c-q-separator class="q-pb-md" />

    <div class="row fit no-wrap items-top">
      <div class="q-pr-xl q-mr-xl">
        <CampaignDevicesSection />
      </div>

      <div class="q-pl-xl q-ml-md">
        <CampaignRequestedAdjustmentsSection />
      </div>
    </div>

    <c-q-separator class="q-pt-xl q-pb-md" />

    <div class="row fit no-wrap items-top">
      <div class="q-pr-xl q-mr-xl">
        <CampaignDevicesDistributionSection />
      </div>
    </div>

    <c-q-separator class="q-pt-xl q-pb-md" />

    <CampaignActionButtonsSection
      :propShowBtnBackEdit="true"
      :propShowBtnCancelCreateNewCampaign="true"
      :propShowBtnSaveFocusedCampaign="true"
      :propShowBtnSendFocusedCampaignToApprove="true"
      :propShowBtnApproveFocusedCampaign="true"
      :propShowBtnResendFocusedCampaignToApprove="true"
    />

    <ModalRequestAdjustments />
  </div>
</template>

<script setup lang="ts">
import PageCaption	                              from '@/components/PageCaption.vue';
import CampaignIdentificationSectionView          from '@/components/campaign/CampaignIdentificationSectionView.vue';
import CampaignFormatSectionView                  from '@/components/campaign/CampaignFormatSectionView.vue';
import CampaignDevicesSection                     from '@/components/campaign/CampaignDevicesSection.vue';
import CampaignDevicesDistributionSection         from "@/components/campaign/CampaignDevicesDistributionSection.vue";
import CampaignRequestedAdjustmentsSection        from '@/components/campaign/CampaignRequestedAdjustmentsSection.vue';
import CampaignActionButtonsSection               from '@/components/campaign/CampaignActionButtonsSection.vue';
import ModalRequestAdjustments                    from '@/views/modals/ModalRequestAdjustments.vue';
import { useCampaignStore }               from '@/stores/campaignStore';
import {onMounted}                           from 'vue'
import { useRouter } from 'vue-router';

const campaignStore = useCampaignStore();
const router = useRouter();

onMounted(() => {
      const from = sessionStorage.getItem('from');

      // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
      if (!from) {
        router.push({ name: 'home' });
      } else {
        sessionStorage.removeItem('from');
      }
    });

  router.beforeEach((to, from, next) => {
    sessionStorage.setItem('from', 'true'); // Mantém o histórico

    next(); // Continua a navegação normalmente
  });
</script>

<style scoped>

</style>