<template lang="">
  <div>
  </div>
</template>
<script setup lang="ts">
import axios from 'axios';
// import authService from '../../utils/auth-service.js';
import { EnumRouteNames, EnumButtonSizes }  from '@/enums/enums';
import { useRouter, useRoute } from 'vue-router'
import {useAuthStore} from '@/stores/authStore';
import { onMounted }						from 'vue';
import {useContainmentStore} from '@/stores/containmentStore'
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';

const router = useRouter()
const route  = useRoute()

const authStore = useAuthStore();

const containmentStore = useContainmentStore();
const pagesUtilStore  = usePagesUtilStore();

// const isAuthenticated = computed(() => {
//   return (authStore.isAuth);
// });

// const onLogoutBtnClick = () => {
//   authStore.clear();
//   // router.push({ name: EnumRouteNames.HOME });
// }

// const exchangeCodeForToken = (code : string) => {
//       // const AUTH_CLIENT_SECRET = "fc1l0rlvkesqrehobtv7b7tuv3cs6d9giu34elgh2412364j1fa"
//       const AUTH_CLIENT_ID= process.env.VUE_APP_APP_ID;
//       const AUTH_CALLBACK_URI = process.env.VUE_APP_AUTH_CALLBACK_URI; 
      
//       const config = {  
//         headers: {    
//           'Content-Type': 'application/x-www-form-urlencoded'  
//         }  
//       }  

//       const body = `grant_type=authorization_code&client_id=${AUTH_CLIENT_ID}&code=${code}&redirect_uri=${AUTH_CALLBACK_URI}&scope=openid%20profile%20email%aws.cognito.signin.user.admin`
//       axios.post(`${process.env.VUE_APP_COGNITO_URL_TOKEN}/oauth2/token`, body, config)
//       .then(response =>{
//         let tokensData = response.data
//         let token = tokensData.id_token
//         let payload = token.split('.')[1];
//         let username = JSON.parse(atob(payload))
         
//         authStore.setUser(payload);
//         authStore.setToken(tokensData.id_token);
//         authStore.setIsAuth(true);
//         authStore.setCurrentLoggedInUserEmail(username['email']);

//         router.push({ name: EnumRouteNames.HOME });
//       }).catch(error =>{
//         authStore.setIsAuth(false);
//         console.log(error);
//       })
//     }
const exchangeCodeForToken = async (code : string) => {
      const AUTH_CLIENT_ID= process.env.VUE_APP_APP_ID;
      const AUTH_CALLBACK_URI = process.env.VUE_APP_AUTH_CALLBACK_URI; 
      const config = {  
        headers: {    
          'Content-Type': 'application/x-www-form-urlencoded'  
        }  
      }  
      const body = `grant_type=authorization_code&client_id=${AUTH_CLIENT_ID}&code=${code}&redirect_uri=${AUTH_CALLBACK_URI}&scope=openid%20profile%20email%aws.cognito.signin.user.admin`
      try{
        const response : any = await axios.post(`${process.env.VUE_APP_COGNITO_URL_TOKEN}/oauth2/token`, body, config)
        console.log(response)
        console.log(response.data)
        let tokensData = response.data
        let token = tokensData.id_token
        let payload = token.split('.')[1];
        let username = JSON.parse(atob(payload))

        authStore.setUser(payload);
        authStore.setToken(tokensData.id_token);
        authStore.setIsAuth(true);
        authStore.setCurrentLoggedInUserEmail(username['email']);

        router.push({ name: EnumRouteNames.HOME });
      }catch(error){
        authStore.setIsAuth(false);
        console.log(error)  
      }
      
    }

onMounted(async () => {
  pagesUtilStore.showLoading = true;
  const code = route.query.code
	try{
     await exchangeCodeForToken(code as string);
     await containmentStore.onFetchContainmentData();
	}catch(error){
		console.error(error);
	}finally{
		pagesUtilStore.showLoading = false;
	}
})
    
</script>
<style lang="">
  
</style>