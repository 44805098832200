<template>
  <div>
    <c-q-separator class="q-pb-lg q-pt-md q-pl-lg" :withBackButton="true" />

    <PageCaption
      class="q-ma-lg"
      pageCaptionTitle="Desvincular Instituições por Slot"
      pageCaptionDescription="Desvincule as instituições por slot de acordo com as regras atuais"
    />

    <div class="row items-center q-pb-md">
      <div class="q-pl-lg col-6">
        <CampaignFormat />
      </div>
    </div>
    <div class="row items-center q-pb-md">
      <div class="q-pl-lg col-6">
        <div>
          <span class="midia-app-caption-sm">Slot </span>

          <q-select
            v-model="selectedSlot"
            @update:model-value="changeSelectedSlot"
            :options="slots"
            outlined
            dense
            color="globalAppPrimaryColor"
            bg-color="globalAppDefaultBackgroundColor"
            option-value="id"
            option-label="description"
            :disable="!campaignStore?.focusedCampaign?.format"
          >
            <template v-slot:selected>
              <span
                :class="
                  selectedSlot?.description
                    ? 'midia-app-content-lg'
                    : 'midia-app-placeholder-md'
                "
                >{{
                  selectedSlot?.description
                    ? selectedSlot.description
                    : "Escolha o Slot"
                }}</span
              >
            </template>
          </q-select>
        </div>
      </div>
    </div>

    <div class="row q-mt-md q-pl-lg" v-show="selectedSlot?.id">
      <div class="col-5">
        <div class="row justify-left">
          <span class="q-my-sm">
            Slot {{ selectedSlot.id }} - Instituições Vinculadas
          </span>
        </div>
        <div class="row justify-left">
          <q-input
            id="searchbar"
            style="position: relative"
            v-model="filterTermsIFLinked"
            @update:model-value="searchLinkedInstitutions"
            color="globalAppPrimaryColor"
            bg-color="globalAppDefaultBackgroundColor"
            outlined
            dense
            placeholder="Pesquisar"
            input-class="midia-app-content-lg"
            @keyup.enter="searchLinkedInstitutions"
            autofocus
          >
            <template v-slot:prepend>
              <q-icon
                name="search"
                class="q-pa-sm"
                style="
                  background: var(--globalAppSecondaryColor4);
                  padding: 6px;
                  position: relative;
                  left: -11px;
                  border-right: 1px solid var(--globalAppSecondaryColor2);
                "
              />
            </template>
          </q-input>

          <c-q-btn
            id="btn-fetch"
            propBtnLabel="Filtrar"
            :propBtnFunction="searchLinkedInstitutions"
            :propBtnTransparent="false"
            :propBtnSize="EnumButtonSizes.MEDIUM"
            class="q-ml-sm"
          />
        </div>

        <q-table
          ref="qTableFinancialInstitutions"
          :rows="filteredFinancialInstitutions"
          row-key="codif"
          :columns="qTableFinancialInstitutionsColumns"
          :visible-columns="qTableFinancialInstitutionsVisibleColumns"
          v-model:selected="selectedFinancialInstitutions"
          virtual-scroll
          no-data-label="Nenhuma Instituição Vinculada"
          rows-per-page-label="Linhas por página:"
          selection="multiple"
          dense
          flat
          separator="horizontal"
          class="midia-app-table q-mt-md"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th
                style="text-align: center; border: 0; padding: 2px"
                :auto-width="true"
              >
                <q-checkbox
                  v-model="props.selected"
                  :color="
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  "
                  :keep-color="!props.selected"
                  size="xs"
                />
              </q-th>

              <q-th
                v-for="col in props.cols"
                :key="col.field"
                :props="props"
                style="border: 0; padding: 2px"
              >
                <div v-if="col.name === 'nomagrpacionista'">
                  <q-select
                    v-model="categoryFilterLinked"
                    :options="categoryOptionsLinked"
                    @update:model-value="filterLinkedInstitutions"
                    style="width: 100%"
                    popup-content-class="midia-app-title-header-table-lg"
                    stack-label
                    hide-dropdown-icon
                    borderless
                    dense
                  >
                    <template v-slot:after></template>
                    <template v-slot:selected>
                      <div class="midia-app-title-header-table-lg">
                        <span>{{
                          categoryFilterLinked
                            ? categoryFilterLinked
                            : "Categoria"
                        }}</span>
                        <q-icon
                          v-if="categoryFilterLinked"
                          size="xs"
                          name="cancel"
                          style="cursor: pointer"
                          @click="
                            categoryFilterLinked = '';
                            filterLinkedInstitutions();
                          "
                          class="q-ml-sm red"
                        />
                        <q-icon
                          v-else
                          size="xs"
                          name="filter_list"
                          style="cursor: pointer"
                          class="q-ml-sm"
                        />
                      </div>
                    </template>
                  </q-select>
                </div>
                <div v-else>
                  <span class="midia-app-title-header-table-lg">{{
                    col.label
                  }}</span>
                </div>
              </q-th>
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr class="table-body-rows" :props="props">
              <q-td
                key="selected"
                style="text-align: center; padding: 2px"
                :auto-width="true"
              >
                <q-checkbox
                  v-model="props.selected"
                  :color="
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  "
                  :keep-color="!props.selected"
                  size="xs"
                />
              </q-td>

              <q-td key="codif" :props="props" style="padding: 2px">
                <div class="row items-center no-wrap">
                  <q-icon size="18px">
                    <img
                      class="svg-icon"
                      src="@/assets/icons/icon_HiOutlineOfficeBuilding.svg"
                    />
                  </q-icon>

                  <span class="midia-app-label-sm q-pl-sm">{{
                    props.row.codif
                  }}</span>
                </div>
              </q-td>

              <q-td key="nome" :props="props" style="padding: 2px">
                <span class="midia-app-label-sm">{{
                  props.row?.nome?.length <= 25
                    ? props.row.nome
                    : `${props.row.nome.slice(0, 25)}...`
                }}</span>
              </q-td>

              <q-td key="nomagrpacionista" :props="props" style="padding: 2px">
                <span class="midia-app-label-sm">{{
                  props.row.nomagrpacionista
                }}</span>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <div class="buttonManageIF collumn q-pa-md"> 
        <div class="col-auto">
          <q-btn
            flat
            color="grey"
            icon="navigate_next"
            size="1.2rem"
            @click="unlinkFi"
          />
        </div>
        <div class="col-auto">
          <q-btn
            flat
            color="grey"
            icon="navigate_before"
            size="1.2rem"
            @click="linkFi"
          />
        </div>
      </div>
      <div class="col-5">
        <div class="row justify-left">
          <span class="q-my-sm">
            Slot {{ selectedSlot.id }} - Instituições Desvinculadas
          </span>
        </div>
        <div class="row justify-left">
          <q-input
            id="searchbar"
            style="position: relative"
            v-model="filterTermsIFUnlinked"
            @update:model-value="searchUnlinkedInstitutions"
            color="globalAppPrimaryColor"
            bg-color="globalAppDefaultBackgroundColor"
            outlined
            dense
            placeholder="Pesquisar"
            input-class="midia-app-content-lg"
            @keyup.enter="searchUnlinkedInstitutions"
            autofocus
          >
            <template v-slot:prepend>
              <q-icon
                name="search"
                class="q-pa-sm"
                style="
                  background: var(--globalAppSecondaryColor4);
                  padding: 6px;
                  position: relative;
                  left: -11px;
                  border-right: 1px solid var(--globalAppSecondaryColor2);
                "
              />
            </template>
          </q-input>

          <c-q-btn
            id="btn-fetch"
            propBtnLabel="Filtrar"
            :propBtnFunction="searchUnlinkedInstitutions"
            :propBtnTransparent="false"
            :propBtnSize="EnumButtonSizes.MEDIUM"
            class="q-ml-sm"
          />
        </div>

        <q-table
          ref="qTableUnlinkedFinancialInstitutions"
          :rows="filteredUnlinkedFinancialInstitutions"
          row-key="codif"
          :columns="qTableFinancialInstitutionsColumns"
          :visible-columns="qTableFinancialInstitutionsVisibleColumns"
          v-model:selected="selectedUnlinkedFinancialInstitutions"
          virtual-scroll
          no-data-label="Nenhuma Instituição Desvinculada"
          rows-per-page-label="Linhas por página:"
          selection="multiple"
          dense
          flat
          separator="horizontal"
          class="midia-app-table q-mt-md"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th
                style="text-align: center; border: 0; padding: 2px"
                :auto-width="true"
              >
                <q-checkbox
                  v-model="props.selected"
                  :color="
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  "
                  :keep-color="!props.selected"
                  size="xs"
                />
              </q-th>

              <q-th
                v-for="col in props.cols"
                :key="col.field"
                :props="props"
                style="border: 0; padding: 2px; marg"
              >
                <div v-if="col.name === 'nomagrpacionista'">
                  <q-select
                    v-model="categoryFilterUnlinked"
                    :options="categoryOptionsUnlinked"
                    @update:model-value="filterUnlinkedInstitutions"
                    style="width: 100%"
                    popup-content-class="midia-app-title-header-table-lg"
                    hide-dropdown-icon
                    stack-label
                    borderless
                    dense
                  >
                    <template v-slot:after></template>
                    <template v-slot:selected>
                      <div class="midia-app-title-header-table-lg">
                        <span>{{
                          categoryFilterUnlinked
                            ? categoryFilterUnlinked
                            : "Categoria"
                        }}</span>
                        <q-icon
                          v-if="categoryFilterUnlinked"
                          size="xs"
                          name="cancel"
                          style="cursor: pointer"
                          @click="
                            categoryFilterUnlinked = '';
                            filterUnlinkedInstitutions();
                          "
                          class="q-ml-sm red"
                        />
                        <q-icon
                          v-else
                          size="xs"
                          name="filter_list"
                          style="cursor: pointer"
                          class="q-ml-sm"
                        />
                      </div>
                    </template>
                  </q-select>
                </div>
                <div v-else>
                  <span class="midia-app-title-header-table-lg">{{
                    col.label
                  }}</span>
                </div>
              </q-th>
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr class="table-body-rows" :props="props">
              <q-td
                key="selected"
                style="text-align: center; padding: 2px; margin-right: 5px"
                :auto-width="true"
              >
                <q-checkbox
                  v-model="props.selected"
                  :color="
                    props.selected
                      ? 'globalAppPrimaryColor'
                      : 'globalAppSecondaryColor2'
                  "
                  :keep-color="!props.selected"
                  size="xs"
                />
              </q-td>

              <q-td key="codif" :props="props" style="padding: 2px">
                <div class="row items-center no-wrap">
                  <q-icon size="18px">
                    <img
                      class="svg-icon"
                      src="@/assets/icons/icon_HiOutlineOfficeBuilding.svg"
                    />
                  </q-icon>

                  <span class="midia-app-label-sm q-pl-sm">{{
                    props.row.codif
                  }}</span>
                </div>
              </q-td>

              <q-td key="nome" :props="props" style="padding: 2px">
                <span class="midia-app-label-sm">{{
                  props.row?.nome?.length <= 25
                    ? props.row.nome
                    : `${props.row.nome.slice(0, 25)}...`
                }}</span>
              </q-td>

              <q-td key="nomagrpacionista" :props="props" style="padding: 2px">
                <span class="midia-app-label-sm">{{
                  props.row.nomagrpacionista
                }}</span>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <div class="col-12 q-my-md">
        <div class="row justify-end" style="margin-right: 78px">
          <c-q-btn
            id="btn-save-all"
            propBtnLabel="Salvar Alterações"
            :propBtnFunction="showDialogSaveAll"
            :propBtnTransparent="false"
            :propBtnSize="EnumButtonSizes.MEDIUM"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalFinancialInstitutions />
  <ModalConfirmationDialog
    propDialogType="slots"
    propDialogTitle="Salvar alterações"
    :propConfirmationMsg="`Deseja confirmar as alterações no Slot ${selectedSlot?.description}?`"
    @confirm="saveAll"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeMount, computed } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useCampaignStore } from "@/stores/campaignStore";
import { useFinancialInstitutionStore } from "@/stores/financialInstitutionStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { quasarCustomQTableColumns } from "@/types/quasarCustomQTableColumn";
import { EnumButtonSizes } from "@/enums/enums";
import { financialInstitutionInterface } from "@/types/types.d";

import PageCaption from "@/components/PageCaption.vue";
import CampaignFormat from "@/components/campaign/CampaignFormat.vue";
import ModalFinancialInstitutions from "@/views/modals/ModalFinancialInstitutions.vue";
import ModalConfirmationDialog from "@/views/modals/ModalConfirmationDialog.vue";
import {useContainmentStore} from '@/stores/containmentStore'

const $q = useQuasar();
const router = useRouter();
const authStore = useAuthStore();
const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();
const financialInstitutionStore = useFinancialInstitutionStore();
const containmentStore = useContainmentStore();

const selectedSlot = ref({});
const categoryFilterLinked = ref("");
const categoryFilterUnlinked = ref("");
const filterTermsIFLinked = ref("");
const filterTermsIFUnlinked = ref("");
const financialInstitutions = ref([]);
const filteredFinancialInstitutions = ref<financialInstitutionInterface[]>([]);
const selectedFinancialInstitutions = ref([]);
const unlinkedFinancialInstitutions = ref([]);
const filteredUnlinkedFinancialInstitutions = ref<
  financialInstitutionInterface[]
>([]);
const selectedUnlinkedFinancialInstitutions = ref([]);

const slots = ref([
  { id: "A1", description: "A1 - Abertura" },
  { id: "A2", description: "A2 - PropagIF" },
  { id: "A3", description: "A3 - Retire o seu Dinheiro" },
]);

const qTableFinancialInstitutionsColumns: quasarCustomQTableColumns[] = [
  {
    name: "codif",
    label: "Código",
    field: "codif",
    align: "left",
  },
  {
    name: "nome",
    label: "Instituição",
    field: "nome",
    align: "left",
  },

  {
    name: "nomagrpacionista",
    label: "Categoria",
    field: "nomagrpacionista",
    align: "left",
  },
];

const qTableFinancialInstitutionsVisibleColumns = [
  "codif",
  "nome",
  "nomagrpacionista",
];

const categoryOptionsUnlinked = computed(() => {
  return [
    ...new Set(
      unlinkedFinancialInstitutions.value.map((row) => row?.nomagrpacionista)
    ),
  ];
});

const categoryOptionsLinked = computed(() => {
  return [
    ...new Set(financialInstitutions.value.map((row) => row?.nomagrpacionista)),
  ];
});

onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});

onMounted(async () => {
  if (!authStore.currentUserCanDisableSlot) {
    showError(
      "Usuário não possuí acesso a esta funcionalidade. Redirecionando..."
    );

    router.back();
    return;
  }

  await searchInstitutions();
});

const showError = (message: string): void => {
  if (!message) return;

  $q.notify({
    type: "negative",
    position: "top",
    message: message,
  });
};

const showMessage = (message: string): void => {
  if (!message) return;

  $q.notify({
    type: "positive",
    position: "top",
    message: message,
  });
};

const sortByIfName = (arr) =>
  arr.sort((a, b) => a?.nome?.localeCompare(b?.nome));

const showFinancialInstitutionsFilter = (filterType: number) => {
  pagesUtilStore.showModalFinancialInstitutions = true;
};

const changeSelectedSlot = async () => {
  await searchInstitutions();
};

const filterInstitutions = async (
  ifs: financialInstitutionInterface[],
  filterTerm: string
): Promise<financialInstitutionInterface[]> => {
  if (!filterTerm) return ifs;

  return ifs.filter(
    (f) =>
      f?.codif?.toLowerCase().includes(filterTerm.toLowerCase()) ||
      f?.nome?.toLowerCase().includes(filterTerm.toLowerCase()) ||
      f?.nomagrpacionista?.toLowerCase().includes(filterTerm.toLowerCase())
  );
};

const filterInstitutionsCategory = async (
  ifs: financialInstitutionInterface[],
  category: string
): Promise<financialInstitutionInterface[]> => {
  if (!category) return ifs;

  return ifs.filter((f) =>
    f?.nomagrpacionista?.toLowerCase().includes(category.toLowerCase())
  );
};

const searchInstitutions = async (filterTerm?: string) => {
  pagesUtilStore.showLoading = true;
  try {
    await financialInstitutionStore.fetchFinancialInstitutions(filterTerm);
  } catch (error) {
    showError("Erro ao buscar as IFs");
    pagesUtilStore.showLoading = false;
  } finally {
    pagesUtilStore.showLoading = false;
    classifyInstitutions(financialInstitutionStore.financialInstitutions);
  }
};

const showDialogSaveAll = () => {
  pagesUtilStore.showModalConfirmationDialogSlots = true;
};

const saveAll = async () => {
  pagesUtilStore.showLoading = true;
  try {
    await financialInstitutionStore.updateFinancialInstitutions(
      "L",
      selectedSlot.value.id,
      filteredFinancialInstitutions.value
    );
    await financialInstitutionStore.updateFinancialInstitutions(
      "UL",
      selectedSlot.value.id,
      filteredUnlinkedFinancialInstitutions.value
    );
  } catch (error) {
    showError("Erro ao salvar alterações as IFs");
    pagesUtilStore.showLoading = false;
  } finally {
    pagesUtilStore.showLoading = false;
    showMessage("IFs atualizadas com sucesso!");
  }
};

const searchLinkedInstitutions = async () => {
  filteredFinancialInstitutions.value = financialInstitutions.value;

  filteredFinancialInstitutions.value = await filterInstitutions(
    financialInstitutions.value,
    filterTermsIFLinked.value
  );
};

const filterLinkedInstitutions = async () => {
  filteredFinancialInstitutions.value = financialInstitutions.value;

  filteredFinancialInstitutions.value = await filterInstitutionsCategory(
    financialInstitutions.value,
    categoryFilterLinked.value
  );
};

const searchUnlinkedInstitutions = async () => {
  filteredUnlinkedFinancialInstitutions.value =
    unlinkedFinancialInstitutions.value;

  filteredUnlinkedFinancialInstitutions.value = await filterInstitutions(
    unlinkedFinancialInstitutions.value,
    filterTermsIFUnlinked.value
  );
};

const filterUnlinkedInstitutions = async () => {
  filteredUnlinkedFinancialInstitutions.value =
    unlinkedFinancialInstitutions.value;

  filteredUnlinkedFinancialInstitutions.value =
    await filterInstitutionsCategory(
      unlinkedFinancialInstitutions.value,
      categoryFilterUnlinked.value
    );
};

const getFisToAdd = (
  selected: financialInstitutionInterface[]
): financialInstitutionInterface[] => {
  if (!selected?.length) return selected;

  const fisToAdd: financialInstitutionInterface[] = [];
  for (let ufi of selected) fisToAdd.push(ufi);

  return fisToAdd;
};

const unlinkFi = () => {
  const fisToAdd = getFisToAdd(selectedFinancialInstitutions.value);

  unlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value.concat(fisToAdd)
  );

  financialInstitutions.value = sortByIfName(
    financialInstitutions.value.filter((fi) => !fisToAdd.includes(fi))
  );

  filteredFinancialInstitutions.value = sortByIfName(
    financialInstitutions.value
  );
  filteredUnlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value
  );
  selectedFinancialInstitutions.value = [];
  console.log(categoryFilterLinked.value);
  categoryFilterLinked.value="";
  console.log(categoryFilterLinked.value);
};

const linkFi = () => {
  const fisToAdd = getFisToAdd(selectedUnlinkedFinancialInstitutions.value);

  financialInstitutions.value = sortByIfName(
    financialInstitutions.value.concat(fisToAdd)
  );

  unlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value.filter((fi) => !fisToAdd.includes(fi))
  );

  filteredFinancialInstitutions.value = sortByIfName(
    financialInstitutions.value
  );
  filteredUnlinkedFinancialInstitutions.value = sortByIfName(
    unlinkedFinancialInstitutions.value
  );
  selectedUnlinkedFinancialInstitutions.value = [];
  categoryFilterUnlinked.value="";
};

const classifyInstitutions = (ifs: financialInstitutionInterface[]): void => {
  financialInstitutions.value = [];
  unlinkedFinancialInstitutions.value = [];

  if (!ifs?.length) return;

  for (let i of ifs) {
    switch (selectedSlot.value.id) {
      case "A1": {
        i?.slot1
          ? financialInstitutions.value.push(i)
          : unlinkedFinancialInstitutions.value.push(i);

        break;
      }
      case "A2": {
        i?.slot2
          ? financialInstitutions.value.push(i)
          : unlinkedFinancialInstitutions.value.push(i);

        break;
      }
      case "A3": {
        i?.slot3
          ? financialInstitutions.value.push(i)
          : unlinkedFinancialInstitutions.value.push(i);

        break;
      }
      default: {
        financialInstitutions.value.push(i);

        break;
      }
    }
  }

  filteredFinancialInstitutions.value = financialInstitutions.value;
  filteredUnlinkedFinancialInstitutions.value =
    unlinkedFinancialInstitutions.value;
};
</script>

<style scoped>
.custom-label-color {
  color: var(--globalRedED1C24);
}
.ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonManageIF{
padding-top: 15%;
}
</style>
