import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { PropType, computed } from "vue";
import { EnumButtonSizes } from "@/enums/enums";
import { cssStyleObjectToStyleString } from "@/utils/commonUtils";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomQBtn',
  props: {
  propBtnLabel: {
    type: String,
    required: true,
  },

  propBtnFunction: {
    type: Function as PropType<() => void>,
    required: false,
  },

  propBtnTransparent: {
    type: Boolean,
    required: false,
    default: false,
  },

  propBtnSize: {
    type: String as () => keyof typeof EnumButtonSizes,
    required: false,
    default: EnumButtonSizes.MEDIUM,
  },

  propBorderless: {
    type: Boolean,
    required: false,
    default: false,
  },

  propDisable: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const onBtnClick = () => {
  if (props.propBtnFunction) {
    props.propBtnFunction();
  }
};

const computedMidiaAppButtonStyle = computed(() => {
  const midiaAppButtonCssStyleObject = {
    border: props.propBorderless
      ? "none"
      : "1px solid var(--globalAppPrimaryColor) !important",
    "border-radius": "6px !important",
    "background-color": props.propBtnTransparent
      ? "transparent"
      : "var(--globalAppPrimaryColor) !important",
  };

  const midiaAppButtonCssStyleString = cssStyleObjectToStyleString(
    midiaAppButtonCssStyleObject
  );

  return midiaAppButtonCssStyleString;
});

const computedFontSize = computed(() => {
  return props.propBtnSize == EnumButtonSizes.SMALL.toString()
    ? "12px"
    : props.propBtnSize == EnumButtonSizes.MEDIUM.toString()
    ? "14px"
    : props.propBtnSize == EnumButtonSizes.LARGE_1.toString()
    ? "16px"
    : props.propBtnSize == EnumButtonSizes.LARGE_2.toString()
    ? "22px"
    : "22px";
});

const computedLineHeight = computed(() => {
  return props.propBtnSize == EnumButtonSizes.SMALL.toString()
    ? "16px"
    : props.propBtnSize == EnumButtonSizes.MEDIUM.toString()
    ? "20px"
    : props.propBtnSize == EnumButtonSizes.LARGE_1.toString()
    ? "24px"
    : props.propBtnSize == EnumButtonSizes.LARGE_2.toString()
    ? "30px"
    : "30px";
});

const computedMidiaAppButtonLabelStyle = computed(() => {
  const midiaAppButtonLabelCssStyleObject = {
    color: props.propBtnTransparent
      ? "var(--globalAppPrimaryColor) !important"
      : "var(--globalAppDefaultBackgroundColor) !important",
    "font-family": "Frutiger",
    "font-weight": "300",
    "font-size": computedFontSize.value,
    "line-height": computedLineHeight.value,
  };

  const midiaAppButtonLabelCssStyleString = cssStyleObjectToStyleString(
    midiaAppButtonLabelCssStyleObject
  );

  return midiaAppButtonLabelCssStyleString;
});

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    style: _normalizeStyle(computedMidiaAppButtonStyle.value),
    size: props.propBtnSize,
    disable: props.propDisable,
    unelevated: "",
    flat: "",
    "no-caps": "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (onBtnClick()))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (props.propBtnLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "m-pl-md",
            style: _normalizeStyle(computedMidiaAppButtonLabelStyle.value)
          }, _toDisplayString(props.propBtnLabel), 5))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "afterLabel")
    ]),
    _: 3
  }, 8, ["style", "size", "disable"]))
}
}

})