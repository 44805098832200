import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';



export default /*@__PURE__*/_defineComponent({
  __name: 'BtnApproveFocusedCampaign',
  setup(__props) {

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onApproveFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
   	await campaignStore.approveFocusedCampaign();
		
		messages.showSuccessMsg("Campanha aprovada com sucesso!");

  } catch (ErrorException:any) {
    messages.showErrorMsg(ErrorException.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};


const onReapproveFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
   	await campaignStore.reapproveFocusedCampaign();
		
		messages.showSuccessMsg("Campanha reaprovada com sucesso!");

  } catch (ErrorException:any) {
    messages.showErrorMsg(ErrorException.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(authStore).currentUserCanApproveCampaign 
		&& _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_APPROVAL.id)
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 0,
          id: "btn-approve-campaign",
          propBtnLabel: "Aprovar",
          propBtnFunction: onApproveFocusedCampaignBtnClick,
          propBtnTransparent: false,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true),
    (_unref(authStore).currentUserCanApproveCampaign 
		&& _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_RE_APPROVAL.id)
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 1,
          id: "btn-approve-campaign",
          propBtnLabel: "Reaprovar",
          propBtnFunction: onReapproveFocusedCampaignBtnClick,
          propBtnTransparent: false,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})