import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "row fit no-wrap items-top justify-between q-pb-md",
  style: {"overflow":"none"}
}
const _hoisted_2 = { class: "midia-app-title-lg" }
const _hoisted_3 = {
  key: 0,
  class: "row fit no-wrap q-pb-md",
  style: {"overflow":"auto"}
}
const _hoisted_4 = { class: "midia-app-caption-modal-lg" }

import ButtonCloseModal	from '@/components/ButtonCloseModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomModalTitle',
  props: {
  propModalTitle: {
    type      : String,
		required	: true
	},

  propModalDescription: {
    type      : String,
		required	: false
	}
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(props.propModalTitle), 1),
      _createVNode(ButtonCloseModal, { style: {"left":"12px","top":"-4px"} })
    ]),
    (props.propModalDescription)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(props.propModalDescription), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})