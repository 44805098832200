import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "div-vue-app-layout" }
const _hoisted_2 = {
  class: "flex items-center",
  style: {"position":"sticky","margin-left":"auto","margin-right":"auto","top":"30px"}
}

import { ref }      from 'vue';
import LayoutHeader           from '@/views/layout/LayoutHeader.vue';
import HeaderContainment           from '@/views/layout/HeaderContainment.vue';
import LayoutSideMenu         from '@/views/layout/LayoutSideMenu.vue';
import LayoutContent			    from '@/views/layout/LayoutContent.vue';
import LayoutFooter           from '@/views/layout/LayoutFooter.vue';
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutApp',
  setup(__props) {

const pagesUtilStore = usePagesUtilStore();

const miniState = ref(true)

return (_ctx: any,_cache: any) => {
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_footer = _resolveComponent("q-footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_layout, { view: "lHh lpR fFf" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_header, {
          modelValue: _unref(pagesUtilStore).showLayoutHeader,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pagesUtilStore).showLayoutHeader) = $event)),
          class: "bg-globalAppSecondaryColor4 text-globalAppDefaultTextColor"
        }, {
          default: _withCtx(() => [
            _createVNode(LayoutHeader)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_drawer, {
          side: "left",
          modelValue: _unref(pagesUtilStore).showLayoutLeftSidePanel,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(pagesUtilStore).showLayoutLeftSidePanel) = $event)),
          "show-if-above": "",
          mini: miniState.value,
          onMouseover: _cache[2] || (_cache[2] = ($event: any) => (miniState.value = false)),
          onMouseout: _cache[3] || (_cache[3] = ($event: any) => (miniState.value = true)),
          width: 270,
          breakpoint: 700,
          bordered: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(LayoutSideMenu)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "mini"]),
        _createVNode(_component_q_page_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_page, null, {
              default: _withCtx(() => [
                _createVNode(LayoutContent)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_footer, {
          id: "qFooter",
          modelValue: _unref(pagesUtilStore).showLayoutFooter,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(pagesUtilStore).showLayoutFooter) = $event)),
          class: "bg-globalAppSecondaryColor4"
        }, {
          default: _withCtx(() => [
            _createVNode(LayoutFooter)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})