import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "row flex no-wrap justify-center items-center content-center q-pb-lg",
  style: {"overflow":"auto"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "row fit no-wrap justify-end",
  style: {"overflow":"auto"}
}
const _hoisted_4 = {
  key: 0,
  class: "q-pr-sm"
}
const _hoisted_5 = { class: "q-pl-sm" }

import { EnumButtonSizes }		from '@/enums/enums';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { onBeforeMount }			from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalImagePreview',
  props: {
	propImgObjectUrl: {
		type			: String,
		required	: true
	},

	propShowDownloadButton: {
		type			: Boolean,
		required	: false,
		default		: true
	}
},
  setup(__props) {

const pagesUtilStore = usePagesUtilStore();

const props = __props;


return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalImagePreview,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pagesUtilStore).showModalImagePreview) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"820px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, { propModalTitle: "Pré-visualização" }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: props.propImgObjectUrl,
                  alt: "img_campanha",
                  style: {"height":"425px","object-fit":"contain"}
                }, null, 8, _hoisted_2)
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              (__props.propShowDownloadButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_c_q_btn, {
                      id: "btn-download-image",
                      propBtnLabel: "Download",
                      propBtnFunction: _ctx.onDownloadImageBtnClick,
                      propBtnTransparent: true,
                      propBtnSize: _unref(EnumButtonSizes).LARGE_1
                    }, null, 8, ["propBtnFunction", "propBtnSize"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createVNode(_component_c_q_btn, {
                  id: "btn-close-modal",
                  propBtnLabel: "Fechar",
                  propBtnTransparent: true,
                  propBtnSize: _unref(EnumButtonSizes).LARGE_1
                }, null, 8, ["propBtnSize"]), [
                  [_directive_close_popup]
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})