<template>
  <div class="row">
    <div
      class="col q-mx-sm"
      v-for="(pcs, group) in pcsPorGrupo"
      v-bind:key="group"
    >
      <div>
        <div class="row q-pb-xs">
          <span class="midia-app-label-sm">Dispositivos {{ `${group}` }}</span>
          
        </div>
        <div class="row q-pb-xs">
          <span class="midia-app-label-sm">Inicio {{getAdjustedStartDate(group as string)}} </span>
        </div>

        <div
          class="row fit no-wrap items-center q-pb-xs"
          style="margin-left: -4px"
        >
          <c-q-btn
            id="btn-icon-download-devices"
            padding="none"
            propBtnLabel=""
            :propBtnFunction="() => onDownloadDevicesGreenBtnClick(group as string)"
            :propBtnTransparent="true"
            :propBtnSize="EnumButtonSizes.SMALL"
            :propBorderless="true"
          >
            <template v-slot:default>
              <q-icon size="32px">
                <img
                  src="@/assets/icons/icon_HiOutlineDocumentDownload.svg"
                  alt="green_arrow_down_icon"
                />
              </q-icon>
            </template>
          </c-q-btn>

          <span class="midia-app-label-lg">{{
            DEFAULT_PC_GROUP_EXPORT_FILENAME.replaceAll("{0}", String(group))
          }}</span>
        </div>

        <div class="row q-py-xs">
          <c-q-btn
            id="btn-download-devices"
            propBtnLabel="Download"
            :propBtnFunction="() => onDownloadDevicesBtnClick(group as string)"
            :propBtnTransparent="true"
            :propBtnSize="EnumButtonSizes.SMALL"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EnumButtonSizes } from "@/enums/enums";
import {computed} from 'vue';
import { useCampaignStore } from "@/stores/campaignStore";
import { exportAsCsvFile } from "@/utils/commonUtils";
import { DEFAULT_PC_GROUP_EXPORT_FILENAME } from "@/static/constants";
import moment from 'moment';

const campaignStore = useCampaignStore();

const pcsPorGrupo = computed(() => {
  return campaignStore.focusedCampaign.pcsPorGrupo;
});

const addDaysToDate = (date: string, days: number): string => {
  return moment(date).add(days, 'days').format('DD-MM-YYYY');
};

const getDaysToAdd = (group: string): number => {
  switch (group) {
    case 'D0':
      return 0; // Início na data cadastrada
    case 'D1':
      return 1; // 1 dia após a data cadastrada
    case 'D2':
      return 2; // 2 dias após a data cadastrada
    case 'D3':
      return 3; // 3 dias após a data cadastrada
    default:
      return 0;
  }
}

const getAdjustedStartDate = (group: string): string => {
  const startDate = campaignStore.focusedCampaign.startDate;
  const daysToAdd = getDaysToAdd(group);
  return addDaysToDate(startDate as string, daysToAdd);
}

const onDownloadDevicesBtnClick = (groupName: string) => {
  const filename = `Download ${DEFAULT_PC_GROUP_EXPORT_FILENAME.replaceAll(
    "{0}",
    String(groupName)
  )}`;

  // Itera sobre as entradas do objeto pcsPorGrupo usando forEach
  Object.entries(pcsPorGrupo.value).forEach(([group, pcs]) => {
    if (group === groupName) {
      exportAsCsvFile(pcs, filename);
    }
  });
};

const onDownloadDevicesGreenBtnClick = (groupName: string) => {
  onDownloadDevicesBtnClick(groupName);
};
</script>

<style scoped></style>
