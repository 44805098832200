<template>
  <div>
      <c-q-separator class="q-pb-lg q-pt-md q-pl-lg" :withBackButton="true" />

      <PageCaption class="q-ma-lg" v-show=!containmentData.is_active pageCaptionTitle="Ativar Botão de Contenção"
        pageCaptionDescription="Contenção desativada no momento. Ative para interromper a distribuição das campanhas às ATMs" />
      
        <PageCaption class="q-ma-lg" v-show=containmentData.is_active pageCaptionTitle="Desativar Botão de Contenção"
        pageCaptionDescription="Contenção ativa no momento. Desative para retomar a distribuição das campanhas às ATMs" />
      <div class="row justify-center">
        <div class="col-auto">
          <c-q-btn 
            class="text-bold" 
            color="red" 
            v-show=!containmentData.is_active 
            padding="lg"
            size="10px" 
            propBtnLabel="Ativar Contenção"
            :propBtnFunction="onActivateContainment"/>
          <c-q-btn 
            class="text-bold" 
            color="red" 
            v-show=containmentData.is_active 
            padding="lg" 
            size="10px"
            propBtnLabel="Desativar Contenção"
            :propBtnFunction="onDesactivateContainment" />
        </div>
      </div>
  </div>
</template>

<script setup lang="ts">
  import PageCaption from "@/components/PageCaption.vue";
  import { usePagesUtilStore }	from '@/stores/pagesUtilStore';

  import { useContainmentStore } from '@/stores/containmentStore'
  import { computed, onBeforeMount } from 'vue'

  const containmentStore = useContainmentStore();
  const pagesUtilStore = usePagesUtilStore();

  const containmentData = computed(() => {
    return containmentStore.containmentData;
  })

  onBeforeMount(async () => {
    pagesUtilStore.showLoading = true;
    try {
        await containmentStore.onFetchContainmentData();
    } catch (error) {
        console.error(error);
    } finally {
        pagesUtilStore.showLoading = false;
    }
});
  

  const onActivateContainment = async () =>{
    pagesUtilStore.showLoading = true;
    try{  
      await containmentStore.onFetchContainmentActivate();
      await containmentStore.onFetchContainmentData();
    }catch(error){
      console.error(error);
    }finally{
      pagesUtilStore.showLoading = false;
    }
  }

  const onDesactivateContainment = async () =>{
    pagesUtilStore.showLoading = true;
    try{
      await containmentStore.onFetchContainmentDeactivate();
      await containmentStore.onFetchContainmentData();
    }catch(error){
      console.error(error);
    }finally{
      pagesUtilStore.showLoading = false;
    }
  }




</script>

<style lang="scss" scoped></style>