<template>
	<c-q-btn
		v-if="authStore.currentUserCanCancelCampaign 
		&& (campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id 
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id 
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.APPROVED.id
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.id
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.id
		||
		(campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.PUBLISHED.id
		))"
		id="btn-cancel-focused-campaign"
		propBtnLabel="cancelar"
		:propBtnFunction="abreDialogo"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>

	<ModalConfirmationDialog
			propDialogTitle="Atenção!"
			propConfirmationMsg="Tem certeza de que deseja cancelar a campanha?"
			propDialogType="cancelar"
			@confirm="onCancelFocusedCampaignBtnClick"
		/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import ModalConfirmationDialog		from '@/views/modals/ModalConfirmationDialog.vue';
import { useCampaignStore } 	from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages								from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';
import moment from 'moment';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const diferancaEntreDatas  = ( data : string | undefined ) => {

const hoje = moment();

data = campaignStore.focusedCampaign.startDate;

const data1 = moment(data);

const diferenca = data1.diff(hoje, 'days');

return diferenca >= 1;

};

//NOVO
const abreDialogo = () => {
  pagesUtilStore.showModalConfirmationCancelCampaign = true;
}

const onCancelFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.cancelFocusedCampaign();

		messages.showSuccessMsg("Campanha cancelada com sucesso!");

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar cancelar a campanha...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
