import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { EnumRequestedAdjustmentsModalType, EnumButtonSizes }	from '@/enums/enums';
import { usePagesUtilStore }																	from '@/stores/pagesUtilStore';
import { useAuthStore }																				from '@/stores/authStore'
import { useCampaignStore }																		from '@/stores/campaignStore';
import { CONST_CAMPAIGN_STATUS }															from '@/static/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnRequestAdjustmentsToFocusedCampaign',
  setup(__props) {

const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();
const campaignStore		= useCampaignStore();

const onRequestAdjustmentsBtnClick = () => {
	pagesUtilStore.modalRequestedAdjustmentsModalType	= EnumRequestedAdjustmentsModalType.CREATE;
  pagesUtilStore.showModalRequestAdjustments				= true;
};

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(authStore).currentUserCanAskAdjustmentCampaign 
		&& _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_APPROVAL.id)
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 0,
          id: "btn-request-adjustments",
          propBtnLabel: "Solicitar Ajuste",
          propBtnFunction: onRequestAdjustmentsBtnClick,
          propBtnTransparent: true,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true),
    (_unref(authStore).currentUserCanAskAdjustmentCampaign 
		&& _unref(campaignStore).focusedCampaign.status == _unref(CONST_CAMPAIGN_STATUS).ON_RE_APPROVAL.id)
      ? (_openBlock(), _createBlock(_component_c_q_btn, {
          key: 1,
          id: "btn-request-adjustments",
          propBtnLabel: "Solicitar Reajuste",
          propBtnFunction: onRequestAdjustmentsBtnClick,
          propBtnTransparent: true,
          propBtnSize: _unref(EnumButtonSizes).LARGE_1
        }, null, 8, ["propBtnSize"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})