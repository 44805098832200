import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import CampaignInstitutions             from '@/components/campaign/CampaignInstitutions.vue';
import CampaignPCs                      from '@/components/campaign/CampaignPCs.vue';
import CampaignFormatSlotsSection       from '@/components/campaign/CampaignFormatSlotsSection.vue';
import PageCaption                      from '@/components/PageCaption.vue';
import { EnumATMScreenFormatSlotTypes } from '@/enums/enums';

export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignFormatATMScreenEdit',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_c_q_separator = _resolveComponent("c-q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PageCaption, {
      pageCaptionTitle: "ATM Screen",
      pageCaptionDescription: "Informe os dados para Campanha"
    }),
    _createVNode(CampaignFormatSlotsSection, {
      propSlotType: _unref(EnumATMScreenFormatSlotTypes).A1,
      propSlotLabel: "Abertura *"
    }, null, 8, ["propSlotType"]),
    _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-lg" }),
    _createVNode(CampaignFormatSlotsSection, {
      propSlotType: _unref(EnumATMScreenFormatSlotTypes).A2,
      propSlotLabel: "PropagIF *"
    }, null, 8, ["propSlotType"]),
    _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-lg" }),
    _createVNode(CampaignFormatSlotsSection, {
      propSlotType: _unref(EnumATMScreenFormatSlotTypes).A3,
      propSlotLabel: "Retire o seu Dinheiro *"
    }, null, 8, ["propSlotType"]),
    _createVNode(_component_c_q_separator, { class: "q-pt-lg q-pb-lg" }),
    _createVNode(CampaignInstitutions, { class: "q-pb-md" }),
    _createVNode(CampaignPCs)
  ]))
}
}

})