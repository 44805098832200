import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row q-pb-xs" }
const _hoisted_2 = {
  key: 0,
  class: "midia-app-label-sm"
}
const _hoisted_3 = {
  key: 0,
  class: "row q-py-xs"
}

import { EnumRequestedAdjustmentsModalType, EnumButtonSizes }	from '@/enums/enums';
import { usePagesUtilStore }																	from '@/stores/pagesUtilStore';
import { useCampaignStore }     from '@/stores/campaignStore';
import { CONST_CAMPAIGN_STATUS }   														from '@/static/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignRequestedAdjustmentsSection',
  setup(__props) {

const campaignStore   = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();

const onViewRequestedAdjustmentsBtnClick = () => {
	pagesUtilStore.modalRequestedAdjustmentsModalType	= EnumRequestedAdjustmentsModalType.UPDATE;
	pagesUtilStore.showModalRequestAdjustments				= true;
}

return (_ctx: any,_cache: any) => {
  const _component_c_q_btn = _resolveComponent("c-q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(campaignStore).focusedCampaign.status)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Ajustes Solicitados"))
        : _createCommentVNode("", true)
    ]),
    (_unref(campaignStore).focusedCampaign.status)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_c_q_btn, {
            id: "btn-view-requested-adjustments",
            propBtnLabel: "Visualizar",
            propBtnFunction: onViewRequestedAdjustmentsBtnClick,
            propBtnTransparent: true,
            propBtnSize: _unref(EnumButtonSizes).SMALL
          }, null, 8, ["propBtnSize"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})