import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_HiOutlineCalendar.svg'


const _hoisted_1 = { class: "q-pb-md" }
const _hoisted_2 = { class: "row col" }
const _hoisted_3 = { class: "q-pb-md" }
const _hoisted_4 = { class: "row col" }
const _hoisted_5 = { class: "q-pb-md" }
const _hoisted_6 = { class: "row col" }
const _hoisted_7 = { class: "row col q-pb-md" }
const _hoisted_8 = { class: "q-pb-md" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { style: {"width":"160px"} }
const _hoisted_11 = { class: "row items-center justify-end" }
const _hoisted_12 = { style: {"width":"160px"} }
const _hoisted_13 = { class: "row items-center justify-end" }
const _hoisted_14 = {
  class: "row fit flex no-wrap justify-between q-pb-xs",
  style: {"width":"100%"}
}
const _hoisted_15 = { class: "q-pr-sm" }
const _hoisted_16 = { class: "col col-grow q-pl-sm" }

import ButtonCloseModal													from '@/components/ButtonCloseModal.vue'
import ModalFilterCampaignCampaignFormat				from '@/components/campaign/ModalFilterCampaignCampaignFormat.vue'
import ModalFilterCampaignCampaignFormatSection	from '@/components/campaign/ModalFilterCampaignCampaignFormatSection.vue'
import { EnumButtonSizes }											from '@/enums/enums';
import { useCampaignStore } 										from '@/stores/campaignStore';
import { usePagesUtilStore }										from '@/stores/pagesUtilStore';
import { ref, computed }              					from 'vue';
import { convertDateToStringYyyyMmDd }					from '@/utils/commonUtils';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';;
import messages                               	from '@/utils/messages';
import { CONST_STATUS }	from '@/static/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFilterCampaign',
  setup(__props) {

const campaignStore 	= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();

const qPopupProxyStartDate  : { value: QPopupProxy | null } = ref(null);
const qPopupProxyEndDate    : { value: QPopupProxy | null } = ref(null);

const onClearFilterBtnClick = () => {
	campaignStore.resetCampaignFilter();
};

const sanitizeHTML = (html:any) => {
      return html; // For demonstration purpose only, please sanitize the HTML properly
    }

const validateFields = () => {
	let fieldsOk = true;

	if (
		campaignStore.campaignFilter.filterCampaign.endDate
		&& campaignStore.campaignFilter.filterCampaign.startDate
		&& (campaignStore.campaignFilter.filterCampaign.endDate <= campaignStore.campaignFilter.filterCampaign.startDate)
	) {
		messages.showWarningMsg("Data final deve ser maior que a inicial.");

		fieldsOk = false;
	}

	if(campaignStore.campaignFilter.filterCampaign.format 
	&& !campaignStore.campaignFilter.filterCampaign.startDate 
	&& !campaignStore.campaignFilter.filterCampaign.endDate ){

		messages.showWarningMsg("Escolha ao menos um período para a consulta");

		fieldsOk = false;
	}

	if(campaignStore.campaignFilter.filterCampaign.startDate && !campaignStore.campaignFilter.filterCampaign.endDate 
	|| campaignStore.campaignFilter.filterCampaign.endDate && !campaignStore.campaignFilter.filterCampaign.startDate ){

		messages.showWarningMsg("Obrigatório informar o Inicio e Fim do período.");

		fieldsOk = false;
	}

	return fieldsOk;
};

const onFetchCampaignsBtnClick = async () => {
  try {
    pagesUtilStore.showLoadingModal = true;

		if (validateFields()) {
			await campaignStore.fetchCampaigns();
		
			pagesUtilStore.showModalFilterCampaign = false;
		}

  } catch (errorFetchingCampaigns) {
    messages.showErrorMsg("Erro ao tentar obter a lista de campanhas...");
		pagesUtilStore.showLoadingModal = false;
  
  } finally {
    pagesUtilStore.showLoadingModal = false;
  }
};

/* const handleUpdateAdvertiser = (value: string) => {
	console.log("handleUpdate - value:", value);
	campaignStore.campaignFilter.filterCampaign.advertiser = value;
} */

const updateStartDate = () => {
  if (campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy) {
    campaignStore.campaignFilter.filterCampaign.startDate = campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateStartDateDdMmYyyy = () => {
  if (campaignStore.campaignFilter.filterCampaign.startDate) {
    campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy = campaignStore.campaignFilter.filterCampaign.startDate.split('-').reverse().join('/');
  }

  if (qPopupProxyStartDate.value) {
    qPopupProxyStartDate.value.hide();
  }
};

const updateEndDate = () => {
  if (campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy) {
    campaignStore.campaignFilter.filterCampaign.endDate = campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateEndDateDdMmYyyy = () => {
  if (campaignStore.campaignFilter.filterCampaign.endDate) {
    campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy = campaignStore.campaignFilter.filterCampaign.endDate.split('-').reverse().join('/');
  }

  if (qPopupProxyEndDate.value) {
    qPopupProxyEndDate.value.hide();
  }
};

const optionsStatus				= computed(() => {
	return CONST_STATUS;
});

const endDateMinDateAllowedStringYyyyMmDd = computed(() => {
	if (campaignStore.campaignFilter.filterCampaign.startDate) {
		const oneDayAfterStartDate = new Date(campaignStore.campaignFilter.filterCampaign.startDate + " 00:00:00");
		oneDayAfterStartDate.setDate(oneDayAfterStartDate.getDate() + 1);

		return convertDateToStringYyyyMmDd(oneDayAfterStartDate);
	
	} else {
		return null;
	}
});

const startDateDefaultYearMonth = computed(() => {
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    year  : 'numeric',
    month : '2-digit',
    day   : '2-digit'
  });

	const now = new Date();
	now.setHours(0, 0, 0, 0);

  const dateStringYyyyMm = formatter.format(now).split('/').reverse().join('/').substring(0, 7);

  return dateStringYyyyMm;
});

const endDateDefaultYearMonth = computed(() => {
	if (endDateMinDateAllowedStringYyyyMmDd.value) {
		const dateStringYyyyMm = endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/').substring(0, 7);

		return dateStringYyyyMm;

	} else {
		return startDateDefaultYearMonth.value;
	}
});

const endDateOptions = (dateStringYyyyMmDd: string) => {
	if (endDateMinDateAllowedStringYyyyMmDd.value) {
		return (dateStringYyyyMmDd >= endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/'));

	} else {
		return true;
	}
};

return (_ctx: any,_cache: any) => {
  const _component_c_modal_title = _resolveComponent("c-modal-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_c_q_separator = _resolveComponent("c-q-separator")!
  const _component_c_q_btn = _resolveComponent("c-q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _unref(pagesUtilStore).showModalFilterCampaign,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(pagesUtilStore).showModalFilterCampaign) = $event)),
      "no-backdrop-dismiss": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "midia-app-padding-modal",
          style: {"width":"500px","max-width":"80vw"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_c_modal_title, {
                propModalTitle: "Filtro de Campanhas",
                propModalDescription: "Consulte e gerencie as campanhas cadastradas"
              }),
              _createElementVNode("div", _hoisted_1, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Anunciante")
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(campaignStore).campaignFilter.filterCampaign.advertiser,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.advertiser) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe um Anunciante",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Agência")
                ], -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(campaignStore).campaignFilter.filterCampaign.agency,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.agency) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe uma Agência",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Campanha")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_input, {
                    modelValue: _unref(campaignStore).campaignFilter.filterCampaign.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.name) = $event)),
                    color: "globalAppPrimaryColor",
                    "bg-color": "globalAppDefaultBackgroundColor",
                    outlined: "",
                    dense: "",
                    placeholder: "Informe uma Campanha",
                    "input-class": "midia-app-content-lg",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Status")
                ], -1)),
                _createVNode(_component_q_select, {
                  modelValue: _unref(campaignStore).campaignFilter.filterCampaign.status,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.status) = $event)),
                  options: optionsStatus.value,
                  outlined: "",
                  "option-label": "description",
                  "option-value": "id",
                  "map-options": "",
                  "emit-value": "",
                  dense: "",
                  color: "globalAppPrimaryColor",
                  style: {"width":"100%"},
                  "label-slot": !_unref(campaignStore).campaignFilter.filterCampaign.status
                }, {
                  label: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("span", { class: "midia-app-placeholder-md" }, "Selecione um Status", -1)
                  ])),
                  option: _withCtx(({ itemProps, opt }) => [
                    _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(itemProps)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(sanitizeHTML(opt.description)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "label-slot"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "row q-pb-xs" }, [
                  _createElementVNode("span", { class: "midia-app-label-modal-sm" }, "Período")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_q_input, {
                      style: {"outline":"1px solid var(--globalAppSecondaryColor2)"},
                      modelValue: _unref(campaignStore).campaignFilter.filterCampaign.startDateDdMmYyyy,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.startDateDdMmYyyy) = $event)),
                        updateStartDate
                      ],
                      mask: "##/##/####",
                      "bg-color": "globalAppDefaultBackgroundColor",
                      borderless: "",
                      dense: "",
                      "input-class": "midia-app-content-lg"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          class: "cursor-pointer q-pa-sm q-mr-sm",
                          style: {"background":"var(--globalAppPrimaryColor)"}
                        }, {
                          default: _withCtx(() => [
                            _cache[14] || (_cache[14] = _createElementVNode("img", {
                              class: "svg-icon",
                              src: _imports_0
                            }, null, -1)),
                            _createVNode(_unref(QPopupProxy), {
                              ref_key: "qPopupProxyStartDate",
                              ref: qPopupProxyStartDate,
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  modelValue: _unref(campaignStore).campaignFilter.filterCampaign.startDate,
                                  "onUpdate:modelValue": [
                                    _cache[4] || (_cache[4] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.startDate) = $event)),
                                    updateStartDateDdMmYyyy
                                  ],
                                  mask: "YYYY-MM-DD",
                                  minimal: "",
                                  "default-year-month": `${startDateDefaultYearMonth.value}`,
                                  color: "globalAppPrimaryColor"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_11, [
                                      _createVNode(ButtonCloseModal)
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "default-year-month"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "midia-app-label-lg q-px-md" }, "até", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_q_input, {
                      style: {"outline":"1px solid var(--globalAppSecondaryColor2)"},
                      modelValue: _unref(campaignStore).campaignFilter.filterCampaign.endDateDdMmYyyy,
                      "onUpdate:modelValue": [
                        _cache[7] || (_cache[7] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.endDateDdMmYyyy) = $event)),
                        updateEndDate
                      ],
                      mask: "##/##/####",
                      "bg-color": "globalAppDefaultBackgroundColor",
                      borderless: "",
                      dense: "",
                      "input-class": "midia-app-content-lg"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          class: "cursor-pointer q-pa-sm q-mr-sm",
                          style: {"background":"var(--globalAppPrimaryColor)"}
                        }, {
                          default: _withCtx(() => [
                            _cache[15] || (_cache[15] = _createElementVNode("img", {
                              class: "svg-icon",
                              src: _imports_0
                            }, null, -1)),
                            _createVNode(_unref(QPopupProxy), {
                              ref_key: "qPopupProxyEndDate",
                              ref: qPopupProxyEndDate,
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_date, {
                                  modelValue: _unref(campaignStore).campaignFilter.filterCampaign.endDate,
                                  "onUpdate:modelValue": [
                                    _cache[6] || (_cache[6] = ($event: any) => ((_unref(campaignStore).campaignFilter.filterCampaign.endDate) = $event)),
                                    updateEndDateDdMmYyyy
                                  ],
                                  mask: "YYYY-MM-DD",
                                  minimal: "",
                                  options: endDateOptions,
                                  "default-year-month": `${endDateDefaultYearMonth.value}`,
                                  color: "globalAppPrimaryColor"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createVNode(ButtonCloseModal)
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "default-year-month"])
                              ]),
                              _: 1
                            }, 512)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ])
              ]),
              _createVNode(ModalFilterCampaignCampaignFormat, { class: "q-pb-sm" }),
              _createVNode(ModalFilterCampaignCampaignFormatSection, { class: "q-pb-sm" }),
              _createVNode(_component_c_q_separator, { class: "q-pt-sm q-pb-lg" }),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_c_q_btn, {
                    id: "btn-clear-filter",
                    propBtnLabel: "Limpar Filtros",
                    propBtnFunction: onClearFilterBtnClick,
                    propBtnTransparent: true,
                    propBtnSize: _unref(EnumButtonSizes).LARGE_1
                  }, null, 8, ["propBtnSize"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_c_q_btn, {
                    id: "btn-fetch-campaigns",
                    style: {"width":"100%"},
                    propBtnLabel: "Buscar",
                    propBtnFunction: onFetchCampaignsBtnClick,
                    propBtnTransparent: false,
                    propBtnSize: _unref(EnumButtonSizes).LARGE_1
                  }, null, 8, ["propBtnSize"])
                ]),
                _createVNode(_component_q_inner_loading, {
                  showing: _unref(pagesUtilStore).showLoadingModal,
                  label: "aguarde..."
                }, null, 8, ["showing"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})