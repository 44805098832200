import { campaignInterface, pcInterface, pcsPorGrupoInterface, financialInstitutionInterface, ImageInfo }	from '@/types/types.d';
import { PcModel }																												from '@/models/PcModel';
import { FinancialInstitutionModel }																			from '@/models/FinancialInstitutionModel';
import { formatDateStringYyyyMmDdAsDdMmYyyy }															from '@/utils/commonUtils';
import { CONST_CAMPAIGN_STATUS }																					from '@/static/constants';
import { useCampaignStore }																								from '@/stores/campaignStore';


export class CampaignModel implements campaignInterface {
	id														: number | undefined;
	name													: string | undefined;
	startDate											: string | undefined;
	startDateDdMmYyyy							: string;
	endDate												: string | undefined;
	endDateDdMmYyyy								: string;
	advertiser										: string | undefined;
	agency												: string | undefined;
	format												: string | undefined;
	piValue												: number | undefined;
	inclusionResponsible					: string | undefined;
	inclusionDate									: string | undefined;
	inclusionDateDdMmYyyy					: string;
	lastEditResponsible						: string | undefined;
	lastEditDate									: string | undefined;
	lastEditDateDdMmYyyy					: string;
	actionType										: string | undefined;
	idSlot												: number | undefined;
	pcs														: Array<pcInterface>;
	pcsPorGrupo										: pcsPorGrupoInterface;
	ifs														: Array<financialInstitutionInterface>;
	slot1													: string | undefined;
	slot2													: string | undefined;
	slot3													: string | undefined;
	status												: number | undefined;
	commentadjustment							: string | undefined;
	pcsIds 												: Array<number | undefined>;
	ifsIds 												: Array<string | undefined>;
	iscampanhaedit								: boolean;
	iscampaignpublished						:	boolean;
	isactive						        :	boolean;
	isapproved						        :	boolean;
	ispendingpublication				    :	boolean;
	
	formatAtmScreenImageSlotS3Url(): ImageInfo  | undefined {
		try {
			const campaignStore = useCampaignStore();

			if (
				this.id
				&& (
					this.slot1
					|| this.slot2
					|| this.slot3
				)
			) {
				let slot = undefined;
	
				if (this.slot1) {
					slot = this.slot1;
				
				} else if (this.slot2) {
					slot = this.slot2;
				
				} else if (this.slot3) {
					slot = this.slot3;
				}
	
				return {
					imageUrl: `${campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(slot).imageObjectUrl}`,
					fileName: `${campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(slot).imageFileName}`
				};
	
			} else {
				return undefined;
			}
				
		} catch (error) {
			console.error;

			return undefined;
		}
	}

	constructor (
		newCampaign: {
			id?														: number,
			name?													: string,
			startDate?										: string,
			endDate?											: string,
			advertiser?										: string,
			agency?												: string,
			format?												: string,
			piValue?											: number,
			inclusionResponsible?					: string,
			inclusionDate?								: string,
			lastEditResponsible?					: string,
			lastEditDate?									: string,
			actionType?										: string,
			idSlot?												: number,
			pcs?													: Array<pcInterface>,
			pcsPorGrupo?									: pcsPorGrupoInterface,
			ifs?													: Array<financialInstitutionInterface>,
			slot1?												: string,
			slot2?												: string,
			slot3?												: string,
			status?												: number,
			commentadjustment?						: string,
			pcsIds?												: Array<number | undefined>,
			ifsIds?												: Array<string | undefined>,
			iscampanhaedit?								: boolean,
			iscampaignpublished?						: boolean		
			isactive?						            : boolean		
			isapproved?						            : boolean	
			ispendingpublication?						: boolean
		
		}
	) {
		this.id														= newCampaign.id;
		this.name													= newCampaign.name;
		this.startDate										= newCampaign.startDate;
		this.startDateDdMmYyyy						= formatDateStringYyyyMmDdAsDdMmYyyy(this.startDate);
		this.endDate											= newCampaign.endDate;
		this.endDateDdMmYyyy							= formatDateStringYyyyMmDdAsDdMmYyyy(this.endDate);
		this.advertiser										= newCampaign.advertiser;
		this.agency												= newCampaign.agency;
		this.format												= newCampaign.format;
		this.piValue											= newCampaign.piValue;
		this.inclusionResponsible					= newCampaign.inclusionResponsible;
		this.inclusionDate								= newCampaign.inclusionDate;
		this.inclusionDateDdMmYyyy				= formatDateStringYyyyMmDdAsDdMmYyyy(this.inclusionDate);
		this.lastEditResponsible					= newCampaign.lastEditResponsible;
		this.lastEditDate									= newCampaign.lastEditDate;
		this.lastEditDateDdMmYyyy					= formatDateStringYyyyMmDdAsDdMmYyyy(this.lastEditDate);
		this.actionType										= newCampaign.actionType;
		this.idSlot												= newCampaign.idSlot;
		//this.pcs													= (newCampaign.pcs ? [ ...newCampaign.pcs ] : []);
		this.pcs													= (newCampaign.pcs ? [ ...newCampaign.pcs.map(pc => new PcModel(pc)) ] : []);
		this.pcsPorGrupo 									= newCampaign.pcsPorGrupo || {};
		//this.ifs													= (newCampaign.ifs ? [ ...newCampaign.ifs ] : []);
		this.ifs													= (newCampaign.ifs ? [ ...newCampaign.ifs.map(financialInstitution => new FinancialInstitutionModel(financialInstitution)) ] : []);
		this.slot1												= newCampaign.slot1;
		this.slot2												= newCampaign.slot2;
		this.slot3												= newCampaign.slot3;
		this.status												= newCampaign.status;
		this.commentadjustment						= newCampaign.commentadjustment;
		this.pcsIds												= (newCampaign.pcsIds ? [ ...newCampaign.pcsIds ] : []);
		this.ifsIds												= (newCampaign.ifsIds ? [ ...newCampaign.ifsIds ] : []);
		this.iscampanhaedit								= newCampaign.iscampanhaedit !== undefined ? newCampaign.iscampanhaedit : false;
		this.iscampaignpublished					= newCampaign.iscampaignpublished !== undefined ? newCampaign.iscampaignpublished : false;
		this.isactive					= newCampaign.isactive !== undefined ? newCampaign.isactive : false;
		this.isapproved					= newCampaign.isapproved !== undefined ? newCampaign.isapproved : false;
		this.ispendingpublication					= newCampaign.ispendingpublication !== undefined ? newCampaign.ispendingpublication : false;
	};

	get statusDescription(): string {
		switch (this.status) {
			case CONST_CAMPAIGN_STATUS.ON_EDITING.id:
				
				return CONST_CAMPAIGN_STATUS.ON_EDITING.description;
		
			case CONST_CAMPAIGN_STATUS.DRAFT.id:
				
				return CONST_CAMPAIGN_STATUS.DRAFT.description;
		
			case CONST_CAMPAIGN_STATUS.ON_APPROVAL.id:
				
				return CONST_CAMPAIGN_STATUS.ON_APPROVAL.description;
		
			case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id:
				
				return CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.description;
		
			case CONST_CAMPAIGN_STATUS.APPROVED.id:
				
				return CONST_CAMPAIGN_STATUS.APPROVED.description;
		
			case CONST_CAMPAIGN_STATUS.PUBLISHED.id:
				
				return CONST_CAMPAIGN_STATUS.PUBLISHED.description;
		
			case CONST_CAMPAIGN_STATUS.CANCELED.id:
				
				return CONST_CAMPAIGN_STATUS.CANCELED.description;

			case CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.id:
				
				return CONST_CAMPAIGN_STATUS.PENDING_PUBLICATION.description;

			case CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.id:
				
				return CONST_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN.description;
				
		    case CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.id:
				
				return CONST_CAMPAIGN_STATUS.CAMPAIGN_COMPLETED.description;

			case CONST_CAMPAIGN_STATUS.ON_REISSUE.id:
				
				return CONST_CAMPAIGN_STATUS.ON_REISSUE.description;

			case CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.id:
				
				return CONST_CAMPAIGN_STATUS.ON_RE_APPROVAL.description;

			case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.id:
				
				return CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED_AGAIN.description;

			case CONST_CAMPAIGN_STATUS.REAPPROVED.id:
				
				return CONST_CAMPAIGN_STATUS.REAPPROVED.description;





		
			default:
				return "";
		}
	};
}