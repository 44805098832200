<template>
	<div>
		<span class="midia-app-caption-sm">Anunciante *</span>

		<!-- <q-select
			v-model="campaignStore.focusedCampaign.advertiser"
			:options="advertiserOptions"
			label="Selecione um Anunciante"
			filled
			use-input
      use-chips
      input-debounce="0"
      @new-value="createValue"
      @filter="filterFn"
		/> -->
		<q-select
			v-model="campaignStore.focusedCampaign.advertiser"
			maxlength="500"
			use-input
			@filter="filterFN"
			:options="options"
			outlined
			dense
			color="globalAppPrimaryColor"
			bg-color="globalAppDefaultBackgroundColor"
			:readonly="campaignStore.focusedCampaign.iscampaignpublished || campaignStore.focusedCampaign.isapproved || campaignStore.focusedCampaign.ispendingpublication"
      use-chips
      input-debounce="0"
      @new-value="createValue"
			:label-slot="!campaignStore.focusedCampaign.advertiser"
			
		>
			<template v-slot:label>
				<!-- <span class="midia-app-placeholder-md">Informe um Anunciante</span> -->
				<span class="midia-app-placeholder-md">Selecione um Anunciante ou digite e tecle Enter</span>
			</template>

			<template v-slot:selected>
				<span class="midia-app-content-lg">{{ campaignStore.focusedCampaign.advertiser }}</span>
			</template>
		</q-select>
	</div>
</template>

<script setup lang="ts">
import { useCampaignStore } from '@/stores/campaignStore';
import { Ref, ref }					from 'vue';



const props = defineProps({
  propUseInput: {
    type    : Boolean,
    default : false
  }
});

const campaignStore			= useCampaignStore();

const advertiserOptions = [
'Governo Federal',
	'Ministério da Saúde',
	'Sebrae',
	'Ministério da Cidadania',
	'Ministério das Comunicações',
	'SECOM',
	'Detran',
	'GDF',
	'Johnson&Johnson',
	'JBS',
	'Polishop',
	'Grupo Petrópolis',
	'Havaianas',
	'Avon',
	'Qualy',
	'Nivea',
	'Neutrogena',
	'O Boticário',
	'Netflix',
	'EAF'
];

// const advertiserOptions : Ref<string[]>	= ref([
// 	'Governo Federal',
// 	'Ministério da Saúde',
// 	'Sebrae',
// 	'Ministério da Cidadania',
// 	'Ministério das Comunicações',
// 	'SECOM',
// 	'Detran',
// 	'GDF',
// 	'Johnson&Johnson',
// 	'JBS',
// 	'Polishop',
// 	'Grupo Petrópolis',
// 	'Havaianas',
// 	'Avon',
// 	'Qualy',
// 	'Nivea',
// 	'Neutrogena',
// 	'O Boticário',
// 	'Netflix',
// 	'EAF'
// ]);

const options = ref(advertiserOptions);

const filterFN = (val: string, update: (callback: () => void) => void, abort: () => void) => {
	update(() => {
		const needle = val.toLocaleLowerCase();
		options.value = advertiserOptions.filter((v: string) => v.toLocaleLowerCase().indexOf(needle) > -1);
	});
};

const createValue = ((
		val: string,
		done: ref<(
			inputValue: string,
			doneFn: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				item?: any,
				mode?: "add" | "add-unique" | "toggle" | undefined
			) => void
		) => void>
	) => {

	if (val.length > 0) {
		if (!advertiserOptions.includes(val)) {
			advertiserOptions.push(val)
		}
		done(val, 'toggle')
	}
});
</script>

<style scoped>

</style>