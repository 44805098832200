import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row fit no-wrap justify-end",
  style: {"overflow":"auto","gap":"20px"}
}

import BtnCancelFocusedCampaign from "@/components/campaign/actionButtons/BtnCancelFocusedCampaign.vue";
import BtnViewCampaignDistribution from "@/components/campaign/actionButtons/BtnViewCampaignDistribution.vue";
import BtnCancelCreateNewCampaign from "@/components/campaign/actionButtons/BtnCancelCreateNewCampaign.vue";
import BtnEditFocusedCampaign from "@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue";
import BtnSaveFocusedCampaign from "@/components/campaign/actionButtons/BtnSaveFocusedCampaign.vue";
import BtnDeleteSelectedCampaigns from "@/components/campaign/actionButtons/BtnDeleteSelectedCampaigns.vue";
import BtnReviewFocusedCampaign from "@/components/campaign/actionButtons/BtnReviewFocusedCampaign.vue";
import BtnRequestAdjustmentsToFocusedCampaign from "@/components/campaign/actionButtons/BtnRequestAdjustmentsToFocusedCampaign.vue";
import BtnSendFocusedCampaignToApprove from "@/components/campaign/actionButtons/BtnSendFocusedCampaignToApprove.vue";
import BtnResendFocusedCampaignToApprove from "@/components/campaign/actionButtons/BtnResendFocusedCampaignToApprove.vue";
import BtnApproveFocusedCampaign from "@/components/campaign/actionButtons/BtnApproveFocusedCampaign.vue";
import BtnPublishFocusedCampaign from "@/components/campaign/actionButtons/BtnPublishFocusedCampaign.vue";
import BtnBackEdit from "@/components/campaign/actionButtons/BtnBackEdit.vue";
import BtnDiscardChanges from "@/components/campaign/actionButtons/BtnDiscardChanges.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CampaignActionButtonsSection',
  props: {
  propShowBtnCancelFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnViewCampaignDistribution: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnCancelCreateNewCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnEditFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnSaveFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnDeleteSelectedCampaigns: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnReviewFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnRequestAdjustmentsToFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnSendFocusedCampaignToApprove: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnResendFocusedCampaignToApprove: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnApproveFocusedCampaign: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnBackEdit: {
    type: Boolean,
    required: false,
    default: false,
  },

  propShowBtnDiscardChanges: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.propShowBtnBackEdit)
      ? (_openBlock(), _createBlock(BtnBackEdit, { key: 0 }))
      : _createCommentVNode("", true),
    (props.propShowBtnCancelFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnCancelFocusedCampaign, { key: 1 }))
      : _createCommentVNode("", true),
    (props.propShowBtnCancelCreateNewCampaign)
      ? (_openBlock(), _createBlock(BtnCancelCreateNewCampaign, { key: 2 }))
      : _createCommentVNode("", true),
    (props.propShowBtnEditFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnEditFocusedCampaign, { key: 3 }))
      : _createCommentVNode("", true),
    (props.propShowBtnDiscardChanges)
      ? (_openBlock(), _createBlock(BtnDiscardChanges, { key: 4 }))
      : _createCommentVNode("", true),
    (props.propShowBtnSaveFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnSaveFocusedCampaign, { key: 5 }))
      : _createCommentVNode("", true),
    (props.propShowBtnDeleteSelectedCampaigns)
      ? (_openBlock(), _createBlock(BtnDeleteSelectedCampaigns, { key: 6 }))
      : _createCommentVNode("", true),
    (props.propShowBtnReviewFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnReviewFocusedCampaign, { key: 7 }))
      : _createCommentVNode("", true),
    (props.propShowBtnRequestAdjustmentsToFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnRequestAdjustmentsToFocusedCampaign, { key: 8 }))
      : _createCommentVNode("", true),
    (props.propShowBtnSendFocusedCampaignToApprove)
      ? (_openBlock(), _createBlock(BtnSendFocusedCampaignToApprove, { key: 9 }))
      : _createCommentVNode("", true),
    (props.propShowBtnResendFocusedCampaignToApprove)
      ? (_openBlock(), _createBlock(BtnResendFocusedCampaignToApprove, { key: 10 }))
      : _createCommentVNode("", true),
    (props.propShowBtnApproveFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnApproveFocusedCampaign, { key: 11 }))
      : _createCommentVNode("", true),
    (props.propShowBtnApproveFocusedCampaign)
      ? (_openBlock(), _createBlock(BtnPublishFocusedCampaign, { key: 12 }))
      : _createCommentVNode("", true),
    (props.propShowBtnViewCampaignDistribution)
      ? (_openBlock(), _createBlock(BtnViewCampaignDistribution, { key: 13 }))
      : _createCommentVNode("", true)
  ]))
}
}

})