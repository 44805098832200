import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { usePagesUtilStore }  from '@/stores/pagesUtilStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutContent',
  setup(__props) {

const pagesUtilStore = usePagesUtilStore();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, { id: "router-view-root-routes" }),
    _createVNode(_component_q_inner_loading, {
      showing: _unref(pagesUtilStore).showLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_spinner, { size: "12em" })
      ]),
      _: 1
    }, 8, ["showing"])
  ], 64))
}
}

})