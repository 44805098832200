import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useCampaignStore } from "@/stores/campaignStore";
import { usePCStore } from "@/stores/pcStore";
import { useFinancialInstitutionStore } from "@/stores/financialInstitutionStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonCloseModal',
  setup(__props) {

const campaignStore = useCampaignStore();
const pcStore = usePCStore();
const financialInstitutionStore = useFinancialInstitutionStore();
const pagesUtilStore = usePagesUtilStore();

const onClearFilterBtnClick = () => {
  financialInstitutionStore.resetFilterState();
  financialInstitutionStore.resetState();
  pcStore.resetFilterState();
  campaignStore.resetCampaignFilter();

  pagesUtilStore.showModalConfirmationDialogIfs = false;
  pagesUtilStore.showModalConfirmationDialogPcs = false;
  pagesUtilStore.showModalConfirmationEditCampaign = false;
  pagesUtilStore.showModalConfirmationCancelCampaign = false;
  pagesUtilStore.showModalConfirmationDialogSlots = false;
};

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
    id: "btn-close-modal",
    icon: "close",
    size: "12px",
    flat: "",
    onClick: onClearFilterBtnClick,
    round: "",
    dense: ""
  }, null, 512)), [
    [_directive_close_popup]
  ])
}
}

})