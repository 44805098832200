import { createRouter, createWebHistory, RouteRecordName, RouteRecordRaw } from "vue-router";
import PageLogin                                          from "@/views/pages/PageLogin.vue";
import PageHome                                           from "@/views/pages/PageHome.vue";
import PageCampaignHome                                   from '@/views/pages/campaign/PageCampaignHome.vue';
import PageCampaignSearch                                 from '@/views/pages/campaign/PageCampaignSearch.vue';
import PageCampaignNew                                    from "@/views/pages/campaign/PageCampaignNew.vue";
import PageCampaignEdit                                   from "@/views/pages/campaign/PageCampaignEdit.vue";
import PageCampaignView                                   from "@/views/pages/campaign/PageCampaignView.vue";
import PageCampaignReview                                 from "@/views/pages/campaign/PageCampaignReview.vue";
import PageCampaignDistribution                           from "@/views/pages/campaign/PageCampaignDistribution.vue";
import PageMyProfile                                      from "@/views/pages/PageMyProfile.vue";
import PageParameterSlot                                  from "@/views/pages/slots/PageParameterSlot.vue";
import ContainmentButton                                  from "@/views/pages/containment/ContainmentButton.vue";
import AuthUser                                           from "@/views/pages/AuthUser.vue";
import { EnumRouteNames }                                 from "@/enums/enums";
import { usePagesUtilStore }                              from "@/stores/pagesUtilStore";
import { SIDE_MENU_ITEM }                                 from '@/static/sideMenuItems';
import { useAuthStore }                                   from '@/stores/authStore';

const routes: Array<RouteRecordRaw> = [
  {
    path      : "/signin",
    name      : EnumRouteNames.ROOT,
    component : PageLogin,
    meta      : {
      enableLayoutHeader        : false,
      enableLayoutLeftSidePanel : false,
      enableLayoutFooter        : true,
      requiresAuth              : false,
      requiredRoles             : ['']
    }
  },

  {
    path      : "/",
    name      : EnumRouteNames.HOME,
    component : PageHome,
    meta      : {
      enableLayoutHeader        : true,
      enableLayoutLeftSidePanel : true,
      enableLayoutFooter        : true,
      requiresAuth              : true,
      requiredRoles             : ['administrator']
    }
  },

  {
    path      : "/campaign",
    name      : EnumRouteNames.CAMPAIGN_HOME,
    component : PageCampaignHome,
    redirect  : { name: EnumRouteNames.CAMPAIGN_SEARCH },
    children  : [
      {
        path      : "",
        name      : EnumRouteNames.CAMPAIGN_SEARCH,
        component : PageCampaignSearch,
        meta      : {
          enableLayoutHeader        : true,
          enableLayoutLeftSidePanel : true,
          enableLayoutFooter        : true,
          requiresAuth              : true,
          requiredRoles             : ['administrator']
        }    
      },
    
      {
        path      : "new",
        name      : EnumRouteNames.CAMPAIGN_NEW,
        component : PageCampaignNew,
        meta      : {
          enableLayoutHeader        : true,
          enableLayoutLeftSidePanel : true,
          enableLayoutFooter        : true,
          requiresAuth              : true,
          requiredRoles             : ['administrator']
        }
      },
    
      {
        path      : "edit",
        name      : EnumRouteNames.CAMPAIGN_EDIT,
        component : PageCampaignEdit,
        meta      : {
          enableLayoutHeader        : true,
          enableLayoutLeftSidePanel : true,
          enableLayoutFooter        : true,
          requiresAuth              : true,
          requiredRoles             : ['administrator']
        }
      },
    
      {
        path      : "view",
        name      : EnumRouteNames.CAMPAIGN_VIEW,
        component : PageCampaignView,
        meta      : {
          enableLayoutHeader        : true,
          enableLayoutLeftSidePanel : true,
          enableLayoutFooter        : true,
          requiresAuth              : true,
          requiredRoles             : ['administrator']
        }
      },
    
      {
        path      : "review",
        name      : EnumRouteNames.CAMPAIGN_REVIEW,
        component : PageCampaignReview,
        meta      : {
          enableLayoutHeader        : true,
          enableLayoutLeftSidePanel : true,
          enableLayoutFooter        : true,
          requiresAuth              : true,
          requiredRoles             : ['administrator']
        }
      },
        {
    path      : "/campaign-distribution",
    name      : EnumRouteNames.CAMPAIGN_DISTRIBUTION,
    component : PageCampaignDistribution,
    meta      : {
      enableLayoutHeader        : true,
      enableLayoutLeftSidePanel : true,
      enableLayoutFooter        : true,
      requiresAuth              : true,
      requiredRoles             : ['administrator']
    }
  }
    ]
  },

  {
    path      : "/parametrizar",
    name      : EnumRouteNames.PARAMETER_SLOT,
    component : PageParameterSlot,
    meta      : {
      enableLayoutHeader        : true,
      enableLayoutLeftSidePanel : true,
      enableLayoutFooter        : true,
      requiresAuth              : true,
      requiredRoles             : ['administrator']
    }
  },

  {
    path      : "/botao-contencao",
    name      : EnumRouteNames.CONTAINMENT_BUTTON,
    component : ContainmentButton,
    meta      : {
      enableLayoutHeader        : true,
      enableLayoutLeftSidePanel : true,
      enableLayoutFooter        : true,
      requiresAuth              : true,
      requiredRoles             : ['administrator']
    }
  },

  {
    path      : "/myprofile",
    name      : EnumRouteNames.MY_PROFILE,
    component : PageMyProfile,
    meta      : {
      enableLayoutHeader        : true,
      enableLayoutLeftSidePanel : true,
      enableLayoutFooter        : true,
      requiresAuth              : true,
      requiredRoles             : ['administrator']
    }
  },

  {
    path      : "/auth",
    name      : "Auth",
    component : AuthUser,
    meta      : {
      enableLayoutHeader        : true,
      enableLayoutLeftSidePanel : true,
      enableLayoutFooter        : true,
      requiresAuth              : false
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const setHeaderInfo = (toName: RouteRecordName | null | undefined) => {
  const pagesUtilStore = usePagesUtilStore();

  switch (toName) {
    case EnumRouteNames.HOME:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.HOME.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Bem-vindo(a)!",
        "Plataforma Mídia Flow para gerenciamento de inserções publicitárias e institucionais em dispositivos compatíveis."
      );

      break;

    case EnumRouteNames.CAMPAIGN_HOME:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Campanhas",
        "Consulte e gerencie as Campanhas"
      );

      break;

    case EnumRouteNames.CAMPAIGN_SEARCH:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Campanhas",
        "Consulte e gerencie as Campanhas"
      );

      break;

    case EnumRouteNames.CAMPAIGN_NEW:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Campanhas",
        "Consulte e gerencie as Campanhas"
      );

      break;

    case EnumRouteNames.CAMPAIGN_EDIT:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Campanhas",
        "Consulte e gerencie as Campanhas"
      );

      break;

    case EnumRouteNames.CAMPAIGN_VIEW:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Campanhas",
        "Consulte e gerencie as Campanhas"
      );

      break;

    case EnumRouteNames.CAMPAIGN_REVIEW:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Campanhas",
        "Consulte e gerencie as Campanhas"
      );

      break;

    case EnumRouteNames.MY_PROFILE:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.MY_PROFILE.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Meu Perfil",
        ""
      );

      break;
    
    case EnumRouteNames.PARAMETER_SLOT:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.PARAMETER_SLOT.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Parametrizar Slots",
        "Consulte e gerencie os Slots dos Dispositivos"
      );

      break;

    case EnumRouteNames.CONTAINMENT_BUTTON:
    pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CONTAINMENT_BUTTON.id;
    pagesUtilStore.setCurrentPageTitleDescription(
      "Botão de Contenção",
      "Interrompa ou retome a distribuição das campanhas às ATMs"
    );

    break;

    case EnumRouteNames.CAMPAIGN_DISTRIBUTION:
      pagesUtilStore.currentSelectedLeftSidePanelItem = SIDE_MENU_ITEM.CAMPAIGNS.id;
      pagesUtilStore.setCurrentPageTitleDescription(
        "Distribuição da Campanha",
        "Consulte e monitore a Distribuição da Campanha"
      );

      break;

    default:
      break;
  }
};

router.beforeEach((to, from, next) => {
  setHeaderInfo(to.name);

  // let userRoles = [];

  if (to.meta?.requiresAuth) {
    const authStore = useAuthStore();

    if(authStore.isAuth){
      next();
    
    }else{
      authStore.clear();
      next({ name: EnumRouteNames.ROOT });
    }
    // (auth.isAuth) ? next() : next({ name: "root" });
  
  } else {
    next();
  }

  const pagesUtilStore = usePagesUtilStore();

  pagesUtilStore.showLayoutHeader         = Boolean(to?.meta?.enableLayoutHeader);
  pagesUtilStore.showLayoutLeftSidePanel  = Boolean(to?.meta?.enableLayoutLeftSidePanel);
  pagesUtilStore.showLayoutFooter         = Boolean(to?.meta?.enableLayoutFooter);
});

// Function to check if the user has the required roles
function hasRequiredRoles(userRoles: string[], requiredRoles: string[]): boolean {
  return requiredRoles.every(role => userRoles.includes(role));
}

export default router;
