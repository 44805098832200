<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalFilterCampaignDistributionPcs"
      :no-backdrop-dismiss="true"
    >
      <q-card
        class="midia-app-padding-modal"
        style="width: 500px; max-width: 80vw"
      >
        <div>
          <c-modal-title
            propModalTitle="Filtro de  Distribução de Campanha"
            propModalDescription="Pesquise PCs da Distribuição de Campanhas"
          />

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Código do PC</span>
            </div>

            <div class="row col">
              <q-input
                v-model="pcCod"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
                placeholder="Informe o Código do PC"
                input-class="midia-app-content-lg"
                style="width: 100%"
              />
            </div>
          </div>

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Nome do PC</span>
            </div>

            <div class="row col">
              <q-input
                v-model="pcName"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
                placeholder="Informe o Nome do PC"
                input-class="midia-app-content-lg"
                style="width: 100%"
              />
            </div>
          </div>

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Status</span>
            </div>

            <div class="row col">
              <q-select
                v-model="statusFilter"
                :options="statusOptions"
                outlined
                dense
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                style="width: 100%"
                :label-slot="!statusFilter"
              >
                <template v-slot:label>
                  <span
                    class="midia-app-content-lg"
                    style="color: var(--globalAppSecondaryColor2) !important"
                    >Informe o status</span
                  >
                </template>

                <template v-slot:selected>
                  <span class="midia-app-content-lg">{{ statusFilter }}</span>
                </template>
              </q-select>
            </div>
          </div>

          <div class="q-pb-md">
            <div class="row q-pb-xs">
              <span class="midia-app-label-modal-sm">Motivo</span>
            </div>

            <div class="row col">
              <q-select
                v-model="reasonFilter"
                :options="reasonOptions"
                outlined
                dense
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                style="width: 100%"
                :label-slot="!reasonFilter"
              >
                <template v-slot:label>
                  <span
                    class="midia-app-content-lg"
                    style="color: var(--globalAppSecondaryColor2) !important"
                    >Informe o motivo</span
                  >
                </template>

                <template v-slot:selected>
                  <span class="midia-app-content-lg">{{ reasonFilter }}</span>
                </template>
              </q-select>
            </div>
          </div>
          <div class="row fit no-wrap justify-end">
            <c-q-btn
              propBtnLabel="Limpar Filtros"
              :propBtnFunction="clearFilters"
              :propBtnTransparent="true"
              :propBtnSize="EnumButtonSizes.LARGE_1"
              class="q-ml-md"
            >
              <template v-slot:afterLabel>
                <div class="custom-icon-color">
                  <q-icon size="1em" name="backspace" class="q-ml-sm" />
                </div>
              </template>
            </c-q-btn>
            <c-q-btn
              propBtnLabel="Filtrar"
              :propBtnFunction="onFetchBtnClick"
              :propBtnTransparent="false"
              :propBtnSize="EnumButtonSizes.LARGE_1"
              class="q-ml-md"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { EnumButtonSizes } from "@/enums/enums";
import { useCampaignStore } from "@/stores/campaignStore";
import { usePagesUtilStore } from "@/stores/pagesUtilStore";
import { computed, ref, defineEmits } from "vue";

import messages from "@/utils/messages";

const emit = defineEmits(["filteredTable"]);
const campaignStore = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();

const statusOptions = computed(() => {
  return [
    ...new Set(
      campaignStore.campaignDistribtion.pcsCampaign?.map((pc) => pc.status)
    ),
  ];
});

const reasonOptions = computed(() => {
  return [
    ...new Set(
      campaignStore.campaignDistribtion.pcsCampaign?.map((pc) => pc.motivo)
    ),
  ];
});

const pcCod = ref("");
const pcName = ref("");
const statusFilter = ref(null);
const reasonFilter = ref(null);

const onFetchBtnClick = () => {
  try {
    let filteredTable =
      campaignStore?.campaignDistribtion?.pcsCampaign &&
      campaignStore.campaignDistribtion.pcsCampaign.length > 0
        ? campaignStore.campaignDistribtion.pcsCampaign
        : [];

    const filterStatus = statusFilter?.value
      ? (statusFilter.value as string).toLowerCase()
      : "";
    const filterReason = reasonFilter?.value
      ? (reasonFilter.value as string).toLowerCase()
      : "";

    filteredTable = filteredTable.filter(
      (pc) =>
        pc?.pc?.toString().toLowerCase().includes(pcCod.value.toLowerCase()) &&
        pc?.nome?.toLowerCase().includes(pcName.value.toLowerCase()) &&
        pc?.status?.toLowerCase().includes(filterStatus.toLowerCase()) &&
        pc?.motivo?.toLowerCase().includes(filterReason)
    );
    emit("filteredTable", filteredTable);
  } catch (error) {
    console.log(error);
    messages.showErrorMsg(`Erro inesperado: ${error?.message}`);
  } finally {
    clearFilters();
    pagesUtilStore.showModalFilterCampaignDistributionPcs = false;
  }
};

const clearFilters = () => {
  pcCod.value = "";
  pcName.value = "";
  statusFilter.value = null;
  reasonFilter.value = null;
};
</script>

<style scoped>
.custom-icon-color {
  color: var(--globalAppPrimaryColor) !important;
}
</style>
